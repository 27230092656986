// reducers.js
import { SET_DRAWER_STATE } from "../types/actionTypes";

const initialState = {
  openDrawer: false,
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DRAWER_STATE:
      return {
        ...state,
        openDrawer: action.payload,
      };
    default:
      return state;
  }
};

export default drawerReducer;
