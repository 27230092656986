import { Card, CardContent, Stack, Typography } from "@mui/material";

const CurrentPlan = (props) => {
  const { value } = props;

  return (
    <Card sx={{ minHeight: "10rem" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Current Plan
            </Typography>
            <Typography variant="h5">{value}</Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            {!props.trialEnded && (
              <img
                alt="trial-icon"
                src="/img/dashboard/trialIcon.jpeg"
                style={{ width: "5rem" }}
              />
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CurrentPlan;
