// authReducer.js
const initialState = {
  isAuthenticated: false,
  user: null,
  notifications: [],
  loading: false,
  error: null,
  email: "",
  password: "",
  remember: false,
  errorText: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case "SET_USER_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: true,
      };

    case "SET_USER_UNAUTHENTICATED":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "BROADCAST_UPDATE":
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user?.user,
            ...action.payload?.user,
            analytics: {
              ...state.user?.user?.analytics,
              ...action.payload?.user?.analytics,
              additions: {
                ...state.user?.user?.analytics?.additions,
                ...action.payload?.user?.analytics?.additions,
              },
            },
            subuser: {
              ...state.user?.user?.subuser,
              ...action.payload?.user?.subuser,
            },
          },
          tier: {
            ...state.user?.tier,
            ...action.payload?.tier,
          },
        },
      };
    case "GET_NOTIFICATION":
      return {
        ...state,
        notifications: action.payload,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: null,
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: null,
      };
    case "SET_DEF_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_REMEMBER":
      return {
        ...state,
        remember: action.payload,
      };
    case "SET_ERROR_TEXT":
      return {
        ...state,
        errorText: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
