import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";

// import { useClerk } from "@clerk/nextjs";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const Layout = (props) => {
  const { pathname } = useLocation();
  const { children } = props;
  const [openNav, setOpenNav] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const noc_data = useSelector((state) => state.nocData.nocData);

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(
    () => {
      // signOut();
      handlePathnameChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  useEffect(() => {
    if (
      !currentUser ||
      !currentUser.user ||
      !currentUser.nocUserForms ||
      !noc_data
    ) {
      // Data is not available yet, do nothing
      return;
    }
  }, [currentUser, noc_data]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // Set the minimum height of the layout to the full viewport height
        }}
      >
        <Box
          component="header"
          sx={{
            backdropFilter: "blur(6px)",
            backgroundColor: (theme) =>
              alpha(theme.palette.background.default, 0.8),
            position: "sticky", // Make the header sticky
            top: 0, // Stick it at the top
            left: {
              lg: `${SIDE_NAV_WIDTH}px`,
            },
            width: {
              lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
            },
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          <TopNav
            user={currentUser}
            noc_data={noc_data}
            onNavOpen={() => setOpenNav(true)}
          />
        </Box>

        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <SideNav
            user={currentUser}
            onClose={() => setOpenNav(false)}
            open={openNav}
          />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              overflowY: "hidden",
              backgroundColor: "#edf1f5",
            }}
          >
            <LayoutRoot>
              <LayoutContainer sx={{ maxWidth: "100%" }}>
                {children}
              </LayoutContainer>
            </LayoutRoot>
          </Box>
        </Box>
        {/* <div
          style={lgUp ? { marginBottom: "8.7rem" } : { marginBottom: "5.5rem" }}
        /> */}
        <Box
          component="footer"
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#1c2536",
            left: {
              lg: `${SIDE_NAV_WIDTH}px`,
            },
            width: {
              lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
            },
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  );
};
