import { Box, Divider, Typography } from "@mui/material";

const SideNavAd = () => {
  return (
    <>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        sx={{
          px: 2,
          py: 1,
        }}
      >
        <Typography color="neutral.100" align="center" variant="h6">
          Powered By
        </Typography>
        <Typography color="neutral.500" align="center" variant="body2">
          SR360 Solutions
        </Typography>

        <Box
          component="a"
          href="https://www.sr360solutions.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            mx: "auto",
            width: "160px",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img alt="SR360solutions" src="/img/logos/Ball-icon.png" />
        </Box>
        {/* <Button
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowTopRightOnSquareIcon />
            </SvgIcon>
          }
          fullWidth
          sx={{ mt: 2 }}
          variant="contained"
        >
          Start Subscription
        </Button> */}
      </Box>
    </>
  );
};

export default SideNavAd;
