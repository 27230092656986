import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSignIn, useClerk } from "@clerk/clerk-react";
import { loginUser, clearError } from "../../store/actions/authActions";

import Cookies from "js-cookie";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

const SmsFaResetAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.auth.error);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (blocked) {
        navigate("/login");
        clearInterval(timer);
      }
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, [blocked]);

  const goLogin = () => {
    navigate("/login");
  };

  const callAuth = async () => {
    const code = inputValues.join("");
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/sms-reset-pass`,
        {
          code: code,
          token: tokenFromQuery,
        },
        { withCredentials: true }
      );
      if (response.data.success) {
        setLoading(false);
        setPageLoading(false);
        setHidden(true);
        setBlockedText("");
      }
    } catch (e) {
      if (e.response.data.message === "blocked") {
        setBlocked(true);
        if (e.response.data.data == null) {
          setTryLeft(3);
        } else {
          setTryLeft(e.response.data.data);
        }

        setBlockedText(
          "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
        );
      } else if (e.response.data.message === "countDown") {
        setBlockedText(
          `You have only ${e.response.data.data} more attempts left. After that, you will be temporarily blocked from authentication for an hour.`
        );
        setTryLeft(e.response.data.data);
      }

      setInputValues(["", "", "", "", "", ""]);
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/sms/sendAuthResetVerify`,
          {
            token: tokenFromQuery,
          }
        );
        if (tokenResponse.data.success) {
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error.response.data.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error.response.data.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    process_data();
  }, []);

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "20rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/logos/nocLogo.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color="primary"
                variant="h4"
                align="center"
              >
                {hidden
                  ? "Your password has been changed successfully"
                  : "Reset Your Password"}
              </Typography>
              {!hidden && (
                <Typography color="primary" variant="body1" align="center">
                  A text message has already been sent to you. Please enter the
                  code below
                </Typography>
              )}

              <Grid container justifyContent="center" spacing={2}>
                {images.map((image, index) => (
                  <Grid item sm={12} lg={4} key={index}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "transparent",
                        flexDirection: "column",
                        cursor: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box sx={{ pb: 3, pt: 1 }}>
                        <img
                          alt={image.alt}
                          src={image.src}
                          width={
                            selectedImage === index
                              ? image.width * 1.1
                              : image.width
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}

                <>
                  <Grid container>
                    {hidden ? (
                      <Typography></Typography>
                    ) : (
                      <Box sx={{ display: "flex", ml: "auto", mr: "auto" }}>
                        {inputValues.map((value, index) => (
                          <Grid item key={index} sx={{ mx: "6px" }}>
                            <TextField
                              disabled={blocked}
                              id={`input-${index}`}
                              value={value}
                              variant="outlined"
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              onPaste={index === 0 ? handlePaste : undefined}
                              inputProps={{
                                maxLength: 1,
                                style: {
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                },
                              }}
                              sx={{
                                width: "48px",
                                textAlign: "center",
                                border: "3px solid #000",
                                borderRadius: "10px",
                              }}
                            />
                          </Grid>
                        ))}
                      </Box>
                    )}
                  </Grid>

                  <Grid container justifyContent="center" mb={2} mt={5}>
                    <Grid item sm={12} sx={{ width: "100%" }}>
                      <LoadingButton
                        onClick={hidden ? goLogin : callAuth}
                        disabled={blocked}
                        loading={loading}
                        fullWidth
                        size="large"
                        sx={{ mt: 0, p: 2 }}
                        type="submit"
                        variant="contained"
                      >
                        {hidden ? "Back to Login" : "S U B M I T"}
                      </LoadingButton>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2, minHeight: "1.5rem" }}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="error"
                        fontWeight="bold"
                      >
                        {blockedText}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

const images = [
  {
    alt: "authenticator",
    src: "/img/logos/2falogo.png",
    // width: 167,
    width: 250,
  },
];

export default SmsFaResetAuth;
