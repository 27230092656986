import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./store/actions/authActions"; // Import your user action
import SSORedirect from "./components/auth/SSORedirect";
import Home from "./components/pages/Home";
import Subscription from "./components/pages/Subscription";
import Login from "./components/pages/Login";
import SuccessChangePlan from "./components/SuccessChangePlan";
import SuccessCreateExpiredSubscription from "./components/SuccessCreateExpiredSubscription";
import Register from "./components/pages/Register";
import Dashboard from "./components/pages/Dashboard";
import CreateNoc from "./components/pages/CreateNoc";
import Payment from "./components/pages/Payment";
import SelectCounties from "./components/pages/SelectCounties";
import Defaults from "./components/pages/Defaults";
import ProfileSettings from "./components/pages/ProfileSettings";
import CustomerService from "./components/pages/CustomerService";
import Overview from "./components/pages/admin/Overview";
import Management from "./components/pages/admin/Management";
import Messages from "./components/pages/admin/Messages";
import Licenses from "./components/pages/admin/Licenses";
import Tasks from "./components/pages/admin/Tasks";
import { Layout } from "./components/layout/Layout";
import ResetPassword from "./components/pages/ResetPassword";
import { ClerkProvider } from "@clerk/clerk-react";
import Customers from "./components/pages/admin/Customers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { initializeWebSocket, closeWebSocket } from "./utils/websocketService";
import DynamicAuth from "./components/pages/DynamicAuth";
import SubuserFinalize from "./components/pages/SubuserFinalize";
import "./styles/globals.css";
import TotpAuth from "./components/pages/TotpAuth";
import EmailFaAuth from "./components/pages/EmailFaAuth";
import EmailFaAuthPass from "./components/pages/EmailFaAuthPass";
import SmsFaAuth from "./components/pages/SmsFaAuth";

import TotpResetAuth from "./components/pages/TotpResetAuth";
import EmailResetFaAuth from "./components/pages/EmailResetFaAuth";
import EmailFaResetAuthPass from "./components/pages/EmailFaResetAuthPass";
import SmsFaResetAuth from "./components/pages/SmsFaResetAuth";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, CircularProgress } from "@mui/material";
import Subusers from "./components/pages/Subusers";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const App = () => {
  const clerk_pub_key = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  // const [message, setMessage] = useState("");
  const user = useSelector((state) => state.auth);
  // const currentUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Add the beforeunload event listener to close the WebSocket gracefully
  // window.addEventListener("beforeunload", () => {
  //   closeWebSocket();
  // });

  useEffect(() => {
    // console.log(noc_data);
    // Check for the "NocCreator" cookie on app load
    dispatch({ type: "CHECK_TOKEN_COOKIE" });
    dispatch(fetchUserData());

    const checkTokenInterval = setInterval(() => {
      dispatch({ type: "CHECK_TOKEN_COOKIE" });
    }, 2000); // Check every 5 seconds

    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("NocCreator="))
      ?.split("=")[1];

    if (token) {
      initializeWebSocket(token, dispatch);
    } else {
      closeWebSocket(); // Close the WebSocket connection if the cookie is not present
    }

    return () => {
      clearInterval(checkTokenInterval);
      // closeWebSocket(); // Always close the WebSocket connection when the app is unmounted
    };
  }, [dispatch, user.isAuthenticated]); // Define the protected routes as an array of route objects

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClerkProvider
        publishableKey={clerk_pub_key}
        navigate={(to) => navigate(to)}
      >
        <Routes>
          <Route
            path="/"
            element={
              user.isAuthenticated ? <Navigate to="/dashboard" /> : <Home />
            }
          />

          <Route
            path="/dashboard"
            element={
              user.isAuthenticated ? (
                user.user ? (
                  <Layout>
                    <Dashboard />
                  </Layout>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CircularProgress color="warning" size="10rem" />
                  </Box>
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/createnoc"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <CreateNoc />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/selectcounties"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <SelectCounties />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/defaults"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Defaults />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/profilesettings"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <ProfileSettings />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/payment"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Elements stripe={stripePromise}>
                    <Payment />
                  </Elements>
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/subusers"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Subusers />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/subscription"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Subscription />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/contact"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <CustomerService />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* STRIPE */}
          <Route path="/changed-plan" element={<SuccessChangePlan />} />
          <Route
            path="/created-expired-plan"
            element={<SuccessCreateExpiredSubscription />}
          />

          {/* SUBUSER */}
          <Route path="/subuser/finalize" element={<SubuserFinalize />} />

          {/* 2FA */}
          <Route path="/2fa-secure" element={<DynamicAuth />} />
          <Route path="/2fa-secure/totp" element={<TotpAuth />} />
          <Route path="/2fa-secure/sms" element={<SmsFaAuth />} />
          <Route path="/2fa-secure/email" element={<EmailFaAuth />} />
          <Route path="2fa-secure/email/pass" element={<EmailFaAuthPass />} />

          <Route
            path="/2fa-secure/reset-pwd/totp"
            element={<TotpResetAuth />}
          />
          <Route
            path="/2fa-secure/reset-pwd/sms"
            element={<SmsFaResetAuth />}
          />
          <Route
            path="2fa-secure/reset-pwd/email"
            element={<EmailResetFaAuth />}
          />
          <Route
            path="2fa-secure/email/reset/pass"
            element={<EmailFaResetAuthPass />}
          />

          {/* ################ */}
          {/* ADMIN PAGES */}
          <Route
            path="/admin/overview"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Overview />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/admin/customers"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Customers />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/admin/tasks"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Tasks />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/admin/licenses"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Licenses />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/admin/management"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Management />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/admin/messages"
            element={
              user.isAuthenticated ? (
                <Layout>
                  <Messages />
                </Layout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* Register page is accessible only to unauthenticated users */}
          <Route
            path="/register"
            element={
              user.isAuthenticated ? <Navigate to="/dashboard" /> : <Register />
            }
          />

          {/* Login page is accessible only to unauthenticated users */}
          <Route
            path="/login"
            element={
              user.isAuthenticated ? <Navigate to="/dashboard" /> : <Login />
            }
          />
          <Route
            path="/sso_redirect_1saf32mf23mnk23f23"
            element={
              user.isAuthenticated ? (
                <Navigate to="/dashboard" />
              ) : (
                <SSORedirect />
              )
            }
          />

          <Route
            path="/resetpassword"
            element={
              user.isAuthenticated ? (
                <Navigate to="/dashboard" />
              ) : (
                <ResetPassword />
              )
            }
          />

          {/* Catch all other routes */}
          <Route
            path="/*"
            element={
              user.isAuthenticated ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </ClerkProvider>
    </LocalizationProvider>
  );
};

export default App;
