import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import InQueueDrawer from "../noc/InQueueDrawer";
import EnlargeEditForm from "../noc/EnlargeEditForm";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  Grid,
  LinearProgress,
  Tooltip,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  Stack,
  Tabs,
  Badge,
  Tab,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Sort } from "@mui/icons-material";
import EnlargeNotificationForm from "../noc/EnlargeNotificationForm";
import Footer from "../layout/Footer";

export const NotificationPopover = (props) => {
  const theme = useTheme();
  const { anchorEl, onClose, open } = props;
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [sortDirection, setSortDirection] = useState("All");
  const [sortedTasks, setSortedTasks] = useState([]);
  const [inQueueDrawer, setInQueueDrawer] = useState(false);
  const [inQueueMessage, setInQueueMessage] = useState(
    "Calculating queue position..."
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPreName, setSelectedPreName] = useState("");
  const [pdfImg, setPdfImg] = useState([]);
  const [currentTask, setCurrentTask] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [createNocLoading, setCreateNocLoading] = useState(false);
  const [enlargeFormInput, setEnlargeFormInput] = useState(
    currentTask?.original_values
  );

  const currentUser = useSelector((state) => state.auth.user);
  const [isPreview, setIsPreview] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewImg, setPreviewImg] = useState([]);
  const [county, setCounty] = useState("");
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [pdf, setPdf] = useState("");
  const [text, setText] = useState(
    "All other fields will be filled automatically. Some fields are not visible in the preview."
  );
  const [fullRetrieve, setFullRetrieve] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [addressRow, setAddressRow] = useState([]);
  const [cardFlip, setCardFlip] = useState(false);
  const [isRetrievingAddress, setIsRetrievingAddress] = useState(false);
  const [retrieveLoading, setRetrieveLoading] = useState(false);
  const [moreResultType, setMoreResultType] = useState("");
  const [copyInputs, setCopyInputs] = useState();

  const [defaultAddress, setDefaultAddress] = useState({
    street: "",
    number: "",
    unit: "",
    suffix: "",
    zip: "",
    fullAddress: "",
    retrieved: "",
    fullRetrieve: "",
  });
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState();
  const [editFromCreate, setEditFromCreate] = useState(false);
  const [isFormCreated, setIsFormCreated] = useState(false);
  const [newRequest, setNewRequest] = useState(false);
  const [newRequestID, setNewRequestID] = useState("");
  const [moreResultsText, setMoreResultsText] = useState("");

  const [licenseType, setLicenseType] = useState("");
  const [copySelected, setCopySelected] = useState({});
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseText, setLicenseText] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0);
  const [disableSelect, setDisableSelect] = useState(false);

  const [notifyValue, setNotifyValue] = useState(0);

  const handleChange = (event, newValue) => {
    setNotifyValue(newValue);
  };

  const handleLicenseClick = async (contractor) => {
    navigate("/profilesettings", {
      state: { option: contractor?.licenseType },
    });

    props.onClose();

    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/remove-license-notification`,
      { licenseType: contractor?.licenseType },
      { withCredentials: true }
    );
  };

  const handleDotStyle = (notificationStatus) => {
    const colorMap = {
      "More results": "#8B4513",
      "In Queue": "#0a4e78",
      "In Progress": "#e9c603",
      Failed: "#e84034",
      Completed: "#10b981",
    };

    return colorMap[notificationStatus] || "#f4f4f4"; // Default color if not found
  };

  const updateNotification = async (task_id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification`,
      { task_id: task_id },
      { withCredentials: true }
    );
  };

  useEffect(() => {
    if (copySelected?.licenseStatus === "not_found") {
      setLicenseText("Invalid License Number");
      setInvalid(true);
      setRetrieveLoading(true);
    } else if (copySelected?.licenseStatus === "") {
      setLicenseText("Pre-Verified License Number");
      setInvalid(false);
      setRetrieveLoading(false);
    } else if (copySelected?.licenseStatus === "waiting") {
      setLicenseText("Waiting for Validation");
      setInvalid(false);
      setRetrieveLoading(false);
    } else {
      setLicenseText("Verified License Number");
      setInvalid(false);
      setRetrieveLoading(false);
    }
  }, [copySelected]);

  useEffect(() => {
    if (newRequest === true) {
      const targetTask = currentUser?.nocUserTasks.find(
        (task) => task.task_id === newRequestID
      );
      if (targetTask) {
        if (targetTask.status === "completed") {
          if (targetTask.success === "more") {
            if (openDrawer && !inQueueDrawer) {
              setDefaultAddress({
                ...defaultAddress,
                retrieved: !targetTask.original_values.allRetrieve
                  ? String(targetTask.listPerPage)
                  : targetTask.return_values.length,
                fullRetrieve: !targetTask.original_values.allRetrieve
                  ? String(targetTask.numberOfLists)
                  : targetTask.return_values.length,
              });

              if (fullRetrieve) {
                setIsRetrievingAddress(false);
                setRetrieveLoading(false);
              } else if (targetTask.listPages === 1 && fullRetrieve) {
                setIsRetrievingAddress(true);
              } else {
                setIsRetrievingAddress(false);
              }
              setAddressRow(targetTask.return_values);
              setCardFlip(true);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setText(targetTask.message);
              if (fullRetrieve) {
                setMoreResultsText("Addresses are retrieved successfully!");
              }
            }
            setNewRequest(false);
            setDisableSelect(false);
            setNewRequestID("");
          } else if (targetTask.success === true) {
            if (openDrawer && !inQueueDrawer) {
              console.log(targetTask.return_values.createdForm);
              setCopyInputs(targetTask.return_values.createdForm);
              setText(targetTask.message);
              setPreview(true);
              setIsReadyToDownload(true);
              setPreviewImg(targetTask.return_values.jpg);
              setPdf(targetTask.return_values.pdf);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setEditFromCreate(true);
              setDisableSelect(true);
              setIsFormCreated(true);
            }
            setNewRequest(false);
            setNewRequestID("");
          } else if (targetTask.success === false) {
            if (openDrawer && !inQueueDrawer) {
              setText(targetTask.message);
              updateNotification(targetTask.task_id);
              setCreateNocLoading(false);
              setPreviewLoading(false);
              setDisableSelect(false);
            }
            setNewRequest(false);
            setNewRequestID("");
          }
        }
      }
    }
  }, [currentUser, newRequest, inQueueDrawer]);

  useEffect(() => {}, [selectedRow]);

  useEffect(() => {
    console.log("Fired");
    let filteredAndSortedTasks = [];

    if (sortDirection === "All") {
      filteredAndSortedTasks = props?.user?.nocUserTasks;
    } else {
      filteredAndSortedTasks = props?.user?.nocUserTasks.filter(
        (task) => task.notificationStatus === sortDirection
      );
    }

    // Update the state with the filtered and sorted tasks
    setSortedTasks(filteredAndSortedTasks);
  }, [sortDirection, props?.user?.nocUserTasks]);

  useEffect(() => {
    if (currentTask && inQueueDrawer) {
      setInQueueMessage((prevMessage) => {
        return `Your queue position is ${
          currentTask.new_position -
          parseInt(process.env.REACT_APP_QUEUE_SIZE, 10)
        }`;
      });
    }
  }, [currentTask, inQueueDrawer]);

  const handleSortClick = () => {
    // Toggle the sorting direction when the button is clicked

    if (sortDirection === "All") {
      setSortDirection("In Progress");
    } else if (sortDirection === "In Progress") {
      setSortDirection("More results");
    } else if (sortDirection === "More results") {
      setSortDirection("Completed");
    } else if (sortDirection === "Completed") {
      setSortDirection("In Queue");
    } else if (sortDirection === "In Queue") {
      setSortDirection("Failed");
    } else if (sortDirection === "Failed") {
      setSortDirection("All");
    }
  };
  const submitMenuItem = async (task) => {
    setCurrentTask(task);
    setDisableSelect(false);
    const licenseTypeToFind = task?.original_values?.licenseType;

    // Assuming props.user.user.contractors is your array of objects
    const contractors = props?.user?.user.contractors;

    // Find the index of the object with matching licenseType
    const index = contractors.findIndex(
      (contractor) => contractor.licenseType === licenseTypeToFind
    );
    console.log(props?.user?.user.contractors[index].licenseStatus);
    setCopySelected(props?.user?.user.contractors[index]);
    setSelectedLicenseIndex(index);
    setLicenseType(props?.user?.user.contractors[index].licenseType);
    setLicenseNumber(props?.user?.user.contractors[index].licenseNumber);
    if (props?.user?.user.contractors[index].licenseStatus === "not_found") {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
    // OPEN IN QUEUE DRAWER ///
    if (
      task.notificationStatus === "More results" &&
      currentUser.user.selectedCountiesNOC.some(
        (county) => county.name === task.original_values.county
      )
    ) {
      setSelectedRow(task);
      setRetrieveLoading(false);
      setOpenDrawer(true);
      setEditFromCreate(false);
      setIsReadyToDownload(false);
      setPreview(false);
      setPdfImg(task.original_values.img);
      setSelectedPreName(task?.original_values?.county);
      setCardFlip(true);
      props.onClose();
      setDefaultAddress({
        ...defaultAddress,
        retrieved: !task.original_values.allRetrieve
          ? String(task.listPerPage)
          : task.return_values.length,
        fullRetrieve: !task.original_values.allRetrieve
          ? String(task.numberOfLists)
          : task.return_values.length,
      });

      if (task?.listPages === 1) {
        setIsRetrievingAddress(true);
      } else {
        setIsRetrievingAddress(false);
        setMoreResultType("notification");
        setCurrentTask((prevTask) => ({
          ...prevTask,
          original_values: {
            ...prevTask.original_values,
            allRetrieve: true,
          },
        }));
      }
      setAddressRow(task?.return_values);
      setCardFlip(true);
      setPreviewLoading(false);
      setCreateNocLoading(false);
      setText(task?.message);
    } else if (
      task.notificationStatus === "Failed" &&
      currentUser.user.selectedCountiesNOC.some(
        (county) => county.name === task.original_values.county
      )
    ) {
      setSelectedRow(task);
      setText(task.message);
      setOpenDrawer(true);
      setAddressRow([]);
      setIsRetrievingAddress(false);
      setRetrieveLoading(false);
      setEditFromCreate(false);
      setIsReadyToDownload(false);
      setPreview(false);
      setPdfImg(task.original_values.img);
      setSelectedPreName(task?.original_values?.county);
      setCardFlip(false);
      props.onClose();
      setDefaultAddress({
        ...defaultAddress,
        retrieved: !task.original_values.allRetrieve
          ? String(task.listPerPage)
          : task.return_values.length,
        fullRetrieve: !task.original_values.allRetrieve
          ? String(task.numberOfLists)
          : task.return_values.length,
      });
    } else if (task.notificationStatus === "Completed") {
      setSelectedRow(task);
      if (task.new) {
        updateNotification(task.task_id);
      }
      setDrawerOpen(true);
      setEditFromCreate(true);
      setIsReadyToDownload(true);
      setPreview(false);
      setPdfImg(task.original_values.img);
      setSelectedPreName(task?.original_values?.county);
      setCardFlip(false);
      props.onClose();
    }
    //////////////////

    if (
      (task.notificationStatus === "More results" && task.new === true) ||
      (task.notificationStatus === "Completed" && task.new === true) ||
      (task.notificationStatus === "Failed" && task.new === true)
    )
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification`,
        { task_id: task.task_id },
        { withCredentials: true }
      );
  };

  const formik = useFormik({
    initialValues: {
      streetNumber: currentTask?.original_values?.streetNumber,
      unitNumber: currentTask?.original_values?.unitNumber,
      streetName: currentTask?.original_values?.streetName,
      zip: currentTask?.original_values?.zip,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      streetNumber: Yup.number()
        .typeError("Must be a number")
        .required("Street Number is required"),
      unitNumber: Yup.string(),
      streetName: Yup.string().required("Street Name is required"),
      zip: Yup.string().matches(/^\d{5}([-]?\d{4})?$/, "Invalid ZIP Code"),
    }),
    onSubmit: async (values, helpers) => {
      // const formImg =
      values["_id"] = String(currentUser.user._id);
      values["allRetrieve"] = false;
      values["county"] = county;
      values["customerId"] = currentUser.user.customerId;
      values["email"] = currentUser?.subuser
        ? currentUser?.subuser?.email
        : currentUser.user.email;
      values["contractorName"] =
        currentUser.user.contractors[selectedLicenseIndex].name;
      values["contractorAddress"] =
        currentUser.user.contractors[selectedLicenseIndex].address;
      values["contractorPhone"] =
        currentUser.user.contractors[selectedLicenseIndex].phone;
      values["contractorFax"] =
        currentUser.user.contractors[selectedLicenseIndex].fax;
      values["contractorEmail"] =
        currentUser.user.contractors[selectedLicenseIndex].email;
      values["licenseNumber"] =
        currentUser.user.contractors[selectedLicenseIndex].licenseNumber;
      values["licenseType"] =
        currentUser.user.contractors[selectedLicenseIndex].licenseType;
      values["licenseStatus"] =
        currentUser.user.contractors[selectedLicenseIndex].licenseStatus;
      values["img"] = currentTask?.original_values.img;
      // values["img"] = selectedPreForm;
      setDefaultValues(values);
      if (isPreview) {
        if (preview && !isMd) {
          setPreview(false);
        } else {
          setText("Please wait while we create the preview.");
          setPreviewLoading(true);
          setCreateNocLoading(true);
          setDisableSelect(true);
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/createPreview`,
              {
                values,
              },
              { withCredentials: true }
            );

            setPreviewImg(resp.data.jpg);
            setText("Preview has been created successfully");
            setIsPreview(false);
            setPreview(true);
            if (isMd) {
              setFormDrawerOpen(true);
            }
            setPreviewLoading(false);
            setCreateNocLoading(false);
            setDisableSelect(false);
          } catch (err) {
            setText(err.message);
            setIsPreview(false);
            setPreviewLoading(false);
            setCreateNocLoading(false);
            setDisableSelect(false);
          }
        }
      } else if (editFromCreate) {
        try {
          setErrorText("Please wait. Editing in progress...");
          setEditLoading(true);
          setPreviewLoading(true);
          setDisableSelect(true);
          // formCreated;
          console.log(copyInputs);
          if (!copyInputs) {
            values["pdfUrl"] = selectedRow?.return_values?.createdForm?.pdfUrl;
            values["pdfImage"] =
              selectedRow?.return_values?.createdForm?.pdfImage;
            values["pdfImage2"] =
              selectedRow?.return_values?.createdForm?.pdfImage2;
            values["county"] =
              selectedRow?.return_values?.createdForm?.inputs.county;
            values["customerId"] =
              selectedRow?.return_values?.createdForm?.inputs.customerId;
            values["id"] = selectedRow?.return_values?.createdForm.id;
            values["contractorName"] =
              selectedRow?.return_values?.createdForm?.inputs.contractorName;
            values["contractorAddress"] =
              selectedRow?.return_values?.createdForm?.inputs.contractorAddress;
            values["contractorPhone"] =
              selectedRow?.return_values?.createdForm?.inputs.contractorPhone;
            values["contractorFax"] =
              selectedRow?.return_values?.createdForm?.inputs.contractorFax;
            values["contractorEmail"] =
              selectedRow?.return_values?.createdForm?.inputs.contractorEmail;
            values["legalDescription"] =
              selectedRow?.return_values?.createdForm?.inputs.legalDescription;
            values["primaryAddress"] =
              selectedRow?.return_values?.createdForm?.inputs.primaryAddress;
            values["owner"] =
              selectedRow?.return_values?.createdForm?.inputs.owner;
            values["ownerAddress"] =
              selectedRow.creturn_values?.reatedForm?.inputs.ownerAddress;
            if (
              selectedRow?.return_values?.createdForm?.inputs.county !== "Duval"
            ) {
              values["taxFolio"] =
                selectedRow?.return_values?.createdForm?.inputs.taxFolio;
            }
          } else {
            values["pdfUrl"] = copyInputs?.pdfUrl;
            values["pdfImage"] = copyInputs?.pdfImage;
            values["pdfImage2"] = copyInputs?.pdfImage2;
            values["county"] = copyInputs?.inputs.county;
            values["customerId"] = copyInputs?.inputs.customerId;
            values["id"] = copyInputs?.id;
            values["contractorName"] = copyInputs?.inputs.contractorName;
            values["contractorAddress"] = copyInputs?.inputs.contractorAddress;
            values["contractorPhone"] = copyInputs?.inputs.contractorPhone;
            values["contractorFax"] = copyInputs?.inputs.contractorFax;
            values["contractorEmail"] = copyInputs?.inputs.contractorEmail;
            values["legalDescription"] = copyInputs?.inputs.legalDescription;
            values["primaryAddress"] = copyInputs?.inputs.primaryAddress;
            values["owner"] = copyInputs?.inputs.owner;
            values["ownerAddress"] = copyInputs.inputs.ownerAddress;
            if (copyInputs?.inputs.county !== "Duval") {
              values["taxFolio"] = copyInputs?.inputs.taxFolio;
            }
          }

          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/editPDF`,
            {
              values,
            },
            { withCredentials: true }
          );

          setCopyInputs(resp.data.data);

          setDisableEdit(true);
          setEditLoading(false);
          setPreviewLoading(false);
          setDisableSelect(true);
          setPreview(true);
          setPreviewImg(resp.data.jpg);
          setPdf(resp.data.pdf);

          ///////////////////////////////////////////////////////////////////////////////////

          setErrorText("Form edited successfully");
        } catch (e) {
          console.log(e);
          setEditLoading(true);
          setPreviewLoading(true);
          setDisableSelect(false);
        }
      } else {
        try {
          setCreateNocLoading(true);
          setPreviewLoading(true);
          setDisableSelect(true);
          setText("Calculating queue position...");

          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/createNoc`,
            {
              values,
            },
            { withCredentials: true }
          );

          if (resp.data.queuePosition <= 0) {
            setNewRequestID(resp.data.task_id);
            setNewRequest(true);
            setText(
              "The data is being downloaded and a PDF file is being created. Please wait, this may take some time."
            );
          } else {
            setInQueueMessage(resp.data.message);
            setInQueueDrawer(true);
          }
        } catch (err) {
          console.log(err);
          setText(err.message);
          setCreateNocLoading(false);
          setPreviewLoading(false);
          setDisableSelect(false);
        }
      }
    },
  });

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left", // Adjusted to "right" instead of "left"
          vertical: "bottom",
        }}
        // transformOrigin={{
        //   horizontal: "right", // Adjusted to "right" instead of "left"
        //   vertical: "top",
        // }}
        onClose={onClose}
        open={open}
      >
        <Box
          sx={
            isMd
              ? {
                  display: "flex",
                  width: "100%",
                  py: 1.5,
                  px: 2,
                }
              : { display: "flex", width: "25rem", py: 1.5, px: 2 }
          }
        >
          {props?.user?.subuser ? (
            <>
              <Box sx={{ flex: 2 }}>
                <Stack>
                  <Typography mt={1} variant="h6">
                    NOC Tracker
                  </Typography>

                  <Typography
                    sx={{ visibility: notifyValue !== 0 && "hidden" }}
                    variant="overline"
                  >
                    ({sortDirection})
                  </Typography>
                </Stack>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ flex: 2 }}>
                <Stack>
                  <Tabs
                    value={notifyValue}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="NOC Tracker" />
                    <Tab label="License Notifications" />
                  </Tabs>

                  <Typography
                    sx={{ visibility: notifyValue !== 0 && "hidden" }}
                    variant="overline"
                  >
                    ({sortDirection})
                  </Typography>
                </Stack>
              </Box>
            </>
          )}

          <Box sx={isMd ? { marginRight: 2 } : { marginRight: 0 }}>
            <Tooltip
              title={sortDirection}
              sx={{ visibility: notifyValue !== 0 && "hidden" }}
            >
              <IconButton onClick={handleSortClick}>
                <Sort
                  color="inherit"
                  style={{
                    transform: `rotate(${
                      sortDirection === "asc" ? "0deg" : "180deg"
                    })`,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider />
        {notifyValue === 0 ? (
          <>
            <MenuList
              disablePadding
              dense
              sx={
                isMd
                  ? {
                      width: "100vw",
                      p: "8px",
                      maxHeight: "20rem",
                      overflow: "auto",
                      "& > *": {
                        borderRadius: 1,
                      },
                    }
                  : {
                      width: "25rem",
                      p: "8px",
                      maxHeight: "20rem",
                      overflow: "auto",
                      "& > *": {
                        borderRadius: 1,
                      },
                    }
              }
            >
              {sortedTasks?.map((task, index) => (
                <MenuItem
                  sx={isMd ? { mr: "2rem" } : { mr: 0 }}
                  key={index}
                  onClick={() => {
                    submitMenuItem(task);
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      {new Date(task.date).toLocaleDateString("en-US")}{" "}
                      {new Date(task.date).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "America/New_York",
                      })}
                    </Grid>

                    <Grid item xs={6} sx={{ ml: "auto" }}>
                      {task?.new && (
                        <div
                          style={{
                            marginTop: "2px",
                            marginLeft: "auto",
                            minWidth: "0.8rem",
                            width: "0.8rem", // Set width
                            height: "0.8rem", // Set height
                            borderRadius: "50%", // To make it a circle
                            backgroundColor: handleDotStyle(
                              task?.notificationStatus
                            ),
                          }}
                        ></div>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" fontWeight="bold">
                        {task?.original_values.streetNumber}{" "}
                        {task?.original_values.unitNumber}{" "}
                        {task?.original_values.streetName}{" "}
                        {task?.original_values.zip}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <LinearProgress
                        variant="determinate"
                        value={task.progressBar || 0} // Set the progress value here
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {task.notificationStatus === "Queue Position"
                        ? task.notificationStatus +
                          ": " +
                          String(
                            parseInt(task.new_position, 10) -
                              parseInt(process.env.REACT_APP_QUEUE_SIZE, 10)
                          )
                        : task.notificationStatus}
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" align="right">
                        {task.original_values.county}
                      </Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </MenuList>
          </>
        ) : (
          <MenuList
            disablePadding
            dense
            sx={
              isMd
                ? {
                    width: "100vw",
                    p: "8px",
                    maxHeight: "20rem",
                    overflow: "auto",
                    "& > *": {
                      borderRadius: 1,
                    },
                  }
                : {
                    width: "25rem",
                    p: "8px",
                    maxHeight: "20rem",
                    overflow: "auto",
                    "& > *": {
                      borderRadius: 1,
                    },
                  }
            }
          >
            {props?.user?.user?.contractors?.map((task, index) => (
              <MenuItem
                sx={isMd ? { mr: "2rem" } : { mr: 0 }}
                key={index}
                onClick={() => {
                  handleLicenseClick(task);
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    {new Date(task.date * 1000).toLocaleDateString("en-US")}{" "}
                    {new Date(task.date * 1000).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: "America/New_York",
                    })}
                  </Grid>

                  <Grid item xs={6} sx={{ ml: "auto" }}>
                    {task?.new && (
                      <div
                        style={{
                          marginTop: "2px",
                          marginLeft: "auto",
                          minWidth: "0.8rem",
                          width: "0.8rem", // Set width
                          height: "0.8rem", // Set height
                          borderRadius: "50%", // To make it a circle
                          backgroundColor: (() => {
                            if (
                              task?.valid === false &&
                              task?.process_status === "completed"
                            ) {
                              return "#e84034";
                            } else if (task?.process_status === "queued") {
                              return "#0a4e78";
                            } else if (
                              task?.valid === true &&
                              task?.process_status === "completed"
                            ) {
                              return "#10b981";
                            } else {
                              return "#8B4513";
                            }
                          })(),
                        }}
                      ></div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" fontWeight="bold">
                      {task?.licenseType}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <LinearProgress
                      variant="determinate"
                      value={task.progressBar || 0} // Set the progress value here
                      sx={{ mt: 1 }}
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    {task?.process_status === "queued"
                      ? "In Progress"
                      : task?.process_status === "completed" &&
                        task?.valid === false
                      ? "Failed"
                      : "Success"}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {task?.licenseNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Popover>
      {/* IN QUEUEDRAWER */}
      <InQueueDrawer
        inQueueDrawer={inQueueDrawer}
        setInQueueDrawer={setInQueueDrawer}
        inQueueMessage={inQueueMessage}
        isMd={isMd}
      />
      {/* NOTIFICATION DRAWER */}
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <EnlargeNotificationForm
          setCopyInputs={setCopyInputs}
          setDisableSelect={setDisableSelect}
          disableSelect={disableSelect}
          currentUser={props?.user}
          setSelectedLicenseIndex={setSelectedLicenseIndex}
          invalid={invalid}
          setLicenseNumber={setLicenseNumber}
          licenseNumber={licenseNumber}
          licenseText={licenseText}
          copySelected={copySelected}
          setCopySelected={setCopySelected}
          licenseType={licenseType}
          setLicenseType={setLicenseType}
          moreResultType={moreResultType}
          currentTask={currentTask}
          moreResultsText={moreResultsText}
          setNewRequestID={setNewRequestID}
          setNewRequest={setNewRequest}
          setInQueueMessage={setInQueueMessage}
          setInQueueDrawer={setInQueueDrawer}
          setFullRetrieve={setFullRetrieve}
          fullRetrieve={fullRetrieve}
          selectedPreName={selectedPreName}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          editFromCreate={editFromCreate}
          setRetrieveLoading={setRetrieveLoading}
          isRetrievingAddress={isRetrievingAddress}
          setIsRetrievingAddress={setIsRetrievingAddress}
          retrieveLoading={retrieveLoading}
          defaultAddress={defaultAddress}
          setCardFlip={setCardFlip}
          cardFlip={cardFlip}
          addressRow={addressRow}
          setAddressRow={setAddressRow}
          setSelectedRow={setSelectedRow}
          text={text}
          pdf={pdf}
          isReadyToDownload={isReadyToDownload}
          pdfImg={pdfImg}
          createNocLoading={createNocLoading}
          setCounty={setCounty}
          previewLoading={previewLoading}
          setIsPreview={setIsPreview}
          formik={formik}
          enlargeType="createNOC"
          enlargeHeader="Create PDF"
          enlargeNote="To create your PDF, please enter the values below."
          enlargeFormInput={enlargeFormInput}
          setEnlargeFormInput={setEnlargeFormInput}
          setOpenDrawer={setOpenDrawer}
          preview={preview}
          setPreview={setPreview}
          previewImg={previewImg}
          defaultValues={defaultValues}
          setCreateNocLoading={setCreateNocLoading}
          setPreviewLoading={setPreviewLoading}
          setDefaultAddress={setDefaultAddress}
          isFormCreated={isFormCreated}
          inputs={selectedRow?.original_values}
          type="moreResults"
        />
        <div style={{ marginTop: "auto" }}></div>
        <Footer />
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <EnlargeEditForm
          setCopyInputs={setCopyInputs}
          licenseType={currentTask?.original_values?.licenseType}
          licenseNumber={currentTask?.original_values?.licenseNumber}
          licenseStatus={currentTask?.original_values?.licenseStatus}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          pdf={pdf}
          disableEdit={disableEdit}
          previewImg={previewImg}
          errorText={errorText}
          editLoading={editLoading}
          previewLoading={previewLoading}
          preview={preview}
          formik={formik}
          type="edit"
          pdfImg={selectedRow?.return_values?.createdForm?.pdfImage}
          pdfImg2={selectedRow?.return_values?.createdForm?.pdfImage2}
          inputs={selectedRow?.return_values?.createdForm?.inputs}
          enlargeType="edit"
          selectedPreName={selectedRow?.return_values?.createdForm?.county}
          selectedPreForm="/assets/images/nocForms/NOC-empty.jpg"
          enlargeHeader="Edit PDF Form"
          enlargeNote=""
          setOpenDrawer={setDrawerOpen}
          setSelectedRow={setSelectedRow}
          addressRow=""
          cardFlip=""
        />
        <div style={{ marginTop: "auto" }}>
          <Footer />
        </div>
      </Drawer>
    </>
  );
};
