import InputField from "../../inputComponents/InputField";
import { useFormikContext } from "formik";
import { useEffect } from "react";

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import SelectField from "../../inputComponents/SelectField";

const ChangePlanContractor = ({
  licenseText,
  setLicenseText,
  contractorList,
  setContractorList,
  additionalData,
  customLicenseType,
  setCustomLicenseType,
  page,
  setPage,
  buttons,
  setButtons,
  setDisableInputs,
  disableInputs,
  validLicense,
  setValidLicense,
  oldPlanType,
  plan,
  fetchOldPlanData,
  userEmail,
}) => {
  const theme = useTheme();
  const {
    values,
    isValid,
    setErrors,
    setTouched,
    setFieldValue,
    setValues,
    setFieldError,
    setFieldTouched,
  } = useFormikContext();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLicenseNumberValid =
    values.licenseNumber && values.licenseNumber.length > 0;
  const contractorNameValid =
    values.contractorName && values.contractorName.length > 0;
  const contractorAddressValid =
    values.contractorAddress && values.contractorAddress.length > 0;
  const contractorPhoneValid =
    values.contractorPhone && values.contractorPhone.length > 0;

  useEffect(() => {
    if (
      values.licenseNumber !== "" &&
      contractorList[page].validLicense === false
    ) {
      setContractorList((prevList) => {
        const newList = [...prevList];
        newList[page].validLicense = true;
        return newList;
      });
    }
  }, [values.licenseNumber, contractorList]);

  const handleCopyValues = () => {
    // Assuming you have a function to get the previous page
    const getPreviousPage = () => (page > 0 ? page - 1 : 0);
    const previousPage = getPreviousPage();

    // Assuming your contractorList structure is an array of objects
    const previousContractor = contractorList[previousPage];
    const currentContractor = contractorList[page];

    if (previousContractor) {
      if (currentContractor) {
        if (!currentContractor.contractorName) {
          values["contractorName"] = previousContractor.contractorName;
        }
        if (!currentContractor.contractorAddress) {
          values["contractorAddress"] = previousContractor.contractorAddress;
        }
        if (!currentContractor.contractorPhone) {
          values["contractorPhone"] = previousContractor.contractorPhone;
        }
        if (!currentContractor.contractorFax) {
          values["contractorFax"] = previousContractor.contractorFax;
        }
        if (!currentContractor.contractorEmail) {
          values["contractorEmail"] = previousContractor.contractorEmail;
        }
      } else {
        // If currentContractor is empty] = set all values from previousContractor
        values["contractorName"] = previousContractor.contractorName;
        values["contractorAddress"] = previousContractor.contractorAddress;

        values["contractorPhone"] = previousContractor.contractorPhone;
        values["contractorFax"] = previousContractor.contractorFax;
        values["contractorEmail"] = previousContractor.contractorEmail;
      }
    }

    setTouched({});
  };

  const handleAddButtonClick = () => {
    setLicenseText("");
    setDisableInputs(true);
    let formObject = {
      licenseType: values.licenseType,
      licenseNumber: values.licenseNumber,
      contractorName: values.contractorName,
      contractorAddress: values.contractorAddress,
      contractorPhone: values.contractorPhone,
      contractorFax: values.contractorFax,
      contractorEmail: values.contractorEmail,
      validLicense: contractorList[page].validLicense,
    };

    // Create a copy of the current contractorList
    const updatedList = [...contractorList];

    // If the current page (index) is within the range of the array
    if (page >= 0 && page < updatedList.length) {
      // Update the item at the specified index
      updatedList[page] = formObject;

      // Update the state with the new array
      setContractorList(updatedList);
    } else {
      // If the page is out of range, add the new item to the end of the array
      setContractorList((prevList) => [...prevList, formObject]);
    }

    setFieldValue("licenseType", "");
    setFieldValue("licenseNumber", "");
    setFieldValue("contractorName", "");
    setFieldValue("contractorAddress", "");
    setFieldValue("contractorPhone", "");
    setFieldValue("contractorFax", "");
    setFieldValue("contractorEmail", "");

    // Add an empty object to the end of the array
    setContractorList((prevList) => [
      ...prevList,
      {
        licenseType: "",
        licenseNumber: "",
        contractorName: "",
        contractorAddress: "",
        contractorPhone: "",
        contractorFax: "",
        contractorEmail: "",
        validLicense: true,
      },
    ]);

    // Update the state with the new button added
    setButtons([...buttons, "New Contractor"]);
    setPage(buttons.length);
  };

  const handleRemoveButtonClick = () => {
    setDisableInputs(false);

    if (contractorList.length === 1 && validLicense) {
      values["licenseType"] = "";
      values["licenseNumber"] = "";
      values["contractorName"] = "";
      values["contractorAddress"] = "";
      values["contractorPhone"] = "";
      values["contractorFax"] = "";
      values["contractorEmail"] = "";
      setDisableInputs(true);

      setContractorList((prevList) => [
        {
          licenseType: "",
          licenseNumber: "",
          contractorName: "",
          contractorAddress: "",
          contractorPhone: "",
          contractorFax: "",
          contractorEmail: "",
          validLicense: true,
        },
      ]);
      setButtons(["New Contractor"]);
    } else {
      // Check if the page index is within the valid range
      if (page >= 0 && page < contractorList.length) {
        // Update the contractorList to match the updatedButtons
        const updatedContractorList = [...contractorList];
        updatedContractorList.splice(page, 1);
        setContractorList(updatedContractorList);

        // Create a copy of the current buttons state
        const updatedButtons = [...buttons];
        // Remove the item at the specified index (page)
        updatedButtons.splice(page, 1);
        // Update the state with the new buttons list
        setButtons(updatedButtons);

        // If the removed tab was the last one, update the page to the previous one
        setPage(0);
        setErrors({});

        // Set Formik values based on the updated contractorList
        const selectedContractor = updatedContractorList[0];
        values["licenseType"] = selectedContractor.licenseType;
        values["licenseNumber"] = selectedContractor.licenseNumber;
        values["contractorName"] = selectedContractor.contractorName;
        values["contractorAddress"] = selectedContractor.contractorAddress;
        values["contractorPhone"] = selectedContractor.contractorPhone;
        values["contractorFax"] = selectedContractor.contractorFax;
        values["contractorEmail"] = selectedContractor.contractorEmail;
      }
    }
  };

  const handleButtonClick = (event, newValue) => {
    setLicenseText("");

    const selectedContractor = contractorList[page];
    if (selectedContractor) {
      const updatedContractor = {
        ...selectedContractor,
        licenseType: values.licenseType,
        licenseNumber: values.licenseNumber,
        contractorName: values.contractorName,
        contractorAddress: values.contractorAddress,
        contractorPhone: values.contractorPhone,
        contractorFax: values.contractorFax,
        contractorEmail: values.contractorEmail,
      };

      // Update the contractorList with the updatedContractor
      setContractorList((prevList) => {
        const newList = [...prevList];
        newList[page] = updatedContractor;
        return newList;
      });
    }

    // Clear formik errors

    setPage(newValue);

    // Set the form fields based on the selected contractorList values
    const updatedContractor = contractorList[newValue];
    if (updatedContractor) {
      setValues({
        licenseType: updatedContractor.licenseType,
        licenseNumber: updatedContractor.licenseNumber,
        contractorName: updatedContractor.contractorName,
        contractorAddress: updatedContractor.contractorAddress,
        contractorPhone: updatedContractor.contractorPhone,
        contractorFax: updatedContractor.contractorFax,
        contractorEmail: updatedContractor.contractorEmail,
      });

      if (contractorList[newValue].validLicense === false) {
        setFieldError("licenseNumber", "License number already exists.");
        setFieldTouched("licenseNumber", true);
        setFieldValue("licenseNumber", "");
      }
    } else {
      const emptyValues = {
        licenseType: "",
        licenseNumber: "",
        contractorName: "",
        contractorAddress: "",
        contractorPhone: "",
        contractorFax: "",
        contractorEmail: "",
      };
      setValues(emptyValues);
    }
    if (contractorList[newValue].validLicense === true) {
      setTouched({});
      setErrors({}); // Clear all errors
    }
  };

  const renderAddButton = () => {
    let planType = "";

    if (plan.type === undefined) {
      if (contractorList.length > 1) {
        planType = "multi";
      } else {
        planType = "single";
      }
    } else {
      planType = plan.type;
    }

    if (oldPlanType === "single" && planType === "multi") {
      if (
        !isValid ||
        !isLicenseNumberValid ||
        !contractorNameValid ||
        !contractorAddressValid ||
        !contractorPhoneValid ||
        contractorList.length > 4
      ) {
        return (
          <Tooltip title="Add Contractor" arrow>
            <IconButton
              disabled={
                !isValid ||
                !isLicenseNumberValid ||
                !contractorNameValid ||
                !contractorAddressValid ||
                !contractorPhoneValid ||
                contractorList.length > 4
              }
              color="primary"
              aria-label="add contractor"
              sx={{ ml: 1 }}
              onClick={handleAddButtonClick}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <IconButton
            disabled={
              !isValid ||
              !isLicenseNumberValid ||
              !contractorNameValid ||
              !contractorAddressValid ||
              !contractorPhoneValid ||
              contractorList.length > 4
            }
            color="primary"
            aria-label="add contractor"
            sx={{ ml: 1 }}
            onClick={handleAddButtonClick}
          >
            <AddIcon />
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };

  const renderCopyButton = () => {
    let planType = "";

    if (plan.type === undefined) {
      if (contractorList.length > 1) {
        planType = "multi";
      } else {
        planType = "single";
      }
    } else {
      planType = plan.type;
    }

    if (
      (oldPlanType === "single" && planType === "multi") ||
      (oldPlanType === "multi" && planType === "multi")
    ) {
      if (buttons.length > 1) {
        return (
          <Tooltip title="Copy Previous Values" arrow>
            <IconButton
              // disabled={buttons.length === 1}
              color="warning"
              aria-label="copy previous values"
              sx={{ ml: 2 }}
              onClick={handleCopyValues}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <IconButton
            disabled={buttons.length === 1}
            color="warning"
            aria-label="copy previous values"
            sx={{ ml: 2 }}
            onClick={handleCopyValues}
          >
            <FileCopyIcon />
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          py: 1,
          backgroundColor: "#f3f3f3",
          height: "3.3rem",
        }}
      >
        <Tabs
          value={page}
          onChange={handleButtonClick}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ mb: "-1rem" }}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {buttons.map((buttonKey, index) => (
            <Tab
              disabled={
                !isValid ||
                !isLicenseNumberValid ||
                !contractorNameValid ||
                !contractorAddressValid ||
                !contractorPhoneValid
              }
              key={index}
              label={buttonKey}
              sx={
                !contractorList[index]?.validLicense
                  ? {
                      minWidth: "7rem",
                      ml: "24px",
                      textTransform: "none", // Prevent text transformation
                      borderBottom: "2px solid transparent", // Add a border to simulate a
                      color: "#F04438",
                      border: "3px solid #F04438",
                      borderRadius: "12px",
                      padding: "12px", // Add padding for better click target
                      backgroundColor:
                        index === page ? "error.main" : "transparent",
                      "&.Mui-selected": {
                        borderBottom: "none", // Change color on selection
                        color: "#ffffff",
                      },
                    }
                  : {
                      minWidth: "7rem",
                      ml: "24px",
                      textTransform: "none", // Prevent text transformation
                      borderBottom: "2px solid transparent", // Add a border to simulate a button
                      border: "3px solid #ddd",
                      borderRadius: "12px",
                      padding: "12px", // Add padding for better click target
                      backgroundColor:
                        index === page ? "primary.main" : "transparent",
                      "&.Mui-selected": {
                        borderBottom: "none", // Change color on selection
                        color: "#ffffff",
                      },
                    }
              }
            />
          ))}
        </Tabs>

        {renderAddButton()}

        {buttons.length > 1 ? (
          <Tooltip title="Remove Contractor" arrow>
            <IconButton
              // disabled={contractorList.length === 1 && !validLicense}
              color="error"
              aria-label="remove contractor"
              sx={{ ml: 2 }}
              onClick={handleRemoveButtonClick}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          // You can optionally render a disabled IconButton without Tooltip here
          <IconButton
            disabled={contractorList.length === 1 && !validLicense}
            color="error"
            aria-label="remove contractor"
            sx={{ ml: 2 }}
            onClick={handleRemoveButtonClick}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {renderCopyButton()}
        <Tooltip title="Reset Contractors" arrow>
          <IconButton
            color="primary"
            aria-label="reset contractor"
            sx={{ ml: 2 }}
            onClick={() => {
              fetchOldPlanData(values, setTouched, setErrors);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Typography
        align="center"
        sx={
          isSm
            ? { color: "#F2CD00", fontWeight: "bold", mt: 3, fontSize: "10px" }
            : { color: "#F2CD00", fontWeight: "bold", mt: 1 }
        }
        variant="subtitle1"
      >
        Please be careful while entering this information and double check your
        entries. This is the contractor information that will be incorporated in
        all the Notices of Commencement (NOC) you will file with all counties.
        You will only be allowed to change this one time after you have
        registered. No other changes will be permitted
      </Typography>
      <Grid container spacing={2} sx={{ mt: "1rem", minHeight: "52.5vh" }}>
        <Grid item xs={6}>
          <SelectField
            setLicenseText={setLicenseText}
            validLicense={validLicense}
            setContractorList={setContractorList}
            contractorList={contractorList}
            setDisableInputs={setDisableInputs}
            buttons={buttons}
            setButtons={setButtons}
            page={page}
            setCustomLicenseType={setCustomLicenseType}
            name="licenseType"
            label="License Type"
            menu={additionalData?.license_types}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            setLicenseText={setLicenseText}
            licenseText={licenseText}
            setDisableInputs={setDisableInputs}
            disableInputs={disableInputs}
            contractorList={contractorList}
            setContractorList={setContractorList}
            page={page}
            validLicense={validLicense}
            buttons={buttons}
            setButtons={setButtons}
            customLicenseType={customLicenseType}
            name="licenseNumber"
            label="License Number*"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disableInputs={disableInputs}
            name="contractorName"
            label="Contractor Name*"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disableInputs={disableInputs}
            name="contractorAddress"
            label="Contractor Address*"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disableInputs={disableInputs}
            name="contractorPhone"
            label="Phone Number*"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disableInputs={disableInputs}
            name="contractorFax"
            label="Fax Number"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disableInputs={disableInputs}
            name="contractorEmail"
            label="Email Address"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePlanContractor;
