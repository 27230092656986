import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Card,
  Typography,
  Drawer,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material/styles";

const InQueueDrawer = ({
  inQueueDrawer,
  setInQueueDrawer,
  inQueueMessage,
  isMd,
}) => {
  const navigate = useNavigate();
  return (
    <Drawer
      PaperProps={
        isMd
          ? {
              sx: {
                pt: 2,

                width: "100vw",
              },
            }
          : {
              sx: {
                backdropFilter: "blur(5px)",
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.3),
                width: "100vw",
              },
            }
      }
      anchor="right"
      open={inQueueDrawer}
      onClose={() => setInQueueDrawer(false)}
    >
      <Box
        sx={
          isMd
            ? {}
            : {
                borderRadius: "5rem",
                margin: "5rem",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)",
              }
        }
      >
        <Card
          sx={
            isMd
              ? { px: 2, pb: 5 }
              : {
                  borderRadius: "5rem",
                  backgroundColor: "#fff",
                  p: 5,
                  height: "70vh",
                }
          }
        >
          <Stack direction="row" justifyContent="flex-end" sx={{ mb: 3 }}>
            <Box sx={{ mr: "auto", ml: "auto" }}>
              <img
                alt="logo"
                style={{ width: "20rem" }}
                src="/img/logos/nocLogo.png"
              />
            </Box>
            <IconButton
              onClick={() => {
                navigate("/dashboard");
                setInQueueDrawer(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            ;{" "}
          </Stack>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h4" align="center">
                The creation of the Notice of Commencement (NOC) PDF has begun
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                Please wait as the PDF file is being created. You can monitor
                the progress in the notification bar, or find the completed NOC
                in your dashboard once it's ready.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="body1"
                fontWeight="bold"
                align="center"
                sx={{ mt: 2 }}
              >
                {inQueueMessage}
              </Typography>
            </Grid>
            <Divider
              sx={{
                my: 3,
                width: "80%",
                ml: "auto",
                mr: "auto",
                borderTop: "2px solid #ccc",
              }}
            />

            <Grid
              item
              md={12}
              sx={isMd ? { ml: "auto", mr: "auto" } : { textAlign: "center" }}
            >
              <img
                src="/img/logos/Ball-icon.png"
                alt="logo"
                style={{ width: "15rem", height: "auto" }}
              />
            </Grid>
            <Grid
              item
              component="a"
              href="https://www.sr360solutions.com"
              target="_blank"
              rel="noopener noreferrer"
              container
              alignItems="center" // Vertically center-align the content
              justifyContent="center" // Horizontally center-align the content
              md={12}
              sx={isMd ? { textAlign: "center" } : {}}
            >
              <Typography
                variant="h5"
                sx={{
                  mt: 2,
                  textDecoration: "underline",
                  color: "#0b4875",
                  cursor: "pointer",
                }}
              >
                The ultimate ALL-INCLUSIVE business solution every contractor
                NEEDS
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Drawer>
  );
};

export default InQueueDrawer;
