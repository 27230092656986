import {
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";

const UpdateTasks = ({
  counties,
  bulkLoading,
  setBulkLoading,
  bulkDisabled,
  setBulkDisabled,
  waitingConfirmation,
  setWaitingConfirmation,
}) => {
  const [errorText, setErrorText] = useState("");
  const [retrievedBulkData, setRetrievedBulkData] = useState([]);
  const [regenerationData, setRegenerationData] = useState({
    fromDate: null,
    toDate: null,
    email: "",
    scrapingFault: "",
    county: [],
    status: [],
  });

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (errorText !== "") {
      const intervalId = setInterval(() => {
        // Your code to run at the interval
        setErrorText("");
      }, 3000);

      // Clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }

    if (
      regenerationData.fromDate !== null ||
      regenerationData.toDate !== null ||
      regenerationData.email !== "" ||
      regenerationData.scrapingFault !== "" ||
      regenerationData.county.length !== 0 ||
      regenerationData.status.length !== 0
    ) {
      setBulkDisabled(false);
    } else {
      setBulkDisabled(true);
    }
  }, [errorText, regenerationData]); // The emp

  const renderSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Define the maximum length you want to display
    const maxLength = 30; // For example, limit to 20 characters

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const cancelBulkGeneration = () => {
    setRetrievedBulkData([]);
    setWaitingConfirmation(false);
    // setRegenerationData({
    //   fromDate: null,
    //   toDate: null,
    //   email: "",
    //   scrapingFault: "",
    //   county: [],
    //   status: [],
    // });
  };

  const submitFinalBulkGeneration = async () => {
    setBulkLoading(true);
    setBulkDisabled(true);
    setWaitingConfirmation(false);
    try {
      const values = retrievedBulkData;

      const finalResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/createNoc`,
        {
          values,
        },
        { withCredentials: true }
      );

      setErrorText(finalResponse.data.message);
      setRegenerationData({
        fromDate: null,
        toDate: null,
        email: "",
        scrapingFault: "",
        county: [],
        status: [],
      });

      setBulkLoading(false);
      setBulkDisabled(true);
      setRetrievedBulkData([]);
    } catch (e) {
      console.log(e);
      setBulkLoading(false);
      setBulkDisabled(false);
    }
  };

  const submitBulkGeneration = async () => {
    setBulkLoading(true);
    setBulkDisabled(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/bulk-generation`,
        { regenerationData },
        { withCredentials: true }
      );
      if (resp.data.success === true) {
        setRetrievedBulkData(resp.data.data);
        setWaitingConfirmation(true);
      } else {
        setErrorText(resp.data.message);
        setRegenerationData({
          fromDate: null,
          toDate: null,
          email: "",
          scrapingFault: "",
          county: [],
          status: [],
        });
      }

      setBulkLoading(false);
      setBulkDisabled(true);
    } catch (e) {
      console.log(e);
      setBulkLoading(false);
      setBulkDisabled(false);
    }
  };

  return (
    <Card>
      <CardHeader title="Bulk NOC Regeneration Manager" sx={{}} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={12} lg={6}>
            <FormControl fullWidth variant="filled">
              <InputLabel
                id="InputLabel"
                sx={{
                  maxWidth: "90%", // Limit the label's width
                  whiteSpace: "nowrap", // Prevent label from wrapping
                  overflow: "hidden",
                  textOverflow: "ellipsis", // Add ellipsis for overflow
                }}
              >
                Select Counties
              </InputLabel>
              <Select
                labelId="countySelect"
                id="countyId"
                label="County"
                multiple={true}
                value={
                  Array.isArray(regenerationData.county)
                    ? regenerationData.county
                    : []
                }
                onChange={(e) =>
                  setRegenerationData((prevData) => ({
                    ...prevData,
                    county: e.target.value,
                  }))
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "1.5px",
                      maxHeight: 150, // Set the desired max height for the dropdown menu
                      width: "auto",
                      maxWidth: "100%",
                    },
                  },
                }}
                sx={{
                  maxWidth: "100%", // Set the desired max-width for the input element
                  "& .MuiTypography-body2": {
                    whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                  },
                }}
                renderValue={renderSelectedValues}
              >
                {counties?.map(function (item, i) {
                  const truncatedItem = truncateText(item, 30); // Truncate item if too long
                  return (
                    <MenuItem key={i} value={item}>
                      {item.length > 30 ? (
                        <Typography variant="body2">{truncatedItem}</Typography>
                      ) : (
                        <Typography variant="body2">{item}</Typography>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} lg={6}>
            <FormControl fullWidth variant="filled">
              <InputLabel
                id="InputLabel"
                sx={{
                  maxWidth: "90%", // Limit the label's width
                  whiteSpace: "nowrap", // Prevent label from wrapping
                  overflow: "hidden",
                  textOverflow: "ellipsis", // Add ellipsis for overflow
                }}
              >
                Select Status
              </InputLabel>
              <Select
                labelId="statusSelect"
                id="statusId"
                label="status"
                multiple={true}
                value={
                  Array.isArray(regenerationData.status)
                    ? regenerationData.status
                    : []
                }
                onChange={(e) =>
                  setRegenerationData((prevData) => ({
                    ...prevData,
                    status: e.target.value,
                  }))
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "1.5px",
                      maxHeight: 150, // Set the desired max height for the dropdown menu
                      width: "auto",
                      maxWidth: "100%",
                    },
                  },
                }}
                sx={{
                  maxWidth: "100%", // Set the desired max-width for the input element
                  "& .MuiTypography-body2": {
                    whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                  },
                }}
                renderValue={renderSelectedValues}
              >
                {["Completed", "More results", "Failed"].map(function (
                  item,
                  i
                ) {
                  const truncatedItem = truncateText(item, 30); // Truncate item if too long
                  return (
                    <MenuItem key={i} value={item}>
                      {item.length > 30 ? (
                        <Typography variant="body2">{truncatedItem}</Typography>
                      ) : (
                        <Typography variant="body2">{item}</Typography>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} lg={6}>
            <FormControl fullWidth variant="filled">
              <InputLabel
                id="InputLabel"
                sx={{
                  maxWidth: "90%", // Limit the label's width
                  whiteSpace: "nowrap", // Prevent label from wrapping
                  overflow: "hidden",
                  textOverflow: "ellipsis", // Add ellipsis for overflow
                }}
              >
                Is Scraping Fault?
              </InputLabel>
              <Select
                labelId="scrapingFaultSelect"
                id="scrapingFaultId"
                label="scrapingFault"
                multiple={false}
                value={regenerationData.scrapingFault}
                onChange={(e) =>
                  setRegenerationData((prevData) => ({
                    ...prevData,
                    scrapingFault: e.target.value,
                  }))
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "1.5px",
                      maxHeight: 150, // Set the desired max height for the dropdown menu
                      width: "auto",
                      maxWidth: "100%",
                    },
                  },
                }}
                sx={{
                  maxWidth: "100%", // Set the desired max-width for the input element
                  "& .MuiTypography-body2": {
                    whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                  },
                }}
                renderValue={renderSelectedValues}
              >
                {["Yes", "No"].map(function (item, i) {
                  const truncatedItem = truncateText(item, 30); // Truncate item if too long
                  return (
                    <MenuItem key={i} value={item}>
                      {item.length > 30 ? (
                        <Typography variant="body2">{truncatedItem}</Typography>
                      ) : (
                        <Typography variant="body2">{item}</Typography>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={regenerationData.email}
              onChange={(e) =>
                setRegenerationData((prevData) => ({
                  ...prevData,
                  email: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <DatePicker
              sx={{ width: "100%" }}
              label="From Date"
              value={regenerationData.fromDate}
              onChange={(val) =>
                setRegenerationData((prevData) => ({
                  ...prevData,
                  fromDate: val,
                }))
              }
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <DatePicker
              sx={{ width: "100%" }}
              label="To Date"
              value={regenerationData.toDate}
              onChange={(val) =>
                setRegenerationData((prevData) => ({
                  ...prevData,
                  toDate: val,
                }))
              }
            />
          </Grid>
        </Grid>
        {waitingConfirmation ? (
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Typography
              variant="body1"
              color="primary"
              fontWeight="bold"
              textAlign="center"
              sx={{ mt: 0.8, mb: 1, minHeight: "1.5rem", fontWeight: "bold" }}
            >
              You are about to regenerate{" "}
              <span
                style={{
                  color: "#800000",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {retrievedBulkData.length}
              </span>{" "}
              NOC records. <br /> Are you sure?
            </Typography>
            <Grid container spacing={1}>
              <Grid item sm={12} lg={6}>
                <LoadingButton
                  loading={bulkLoading}
                  disabled={bulkLoading}
                  fullWidth
                  onClick={cancelBulkGeneration}
                  size="large"
                  sx={{ mt: 0 }}
                  color="error"
                  variant="contained"
                >
                  CANCEL
                </LoadingButton>
              </Grid>
              <Grid item sm={12} lg={6}>
                <LoadingButton
                  loading={bulkLoading}
                  disabled={bulkLoading}
                  fullWidth
                  onClick={submitFinalBulkGeneration}
                  size="large"
                  sx={{ mt: 0 }}
                  variant="contained"
                  color="success"
                >
                  PROCEED
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Typography
              variant="body1"
              color="#F2CD00"
              fontWeight="bold"
              textAlign="center"
              sx={{ mt: 0.8, mb: 1, minHeight: "1.5rem" }}
            >
              {errorText}
            </Typography>
            <LoadingButton
              loading={bulkLoading}
              disabled={bulkDisabled}
              fullWidth
              onClick={submitBulkGeneration}
              size="large"
              sx={{ mt: 0 }}
              variant="contained"
            >
              Start Bulk Generation
            </LoadingButton>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UpdateTasks;
