import { useAuth, useClerk } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "axios";
import { CircularProgress, Box, Typography } from "@mui/material";

export default function SSORedirect() {
  const isCookieSet = Cookies.get("NocCreator") !== undefined;
  const { isLoaded, userId } = useAuth();
  const navigate = useNavigate();

  const { signOut } = useClerk();

  useEffect(() => {
    const handleSSO = async () => {
      if (userId) {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/clerk/get-user-data`,
          {
            userId,
          },
          { withCredentials: true }
        );

        // Wait for a certain duration
        await new Promise((resolve) => setTimeout(resolve, 4000)); // Replace 1000 with the desired duration in milliseconds

        if (resp.data.success && isCookieSet) {
          navigate("/dashboard");
          signOut();
        } else {
          navigate("/login");
          signOut();
        }
      }
    };

    if (isLoaded) {
      handleSSO();
    }
  }, [isLoaded]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100&",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h2" my={5} color="primary">
        Redirecting...
      </Typography>
      <CircularProgress color="warning" size="10rem" />
    </Box>
  );
}
