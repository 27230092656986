import React, { useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { items, adminItems } from "./side-nav-items";
import { SideNavItem } from "./side-nav-item";

import SideNavAd from "./side-nav-ad";

const SideNav = (props) => {
  const { open, onClose } = props;
  const pathname = window.location.pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [hideTrial, setHideTrial] = useState(false);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
            display: "flex",
            textDecoration: "none",
            borderRadius: "27px",
            p: "12px",
          }}
        >
          <div>
            <img
              style={{
                width: "10rem",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-3px",
                marginBottom: "-13px",
              }}
              alt="logo"
              src="/img/logos/nocLogo.png"
            />
          </div>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          {items.map((item) => {
            const active = item.path ? pathname === item.path : false;

            return (
              <SideNavItem
                active={active}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.path}
                title={item.title}
              />
            );
          })}

          {props?.user?.user?.role === "admin" &&
            adminItems.map((item) => {
              const active = item.path ? pathname === item.path : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
        </Stack>
      </Box>
      {!hideTrial && <SideNavAd setHideTrial={setHideTrial} />}
      <Typography
        color="neutral.500"
        sx={{ textAlign: "center" }}
        variant="body2"
      >
        2023, All Rights Reserved
      </Typography>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default SideNav;
