import {
  Card,
  CardHeader,
  CardContent,
  Drawer,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import EnlargeEditForm from "../noc/EnlargeEditForm";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { PencilIcon } from "@heroicons/react/24/outline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import SendIcon from "@mui/icons-material/Send";
import Footer from "../layout/Footer";
import EnlargeSendPdf from "../noc/EnlargeSendPdf";

const CreatedForms = ({ title, createdFormRow, fromSub }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [sendFormDrawer, setSendFormDrawer] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [previewImg, setPreviewImg] = useState([]);
  const [pdf, setPdf] = useState();

  const sendFormFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().min(3).required("Name is required"),
      email: Yup.string().email("Must be a valid email").required(),
      message: Yup.string(),
    }),

    onSubmit: async (values, helpers) => {
      try {
        setEditLoading(true);
        setPreviewLoading(true);
        setErrorText("Please wait");
        values["pdfUrl"] = selectedRow.pdfUrl;
        values["pdfImage"] = selectedRow.pdfImage;
        values["pdfImage2"] = selectedRow.pdfImage2;
        values["county"] = selectedRow.county;
        values["customerId"] = selectedRow.inputs.customerId;
        values["senderEmail"] = selectedRow.inputs.email;
        values["id"] = selectedRow.id;
        values["contractorName"] = selectedRow.inputs.contractorName;
        values["contractorAddress"] = selectedRow.inputs.contractorAddress;
        values["contractorPhone"] = selectedRow.inputs.contractorPhone;
        values["contractorFax"] = selectedRow.inputs.contractorFax;
        values["contractorEmail"] = selectedRow.inputs.contractorEmail;
        values["legalDescription"] = selectedRow.inputs.legalDescription;
        values["primaryAddress"] = selectedRow.inputs.primaryAddress;
        values["owner"] = selectedRow.inputs.owner;
        values["ownerAddress"] = selectedRow.inputs.ownerAddress;
        if (values["county"] !== "Duval") {
          values["taxFolio"] = selectedRow.inputs.taxFolio;
        }
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/email/sendPDF`,
          {
            values,
          },
          { withCredentials: true }
        );

        setPreviewLoading(false);
        setDisableEdit(true);
        setEditLoading(false);
        helpers.resetForm();
        // setPreview(true);
        // setPreviewImg(resp.data.jpg);
        // setPdf(resp.data.pdf);
        setErrorText("You have successfully sent your PDF form");
      } catch (err) {
        setErrorText(err.message);
        setEditLoading(false);
      }

      formik.resetForm();
    },
  });

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    // preventDefault: true,

    onSubmit: async (values, helpers) => {
      if (!Object.keys(values).length) {
        setErrorText("Editing input values is required before proceeding");
      } else {
        try {
          setEditLoading(true);
          setPreviewLoading(true);
          setErrorText("Please wait. Editing in progress");
          values["pdfUrl"] = selectedRow.pdfUrl;
          // values["img"] = currentTask?.original_values.img;
          values["pdfImage"] = selectedRow.pdfImage;
          values["pdfImage2"] = selectedRow.pdfImage2;
          values["county"] = selectedRow.county;
          values["customerId"] = selectedRow.inputs.customerId;
          values["email"] = selectedRow.inputs.email;
          values["id"] = selectedRow.id;
          values["contractorName"] = selectedRow.inputs.contractorName;
          values["contractorAddress"] = selectedRow.inputs.contractorAddress;
          values["contractorPhone"] = selectedRow.inputs.contractorPhone;
          values["contractorFax"] = selectedRow.inputs.contractorFax;
          values["contractorEmail"] = selectedRow.inputs.contractorEmail;
          values["legalDescription"] = selectedRow.inputs.legalDescription;
          values["primaryAddress"] = selectedRow.inputs.primaryAddress;
          values["owner"] = selectedRow.inputs.owner;
          values["ownerAddress"] = selectedRow.inputs.ownerAddress;
          if (values["county"] !== "Duval") {
            values["taxFolio"] = selectedRow.inputs.taxFolio;
          }
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/editPDF`,
            {
              values,
            },
            { withCredentials: true }
          );
          const respData = resp.data.data;
          setSelectedRow((prevSelectedRow) => ({
            ...prevSelectedRow,
            ...respData,
            pdfUrl: respData.pdfUrl,
            pdfImage: respData.pdfImage,
            pdfImage2: respData.pdfImage2,
            county: respData.county,
            customerId: respData.inputs.customerId,
            email: respData.inputs.email,
            id: respData.id,
            contractorName: respData.inputs.contractorName,
            contractorAddress: respData.inputs.contractorAddress,
            contractorPhone: respData.inputs.contractorPhone,
            contractorFax: respData.inputs.contractorFax,
            contractorEmail: respData.inputs.contractorEmail,
            legalDescription: respData.inputs.legalDescription,
            primaryAddress: respData.inputs.primaryAddress,
            owner: respData.inputs.owner,
            ownerAddress: respData.inputs.ownerAddress,
            ...(respData.county !== "Duval"
              ? { taxFolio: respData.inputs.taxFolio }
              : {}),
          }));
          setPreviewLoading(false);
          setDisableEdit(true);
          setEditLoading(false);
          setPreview(true);
          setPreviewImg(resp.data.jpg);
          setPdf(resp.data.pdf);
          setErrorText("You have successfully edited your form");
        } catch (err) {
          setErrorText(err.message);
          setEditLoading(false);
        }
      }
      // formik.resetForm();
    },
  });

  const handleOpenSendFormDrawer = (params) => {
    setSendFormDrawer(true);
    setPreview(false);
    setDisableEdit(false);
    setErrorText("Please fill out the required fields");
    setSelectedRow(params.row);
    setPdf(params.row.pdfUrl);
  };

  const handleOpenDrawer = (params) => {
    setDrawerOpen(true);
    setPreview(false);
    setDisableEdit(false);
    setErrorText("You can edit, then download your NOC form");
    setSelectedRow(params.row);
    setPdf(params.row.pdfUrl);
  };

  const handleCloseDrawer = () => {
    setSelectedRow(null);
    setDrawerOpen(false);
  };

  const handleCloseSendFormDrawer = (params) => {
    setSelectedRow(null);
    setSendFormDrawer(false);
  };

  useEffect(() => {}, [selectedRow]);

  const createdFormColumns = [
    { field: "id", headerName: "ID", width: 130 },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
    },
    {
      field: "ownersName",
      headerName: "Owner's Name",
      width: 250,
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      editable: false,
    },
    {
      field: "county",
      headerName: "County",
      width: 110,
      editable: false,
    },
    {
      field: "download",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Download PDF" arrow>
              <IconButton
                href={params.row.pdfUrl}
                target="_blank"
                variant="contained"
                color="primary"
              >
                <DocumentArrowDownIcon width={25} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        if (fromSub) {
          return (
            <div>
              <Tooltip title="View Form" arrow>
                <IconButton
                  variant="contained"
                  color="warning"
                  onClick={() => handleOpenDrawer(params)}
                >
                  <VisibilityIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Edit PDF" arrow>
                <IconButton
                  variant="contained"
                  color="warning"
                  onClick={() => handleOpenDrawer(params)}
                >
                  <PencilIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: "send",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        if (!fromSub) {
          return (
            <div>
              <Tooltip title="Send PDF" arrow>
                <IconButton
                  variant="contained"
                  color="warning"
                  onClick={() => handleOpenSendFormDrawer(params)}
                >
                  <SendIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    // {
    //   field: "upload",
    //   headerName: "",
    //   type: "actions",
    //   width: 50,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Tooltip title="Upload PDF" arrow>
    //           <IconButton
    //             variant="contained"
    //             color="primary"
    //             // onClick={() => handleOpenDrawer(params)}
    //           >
    //             <CloudUploadIcon width={25} />
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <Card sx={{ position: "relative" }}>
        {!fromSub && (
          <Tooltip
            title="Explore your NOC form creation history specific to your user profile. This data reflects forms created by you and is not representative of forms created by other users within the organization."
            placement="top"
          >
            <HelpOutlineIcon
              color="primary"
              sx={{ position: "absolute", right: 10, top: 10 }}
            />
          </Tooltip>
        )}

        <CardHeader title={title} />
        <CardContent>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={createdFormRow}
            columns={createdFormColumns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={drawerOpen}
        onClose={handleCloseDrawer}
      >
        <EnlargeEditForm
          fromSub={fromSub}
          licenseNumber={selectedRow?.inputs.licenseNumber}
          licenseType={selectedRow?.inputs.licenseType}
          licenseStatus={selectedRow?.inputs.licenseStatus}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          pdf={pdf}
          disableEdit={disableEdit}
          previewImg={previewImg}
          errorText={errorText}
          editLoading={editLoading}
          previewLoading={previewLoading}
          preview={preview}
          formik={formik}
          type="edit"
          pdfImg={selectedRow?.pdfImage}
          pdfImg2={selectedRow?.pdfImage2}
          inputs={selectedRow?.inputs}
          enlargeType="edit"
          selectedPreName={selectedRow?.county}
          selectedPreForm="/assets/images/nocForms/NOC-empty.jpg"
          enlargeHeader="Edit PDF Form"
          enlargeNote=""
          setOpenDrawer={setDrawerOpen}
          setSelectedRow={setSelectedRow}
          addressRow=""
          cardFlip=""
        />
        <div style={{ marginTop: "auto" }}>
          <Footer />
        </div>
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={sendFormDrawer}
        onClose={handleCloseSendFormDrawer}
      >
        <EnlargeSendPdf
          sendFormFormik={sendFormFormik}
          setSendFormDrawer={setSendFormDrawer}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          pdf={pdf}
          disableEdit={disableEdit}
          previewImg={previewImg}
          errorText={errorText}
          editLoading={editLoading}
          previewLoading={previewLoading}
          preview={preview}
          formik={formik}
          type="edit"
          pdfImg={selectedRow?.pdfImage}
          pdfImg2={selectedRow?.pdfImage2}
          inputs={selectedRow?.inputs}
          enlargeType="edit"
          selectedPreName={selectedRow?.county}
          selectedPreForm="/assets/images/nocForms/NOC-empty.jpg"
          enlargeHeader="Send NOC PDF Form"
          enlargeNote=""
          setOpenDrawer={setDrawerOpen}
          setSelectedRow={setSelectedRow}
          addressRow=""
          cardFlip=""
        />
        <div style={{ marginTop: "auto" }}>
          <Footer />
        </div>
      </Drawer>
    </>
  );
};

export default CreatedForms;
