import {
  Grid,
  Button,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Pagination,
  Drawer,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import moment from "moment-timezone";
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";
import EnlargeCountyForm from "./EnlargeCountyForm";
import { useEffect } from "react";
import Footer from "../layout/Footer";
import PeopleIcon from "@mui/icons-material/People";

const SelectCountyForm = ({
  setOpenDefaultModal,
  setDisableSelect,
  disableSelect,
  setSelectedLicenseIndex,
  invalid,
  licenseNumber,
  setLicenseNumber,
  licenseText,
  copySelected,
  setCopySelected,
  licenseType,
  setLicenseType,
  noc_data,
  setFilteredCountiesData,
  setCountiesData,
  favoritesOpen,
  currentUser,
  isSmallScreen,
  intervalStarts,
  elapsedTime,
  targetTime,
  progressDisabled,
  progressValue,
  moreResultsText,
  setFullRetrieve,
  selectedPreForm,
  setSelectedPreForm,
  openDrawer,
  setOpenDrawer,
  editFromCreate,
  defaultAddress,
  pdf,
  selectedName,
  selectCards,
  setLoading,
  cardPerPage,
  currentPage,
  setCurrentPage,
  enlargeType,
  enlargeHeader,
  enlargeNote,
  enlargeFormInput,
  setEnlargeFormInput,
  formik,
  type,
  setIsPreview,
  preview,
  setPreview,
  previewImg,
  previewLoading,
  setCounty,
  createNocLoading,
  getCurrentTasks,
  filteredCountiesData,
  isReadyToDownload,
  setIsReadyToDownload,
  text,
  freeCounty,
  lockedNames,
  cardText,
  setSelectedRow,
  addressRow,
  cardFlip,
  setAddressRow,
  setCardFlip,
  setText,
  retrieveLoading,
  isRetrievingAddress,
  setIsRetrievingAddress,
  setRetrieveLoading,
  defaultValues,
  setCreateNocLoading,
  setPreviewLoading,
  setDefaultAddress,
  setEditFromCreate,
  formDrawerOpen,
  setFormDrawerOpen,
  isFormCreated,
  setNocStartedDrawer,
  setNewRequestID,
  setNewRequest,
  setInQueueMessage,
  setInQueueDrawer,
  setCardDisabled,
  cardDisabled,
  selectedPreName,
  setSelectedPreName,
}) => {
  const [tooltipText, setTooltipText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isCurrentCard, setIsCurrentCard] = useState("");

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const isFavorite = (county) => {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    return favorites.some((favorite) => favorite.name === county.name);
  };

  const handleAddToFavorites = (county) => {
    // Get the existing favorites from local storage or initialize an empty array
    const existingFavorites =
      JSON.parse(localStorage.getItem("favorites")) || [];

    // Check if the county is already in the favorites
    const existingIndex = existingFavorites.findIndex(
      (favorite) => favorite.name === county.name
    );

    let buttonText;

    if (type === "create-noc") {
      if (existingIndex === -1) {
        // Add the county to the favorites array
        existingFavorites.push(county);
        buttonText = "Added";
      } else {
        // Remove the county from the favorites array
        existingFavorites.splice(existingIndex, 1);
        buttonText = "Add";
      }

      // Save the updated favorites array to local storage
      localStorage.setItem("favorites", JSON.stringify(existingFavorites));

      // Update the buttonText for the current card
      // Find the index of the card in the filteredCountiesData array
      const cardIndex = filteredCountiesData.findIndex(
        (item) => item.name === county.name
      );

      // Create a copy of filteredCountiesData to update the buttonText
      const updatedFilteredCountiesData = [...filteredCountiesData];

      // Update the buttonText for the current card
      updatedFilteredCountiesData[cardIndex].buttonText = buttonText;

      // Update the state to reflect the changes
      setFilteredCountiesData(updatedFilteredCountiesData);

      if (favoritesOpen) {
        setCountiesData(existingFavorites);
        setFilteredCountiesData(existingFavorites);
      }
    }
  };

  const handleCardAction = (name, form, isDisabledCard) => {
    if (type === "create-noc") {
      const isWarning = currentUser.user.selectedCountiesNOC.some((noc) => {
        return (
          noc.name === name &&
          ((currentUser?.subuser &&
            noc.added_email !== currentUser?.subuser?.email &&
            !noc?.saved) ||
            (!currentUser?.subuser &&
              noc.added_email !== currentUser?.user?.email &&
              !noc?.saved))
        );
      });

      setDisableSelect(false);

      if (
        (freeCounty - selectedName.length === 0 ||
          freeCounty - selectedName.length < 0) &&
        !selectedName.includes(name)
      ) {
        return;
      } else if (selectedName.includes(name)) {
        selectCards(name, form);
      } else {
        setSelectedPreName(name);
        setSelectedPreForm(form);
        if (!isWarning) {
          setOpenDrawer(true);
        } else {
          setOpenDefaultModal(true);
        }
      }

      return;
    }

    let isLocked = lockedNames?.includes(name);

    if (isDisabledCard && type === "welcome" && isLocked == false) {
      // Set the tooltip text
      setTooltipText(
        "Modifying this county is not possible, as another member within your organization has made recent changes to it."
      );
      setIsCurrentCard(name);
      // Show the tooltip
      setShowTooltip(true);

      // Hide the tooltip after 2 seconds
      setTimeout(() => {
        setShowTooltip(false);
      }, 4000);
      return;
    } else if (type === "welcome" && isLocked == true) {
      // Set the tooltip text
      setTooltipText(
        "Modifications for this county are restricted as an NOC PDF file has already been generated specifically for it."
      );
      setIsCurrentCard(name);
      // Show the tooltip
      setShowTooltip(true);

      // Hide the tooltip after 2 seconds
      setTimeout(() => {
        setShowTooltip(false);
      }, 4000);
      return;
    }

    if (type === "welcome") {
      if (
        (freeCounty - selectedName.length === 0 ||
          freeCounty - selectedName.length < 0) &&
        !selectedName.includes(name)
      ) {
        return;
      } else if (selectedName.includes(name)) {
        selectCards(name, form);
      } else {
        setSelectedPreName(name);
        setSelectedPreForm(form);
        setOpenDrawer(true);
      }
    }
    // Add your other logic here
  };

  useEffect(() => {
    if (!openDrawer && enlargeType !== "firstSelect") {
      formik?.resetForm();
      setIsReadyToDownload(false);
      if (setPreview) {
        setPreview(false);
      }
      if (enlargeType === "createNOC") {
        setText(
          "All other fields will be filled automatically. Some fields are not visible in the preview."
        );
        setAddressRow([]);
        setCardFlip(false);
        setEditFromCreate(false);
      }
    }
  }, [openDrawer, preview, previewImg]);

  const getStyles = (countyName, isDisabledCard) => {
    if (lockedNames?.includes(countyName)) {
      if (isSmallScreen) {
        return {
          position: "relative",
          minWidth: "14rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #fff",
          backgroundColor: "#c1d7e1",
          minHeight: "10rem",
          "&:hover": {
            backgroundColor: "#c1d7e1", // Set the same background color on hover
          },
          cursor: "not-allowed",
        };
      } else {
        return {
          position: "relative",
          minWidth: "14rem",
          border: "8px solid #fff",
          backgroundColor: "#c1d7e1",
          minHeight: "15.9rem",
          "&:hover": {
            backgroundColor: "#c1d7e1", // Set the same background color on hover
          },
          cursor: "not-allowed",
        };
      }
    } else if (selectedName.includes(countyName)) {
      if (isSmallScreen) {
        return {
          cursor: isDisabledCard ? "not-allowed" : "pointer",
          position: "relative",
          minWidth: "14rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #76A0B9",
          backgroundColor: "#EEF2F6",
          minHeight: "10rem",
        };
      } else {
        return {
          cursor: isDisabledCard ? "not-allowed" : "pointer",
          position: "relative",
          minWidth: "14rem",
          border: "8px solid #76A0B9",
          backgroundColor: "#EEF2F6",
          minHeight: "15.9rem",
        };
      }
    } else if (type === "create-noc") {
      if (isSmallScreen) {
        return {
          position: "relative",
          minWidth: "10rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "10rem",
        };
      } else {
        return {
          position: "relative",
          minWidth: "14rem",
          border: "8px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "15.9rem",
        };
      }
    } else {
      if (isSmallScreen) {
        return {
          position: "relative",
          minWidth: "14rem",
          paddingLeft: 1,
          paddingRight: 1,
          border: "4px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "10rem",
        };
      } else {
        return {
          position: "relative",
          minWidth: "14rem",
          border: "8px solid #fff",
          backgroundColor: "#EEF2F6",
          minHeight: "15.9rem",
        };
      }
    }
  };

  function getTooltipTitle(county) {
    for (let i = 0; i < currentUser.user.selectedCountiesNOC.length; i++) {
      const selectedCounty = currentUser.user.selectedCountiesNOC[i];
      if (county.name === selectedCounty.name) {
        if (currentUser.subuser) {
          if (
            selectedCounty.added_email !== currentUser.subuser.email &&
            selectedCounty.added_email !== currentUser.user.email
          ) {
            return `The selection made by ${selectedCounty.added_name}.`;
          } else if (selectedCounty.added_email === currentUser.subuser.email) {
            return "The selection made by you.";
          } else {
            return "The selection made by your administrator.";
          }
        } else {
          if (selectedCounty.added_email !== currentUser.user.email) {
            return `The selection made by ${selectedCounty.added_name}.`;
          } else {
            return "The selection made by you.";
          }
        }
      }
    }

    return ""; // Return an empty string if not found in selectedCountiesNOC
  }

  function getIsDisabled(county) {
    for (let i = 0; i < currentUser.user.selectedCountiesNOC.length; i++) {
      const selectedCounty = currentUser.user.selectedCountiesNOC[i];
      if (county.name === selectedCounty.name) {
        if (currentUser.subuser) {
          if (selectedCounty.added_email !== currentUser.subuser.email) {
            return true;
          } else if (selectedCounty.added_email === currentUser.subuser.email) {
            return false;
          } else {
            return true;
          }
        } else {
          if (selectedCounty.added_email !== currentUser.user.email) {
            return false;
          } else {
            return false;
          }
        }
      }
    }

    return false; // Return an empty string if not found in selectedCountiesNOC
  }

  return (
    <>
      <Grid
        container
        // alignItems="center"
        justifyContent="center"
        gap={isSmallScreen ? 1 : 2}
        mt={5}
      >
        {type !== "trial-ended" &&
          getCurrentTasks(filteredCountiesData).map((county) => {
            let avgCreationTime = ""; // console.log(noc_data[0].avg_nocs);
            let isAppraiserSuccess = "";
            let checkedDate = "";
            let isDisabledCard = getIsDisabled(county);

            if (noc_data) {
              const currentCounty =
                noc_data[0]?.appraiser_results?.[county.name];
              const matchingEntry = noc_data[0]?.avg_nocs?.find(
                (entry) => entry?._id === county?.name
              );

              avgCreationTime = matchingEntry
                ? Math.floor(matchingEntry?.avg_second_took)
                : "N/A";

              // APPraiser //////
              isAppraiserSuccess = currentCounty?.is_success ? true : false;
              const checkedDateTimestamp = currentCounty?.date * 1000;
              const desiredTimezone = "America/New_York";
              // Create a Moment.js object with the timestamp and timezone
              const checkedDateMoment = moment.tz(
                checkedDateTimestamp,
                desiredTimezone
              );

              // Get the current date in the specified timezone
              const currentDate = moment().tz(desiredTimezone);

              // Check if the date is today in the specified timezone
              const isToday = checkedDateMoment.isSame(currentDate, "day");

              // Format the date based on whether it's today or not
              if (isToday) {
                checkedDate = checkedDateMoment.format("h:mm A"); // Today, show time only
              } else {
                checkedDate = checkedDateMoment.format("MMM D, h:mm A"); // Other days, show date and time
              }
            }
            const inFavorite = isFavorite(county);

            let buttonText;
            let contained = false;
            let btnDisabled = false;

            if (type === "create-noc") {
              if (inFavorite && !favoritesOpen) {
                buttonText = "Added";
                contained = true;
                btnDisabled = true;
              } else if (!inFavorite && !favoritesOpen) {
                buttonText = "Add";
                contained = false;
                btnDisabled = false;
              } else if (inFavorite && favoritesOpen) {
                buttonText = "Remove";
                contained = true;
                btnDisabled = false;
              }
            }
            return (
              <Card
                // disabled={
                //   type === "welcome" &&
                //   (lockedNames?.includes(county.name) || cardText !== "")
                // }
                onClick={(e) => {
                  handleCardAction(county.name, county.form, isDisabledCard);
                }}
                key={county.name}
                sx={getStyles(county.name, isDisabledCard)}
                component={Button}
                disableRipple // IF Button clicked, else not disable.
              >
                {/* Tooltip */}
                {showTooltip &&
                  type === "welcome" &&
                  isCurrentCard === county.name && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "100%",
                        transform: "translate(-50%, -50%)",
                        background: "#fff",
                        padding: "10px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      {tooltipText}
                    </div>
                  )}
                {currentUser.user.selectedCountiesNOC.some(
                  (item) => item.name === county.name
                ) &&
                  type === "welcome" && (
                    <Tooltip title={getTooltipTitle(county)} placement="top">
                      <PeopleIcon
                        color="primary"
                        sx={{ position: "absolute", right: 10, top: 10 }}
                      />
                    </Tooltip>
                  )}
                <Stack direction="column">
                  {type === "create-noc" ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifySelf: "flex-start",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        disabled={btnDisabled}
                        color="#cdaf0b"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!btnDisabled) {
                            handleAddToFavorites(county);
                          }
                        }}
                        sx={
                          isSmallScreen
                            ? {
                                width: "100%",
                                height: "2rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center", // Align center horizontally
                                alignItems: "center", // Align center vertically
                                marginBottom: isSmallScreen ? "1rem" : 0, // Add margin only on small screens
                                border: "1px solid #FFC107", // Add a border
                                borderRadius: "4px", // Add border radius
                                padding: "0 1rem", // Add padding
                                ...(contained && {
                                  backgroundColor: "#FFC107",
                                  color: "#fff",
                                }),
                              }
                            : {
                                fontWeight: "bold",
                                width: "100%",
                                height: "2rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center", // Align center horizontally
                                alignItems: "center", // Align center vertically
                                marginBottom: isSmallScreen ? "1rem" : 0, // Add margin only on small screens
                                border: "1px solid #FFC107", // Add a border
                                borderRadius: "4px", // Add border radius
                                padding: "0 1rem", // Add padding
                                ...(contained && {
                                  backgroundColor: "#FFC107",
                                  color: "#fff",
                                }),
                              }
                        }
                      >
                        {<StarIcon />}
                        <span style={{ paddingTop: "0px" }}>{buttonText}</span>
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ height: "0.5rem" }}></Box>
                  )}

                  <CardContent
                    sx={
                      isSmallScreen
                        ? { p: 0, minHeight: "8.6rem" }
                        : { pb: 0, minHeight: "8.6rem" }
                    }
                  >
                    <img
                      alt="noc-pdf"
                      src={county.form}
                      style={{ width: "5rem" }}
                    />
                  </CardContent>
                  <CardHeader
                    sx={isSmallScreen ? { p: 0 } : { pt: 1, pb: 2 }}
                    subheader={county.name}
                    subheaderTypographyProps={{
                      align: "center",
                      fontWeight: "bold",
                      color: "primary",
                      variant: "h7",
                    }}
                  />
                  <Typography
                    variant="h7"
                    color="#CDAF0B"
                    sx={{
                      ml: 1, // Add margin for separation
                      fontWeight: "bold",
                    }}
                  >
                    Avg time: {avgCreationTime}{" "}
                    {avgCreationTime !== "N/A" && "sec"}
                  </Typography>
                  <Typography
                    variant="h7"
                    color={isAppraiserSuccess ? "#008000" : "#800000"}
                    sx={{
                      ml: 1, // Add margin for separation
                      fontWeight: "bold",
                    }}
                  >
                    {isAppraiserSuccess
                      ? `Last successful NOC`
                      : `Last failed NOC`}
                  </Typography>
                  <Typography
                    variant="h7"
                    color={isAppraiserSuccess ? "#008000" : "#800000"}
                    sx={{
                      ml: 1, // Add margin for separation
                      fontWeight: "bold",
                    }}
                  >
                    {isAppraiserSuccess
                      ? `Today ${checkedDate}`
                      : `Today ${checkedDate}`}
                  </Typography>
                </Stack>
              </Card>
            );
          })}
      </Grid>
      <Stack spacing={2} ml="auto" mr="auto" mt={4}>
        <Pagination
          count={Math.ceil(filteredCountiesData.length / cardPerPage)}
          page={currentPage}
          onChange={handleChange}
        />
      </Stack>
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {
          <EnlargeCountyForm
            disableSelect={disableSelect}
            setSelectedLicenseIndex={setSelectedLicenseIndex}
            invalid={invalid}
            licenseNumber={licenseNumber}
            setLicenseNumber={setLicenseNumber}
            licenseText={licenseText}
            copySelected={copySelected}
            setCopySelected={setCopySelected}
            licenseType={licenseType}
            setLicenseType={setLicenseType}
            currentUser={currentUser}
            intervalStarts={intervalStarts}
            elapsedTime={elapsedTime}
            targetTime={targetTime}
            progressDisabled={progressDisabled}
            progressValue={progressValue}
            moreResultsText={moreResultsText}
            setFullRetrieve={setFullRetrieve}
            setNewRequestID={setNewRequestID}
            setNewRequest={setNewRequest}
            setInQueueMessage={setInQueueMessage}
            setInQueueDrawer={setInQueueDrawer}
            setNocStartedDrawer={setNocStartedDrawer}
            setFormDrawerOpen={setFormDrawerOpen}
            formDrawerOpen={formDrawerOpen}
            editFromCreate={editFromCreate}
            setRetrieveLoading={setRetrieveLoading}
            isRetrievingAddress={isRetrievingAddress}
            setIsRetrievingAddress={setIsRetrievingAddress}
            retrieveLoading={retrieveLoading}
            defaultAddress={defaultAddress}
            setCardFlip={setCardFlip}
            cardFlip={cardFlip}
            addressRow={addressRow}
            setAddressRow={setAddressRow}
            setSelectedRow={setSelectedRow}
            text={text}
            pdf={pdf}
            isReadyToDownload={isReadyToDownload}
            pdfImg={selectedPreForm}
            createNocLoading={createNocLoading}
            setCounty={setCounty}
            previewLoading={previewLoading}
            setLoading={setLoading}
            setIsPreview={setIsPreview}
            type={type}
            formik={formik}
            selectCards={selectCards}
            enlargeType={enlargeType}
            selectedPreName={selectedPreName}
            selectedPreForm={selectedPreForm}
            enlargeHeader={enlargeHeader}
            enlargeNote={enlargeNote}
            enlargeFormInput={enlargeFormInput}
            setEnlargeFormInput={setEnlargeFormInput}
            setOpenDrawer={setOpenDrawer}
            preview={preview}
            setPreview={setPreview}
            previewImg={previewImg}
            defaultValues={defaultValues}
            setCreateNocLoading={setCreateNocLoading}
            setPreviewLoading={setPreviewLoading}
            setDefaultAddress={setDefaultAddress}
            isFormCreated={isFormCreated}
          />
        }
        <div style={{ marginTop: "auto" }}></div>
        <Footer />
      </Drawer>
    </>
  );
};

export default SelectCountyForm;
