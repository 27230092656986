import {
  Grid,
  Stack,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

const EnlargeSendPdf = ({
  setSendFormDrawer,
  editFromCreate,
  selectedPreName,
  enlargeHeader,
  enlargeNote,
  setOpenDrawer,
  sendFormFormik,
  type,
  preview,
  previewImg,
  previewLoading,
  pdfImg,
  pdfImg2,
  inputs,
  editLoading,
  errorText,
  disableEdit,
  pdf,
  setSelectedRow,
  setFormDrawerOpen,
  formDrawerOpen,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleIMG = () => {
    if (pdfImg2 !== "") {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={previewImg[1]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={pdfImg2} style={{ width: "100%" }} />;
          </>
        );
      }
    } else {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
          </>
        );
      }
    }
  };

  const handleIconButton = () => {
    setSelectedRow(null);
    setSendFormDrawer(false);
    sendFormFormik.resetForm();
  };

  const handleReturnForm = () => {
    return (
      <>
        <form
          noValidate
          autoComplete="off"
          onSubmit={sendFormFormik.handleSubmit}
        >
          <Stack
            spacing={3}
            mt={4}
            px={1}
            py={2}
            sx={{ maxHeight: "55vh", overflowY: "auto" }}
          >
            <TextField
              error={
                !!(sendFormFormik.touched.name && sendFormFormik.errors.name)
              }
              fullWidth
              helperText={
                sendFormFormik.touched.name && sendFormFormik.errors.name
              }
              label="Full Name"
              name="name"
              onBlur={sendFormFormik.handleBlur}
              onChange={sendFormFormik.handleChange}
              value={sendFormFormik.values.name}
            />
            <TextField
              error={
                !!(sendFormFormik.touched.email && sendFormFormik.errors.email)
              }
              fullWidth
              helperText={
                sendFormFormik.touched.email && sendFormFormik.errors.email
              }
              label="Email"
              name="email"
              onBlur={sendFormFormik.handleBlur}
              onChange={sendFormFormik.handleChange}
              value={sendFormFormik.values.email}
            />
            <TextField
              value={sendFormFormik.values.message}
              onChange={sendFormFormik.handleChange}
              sx={{ width: "100%", mt: 4 }}
              label="Message"
              name="message"
              multiline
              rows={10}
            />
          </Stack>
          <Box
            gap={isMd ? 0 : 2}
            sx={
              isMd
                ? { display: "flex", flexDirection: "column" }
                : { display: "flex", flexDirection: "row" }
            }
          >
            {handleCheckButton()}
          </Box>
          {isMd && (
            <Box
              gap={isMd ? 0 : 2}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingButton
                loading={previewLoading}
                onClick={() => {
                  setFormDrawerOpen(true);
                }}
                variant="contained"
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "#F2CD00",
                  ":hover": { backgroundColor: "#CDAF0B" },
                  mb: 1,
                }}
              >
                SHOW FORM
              </LoadingButton>
            </Box>
          )}

          <Typography
            variant="body1"
            sx={{
              color: "#BCA631",
              fontWeight: "bold",
              textAlign: "center",
              pb: 2,
            }}
          >
            {errorText}
          </Typography>
        </form>
      </>
    );
  };

  const handleCheckButton = () => {
    return (
      <>
        <LoadingButton
          startIcon={<SendIcon color="#fff" width={isMd ? 15 : 20} />}
          loading={editLoading}
          color="warning"
          type="submit"
          fullWidth
          size={isMd ? "small" : "large"}
          sx={
            isMd
              ? {
                  mt: 2,
                  mb: 1,
                }
              : { mt: 2, mb: 2 }
          }
          variant="contained"
        >
          SEND
        </LoadingButton>
      </>
    );
  };

  return (
    <>
      <Grid container sx={{ display: "flex", overflow: "auto" }}>
        {!isMd && (
          <Grid
            item
            md={6}
            sx={
              isMd
                ? { maxHeight: "100vh", overflow: "auto", order: 2 }
                : { maxHeight: "100vh", overflow: "auto" }
            }
          >
            <Box>{handleIMG()}</Box>
          </Grid>
        )}

        <Grid
          item
          md={6}
          px={isMd ? 1 : 4}
          sx={isMd ? { order: 1, width: "100%" } : {}}
        >
          <Stack pt={2}>
            <Box sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center", flex: "2" }}>
                <Typography variant="h4" textAlign="center">
                  {selectedPreName} County
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {enlargeHeader}
                </Typography>
                <Typography mt={2} variant="body2" textAlign="center">
                  {enlargeNote}
                </Typography>
              </Stack>
              <Stack sx={{ width: "auto" }}>
                <IconButton onClick={handleIconButton}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          {handleReturnForm()}
        </Grid>
      </Grid>

      <Drawer
        PaperProps={{}}
        anchor="right"
        open={formDrawerOpen}
        onClose={() => setFormDrawerOpen(false)}
      >
        <Stack sx={{ width: "auto", ml: "auto" }}>
          <IconButton onClick={() => setFormDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item md={6} sx={{ maxHeight: "100%", order: 2 }}>
          <Box>{handleIMG()}</Box>
        </Grid>
      </Drawer>
    </>
  );
};

export default EnlargeSendPdf;
