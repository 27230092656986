import {
  Box,
  CircularProgress,
  Container,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import CurrentPlan from "../dashboard/CurrentPlan";
import TotalNoc from "../dashboard/TotalNoc";
import TotalCounties from "../dashboard/TotalCounties";
import NewNoc from "../dashboard/NewNoc";
import AvailableNoc from "../dashboard/AvailableNoc";
import NocUsage from "../dashboard/NocUsage";
import CreatedForms from "../dashboard/CreatedForms";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const nocFormDefault = [
    { id: "", date: "", ownersName: "", address: "", county: "" },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser || !currentUser.user || !currentUser.nocUserForms) {
      return;
    }
    if (currentUser.user.expired === true) {
      navigate("/subscription");
    }
    setLoading(false);
  }, [currentUser]);

  const handleChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    currentUser?.user.analytics.nocUsage.map((item) => {
      if (item.year === year) {
        chartList[item.month] = item.noc;
      }
      return null;
    });
    // 1699958720
    return chartList;
  };
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} lg={3}>
                  <CurrentPlan
                    trialEnded={currentUser.user.trialEnded}
                    difference={12}
                    positive
                    value={
                      currentUser?.user.role === "user"
                        ? currentUser?.tier.title +
                          " (" +
                          currentUser?.user.interval +
                          ")"
                        : currentUser?.tier.title
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={3}>
                  <TotalNoc
                    difference={16}
                    positive={false}
                    value={currentUser?.user.analytics.nocCreated}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={3}>
                  <TotalCounties
                    difference={16}
                    positive={false}
                    value={
                      currentUser?.user.role === "admin" ||
                      currentUser?.user.role === "tester" ||
                      currentUser?.user?.admin === true
                        ? 67
                        : currentUser?.user.trialEnded
                        ? currentUser?.tier.numCounty === "all"
                          ? 67 // Change to 67 if trial ended and tier numCounty is "all"
                          : currentUser?.tier.numCounty +
                            currentUser?.user.analytics?.additions.numCounties
                        : currentUser?.tier.freeCounty +
                          currentUser?.user.analytics?.additions.numCounties
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={3}>
                  <NewNoc
                    text={currentUser?.user.analytics.timeLeft}
                    value={currentUser?.user.analytics.nocLeftBar}
                  />
                </Grid>

                <Grid xs={12} lg={8}>
                  <NocUsage
                    year={year}
                    setYear={setYear}
                    handleChartValues={handleChartValues}
                    chartSeries={[
                      {
                        name: "Created NOC's",
                        data: handleChartValues(),
                      },
                    ]}
                    sx={{ height: "100%" }}
                  />
                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  {currentUser ? (
                    <AvailableNoc
                      chartSeries={
                        currentUser?.user.role === "admin" ||
                        currentUser?.user.role === "tester" ||
                        currentUser?.user?.admin === true
                          ? [
                              100000000000000,
                              currentUser?.user.analytics.nocCreatedInPeriod,
                            ]
                          : [
                              currentUser?.user.analytics
                                ?.availableNocInPeriod === "unlimited"
                                ? 100000000000000
                                : currentUser?.user.analytics
                                    ?.availableNocInPeriod,
                              currentUser?.user.analytics.nocCreatedInPeriod,
                            ]
                      }
                      labels={["Available", "Used"]}
                      role={currentUser?.user.role}
                      admin={currentUser?.user?.admin}
                      tier={
                        currentUser?.user.trialEnded
                          ? currentUser?.tier.numNOC
                          : ""
                      }
                      sx={{ height: "100%" }}
                    />
                  ) : (
                    <div></div>
                  )}
                </Grid>
                <Grid xs={12} md={12} lg={12}>
                  {currentUser && (
                    <CreatedForms
                      createdFormRow={
                        currentUser ? currentUser.nocUserForms : nocFormDefault
                      }
                      payments={false}
                      title="NOC PDF Forms created"
                      // setOpenDrawer={setOpenDrawer}
                      // openDrawer={openDrawer}
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default Dashboard;
