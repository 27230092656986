import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, useTheme, useMediaQuery } from "@mui/material";

const HomeComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      sx={
        isXs
          ? {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              backgroundSize: "cover",
              height: "40vh",
            }
          : isMd
          ? {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              backgroundSize: "cover",
              height: "82vh",
            }
          : {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              height: "100vh",
            }
      }
    >
      <Grid item sm={12} xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ flexDirection: "column" }}
        >
          <img
            style={
              isXs
                ? {
                    width: "20rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "1rem",
                  }
                : {
                    width: "27rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "2rem",
                  }
            }
            alt="nocCreatorlogo"
            src="/img/logos/nocLogo.png"
          />
          <Button
            onClick={(e) => {
              navigate("/login");
            }}
            color="warning"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#F2CD00",
              borderRadius: 0,
              ":hover": { backgroundColor: "#CDAF0B" },
            }}
            variant="contained"
          >
            L O G I N / R E G I S T E R
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomeComponent;
