import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const NewNoc = (props) => {
  const { value, text } = props;

  return (
    <Card sx={{ minHeight: "10rem", position: "relative" }}>
      <Tooltip
        title="The Available NOCs will reset at the end of each 30-day period, ensuring a renewal for the next cycle."
        placement="top"
      >
        <HelpOutlineIcon
          color="primary"
          sx={{ position: "absolute", right: 10, top: 10 }}
        />
      </Tooltip>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" gutterBottom variant="overline">
              New NOC's In
            </Typography>
            <Typography variant="h5">{text} Days</Typography>
          </Stack>
          <img
            alt="pdfIcon"
            src="/img/dashboard/pdfIcon.png"
            style={{ width: "3.5rem" }}
          />
        </Stack>
        <Box sx={{ mt: 3 }}>
          <LinearProgress value={value} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewNoc;
