import { LoadingButton } from "@mui/lab";
import { Card, CardHeader, CardContent, Typography, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";

const MoreResultsList = ({
  setDisableSelect,
  moreResultType,
  currentTask,
  updatedTask,
  moreResultsText,
  fullRetrieve,
  setFullRetrieve,
  addressRow,
  handleRowClick,
  defaultAddress,
  retrieveLoading,
  setRetrieveLoading,
  isRetrievingAddress,
  setIsRetrievingAddress,
  defaultValues,
  setCreateNocLoading,
  setPreviewLoading,
  setAddressRow,
  setDefaultAddress,
  setNewRequestID,
  setNewRequest,
  setInQueueMessage,
  setInQueueDrawer,
  setIsPreview,
}) => {
  const addressColumns = [
    {
      field: "owner",
      headerName: "Owner's Name",
      minWidth: 100,
      flex: 1,
      editable: false,
    },
    {
      field: "primaryAddress",
      headerName: "Property Address",
      minWidth: 100,
      flex: 1,
      editable: false,
    },
  ];

  const [text, setText] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setText("");
    }, 10000);

    return () => clearInterval(timer);
  }, [text]);

  const handleRetrieveAddress = async () => {
    try {
      setDisableSelect(true);
      setIsRetrievingAddress(true);
      setRetrieveLoading(true);
      setCreateNocLoading(true);
      setPreviewLoading(true);
      setRetrieveLoading(true);
      setText("Calculating queue position...");

      let values = "";

      if (moreResultType === "notification") {
        values = currentTask.original_values;
      } else {
        let updatedDefaultValues = { ...defaultValues, allRetrieve: true };
        values = updatedDefaultValues;
      }
      // let values = updatedDefaultValues;

      // const resp = await axios.post("https://sr360noc.com/nocApi/createNoc", {
      //   values,
      // });

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/createNoc`,
        {
          values,
        },
        { withCredentials: true }
      );
      if (resp.data.queuePosition <= 0) {
        setNewRequestID(resp.data.task_id);
        setNewRequest(true);
        setText("The property addresses are being downloaded. Please wait...");
      } else {
        setInQueueMessage("");
        setInQueueDrawer(true);
      }
      setFullRetrieve(true);

      // const updatedDefaultAddress = {
      //   ...defaultAddress,
      //   retrieved: defaultAddress["fullRetrieve"],
      // };

      // // Update the state with the new object
      // setDefaultAddress(updatedDefaultAddress);

      // setAddressRow(resp.data.data);

      // setText("All Addresses are Retrieved");
      // setRetrieveLoading(false);
      // setCreateNocLoading(false);
      // setPreviewLoading(false);
    } catch (err) {
      console.log(err);
      setText(err.message);
      setCreateNocLoading(false);
      setPreviewLoading(false);
      setRetrieveLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Search Results"
          titleTypographyProps={{ variant: "h4" }}
          sx={{ textAlign: "center", pt: 2 }}
        />
        <Typography
          align="center"
          sx={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}
        >
          Select the address you wish to use or enter more information in the
          input fields to narrow the search
        </Typography>
        <CardContent>
          <DataGrid
            sx={{
              ".MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
              ".MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
                display: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={addressRow ? addressRow : []}
            columns={addressColumns}
            onRowClick={(params) => {
              handleRowClick(params.row);
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
          <Grid container alignItems="center" spacing={2} mt={2}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                Retrieved Addresses:{" "}
                <span style={{ fontSize: "1.2rem" }}>
                  {defaultAddress["retrieved"]}
                </span>{" "}
                out of{" "}
                <span style={{ fontSize: "1.2rem" }}>
                  {defaultAddress["fullRetrieve"]}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end">
              <LoadingButton
                onClick={handleRetrieveAddress}
                disabled={isRetrievingAddress}
                loading={retrieveLoading}
                variant="contained"
              >
                Retrieve All
              </LoadingButton>
            </Grid>
            {/* <Grid item sm={6} display="flex">
              <Typography variant="body2">{defaultAddress["fullAddress"]}</Typography>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
      <Grid item xs={12} display="flex">
        <Typography
          variant="body1"
          sx={{
            color: "#BCA631",
            fontWeight: "bold",
            py: 2,
            ml: "auto",
            mr: "auto",
          }}
        >
          {text}
        </Typography>
      </Grid>
    </>
  );
};

export default MoreResultsList;
