import {
  Grid,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import TextFields from "./TextFields";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { PencilIcon } from "@heroicons/react/24/outline";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import ReactCardFlip from "react-card-flip";
import MoreResultsList from "../noc/MoreResultsList";

const EnlargeNotificationForm = ({
  setDisableSelect,
  disableSelect,
  licenseType,
  setLicenseType,
  setSelectedLicenseIndex,
  invalid,
  licenseNumber,
  setLicenseNumber,
  licenseText,
  copySelected,
  setCopySelected,
  currentUser,
  moreResultType,
  updatedTask,
  currentTask,
  setFullRetrieve,
  fullRetrieve,
  editFromCreate,
  retrieveLoading,
  defaultAddress,
  selectedPreName,
  enlargeHeader,
  enlargeNote,
  setOpenDrawer,
  formik,
  type,
  preview,
  setIsPreview,
  previewImg,
  previewLoading,
  setCounty,
  createNocLoading,
  pdfImg,
  inputs,
  errorText,
  pdf,
  isReadyToDownload,
  text,
  setSelectedRow,
  addressRow,
  cardFlip,
  setCardFlip,
  setAddressRow,
  isRetrievingAddress,
  setIsRetrievingAddress,
  setRetrieveLoading,
  defaultValues,
  setCreateNocLoading,
  setPreviewLoading,
  setDefaultAddress,
  formDrawerOpen,
  setFormDrawerOpen,
  setPreview,
  isFormCreated,
  setNewRequestID,
  setNewRequest,
  setInQueueMessage,
  setInQueueDrawer,
  moreResultsText,
}) => {
  const [dynamicValues, setDynamicValues] = useState({});
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleRowClick = (inputs) => {
    // Extract the required fields from the rowData object (adjust as per your actual data structure)
    const { street, number, unit, zip } = inputs;

    // Get the current formik values

    // Update the dynamicValues state with the clicked row's data for streetName, streetNumber, unitNumber, and zip
    setDynamicValues({
      streetName: street || "",
      streetNumber: number || "",
      unitNumber: unit || "",
      zip: zip || "",
    });

    // Set the other formik values back to the formik state
    // formik.setValues({ ...formik.values, ...otherValues });
  };

  const handleIMG = () => {
    let regex = /(\d+)(?=\.[^.]+$)/;
    // Check if the filename contains a number
    if (regex.test(pdfImg)) {
      // If it does, keep the original file path
      let originalFilePath = pdfImg;

      // And create a new file path with the number replaced with the specific number
      let newFilePath = pdfImg.replace(regex, 2);
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={previewImg[1]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img
              alt="noc-pdf"
              src={originalFilePath}
              style={{ width: "100%" }}
            />
            <img alt="noc-pdf" src={newFilePath} style={{ width: "100%" }} />;
          </>
        );
      }
    } else {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
          </>
        );
      }
    }
  };

  const handleIconButton = async () => {
    setOpenDrawer(false);
    setCreateNocLoading(false);
    setPreviewLoading(false);
    // setNewRequest(false);
    // setNewRequestID("");
  };

  const handlePreview = () => {
    if (preview && isMd) {
      setPreview(false);
    }
    setCardFlip(false);
    setCounty(selectedPreName);
    setIsPreview(true);
  };

  const handleReturnForm = () => {
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Stack
            spacing={3}
            mt={4}
            px={1}
            py={2}
            sx={{ maxHeight: "45vh", overflowY: "auto" }}
          >
            <TextFields
              editFromCreate={editFromCreate}
              dynamicValues={dynamicValues}
              setDynamicValues={setDynamicValues}
              formik={formik}
              selectedPreName={selectedPreName}
              type={type}
              inputs={inputs}
            />
          </Stack>
          <Box
            gap={isMd ? 0 : 2}
            sx={
              isMd
                ? { display: "flex", flexDirection: "column" }
                : { display: "flex", flexDirection: "row" }
            }
          >
            {handleCheckButton()}
          </Box>
          {isMd && (
            <Box
              gap={isMd ? 0 : 2}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingButton
                loading={previewLoading}
                onClick={() => {
                  setFormDrawerOpen(true);
                }}
                variant="contained"
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "#F2CD00",
                  ":hover": { backgroundColor: "#CDAF0B" },
                  mb: 1,
                }}
              >
                SHOW FORM
              </LoadingButton>
            </Box>
          )}

          <Typography
            variant="body1"
            sx={{
              color: "#BCA631",
              fontWeight: "bold",
              textAlign: "center",
              pb: 2,
            }}
          >
            {type === "edit" ? errorText : text}
          </Typography>
        </form>
      </>
    );
  };

  const handleCheckButton = () => {
    // AFTER CREATED
    if (editFromCreate) {
      return (
        <>
          <LoadingButton
            startIcon={<PencilIcon color="#fff" width={isMd ? 15 : 20} />}
            type="submit"
            loading={previewLoading}
            // onClick={handlePreview}
            fullWidth
            size={isMd ? "small" : "large"}
            sx={
              isMd
                ? {
                    mt: 2,
                    mb: 1,
                    color: "#fff",
                    backgroundColor: "#F2CD00",
                    ":hover": { backgroundColor: "#CDAF0B" },
                  }
                : {
                    mt: 2,
                    mb: 2,
                    color: "#fff",
                    backgroundColor: "#F2CD00",
                    ":hover": { backgroundColor: "#CDAF0B" },
                  }
            }
            variant="contained"
          >
            EDIT
          </LoadingButton>
          {isReadyToDownload ? (
            <>
              <LoadingButton
                startIcon={
                  <DocumentArrowDownIcon color="#fff" width={isMd ? 15 : 20} />
                }
                href={pdf}
                target="_blank"
                loading={createNocLoading}
                fullWidth
                size={isMd ? "small" : "large"}
                sx={
                  isMd
                    ? {
                        mb: 1,
                        backgroundColor: "primary",
                      }
                    : { mt: 2, mb: 2, backgroundColor: "primary" }
                }
                variant="contained"
              >
                DOWNLOAD
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                onClick={(e) => {
                  setCounty(selectedPreName);
                  setCardFlip(false);
                  setAddressRow([]);
                  setIsPreview(false);
                }}
                loading={createNocLoading}
                disabled={retrieveLoading}
                type="submit"
                fullWidth
                size="large"
                sx={{
                  mt: 2,
                  mb: 2,
                  backgroundColor: "primary",
                }}
                variant="contained"
              >
                CREATE
              </LoadingButton>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <LoadingButton
            disabled={isReadyToDownload || retrieveLoading}
            type="submit"
            loading={previewLoading}
            onClick={handlePreview}
            fullWidth
            size={isMd ? "small" : "large"}
            sx={
              isMd
                ? {
                    mt: 2,
                    color: "#fff",
                    backgroundColor: "#F2CD00",
                    ":hover": { backgroundColor: "#CDAF0B" },
                  }
                : {
                    mt: 2,
                    mb: 2,
                    color: "#fff",
                    backgroundColor: "#F2CD00",
                    ":hover": { backgroundColor: "#CDAF0B" },
                  }
            }
            variant="contained"
          >
            {!isMd ? (preview ? "RESET" : "PREVIEW") : "PREVIEW"}
          </LoadingButton>
          {isReadyToDownload ? (
            <>
              <Button
                href={pdf}
                target="_blank"
                loading={createNocLoading}
                fullWidth
                size={isMd ? "small" : "large"}
                sx={
                  isMd
                    ? {
                        mb: 2,
                        backgroundColor: "primary",
                      }
                    : { mt: 2, mb: 2, backgroundColor: "primary" }
                }
                variant="contained"
              >
                Download
              </Button>
            </>
          ) : (
            <>
              <LoadingButton
                onClick={(e) => {
                  // setNocStartedDrawer(true);
                  setCounty(selectedPreName);
                  setCardFlip(false);
                  setAddressRow([]);
                  setIsPreview(false);
                }}
                loading={createNocLoading}
                disabled={retrieveLoading}
                type="submit"
                fullWidth
                size={isMd ? "small" : "large"}
                sx={
                  isMd
                    ? {
                        mt: 1,
                        mb: 1,
                        backgroundColor: "primary",
                      }
                    : { mt: 2, mb: 2, backgroundColor: "primary" }
                }
                variant="contained"
              >
                CREATE
              </LoadingButton>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Grid container sx={{ display: "flex", overflow: "auto" }}>
        {!isMd && (
          <Grid
            item
            md={6}
            sx={
              isMd
                ? { maxHeight: "100vh", overflow: "auto", order: 2 }
                : { maxHeight: "100vh", overflow: "auto" }
            }
          >
            <Box
              sx={{
                display: "column",
                position: "relative",
              }}
            >
              <IconButton
                sx={
                  type !== "create-noc" && type !== "moreResults"
                    ? { display: "none", mt: "1rem", ml: "1rem" }
                    : {
                        display: "block",
                        mt: "1rem",
                        ml: "1rem",
                        position: "absolute",
                        left: 0,
                        top: 5,
                        zIndex: 2,
                      }
                }
                disabled={addressRow?.length === 0}
                onClick={() => setCardFlip(!cardFlip)}
              >
                {cardFlip === true ? <CloseIcon /> : <FormatListBulletedIcon />}
              </IconButton>

              <ReactCardFlip
                isFlipped={cardFlip}
                flipDirection="horizontal"
                flipSpeedBackToFront={1}
                flipSpeedFrontToBack={1}
              >
                <Box>{handleIMG()}</Box>
                <Box sx={{ height: "100vh" }}>
                  <MoreResultsList
                    setDisableSelect={setDisableSelect}
                    moreResultType={moreResultType}
                    updatedTask={updatedTask}
                    currentTask={currentTask}
                    moreResultsText={moreResultsText}
                    setIsPreview={setIsPreview}
                    setNewRequestID={setNewRequestID}
                    setNewRequest={setNewRequest}
                    setInQueueMessage={setInQueueMessage}
                    setInQueueDrawer={setInQueueDrawer}
                    setFullRetrieve={setFullRetrieve}
                    fullRetrieve={fullRetrieve}
                    setRetrieveLoading={setRetrieveLoading}
                    isRetrievingAddress={isRetrievingAddress}
                    setIsRetrievingAddress={setIsRetrievingAddress}
                    retrieveLoading={retrieveLoading}
                    defaultAddress={defaultAddress}
                    handleRowClick={handleRowClick}
                    setSelectedRow={setSelectedRow}
                    addressRow={addressRow}
                    formik={formik}
                    defaultValues={defaultValues}
                    setCreateNocLoading={setCreateNocLoading}
                    setPreviewLoading={setPreviewLoading}
                    setAddressRow={setAddressRow}
                    setDefaultAddress={setDefaultAddress}
                  />
                </Box>
              </ReactCardFlip>
            </Box>
          </Grid>
        )}

        <Grid
          item
          md={6}
          px={isMd ? 1 : 4}
          sx={isMd ? { order: 1, width: "100%" } : {}}
        >
          <Stack pt={2}>
            <Box sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center", flex: "2" }}>
                <Typography variant="h4" textAlign="center">
                  {selectedPreName} County
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {enlargeHeader}
                </Typography>
                <Typography mt={2} variant="body2" textAlign="center">
                  {enlargeNote}
                </Typography>
                {invalid && (
                  <Typography sx={{ color: "#FF0000" }} align="center">
                    Your license number is invalid. Please update it through
                    your profile settings to be able to create an NOC for this
                    license type.
                  </Typography>
                )}
                <Grid
                  container
                  sx={{
                    mt: 2,
                    marginBottom: "-2rem",
                    backgroundColor: "#f6f6f6",
                    p: 2,
                    borderRadius: "1rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Grid item lg={6} sm={12} order={isMd ? 2 : 1}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="licenseType-label">
                        License Type
                      </InputLabel>
                      <Select
                        disabled={disableSelect}
                        labelId="license_types"
                        name="licenseType"
                        label="License Type"
                        value={licenseType}
                        onChange={(e) => {
                          const index =
                            currentUser?.user?.contractors.findIndex(
                              (contractor) =>
                                contractor.licenseType === e.target.value
                            );

                          setSelectedLicenseIndex(index);
                          // Use Formik's setFieldValue to update the licenseType
                          setLicenseType(e.target.value);

                          // Now you can update other form fields based on the selected licenseType
                          const selectedContractor =
                            currentUser?.user?.contractors.find(
                              (contractor) =>
                                contractor.licenseType === e.target.value
                            );

                          setLicenseNumber(selectedContractor.licenseNumber);
                          setCopySelected(selectedContractor);
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              marginTop: "1.5px",
                              maxHeight: 150, // Set the desired max height for the dropdown menu
                              width: "auto",
                              maxWidth: "100%",
                            },
                          },
                        }}
                        sx={{
                          maxWidth: "100%", // Set the desired max-width for the input element
                          "& .MuiTypography-body2": {
                            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                          },
                        }}
                      >
                        {currentUser?.user?.contractors?.map(function (
                          contractor,
                          i
                        ) {
                          return (
                            <MenuItem key={i} value={contractor.licenseType}>
                              <Typography variant="body2">
                                {contractor.licenseType}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Vertically center the content
                        justifyContent: "center",
                        height: "100%", // Ensure the Box takes up the full height
                      }}
                    >
                      <Box
                        sx={
                          copySelected?.licenseStatus === "not_found"
                            ? { borderBottom: "5px solid #990000" }
                            : { borderBottom: "5px solid #006400" }
                        }
                      >
                        <Typography
                          variant="h4"
                          sx={
                            copySelected?.licenseStatus === "not_found"
                              ? { marginBottom: "-7px", color: "#990000" }
                              : { marginBottom: "-7px", color: "#006400" }
                          }
                        >
                          {licenseNumber}
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        sx={
                          copySelected?.licenseStatus === "not_found"
                            ? { color: "#990000" }
                            : { color: "#006400" }
                        }
                      >
                        {licenseText}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
              <Stack sx={{ width: "auto" }}>
                <IconButton onClick={handleIconButton}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          {handleReturnForm()}
        </Grid>
      </Grid>

      <Drawer
        PaperProps={{}}
        anchor="right"
        open={formDrawerOpen}
        onClose={() => setFormDrawerOpen(false)}
      >
        <Stack sx={{ width: "auto", ml: "auto" }}>
          <IconButton onClick={() => setFormDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item md={6} sx={{ maxHeight: "100%", order: 2 }}>
          <Box
            sx={{
              display: "column",
              position: "relative",
            }}
          >
            <IconButton
              sx={
                type !== "create-noc"
                  ? { display: "none", mt: "1rem", ml: "1rem" }
                  : {
                      display: "block",
                      mt: "1rem",
                      ml: "1rem",
                      position: "absolute",
                      left: 0,
                      top: 5,
                      zIndex: 2,
                    }
              }
              disabled={addressRow?.length === 0}
              onClick={() => setCardFlip(!cardFlip)}
            >
              {cardFlip === true ? <CloseIcon /> : <FormatListBulletedIcon />}
            </IconButton>

            <ReactCardFlip
              isFlipped={cardFlip}
              flipDirection="horizontal"
              flipSpeedBackToFront={1}
              flipSpeedFrontToBack={1}
            >
              <Box>{handleIMG()}</Box>
              <Box sx={{ height: "100vh" }}>
                <MoreResultsList
                  setDisableSelect={setDisableSelect}
                  moreResultType={moreResultType}
                  updatedTask={updatedTask}
                  currentTask={currentTask}
                  moreResultsText={moreResultsText}
                  setIsPreview={setIsPreview}
                  setNewRequestID={setNewRequestID}
                  setNewRequest={setNewRequest}
                  setInQueueMessage={setInQueueMessage}
                  setInQueueDrawer={setInQueueDrawer}
                  setFullRetrieve={setFullRetrieve}
                  fullRetrieve={fullRetrieve}
                  setRetrieveLoading={setRetrieveLoading}
                  isRetrievingAddress={isRetrievingAddress}
                  setIsRetrievingAddress={setIsRetrievingAddress}
                  retrieveLoading={retrieveLoading}
                  defaultAddress={defaultAddress}
                  handleRowClick={handleRowClick}
                  setSelectedRow={setSelectedRow}
                  addressRow={addressRow}
                  formik={formik}
                  defaultValues={defaultValues}
                  setCreateNocLoading={setCreateNocLoading}
                  setPreviewLoading={setPreviewLoading}
                  setAddressRow={setAddressRow}
                  setDefaultAddress={setDefaultAddress}
                />
              </Box>
            </ReactCardFlip>
          </Box>
          {preview && !isFormCreated && (
            <LoadingButton
              type="submit"
              loading={previewLoading}
              onClick={handlePreview}
              fullWidth
              size="mall"
              sx={{
                position: "fixed",
                bottom: 0,
                borderRadius: 0,
                mt: 2,
                color: "#fff",
                backgroundColor: "#F2CD00",
                ":hover": { backgroundColor: "#CDAF0B" },
              }}
              variant="contained"
            >
              RESET
            </LoadingButton>
          )}
        </Grid>
      </Drawer>
    </>
  );
};

export default EnlargeNotificationForm;
