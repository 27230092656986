import { useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const AddNewContractor = ({
  isSm,
  additionalData,
  formik,
  currentUser,
  setFormattedNewPhone,
  disableInputs,
  newSubmit,
  newContractorError,
}) => {
  const theme = useTheme();

  const handleLicenseChange = (event) => {
    // Replace letters with capital letters
    const value = event.target.value.toUpperCase();

    // Use Formik's setFieldValue to update the value
    formik.setFieldValue("licenseNumber", value);
  };

  const handleNewPhoneChange = (event) => {
    const { value } = event.target;
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    setFormattedNewPhone(formattedPhoneNumber); // Update the formatted phone in state
    formik.setFieldValue("phone", formattedPhoneNumber); // Update formik field value
  };

  return (
    <>
      <Typography
        align="center"
        sx={
          isSm
            ? {
                color: "#F2CD00",
                fontWeight: "bold",
                mt: 3,
                fontSize: "10px",
              }
            : { color: "#F2CD00", fontWeight: "bold", mt: 1 }
        }
        variant="subtitle1"
      >
        Please be careful while entering this information and double check your
        entries. This is the contractor information that will be incorporated in
        all the Notices of Commencement (NOC) you will file with all counties.
        You will only be allowed to change this one time after you have
        registered. No other changes will be permitted
      </Typography>
      <Grid container spacing={2} sx={{ mt: "1rem", minHeight: "52.5vh" }}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            variant="filled"
            error={!!(formik.touched.licenseType && formik.errors.licenseType)}
          >
            <InputLabel id="licenseType-label">License Type</InputLabel>
            <Select
              labelId="license_types"
              name="licenseType"
              label="License Type"
              value={formik.values.licenseType}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              sx={{
                maxWidth: "100%", // Set the desired max-width for the input element
                "& .MuiTypography-body2": {
                  whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                },
              }}
            >
              {additionalData?.license_types.map(function (contractor, i) {
                const isDisabled = currentUser?.user.contractors.some(
                  (obj) => obj.licenseType === contractor
                );
                return (
                  <MenuItem disabled={isDisabled} key={i} value={contractor}>
                    <Typography variant="body2">{contractor}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {formik.touched.licenseType && formik.errors.licenseType
                ? formik.errors.licenseType
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={disableInputs}
            fullWidth
            error={
              formik.touched.licenseNumber &&
              Boolean(formik.errors.licenseNumber)
            }
            helperText={
              formik.touched.licenseNumber && formik.errors.licenseNumber
            }
            value={formik.values.licenseNumber}
            name="licenseNumber"
            label="License Number*"
            onChange={handleLicenseChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disableInputs}
            fullWidth
            name="name"
            label="Contractor Name*"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disableInputs}
            fullWidth
            name="address"
            label="Contractor Address*"
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disableInputs}
            fullWidth
            name="phone"
            label="Phone Number*"
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            value={formik.values.phone}
            onChange={handleNewPhoneChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disableInputs}
            fullWidth
            name="fax"
            label="Fax Number"
            error={formik.touched.fax && Boolean(formik.errors.fax)}
            helperText={formik.touched.fax && formik.errors.fax}
            value={formik.values.fax}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disableInputs}
            fullWidth
            name="email"
            label="Email Address"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>
      <Box>
        <Typography
          variant="body1"
          color="error"
          fontWeight="bold"
          textAlign="center"
        >
          {newContractorError}
        </Typography>
        <LoadingButton
          loading={newSubmit}
          type="submit"
          variant="contained"
          size="large"
          sx={{ mt: 5 }}
          fullWidth
        >
          Submit
        </LoadingButton>
      </Box>
    </>
  );
};

export default AddNewContractor;
