import { useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Badge,
  IconButton,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import RefreshIcon from "@mui/icons-material/Refresh";

const MessageNotifications = ({
  setMessagesDataRows,
  reasonsData,
  setReasonsData,
  isLoading,
  setIsLoading,
  selectedName,
  setSelectedName,
  isSm,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(9);

  const handleListItemClick = async (event, index, item) => {
    setSelectedIndex(index);
    setSelectedName(item);
    try {
      const messagesResp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/get-posted-messages`,
        { item },
        { withCredentials: true }
      );
      setMessagesDataRows(messagesResp.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefresh = async () => {
    try {
      setIsLoading(true);
      const reasonsResp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/message-reasons-amounts`,
        { withCredentials: true }
      );

      setReasonsData(reasonsResp.data.data);
      // setSelectedIndex(9);
      // setMessagesDataRows([]);
      if (selectedName) {
        const item = selectedName;
        const messagesResp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/get-posted-messages`,
          { item },
          { withCredentials: true }
        );
        setMessagesDataRows(messagesResp.data.data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Card>
      <CardHeader
        title="Categories"
        action={
          <IconButton disabled={isLoading} onClick={handleRefresh}>
            <RefreshIcon className={isLoading ? "rotate" : ""} />
          </IconButton>
        }
      />
      <CardContent sx={{ height: "20rem", overflowY: "auto" }}>
        <List component="nav" aria-label="main mailbox folders">
          {reasonsData?.map((item, i) => (
            <ListItemButton
              key={i}
              selected={selectedIndex === i}
              onClick={(event) => handleListItemClick(event, i, item)}
            >
              <ListItemText primary={item.reason} />
              <Badge badgeContent={item.count} color="warning">
                <MailIcon sx={{ color: "#00588F" }} />
              </Badge>
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default MessageNotifications;
