import {
  Box,
  Grid,
  Container,
  Card,
  CircularProgress,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SelectCountyHeader from "../noc/SelectCountyHeader";
import SelectCountyForm from "../noc/SelectCountyForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

const SelectCounties = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [selectedPreForm, setSelectedPreForm] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [nameInitial, setNameInitial] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [cardRemain, setCardRemain] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cardPerPage = 8;
  const [notModified, setNotModified] = useState(true);
  const [load, setLoad] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const currentUser = useSelector((state) => state.auth.user);
  const noc_data = useSelector((state) => state.nocData.nocData);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedPreName, setSelectedPreName] = useState("");

  const [cardDisabled, setCardDisabled] = useState({});

  const [freeCounty, setFreeCounty] = useState(0);
  const loading = true;
  const [lockedNames, setLockedNames] = useState([]);
  const [cardText, setCardText] = useState("");
  const [enlargeFormInput, setEnlargeFormInput] = useState({
    general: "Alterations and repairs",
    interest: "Fee simple",
    form: "",
    name: "",
    added_name: "",
    added_email: "",
    locked: false,
  });
  const [countiesData, setCountiesData] = useState([]);
  const [filteredCountiesData, setFilteredCountiesData] = useState([
    countiesData,
  ]);
  const handleSubmit = async () => {
    try {
      setLoad(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/selectnoc`,
        { selectedForm },
        { withCredentials: true }
      );

      /////////////////////////
      setCardText("Your selected counties have been saved successfully");
      setNotModified(true);
      setLoad(false);
      const inputElement = document.getElementById("searchInput");
      if (inputElement) {
        inputElement.value = "";
      }
      setCurrentPage(1);
    } catch (err) {
      window.location.reload();
      setLoad(false);
    }
  };

  useEffect(() => {
    let names = [];
    let lockedNames = [];
    if (
      !currentUser ||
      !currentUser.user ||
      !currentUser.nocUserForms ||
      !noc_data
    ) {
      // Data is not available yet, do nothing
      return;
    }
    if (currentUser.user.expired === true) {
      navigate("/subscription");
    } else {
      try {
        if (
          currentUser.user.role === "admin" ||
          currentUser.user.role === "tester" ||
          currentUser?.user?.admin === true
        ) {
          setCardRemain(67);
          setFreeCounty(67);
        } else if (currentUser.user.role === "user") {
          if (currentUser.user.trialEnded === false) {
            setCardRemain(
              currentUser.tier.freeCounty +
                currentUser?.user.analytics?.additions.numCounties
            );
            setFreeCounty(
              currentUser.tier.freeCounty +
                currentUser?.user.analytics?.additions.numCounties
            );
          } else {
            if (currentUser.tier.numCounty === "all") {
              setCardRemain(67);
              setFreeCounty(67);
            } else {
              setCardRemain(
                currentUser.tier.numCounty +
                  currentUser?.user.analytics?.additions.numCounties
              );
              setFreeCounty(
                currentUser.tier.numCounty +
                  currentUser?.user.analytics?.additions.numCounties
              );
            }
          }
        }

        setSelectedForm(currentUser.user.selectedCountiesNOC);
        currentUser.user.selectedCountiesNOC.forEach((item) => {
          names.push(item.name);
          if (item.locked) {
            lockedNames.push(item.name);
          }
        });

        setNameInitial(names);
        setSelectedName(names);
        setLockedNames(lockedNames);
      } catch (err) {
        console.log(err.code);
        if (err.code === "ERR_NETWORK") {
          document.cookie =
            "NocCreator=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
        window.location.reload();
        setPageLoading(false);
      }

      const { displays, user } = currentUser;

      // Assuming displays and user are defined
      if (displays && user) {
        // Assuming both countyCards and selectedCountiesNOC are arrays
        const { countyCards } = displays;

        const sortedCounties = [...countyCards].sort((a, b) => {
          // Find the index of the current county name in selectedCountiesNOC
          const indexA = user.selectedCountiesNOC.findIndex(
            (county) => county.name === a.name
          );
          const indexB = user.selectedCountiesNOC.findIndex(
            (county) => county.name === b.name
          );

          // If both counties are in selectedCountiesNOC, sort by locked status
          if (indexA !== -1 && indexB !== -1) {
            return user.selectedCountiesNOC[indexA].locked
              ? -1
              : user.selectedCountiesNOC[indexB].locked
              ? 1
              : 0;
          }

          // If only one of the counties is in selectedCountiesNOC, prioritize it
          if (indexA !== -1) {
            return -1;
          }

          if (indexB !== -1) {
            return 1;
          }

          // If neither is in selectedCountiesNOC, maintain the current order
          return 0;
        });

        setCountiesData(sortedCounties);
        setFilteredCountiesData(sortedCounties);
      }

      setPageLoading(false);
    }
  }, [currentUser, noc_data]);

  useEffect(() => {
    if (cardText !== "") {
      const timer = setTimeout(() => setCardText(""), 3000);
      return () => clearTimeout(timer);
    }
    const isChanged =
      JSON.stringify(selectedName?.sort()) !==
      JSON.stringify(nameInitial?.sort());
    if (isChanged) {
      setNotModified(false);
    } else {
      setNotModified(true);
    }
  }, [nameInitial, selectedName, cardRemain, cardText]);

  // resp.data.data.tier.freeCounty

  const handleSearch = (event) => {
    setCurrentPage(1);
    let value = event.target.value.toLowerCase();
    let result = [];
    result = countiesData.filter((data) => {
      return data.name.toLowerCase().search(value) !== -1;
    });
    // setTasks(result)
    setFilteredCountiesData(result);
  };

  const getCurrentTasks = (tasks) => {
    const indexOfLastCard = currentPage * cardPerPage;
    const indexOfFirstCard = indexOfLastCard - cardPerPage;
    return tasks.slice(indexOfFirstCard, indexOfLastCard);
  };

  const selectCards = (countyName, form) => {
    setSelectedName((prevState) => {
      let copyArray = [...prevState];
      const index = copyArray.findIndex((name) => name === countyName);
      if (index !== -1) {
        copyArray.splice(index, 1);
      } else {
        copyArray.push(countyName);
      }
      return copyArray;
    });

    setSelectedForm((prevState) => {
      let copyForm = [...prevState];
      const index = copyForm.findIndex((item) => item.name === countyName);
      if (index !== -1) {
        copyForm.splice(index, 1);
      } else {
        copyForm.push({
          ...enlargeFormInput,
          form: form,
          name: countyName,
          added_name: `${currentUser?.user.personal[0].firstName[0].toUpperCase()}.${
            currentUser?.user.personal[0].lastName
          }`,
          added_email: currentUser?.subuser
            ? currentUser?.subuser?.email
            : currentUser?.user.email,
          locked: false,
        });
      }
      return copyForm;
    });

    setCardRemain((prevState) => {
      const index = selectedName.findIndex((name) => name === countyName);
      return index !== -1 ? prevState + 1 : prevState - 1;
    });
  };
  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl" py={3}>
              <Card
                py={3}
                px={isSmallScreen ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container>
                  <SelectCountyHeader
                    type="welcome"
                    handleSearch={handleSearch}
                    remainingLebal="Counties Remaining"
                    countyLeft={
                      freeCounty - selectedName.length < 0
                        ? "0"
                        : freeCounty - selectedName.length
                    }
                    countyOutOf={freeCounty}
                    headerText="Select Counties"
                    subtitleText="Select the counties you want to use. Once you have selected a county it will be highlighted. Don't forget to hit the Save button to confirm all your selected counties."
                  />
                </Grid>
                <Grid container justifyContent="center">
                  <Stack spacing={1} mt={2} mb={0}>
                    <LoadingButton
                      loading={load}
                      onClick={() => handleSubmit()}
                      disabled={notModified}
                      size="large"
                      sx={{
                        backgroundColor: "primary",
                        width: "30rem",
                      }}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                    <Typography
                      variant="body1"
                      color="#F2CD00"
                      fontWeight="bold"
                      textAlign="center"
                      sx={
                        isSmallScreen
                          ? {
                              minHeight: "1.5rem",
                              paddingLeft: "3rem",
                              paddingRight: "3rem",
                            }
                          : { minHeight: "1.5rem" }
                      }
                    >
                      {cardText}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid container>
                  <SelectCountyForm
                    setSelectedPreName={setSelectedPreName}
                    selectedPreName={selectedPreName}
                    cardDisabled={cardDisabled}
                    setCardDisabled={setCardDisabled}
                    noc_data={noc_data}
                    formDrawerOpen={formDrawerOpen}
                    setFormDrawerOpen={setFormDrawerOpen}
                    isSmallScreen={isSmallScreen}
                    setSelectedPreForm={setSelectedPreForm}
                    selectedPreForm={selectedPreForm}
                    setOpenDrawer={setOpenDrawer}
                    openDrawer={openDrawer}
                    cardText={cardText}
                    lockedNames={lockedNames}
                    freeCounty={freeCounty}
                    currentUser={currentUser}
                    filteredCountiesData={filteredCountiesData}
                    getCurrentTasks={getCurrentTasks}
                    type="welcome"
                    selectedName={selectedName}
                    selectCards={selectCards}
                    cardRemain={cardRemain}
                    loading={loading}
                    totalCounty={countiesData.length}
                    cardPerPage={cardPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    enlargeType="firstSelect"
                    enlargeHeader="Add Default Fields"
                    enlargeNote="It is not necessary to type in the same values over and over again. 
You can simply set these values as defaults.
Leave it blank if you are unsure."
                    enlargeFormInput={enlargeFormInput}
                    setEnlargeFormInput={setEnlargeFormInput}
                    setSelectedRow=""
                    addressRow=""
                    cardFlip=""
                  />
                </Grid>
              </Card>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default SelectCounties;
