import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Advertising } from "../../admin/management/Advertising";
import { CustomerService } from "../../admin/management/CustomerService";
import { Registration } from "../../admin/management/Registration";
import { LeavingReasons } from "../../admin/management/LeavingReasons";

const Management = () => {
  const theme = useTheme();
  const [managementData, setManagementData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  let count = 0;

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/managementData`,
          { withCredentials: true }
        );

        setManagementData(resp.data.data);

        setDataLoading(false);
      } catch (e) {
        console.log(e);
        setDataLoading(false);
      }
    };

    if (count < 1) {
      getData();
      count += 1;
    }
  }, []);

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 3,
            backgroundColor: "#EEF2F6",
          }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid xs={12} md={12} lg={12}>
                <Advertising
                  title="Advertising"
                  advertisingRows={managementData?.advertising}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid xs={12} md={12} lg={12}>
                <Registration
                  title="Registration"
                  registrationRows={managementData?.additional_registration}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid xs={12} md={12} lg={12}>
                <CustomerService
                  title="Customer Service"
                  customerServiceRows={managementData?.customer_service}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid xs={12} md={12} lg={12}>
                <LeavingReasons
                  title="Leaving Reasons"
                  leavingRows={managementData?.leaving_reasons}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Management;
