import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import validator from "validator";
import { useSignIn, useClerk } from "@clerk/clerk-react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { loginUser, clearError } from "../../store/actions/authActions";
import SelectField from "../inputComponents/SelectField";

import Cookies from "js-cookie";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  IconButton,
} from "@mui/material";
YupPassword(Yup);

const SubuserFinalize = () => {
  const { signIn } = useSignIn();
  const { signOut } = useClerk();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.auth.error);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [processLoad, setProcessLoad] = useState(false);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [iconsBlocked, setIconsBlocked] = useState(true);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subUserQuery = queryParams.get("token");
  const mainUserQuery = queryParams.get("main");
  const [step, setStep] = useState(1); // To track the current step
  const [emailLoading, setEmailLoading] = useState(false);
  const [mobileInputDisabled, setMobileInputDisabled] = useState(false);
  const [mobileText, setMobileText] = useState("");
  const [memorable, setMemorable] = useState("");
  const [memorableText, setMemorableText] = useState("");
  const [memorableSelections, setMemorableSelection] = useState([]);
  const [fromMemorableBlocked, setFromMemorableBlocked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    mobile: "",
    code: "",
  };

  useEffect(() => {
    if (blocked) {
      const timer = setInterval(() => {
        navigate("/login");
        clearInterval(timer);
      }, 6000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [blocked, step]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if a cookie named 'yourCookieName' has the value 'NocCreator'
      if (Cookies.get("NocCreator")) {
        navigate("/selectcounties");
      } else {
        // Cookie does not have the desired value
        // Add an alternative code block here if needed
      }
    }, 3000); // Check every 3 seconds

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const areAllFieldsFilled = inputValues.every((val) => val !== "");

    const callAuth = async () => {
      setProcessLoad(true);
      const code = inputValues.join("");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/finalize/auth-user`,
          {
            code: code,
            subUser: subUserQuery,
            mainUser: mainUserQuery,
            values: detailsFormik.values,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          dispatch({ type: "CHECK_TOKEN_COOKIE" });
        }
      } catch (e) {
        setProcessLoad(false);
        if (e.response.data.message === "blocked") {
          setBlocked(true);
          if (e.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(e.response.data.data);
          }

          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
          );
        } else if (e.response.data.message === "countDown") {
          setBlockedText(
            `You have only ${e.response.data.data} more attempts left. After that, you will be temporarily blocked from authentication for an hour.`
          );
          setTryLeft(e.response.data.data);
        }

        setInputValues(["", "", "", "", "", ""]);
        console.log(e);
      }
    };

    if (areAllFieldsFilled) {
      callAuth();
    }
  }, [inputValues]);

  useEffect(() => {
    if (!subUserQuery || !mainUserQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/finalize/get-user-detail`,
          {
            subUser: subUserQuery,
            mainUser: mainUserQuery,
          }
        );

        if (tokenResponse.data.success) {
          const maskedEmail = maskEmail(tokenResponse.data.data);
          setMemorableSelection(tokenResponse.data.memorableList);
          setUserEmail(maskedEmail);
          // setUserEmail(tokenResponse.data.data);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/finalize/generate_qr`,
          {
            subUser: subUserQuery,
            mainUser: mainUserQuery,
          },
          {
            responseType: "blob",
          }
        );
        if (response.status === 200) {
          const qrCodeUrl = URL.createObjectURL(new Blob([response.data]));
          setQRCodeSrc(qrCodeUrl);
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error.response.data.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error.response.data.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    process_data();
  }, []);

  const handleEmailFa = async () => {
    try {
      setEmailLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/finalize/email_auth`,
        {
          subUser: subUserQuery,
          mainUser: mainUserQuery,
          values: detailsFormik.values,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        dispatch({ type: "CHECK_TOKEN_COOKIE" });
      }

      if (response.data.success === false) {
        setEmailLoading(false);
      }
    } catch (e) {
      console.log(e);
      setEmailLoading(false);
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleImageClick = (index) => {
    if (selectedImage === index) {
      setSelectedImage(null);
    } else {
      setSelectedImage(index);
    }
  };

  const validationSchemas = [
    // Validation schema for Step 1
    Yup.object({
      mobile: Yup.string()
        .min(10, "Number is too short")
        .max(10, "Number is too long")
        .required("Mobile number is required"),
    }),
    // Validation schema for Step 2 (code)
    Yup.object({
      code: Yup.string().required("Verification code is required"),
    }),
  ];

  useEffect(() => {
    const getItemFromLocalStorage = (key) => {
      const item = localStorage.getItem(key);
      return JSON.parse(item); // If the item is an object, parse it back to an object
    };

    const userData = getItemFromLocalStorage("ujsffq");

    if (userData) {
      setDefEmail(userData.email);
      setDefPwd(userData.password);
      setRemember(true);
    }
  }, []);

  useEffect(() => {
    if (
      error !== "" &&
      error !== "We are updating our application. Please come back later"
    ) {
      const timer = setTimeout(() => {
        dispatch(clearError()); // Dispatch the clearError action
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  // Generate a random six-character number

  const mobileFormik = useFormik({
    initialValues,
    validationSchema: validationSchemas[step - 1], // Use the schema for the current step
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      const send_sms = async () => {
        try {
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/finalize/sms/sendVerify`,
            {
              mobile: values["mobile"],
              subUser: subUserQuery,
              mainUser: mainUserQuery,
            }
          );
          return resp;
        } catch (e) {
          console.log(e);
        }
      };

      if (step === 1) {
        const send_response = await send_sms();

        if (send_response.data.success === true) {
          setStep(2);
          setMobileInputDisabled(true);
          setMobileText(send_response.data.message);
          setIconsBlocked(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(true);
        const verify_sms = async () => {
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/finalize/getVerify`,
              {
                code: values["code"],
                subUser: subUserQuery,
                mainUser: mainUserQuery,
                values: detailsFormik.values,
              },
              { withCredentials: true }
            );
            return resp;
          } catch (e) {
            values["code"] = "";
            setLoading(false);
            setMobileText("Invalid Verification code");
            console.log(e);
          }
        };

        const smsResp = await verify_sms();

        if (smsResp.data.success) {
          dispatch({ type: "CHECK_TOKEN_COOKIE" });
        } else {
          values["code"] = "";
          setLoading(false);
          setMobileText("Invalid Verification code");
        }
      }
    },
  });

  const maskEmail = (email) => {
    const atPosition = email.indexOf("@");
    if (atPosition !== -1) {
      const charsToMask = Math.max(
        1,
        Math.floor(email.slice(0, atPosition).length / 2)
      );
      const maskedEmail =
        email.slice(0, charsToMask) +
        "*".repeat(atPosition - charsToMask) +
        email.slice(atPosition);
      return maskedEmail;
    } else {
      return email;
    }
  };

  const detailsFormik = useFormik({
    enableReinitialize: false,
    initialValues: {
      password: "",
      confirmPassword: "",
      selectMemorable: "",
      memorable: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "Password must contain at least 1 lower case letter")
        .minUppercase(1, "Password must contain at least 1 upper case letter")
        .minNumbers(1, "Password must contain at least 1 number")
        .minSymbols(1, "Password must contain at least 1 special character")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .required("Password confirmation is required")
        .test("Passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
      selectMemorable: Yup.string().required("Reminder question is reqired"),
      memorable: Yup.string()
        .min(5, "Reminder answer must be at least 5 characters long")
        .required("Reminder answer is required"),
    }),
    onSubmit: async (values, helpers) => {
      console.log("submit");
    },
  });

  useEffect(() => {
    if (detailsFormik) {
      if (
        detailsFormik?.values.password !== "" &&
        detailsFormik?.values.confirmPassword !== "" &&
        detailsFormik?.values.selectMemorable !== "" &&
        detailsFormik?.values.memorable !== "" &&
        detailsFormik?.isValid
      ) {
        setFromMemorableBlocked(false);
        setIconsBlocked(false);
      } else {
        setFromMemorableBlocked(true);
        setIconsBlocked(true);
        setSelectedImage(null);
      }
    }
  }, [detailsFormik]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "20rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/logos/nocLogo.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color="primary"
                variant="h4"
                align="center"
              >
                Complete your user account setup and activate two-factor
                authentication (2FA)
              </Typography>
              <Typography color="primary" variant="body1" align="center">
                At NOC Creator, your security is paramount. To safeguard your
                account and data, we require Two-Factor Authentication (2FA).
                This extra layer of protection helps prevent unauthorized access
                and keeps your information secure. Setting up 2FA is quick,
                easy, and essential for safe use of our platform.
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="Password"
                    error={
                      detailsFormik.touched.password &&
                      Boolean(detailsFormik.errors.password)
                    }
                    helperText={
                      detailsFormik.touched.password &&
                      detailsFormik.errors.password
                    }
                    value={detailsFormik.values.password}
                    onChange={detailsFormik.handleChange}
                    onBlur={detailsFormik.handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                            onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    error={
                      detailsFormik.touched.confirmPassword &&
                      Boolean(detailsFormik.errors.confirmPassword)
                    }
                    helperText={
                      detailsFormik.touched.confirmPassword &&
                      detailsFormik.errors.confirmPassword
                    }
                    value={detailsFormik.values.confirmPassword}
                    onChange={detailsFormik.handleChange}
                    onBlur={detailsFormik.handleBlur}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={
                      detailsFormik.touched.selectMemorable &&
                      Boolean(detailsFormik.errors.selectMemorable)
                    }
                    onBlur={detailsFormik.handleBlur}
                  >
                    <InputLabel>Select a reminder question</InputLabel>
                    <Select
                      label="Select"
                      name="selectMemorable"
                      value={detailsFormik.values.selectMemorable || ""}
                      onChange={(e) =>
                        detailsFormik.setFieldValue(
                          "selectMemorable",
                          e.target.value
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "1.5px",
                            maxHeight: 150, // Set the desired max height for the dropdown menu
                            width: "auto",
                            maxWidth: "100%",
                          },
                        },
                      }}
                      sx={{
                        maxWidth: "100%", // Set the desired max-width for the input element
                        "& .MuiTypography-body2": {
                          whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                        },
                      }}
                    >
                      {memorableSelections?.map(function (item, i) {
                        return (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {detailsFormik.touched.selectMemorable &&
                      detailsFormik.errors.selectMemorable && (
                        <FormHelperText>
                          {detailsFormik.errors.selectMemorable}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="memorable"
                    label="Enter Your Answer Here"
                    error={
                      detailsFormik.touched.memorable &&
                      Boolean(detailsFormik.errors.memorable)
                    }
                    helperText={
                      detailsFormik.touched.memorable &&
                      detailsFormik.errors.memorable
                    }
                    value={detailsFormik.values.memorable}
                    onChange={detailsFormik.handleChange}
                    onBlur={detailsFormik.handleBlur}
                  />
                </Grid>
              </Grid>

              <Typography
                sx={{ mb: 2, mt: 5 }}
                color="primary"
                variant="h5"
                align="center"
              >
                Please Select One
              </Typography>

              <Grid container spacing={2} sx={{ mt: 4 }}>
                {images.map((image, index) => (
                  <Grid item sm={12} lg={4} key={index}>
                    <Box
                      sx={
                        blocked || iconsBlocked || fromMemorableBlocked
                          ? {
                              borderRadius: "10px",
                              backgroundColor: "transparent",
                              flexDirection: "column",
                              cursor: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }
                          : {
                              borderRadius: "10px",
                              backgroundColor:
                                selectedImage === index
                                  ? "#f3f3f3"
                                  : "transparent",
                              flexDirection: "column",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              transform:
                                hovered === index ? "scale(1.1)" : "scale(1)",
                              transition: "transform 0.3s ease",
                            }
                      }
                      onClick={() =>
                        !iconsBlocked || !fromMemorableBlocked
                          ? handleImageClick(index)
                          : null
                      }
                      onMouseEnter={() =>
                        !iconsBlocked || !fromMemorableBlocked
                          ? setHovered(index)
                          : null
                      }
                      // onMouseLeave={handleImageLeave}
                    >
                      <Box sx={{ pb: 3, pt: 1 }}>
                        <img
                          alt={image.alt}
                          src={image.src}
                          width={
                            selectedImage === index
                              ? image.width * 1.1
                              : image.width
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
                {selectedImage === 0 && (
                  <>
                    <Grid sx={{ mt: 6 }} item sm={12}>
                      <Box
                        sx={{
                          p: 4,
                          borderRadius: "10px",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        <Typography variant="h5" align="center">
                          {userEmail}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item sm={12}>
                      <LoadingButton
                        // SUBMIT //
                        onClick={handleEmailFa}
                        loading={emailLoading}
                        fullWidth
                        size="large"
                        sx={{ mt: 0, p: 2 }}
                        variant="contained"
                      >
                        P R O C E E D
                      </LoadingButton>
                    </Grid>
                    <Grid item sm={12}>
                      <Typography
                        variant="body1"
                        sx={{ minHeight: "1.5rem" }}
                        textAlign="center"
                        my={1}
                        color="error"
                        fontWeight="bold"
                      >
                        {error}
                      </Typography>
                    </Grid>
                  </>
                )}
                {selectedImage === 1 && (
                  <>
                    <form
                      style={{ width: "100%" }}
                      noValidate
                      autoComplete="off"
                      onSubmit={mobileFormik.handleSubmit}
                    >
                      <Grid container spacing={2}>
                        <Grid sx={{ mt: 6, mb: 6 }} item sm={12} lg={6}>
                          <TextField
                            error={
                              !!(
                                mobileFormik.touched.mobile &&
                                mobileFormik.errors.mobile
                              )
                            }
                            helperText={
                              mobileFormik.touched.mobile &&
                              mobileFormik.errors.mobile
                            }
                            disabled={mobileInputDisabled}
                            name="mobile"
                            type="number"
                            label="Mobile Number"
                            variant="filled"
                            fullWidth
                            onBlur={mobileFormik.handleBlur}
                            value={mobileFormik.values.mobile}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +1
                                </InputAdornment>
                              ),
                              inputProps: { pattern: "[0-9]*" }, // Allow only numeric input
                              // Add any other input props or attributes as needed
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              mobileFormik.handleChange(e); // Trigger Formik's handleChange as well
                            }}
                          />
                        </Grid>
                        <Grid sx={{ mt: 6 }} item sm={12} lg={6}>
                          {step === 2 && (
                            <TextField
                              error={
                                !!(
                                  mobileFormik.touched.code &&
                                  mobileFormik.errors.code
                                )
                              }
                              fullWidth
                              helperText={
                                mobileFormik.touched.code &&
                                mobileFormik.errors.code
                              }
                              name="code"
                              value={mobileFormik.values.code}
                              label="Verification Code"
                              variant="filled"
                              onChange={(e) => {
                                mobileFormik.handleChange(e); // Trigger Formik's handleChange as well
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography
                          variant="body1"
                          sx={{ minHeight: "1.5rem" }}
                          textAlign="center"
                          my={1}
                          color="#004976"
                          fontWeight="bold"
                        >
                          {mobileText}
                        </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          size="large"
                          sx={{ mt: 0, p: 2 }}
                          type="submit"
                          variant="contained"
                        >
                          {step === 1
                            ? "Send Verification Code"
                            : "S U B M I T"}
                        </LoadingButton>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography
                          variant="body1"
                          sx={{ minHeight: "1.5rem" }}
                          textAlign="center"
                          my={1}
                          color="error"
                          fontWeight="bold"
                        >
                          {error}
                        </Typography>
                      </Grid>
                    </form>
                  </>
                )}
                {selectedImage === 2 && (
                  <>
                    <Grid
                      sx={{ mt: 3, overflowY: "auto", maxHeight: "15rem" }}
                      item
                      sm={12}
                      md={6}
                    >
                      <Typography variant="h5">Set up Authenticator</Typography>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Link
                              // component={NextLink}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                // ml: "auto",
                                mt: 2,
                                cursor: "pointer",
                              }}
                              target="_blank"
                              underline="hover"
                              variant="subtitle2"
                              rel="noopener noreferrer"
                              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            >
                              1. Download Google Authenticator
                            </Link>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            2. On your mobile device, go to your{" "}
                            <Link
                              // component={NextLink}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                // ml: "auto",
                                mt: 2,
                                cursor: "pointer",
                              }}
                              target="_blank"
                              underline="hover"
                              variant="subtitle2"
                              rel="noopener noreferrer"
                              href="https://myaccount.google.com/?pli=1&nlr=1"
                            >
                              Google Account
                            </Link>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            3. At the top, tap the Security tab
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            4. Under "You can add more sign-in options," tap
                            Authenticator
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            5. Tap Set up authenticator
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            6. Follow the on-screen steps
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Box
                        display="flex"
                        justifyContent="center" // Center horizontally
                        alignItems="center" // Center vertically
                        height="100%" // Ensure the box takes the full height
                      >
                        {qrCodeSrc ? (
                          <img width={200} src={qrCodeSrc} alt="QR Code" />
                        ) : (
                          <p>Loading QR Code...</p>
                        )}
                      </Box>
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          width: "50%",
                        }}
                      >
                        <Typography color="error" fontWeight="bold">
                          {blockedText}
                        </Typography>
                      </Box>

                      <Box
                        sx={
                          processLoad
                            ? { display: "flex", ml: "auto", mr: "auto" }
                            : { display: "flex", ml: "auto" }
                        }
                      >
                        {processLoad ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                height: "100&",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <CircularProgress color="warning" size="5rem" />
                            </Box>
                          </>
                        ) : (
                          inputValues.map((value, index) => (
                            <Grid item key={index} sx={{ mx: "6px" }}>
                              <TextField
                                disabled={blocked}
                                id={`input-${index}`}
                                value={value}
                                variant="outlined"
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                onPaste={index === 0 ? handlePaste : undefined}
                                inputProps={{
                                  maxLength: 1,
                                  style: {
                                    fontSize: "28px",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  width: "48px",
                                  textAlign: "center",
                                  border: "3px solid #000",
                                  borderRadius: "10px",
                                }}
                              />
                            </Grid>
                          ))
                        )}
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

const images = [
  {
    alt: "email",
    src: "/img/logos/emailImage.png",
    // width: 120,
    width: 100,
  },
  {
    alt: "mobile",
    src: "/img/logos/phone.png",
    // width: 180,
    width: 160,
  },
  {
    alt: "authenticator",
    src: "/img/logos/authenticator.png",
    // width: 167,
    width: 147,
  },
];

export default SubuserFinalize;
