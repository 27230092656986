import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSignIn, useClerk } from "@clerk/clerk-react";
import { loginUser, clearError } from "../../store/actions/authActions";
import { fetchUserData } from "../../store/actions/authActions"; // Import your user action
import { setLocalStorageItem } from "../../store/actions/localStorageActions";
import {
  initializeWebSocket,
  closeWebSocket,
} from "../../utils/websocketService";

import Cookies from "js-cookie";

import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";

const SmsFaAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.auth.error);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const rememberFromQuery = queryParams.get("remember");
  const [isTrouble, setIsTrouble] = useState(false);
  const [memorableValue, setMemorableValue] = useState("");
  const [memorableOption, setMemorableOption] = useState("");
  const [showMemorable, setShowMemorable] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [submitBlocked, setSubmitBlocked] = useState(true);

  let count = 0;
  useEffect(() => {
    if (blocked) {
      setSubmitBlocked(true);
    } else if (isTrouble && memorableValue === "") {
      setSubmitBlocked(true);
    } else if (
      !isTrouble &&
      (inputValues[0] === "" ||
        inputValues[1] === "" ||
        inputValues[2] === "" ||
        inputValues[3] === "" ||
        inputValues[4] === "" ||
        inputValues[5] === "")
    ) {
      setSubmitBlocked(true);
    } else {
      setSubmitBlocked(false);
    }
  }, [isTrouble, memorableValue, inputValues, blocked]);

  useEffect(() => {
    const memInterval = setInterval(() => {
      setBlockedText("");
    }, 5000);

    return () => {
      clearInterval(memInterval);
    };
  }, [blockedText]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (blocked) {
        navigate("/login");
        clearInterval(timer);
      }
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, [blocked]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if a cookie named 'yourCookieName' has the value 'NocCreator'
      if (Cookies.get("NocCreator")) {
        navigate("/dashboard");
      } else {
        // Cookie does not have the desired value
        // Add an alternative code block here if needed
      }
    }, 3000); // Check every 3 seconds

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const callAuth = async () => {
    const code = inputValues.join("");
    setLoading(true);

    if (isTrouble && !isEmailSent) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/memorable-from-sms`,
          {
            token: tokenFromQuery,
            memorableValue: memorableValue,
          },
          { withCredentials: true }
        );

        setBlockedText(response.data.message);

        if (response.data.success) {
          setIsEmailSent(true);
          setLoading(false);
          setMemorableValue("");
        } else {
          setMemorableValue("");
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
        setMemorableValue("");
        setBlockedText(e.response.data.message);
      }
    } else if (isTrouble && isEmailSent) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/validate-memorable-email`,
          {
            token: tokenFromQuery,
            memorableValue: memorableValue,
            remember_token: rememberFromQuery,
          },
          { withCredentials: true }
        );

        if (response.data.success) {
          if (rememberFromQuery === "true") {
            dispatch(
              setLocalStorageItem("ujsffq", { email: response.data.data.email })
            );
          }
          dispatch({ type: "CHECK_TOKEN_COOKIE" });
          dispatch(fetchUserData());
          const token = document.cookie
            .split("; ")
            .find((row) => row.startsWith("NocCreator="))
            ?.split("=")[1];

          if (token) {
            initializeWebSocket(token, dispatch);
          }
        } else {
          setLoading(false);
          setMemorableValue("");
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
        setBlockedText(e.response.data.message);
        setMemorableValue("");
        setShowMemorable(false);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/sms-pass`,
          {
            code: code,
            token: tokenFromQuery,
            remember_token: rememberFromQuery,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          if (rememberFromQuery === "true") {
            dispatch(
              setLocalStorageItem("ujsffq", { email: response.data.data.email })
            );
          }
          dispatch({ type: "CHECK_TOKEN_COOKIE" });
          dispatch(fetchUserData());
          const token = document.cookie
            .split("; ")
            .find((row) => row.startsWith("NocCreator="))
            ?.split("=")[1];

          if (token) {
            initializeWebSocket(token, dispatch);
          }
        }
      } catch (e) {
        if (e.response.data.message === "blocked") {
          setBlocked(true);
          if (e.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(e.response.data.data);
          }

          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
          );
        } else if (e.response.data.message === "countDown") {
          setBlockedText(
            `You have only ${e.response.data.data} more attempts left. After that, you will be temporarily blocked from authentication for an hour.`
          );
          setTryLeft(e.response.data.data);
        }

        setInputValues(["", "", "", "", "", ""]);
        setLoading(false);
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/sms/sendAuthVerify`,
          {
            token: tokenFromQuery,
          }
        );
        if (tokenResponse.data.success) {
          setPageLoading(false);
          setMemorableOption(tokenResponse.data.data);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error.response.data.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error.response.data.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    if (count < 1) {
      process_data();
      count += 1;
    }
  }, []);

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "20rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/logos/nocLogo.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color="primary"
                variant="h4"
                align="center"
              >
                Two Factor Authentication is Required
              </Typography>
              <Typography color="primary" variant="h5" align="center">
                {isTrouble
                  ? "Unlock with your reminder answer"
                  : "A text message has already been sent to you"}
              </Typography>
              <Typography
                color="primary"
                variant="body1"
                align="center"
                sx={{ mb: 5 }}
              >
                {isTrouble
                  ? !isEmailSent
                    ? "To proceed with the login process, please enter your reminder answer below."
                    : "To proceed with the login process, please enter your verification code sent by email."
                  : "To proceed with the login process, please enter the code below."}
              </Typography>

              <Grid container justifyContent="center" spacing={2}>
                {images.map((image, index) => (
                  <Grid item sm={12} lg={4} key={index}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "transparent",
                        flexDirection: "column",
                        cursor: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box sx={{ pb: 3, pt: 1 }}>
                        <img
                          alt={image.alt}
                          src={image.src}
                          width={
                            selectedImage === index
                              ? image.width * 1.1
                              : image.width
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}

                <>
                  <Grid container>
                    <Box
                      sx={
                        isTrouble
                          ? {
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mx: "auto",
                            }
                          : {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              mx: "auto",
                            }
                      }
                    >
                      {isTrouble ? (
                        <>
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            {isEmailSent
                              ? "Enter your Verification Code"
                              : memorableOption}
                          </Typography>
                          <TextField
                            type={showMemorable ? "text" : "password"}
                            label={
                              isEmailSent
                                ? "Verification Code"
                                : "Enter your reminder answer"
                            }
                            value={memorableValue}
                            onChange={(e) => setMemorableValue(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowMemorable(!showMemorable);
                                    }}
                                    onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                    onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                    edge="end"
                                  >
                                    {showMemorable ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        inputValues.map((value, index) => (
                          <Grid item key={index} sx={{ mx: "6px" }}>
                            <TextField
                              disabled={blocked}
                              id={`input-${index}`}
                              value={value}
                              variant="outlined"
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              onPaste={index === 0 ? handlePaste : undefined}
                              inputProps={{
                                maxLength: 1,
                                style: {
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                },
                              }}
                              sx={{
                                width: "48px",
                                textAlign: "center",
                                border: "3px solid #000",
                                borderRadius: "10px",
                              }}
                            />
                          </Grid>
                        ))
                      )}
                    </Box>
                  </Grid>

                  <Grid container justifyContent="center" mb={2} mt={5}>
                    <Grid item sm={12} sx={{ width: "100%" }}>
                      <LoadingButton
                        onClick={callAuth}
                        disabled={submitBlocked}
                        loading={loading}
                        fullWidth
                        size="large"
                        sx={{ mt: 0, p: 2 }}
                        type="submit"
                        variant="contained"
                      >
                        S U B M I T
                      </LoadingButton>
                    </Grid>
                    <Link
                      // component={NextLink}
                      sx={{
                        fontWeight: "bold",
                        // ml: "auto",
                        mt: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsTrouble(!isTrouble);
                        setMemorableValue("");
                        setInputValues(["", "", "", "", "", ""]);
                      }}
                      underline="hover"
                      variant="subtitle2"
                    >
                      {isTrouble ? "Back to 2FA" : "Having trouble logging in?"}
                    </Link>
                    <Grid item sm={12} sx={{ mt: 2, minHeight: "1.5rem" }}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="error"
                        fontWeight="bold"
                      >
                        {blockedText}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

const images = [
  {
    alt: "authenticator",
    src: "/img/logos/2falogo.png",
    // width: 167,
    width: 250,
  },
];

export default SmsFaAuth;
