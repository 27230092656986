import { Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const TotalNoc = (props) => {
  const { value = 0 } = props;

  return (
    <Card sx={{ minHeight: "10rem", position: "relative" }}>
      {!props?.fromSub && (
        <Tooltip
          title="This represents the total number of NOC forms you have personally created."
          placement="top"
        >
          <HelpOutlineIcon
            color="primary"
            sx={{ position: "absolute", right: 10, top: 10 }}
          />
        </Tooltip>
      )}

      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {props?.fromSub ? "TOTAL NOC Created" : "Your TOTAL NOC Created"}
            </Typography>
            <Typography variant="h5">{value}</Typography>
          </Stack>
          <img
            alt="noc-form"
            src="/img/dashboard/nocformIcon.jpeg"
            style={{ width: "7.5rem", margin: 0 }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TotalNoc;
