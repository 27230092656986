import BellIcon from "@heroicons/react/24/solid/BellIcon";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ScrollingText from "./ScrollingText";
import { usePopover } from "../../hooks/use-popover";
import { AccountPopover } from "../popover/account-popover";
import { NotificationPopover } from "../popover/notification-popover";
const TOP_NAV_HEIGHT = 64;

const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const notificationPopover = usePopover();
  const [failedCounties, setFailedCounties] = useState([]);
  const [expSubText, setExpSubText] = useState("");
  const renewDateObj = new Date(props?.user?.user?.cancellation_time * 1000);

  useEffect(() => {
    if (props?.user?.user?.expired) {
      if (!props?.user?.subuser) {
        setExpSubText(
          'Payment Failed *** Your subscription has expired! Please create a new subscription to continue using NOC Creator by visiting the "Manage Subscription" page.'
        );
      } else {
        setExpSubText(
          "Payment Failed *** Your subscription has expired! Please contact your Administrator to create a  new subscription to continue using NOC Creator."
        );
      }
    } else if (props?.user?.user?.cancellation_time) {
      if (!props?.user?.subuser) {
        setExpSubText(
          `Canceled Subscription *** Your subscription will be canceled on ${renewDateObj.toLocaleDateString(
            "en-US"
          )}. You can reactivate it anytime on the "Manage Subscription" page.`
        );
      } else {
        setExpSubText(
          `Canceled Subscription *** Your subscription will be canceled on ${renewDateObj.toLocaleDateString(
            "en-US"
          )}. Please contact your Administrator to reactivate it.`
        );
      }
    } else if (props.noc_data) {
      if (props?.noc_data[0]?.failed_counties.length === 0) {
        setExpSubText("");
        setFailedCounties("");
      } else {
        setFailedCounties(props?.noc_data[0]?.failed_counties);
      }
    } else {
      setExpSubText("");
    }
  }, [props.user, props.noc_data, expSubText]);
  return (
    <>
      <Box>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <div className="scroll-wrapper">
            <ScrollingText
              expiredSubscription={expSubText}
              failedCounties={failedCounties}
            />
          </div>

          <Stack alignItems="center" direction="row" spacing={2}>
            <Tooltip title="Notifications">
              <IconButton
                disabled={props?.user?.user?.expired ? true : false}
                onClick={notificationPopover.handleOpen}
                ref={notificationPopover.anchorRef}
              >
                <Badge
                  badgeContent={props?.user?.user?.analytics.num_notification}
                  color="success"
                  variant="number"
                >
                  <SvgIcon fontSize="medium">
                    <BellIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src="/assets/avatars/avatar-marcus-finn.png"
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        user={props.user}
        anchorEl={notificationPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
      <NotificationPopover
        user={props.user}
        anchorEl={notificationPopover.anchorRef.current}
        open={notificationPopover.open}
        onClose={notificationPopover.handleClose}
      />
    </>
  );
};

export default TopNav;
