import {
  Typography,
  useMediaQuery,
  useTheme,
  Switch,
  styled,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Box,
  Tabs,
  Button,
  CardActions,
} from "@mui/material";

const useSliderStyle = (isChangePlan, isExpired) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  if (isChangePlan === true) {
    if (isLg) {
      return { ml: "auto", mr: "auto", maxWidth: "70vw", minHeight: "52vh" };
    } else if (isSm) {
      return { ml: "auto", mr: "auto", maxWidth: "85vw", minHeight: "52vh" };
    } else if (isMd) {
      return { ml: "auto", mr: "auto", maxWidth: "89vw", minHeight: "52vh" };
    }
  } else if (isExpired) {
    if (isLg) {
      return { ml: "auto", mr: "auto", maxWidth: "80vw", minHeight: "52vh" };
    } else if (isSm) {
      return { ml: "auto", mr: "auto", maxWidth: "85vw", minHeight: "52vh" };
    } else if (isMd) {
      return { ml: "auto", mr: "auto", maxWidth: "89vw", minHeight: "52vh" };
    }
  } else {
    if (isLg) {
      return { ml: "auto", mr: "auto", maxWidth: "60.5vw", minHeight: "52vh" };
    } else if (isSm) {
      return { ml: "auto", mr: "auto", maxWidth: "85vw", minHeight: "52vh" };
    } else if (isMd) {
      return { ml: "auto", mr: "auto", maxWidth: "89vw", minHeight: "52vh" };
    }
  }
};

const ChoosePlan = ({
  additionalData,
  userEmail,
  selectedName,
  setSelectedName,
  tiers,
  isChangePlan,
  currentPlan,
  isExpired,
  setPlan,
  checked,
  setChecked,
  contractorList,
}) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const sliderStyle = useSliderStyle(isChangePlan, isExpired);
  const handleDisabled = (tier) => {
    // if (!isChangePlan && selectedName !== tier.title) {
    //   return true;
    // } else {
    //   if (checked) {
    //     if (currentPlan === tier.price_id_year) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     if (currentPlan === tier.price_id_month) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // }
    if (checked) {
      if (currentPlan === tier.price_id_year) {
        return true;
      } else {
        return false;
      }
    } else {
      if (currentPlan === tier.price_id_month) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSelect = (tier) => {
    setSelectedName(tier.title);

    setPlan(tier);
  };

  const handleCardStyle = (tier) => {
    if (isChangePlan) {
      if (checked) {
        if (currentPlan === tier.price_id_year) {
          return {
            mr: "1rem",
            minWidth: "15rem",
            backgroundColor: "#76A0B9",
            color: "#fff",
          };
        }
        if (selectedName === tier.title) {
          return {
            mr: "1rem",
            minWidth: "15rem",
            backgroundColor: "#F2CD00",
            color: "#004976",
          };
        } else {
          return {
            mr: "1rem",
            minWidth: "15rem",
            backgroundColor: "#EEF2F6",
            color: "#004976",
          };
        }
      } else {
        if (currentPlan === tier.price_id_month) {
          return {
            mr: "1rem",
            minWidth: "15rem",
            backgroundColor: "#76A0B9",
            color: "#fff",
          };
        }
        if (selectedName === tier.title) {
          return {
            mr: "1rem",
            minWidth: "15rem",
            backgroundColor: "#F2CD00",
            color: "#004976",
          };
        } else {
          return {
            mr: "1rem",
            minWidth: "15rem",
            backgroundColor: "#EEF2F6",
            color: "#004976",
          };
        }
      }
    } else if (isExpired) {
      if (selectedName === tier.title) {
        return {
          mr: "1rem",

          minWidth: "12rem",
          backgroundColor: "#F2CD00",
          color: "#004976",
          mt: 2,
        };
      } else {
        return {
          mr: "1rem",
          mt: 2,
          minWidth: "12rem",
          backgroundColor: "#EEF2F6",
          color: "#004976",
        };
      }
    } else {
      if (selectedName === tier.title) {
        return {
          mr: "1rem",
          backgroundColor: "#F2CD00",
          color: "#004976",
          minWidth: "14rem",
          height: "20rem",
        };
      } else {
        return {
          mr: "1rem",
          backgroundColor: "#EEF2F6",
          color: "#004976",
          minWidth: "14rem",
          height: "20rem",
        };
      }
    }
  };

  return (
    <>
      <Stack
        sx={isChangePlan ? { pb: 2 } : { mt: 2, mb: 2 }}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body1"
          sx={!checked ? { fontWeight: "bold" } : { fontWeight: "normal" }}
        >
          Monthly
        </Typography>
        <AntSwitch
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography
          variant="body1"
          sx={
            checked
              ? { fontWeight: "bold", color: "#1890ff" }
              : { fontWeight: "normal" }
          }
        >
          Annual
        </Typography>
      </Stack>

      {!isChangePlan && !isExpired && (
        <Typography
          align="center"
          sx={{ color: "#F2CD00", fontWeight: "bold", mt: 1, mb: 5 }}
          variant="subtitle1"
        >
          For a limited time, enjoy full access to our website {<br />} and all
          its features 30 Days FREE of charge!
        </Typography>
      )}

      <Box
        component={Tabs}
        value={selectedName}
        sx={sliderStyle}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {tiers
          ?.filter((tier) => {
            const staff = additionalData?.staff;
            const tester = additionalData?.tester;

            // Check if userEmail is in either of the allowed email lists
            const isUserAllowed =
              staff.includes(userEmail) || tester.includes(userEmail);

            // Include the "Free" plan only if it's not changing plan and the user is allowed
            const isFreePlan = tier.title === "Free";

            const shouldInclude =
              !isChangePlan &&
              (isUserAllowed || !isFreePlan) &&
              // Additional condition based on the length of contractorList
              (contractorList.length === 1 || // Allow all types when length is 1
                (contractorList.length > 1 && tier.type === "multi")); // Show "multi" type only when length is greater than 1

            return shouldInclude;
          })
          .sort((a, b) => a.id - b.id)
          .map((tier, i) => (
            // Enterprise card is full width at sm breakpoint
            <Stack key={i} value={tier?.title}>
              <Card sx={handleCardStyle(tier)}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: "inherit",
                    pt: 2,
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      color="primary"
                      // sx={tier.title !== "Free" && { visibility: "hidden" }}
                    >
                      {/* {tier.title === "Free"
                      ? "$" + (!checked ? tier.monthlyPrice : tier.yearlyPrice)
                      : "..."} */}
                      {"$" + (!checked ? tier.monthlyPrice : tier.yearlyPrice)}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {/* {tier.title === "Free" && (!checked ? "/month" : "/year")} */}
                      {!checked ? "/month" : "/year"}
                    </Typography>
                  </Box>

                  {tier.description.map((line) => (
                    <Typography
                      sx={
                        tier.title === "Free"
                          ? { textAlign: "center" }
                          : { textAlign: "center" }
                      }
                      variant="subtitle2"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                  {/* {tier.title !== "Free" && (
                  <Typography align="center" fontWeight="bold">
                    COMING SOON...
                  </Typography>
                )} */}
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#F01329",
                      fontWeight: "bold",
                      mt: 1,
                    }}
                    variant="subtitle2"
                  >
                    {tier.title === "Free" && tier?.discount}
                    {!isChangePlan &&
                      tier.title !== "Free" &&
                      !isExpired &&
                      tier.freeCounty +
                        " County and " +
                        tier.freeNOC +
                        " NOC FREE"}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    disabled={handleDisabled(tier)}
                    onClick={() => handleSelect(tier)}
                    sx={{ width: "100%" }}
                    variant={
                      selectedName === tier.title ? "contained" : "outlined"
                    }
                  >
                    {selectedName === tier.title ? "SELECTED" : tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Stack>
          ))}
      </Box>
    </>
  );
};

export default ChoosePlan;
