import {
  Card,
  CardContent,
  Button,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { LoadingButton } from "@mui/lab";

const SubusersGrid = ({
  subusersRow,
  formik,
  isExpanded,
  setIsExpanded,
  isCancelExpanded,
  setIsCancelExpanded,
  subuserError,
  subuserLoading,
  setRemoveSubuserLoading,
  removeSubuserLoading,
  addedText,
  currentUser,
  selectedUser,
  setSelectedUser,
}) => {
  const [loadingRows, setLoadingRows] = useState([]);

  const subusersColumns = [
    { field: "id", headerName: "User ID", flex: 1 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "creationDate",
      headerName: "Creation Date",
      flex: 1,
      editable: false,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      editable: false,
    },
    {
      field: "remove",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        const rowIndex = params.row.id;

        if (params?.row?.status && params?.row?.status === "pending") {
          return (
            <>
              {loadingRows[rowIndex] ? (
                <CircularProgress size={25} />
              ) : (
                <Tooltip title="Cancel Invitation" arrow>
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleCancelSubUser(params.row?.email, rowIndex);
                    }}
                    className="delete-icon"
                  >
                    <DeleteIcon width={25} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        } else {
          // Return null or an empty div if you don't want to render anything
          return null;
        }
      },
    },
  ];

  const handleCancelSubUser = async (email, rowIndex) => {
    try {
      // Set the loading state for the specific row to true
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = true;
        return newLoadingRows;
      });

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/cancel-invitation`,
        {
          email,
        },
        { withCredentials: true }
      );
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = false;
        return newLoadingRows;
      });
    } catch (e) {
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = false;
        return newLoadingRows;
      });
      console.log(e);
    }
  };

  const handleRemoveSubUser = async () => {
    try {
      setRemoveSubuserLoading(true);
      const subEmail = selectedUser?.email;
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/remove-subuser`,
        {
          subEmail,
        },
        { withCredentials: true }
      );
      setIsCancelExpanded(false);
      setSelectedUser(null);
      setRemoveSubuserLoading(false);
    } catch (e) {
      setRemoveSubuserLoading(false);
      console.log(e);
    }
  };

  const getUserData = (params, event, details) => {
    if (!event.target.closest(".delete-icon")) {
      setSelectedUser(params.row);
    }
  };

  // const handleCellClick = (params, event) => {
  //   // Check if Ctrl (or Cmd on macOS) key is pressed
  //   if (event.ctrlKey) {
  //     console.log(selectedUser?.email);
  //     if (params.row.email === selectedUser?.email) {
  //       console.log("match");
  //       setSelectedUser(false);
  //       console.log(selectedUser);
  //     }
  //   }
  // };

  return (
    <>
      <Card>
        <CardContent>
          <DataGrid
            className="cell"
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={subusersRow || []}
            columns={subusersColumns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            onRowClick={(params, event, details) => {
              getUserData(params, event, details);
            }}
            // onCellClick={handleCellClick}
          />

          {/* ADD SUBUSER */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Accordion
              expanded={isExpanded}
              elevation={0}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none",
                  cursor: "default",
                  "&.MuiAccordionSummary-root:hover": {
                    cursor: "default",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {isExpanded ? (
                    <Button
                      disabled={currentUser?.subusers.length >= 20}
                      sx={{ cursor: "pointer", minWidth: "178.17px" }}
                      variant="contained"
                      color="error"
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setIsExpanded(false);
                        formik.resetForm();
                      }}
                    >
                      Hide
                    </Button>
                  ) : (
                    <Button
                      disabled={currentUser?.subusers.length >= 20}
                      sx={{ cursor: "pointer", minWidth: "178.17px" }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setIsExpanded(true);
                      }}
                    >
                      Add User
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" align="center" mb={2}>
                        Add New User
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="first"
                        label="First Name"
                        error={
                          formik.touched.first && Boolean(formik.errors.first)
                        }
                        helperText={formik.touched.first && formik.errors.first}
                        value={formik.values.first}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="last"
                        label="Last Name"
                        error={
                          formik.touched.last && Boolean(formik.errors.last)
                        }
                        helperText={formik.touched.last && formik.errors.last}
                        value={formik.values.last}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email Address"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        variant="filled"
                        error={!!(formik.touched.role && formik.errors.role)}
                      >
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          labelId="license_types"
                          name="role"
                          label="Role Type"
                          value={formik.values.role}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {["Basic User", "Power User"].map(function (user, i) {
                            return (
                              <MenuItem key={i} value={user}>
                                <Typography variant="body2">{user}</Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {formik.touched.role && formik.errors.role
                            ? formik.errors.role
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography
                      variant="body1"
                      color="error"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ mt: 2, minHeight: "1.5rem" }}
                    >
                      {subuserError}
                    </Typography>
                    <LoadingButton
                      loading={subuserLoading}
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      Add User
                    </LoadingButton>
                  </Box>
                </form>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* REMOVE SUBUSER */}
            <Accordion
              expanded={isCancelExpanded}
              elevation={0}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none",
                  cursor: "default",
                  "&.MuiAccordionSummary-root:hover": {
                    cursor: "default",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {isCancelExpanded ? (
                    <Button
                      disabled={
                        !selectedUser || selectedUser?.status === "pending"
                      }
                      sx={{ cursor: "pointer", minWidth: "178.16px" }}
                      variant="contained"
                      color="error"
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setIsCancelExpanded(false);
                      }}
                    >
                      Hide
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        !selectedUser || selectedUser?.status === "pending"
                      }
                      sx={{ cursor: "pointer", minWidth: "178.16px" }}
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setIsCancelExpanded(true);
                      }}
                    >
                      Remove User
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" align="center" mb={2}>
                        Remove User
                      </Typography>
                      <Typography variant="h6" align="center" mb={2}>
                        Are you certain about permanently removing this subuser?
                        This action is irreversible.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        name="first"
                        label="First Name"
                        value={selectedUser?.first || ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        name="last"
                        label="Last Name"
                        value={selectedUser?.last || ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email Address"
                        disabled
                        value={selectedUser?.email || ""}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl disabled fullWidth variant="filled">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          labelId="license_types"
                          name="role"
                          label="Role Type"
                          value={selectedUser?.role || ""}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {["Power User", "Basic User"].map(function (user, i) {
                            return (
                              <MenuItem key={i} value={user}>
                                <Typography variant="body2">{user}</Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography
                      variant="body1"
                      color="error"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ mt: 2, minHeight: "1.5rem" }}
                    >
                      {subuserError}
                    </Typography>
                    <LoadingButton
                      onClick={handleRemoveSubUser}
                      loading={removeSubuserLoading}
                      color="error"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      Confirm Remove User Permanently
                    </LoadingButton>
                  </Box>
                </form>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Typography
            variant="body1"
            color="error"
            fontWeight="bold"
            textAlign="center"
          >
            {addedText}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default SubusersGrid;
