import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Footer = () => {
  const currentUser = useSelector((state) => state.auth.user);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [transTime, setTransTime] = useState(0);
  const [adv_text, setAdv_text] = useState([]);
  const textRef = useRef(null);

  const getRandomIndex = useCallback(() => {
    const textArray = adv_text || [];
    return Math.floor(Math.random() * textArray.length);
  }, [adv_text]);

  useEffect(() => {
    if (!currentUser || !currentUser.user || !currentUser.nocUserForms) {
      // Data is not available yet, do nothing
      return;
    }

    setTransTime(
      currentUser.displays.advertising.footer_text_change_interval_min * 60000
    );
    setAdv_text(currentUser.displays.advertising.footer_texts);
    setCurrentTextIndex(getRandomIndex()); // Set random index initially
    const interval = setInterval(() => {
      setCurrentTextIndex(getRandomIndex());
    }, transTime);

    return () => clearInterval(interval);
  }, [currentUser]);

  const handleTextClick = () => {
    const currentText = adv_text[currentTextIndex];
    if (currentText.link) {
      // Open the link in a new tab
      window.open(currentText.link, "_blank");
    }
  };

  return (
    <>
      {adv_text[currentTextIndex]?.link === "" ? (
        <>
          <Box
            className="footer"
            sx={
              lgUp
                ? {
                    backgroundColor: "#1c2536",
                    // minHeight: "6.2rem",
                    py: "1rem",
                    pr: "1rem",
                    textAlign: "center",
                    textDecoration: "none",
                  }
                : {
                    backgroundColor: "#1c2536",
                    py: "1rem",
                    textAlign: "center",
                    textDecoration: "none",
                  }
            }
          >
            <TransitionGroup component={null}>
              <CSSTransition
                key={currentTextIndex}
                timeout={500}
                classNames="fade"
                exit={false}
                nodeRef={textRef}
              >
                <Typography
                  ref={textRef}
                  color="#fff"
                  fontWeight="bold"
                  variant={lgUp ? "h3" : "body1"}
                  className="text-transition"
                >
                  {adv_text[currentTextIndex]?.text}
                </Typography>
              </CSSTransition>
            </TransitionGroup>
          </Box>
        </>
      ) : (
        <>
          <Box
            component="a"
            href={`https://www.${adv_text[currentTextIndex]?.link}`}
            target="_blank"
            rel="noopener noreferrer"
            className="footer"
            sx={
              lgUp
                ? {
                    backgroundColor: "#1c2536",
                    // minHeight: "6.2rem",
                    py: "1rem",
                    pr: "1rem",
                    display: "block",
                    textAlign: "center",
                    textDecoration: "none",
                  }
                : {
                    backgroundColor: "#1c2536",
                    py: "1rem",
                    display: "block",
                    textAlign: "center",
                    textDecoration: "none",
                  }
            }
          >
            <TransitionGroup component={null}>
              <CSSTransition
                key={currentTextIndex}
                timeout={500}
                classNames="fade"
                exit={false}
                nodeRef={textRef}
              >
                <Typography
                  ref={textRef}
                  color="#fff"
                  fontWeight="bold"
                  variant={lgUp ? "h3" : "body1"}
                  className="text-transition"
                >
                  {adv_text[currentTextIndex]?.text}
                </Typography>
              </CSSTransition>
            </TransitionGroup>
          </Box>
        </>
      )}
    </>
  );
};

export default Footer;
