import { SvgIcon } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PublicIcon from "@mui/icons-material/Public";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupIcon from "@mui/icons-material/Group";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import BusinessIcon from "@mui/icons-material/Business";
import { AssignmentTurnedIn } from "@mui/icons-material";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DescriptionIcon from "@mui/icons-material/Description";

export const items = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: (
      <SvgIcon fontSize="small">
        <BarChartIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Create NOC",
    path: "/createnoc",
    icon: (
      <SvgIcon fontSize="small">
        <PictureAsPdfIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Select Counties",
    path: "/selectcounties",
    icon: (
      <SvgIcon fontSize="small">
        <PublicIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Manage Defaults",
    path: "/defaults",
    icon: (
      <SvgIcon fontSize="small">
        <DriveFileRenameOutlineOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Payment",
    path: "/payment",
    icon: (
      <SvgIcon fontSize="small">
        <PaymentIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Users",
    path: "/subusers",
    icon: (
      <SvgIcon fontSize="small">
        <GroupIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Manage Subscription",
    path: "/subscription",
    icon: (
      <SvgIcon fontSize="small">
        <SubscriptionsIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Profile Settings",
    path: "/profilesettings",
    icon: (
      <SvgIcon fontSize="small">
        <PersonSearchOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Customer Service",
    path: "/contact",
    icon: (
      <SvgIcon fontSize="small">
        <ThreePOutlinedIcon />
      </SvgIcon>
    ),
  },
];

export const adminItems = [
  {
    title: "Overview",
    path: "/admin/overview",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Customers",
    path: "/admin/customers",
    icon: (
      <SvgIcon fontSize="small">
        <PeopleIcon />
      </SvgIcon>
    ),
  },
  {
    title: "NOC Management",
    path: "/admin/tasks",
    icon: (
      <SvgIcon fontSize="small">
        <AssignmentTurnedIn />
      </SvgIcon>
    ),
  },
  {
    title: "DBPR Licenses",
    path: "/admin/licenses",
    icon: (
      <SvgIcon fontSize="small">
        <DescriptionIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Configurations",
    path: "/admin/management",
    icon: (
      <SvgIcon fontSize="small">
        <BusinessIcon />
      </SvgIcon>
    ),
  },
  {
    title: "New Messages",
    path: "/admin/messages",
    icon: (
      <SvgIcon fontSize="small">
        <EmailIcon />
      </SvgIcon>
    ),
  },
];
