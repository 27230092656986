import { TextField, Typography, Grid } from "@mui/material";
import { useEffect } from "react";
import { initials } from "../../data/InitialValues";

const TextFields = ({
  formik,
  selectedPreName,
  type,
  inputs,
  dynamicValues,
  setDynamicValues,
  editFromCreate,
}) => {
  useEffect(() => {
    // Update the formik values whenever the input values change
    formik.setValues((prevValues) => ({
      ...prevValues,
      ...dynamicValues,
    }));
  }, [dynamicValues]);

  useEffect(() => {
    // Fetch the initial values when the component mounts or selectedPreName changes
    const getInitials = () => {
      const initialData = initials.find(
        (initial) => initial.county === selectedPreName
      );
      if (initialData) {
        if (type === "edit" || type === "moreResults") {
          // Set only the non-empty fields from initialData into dynamicValues
          const filteredInitialValues = Object.keys(
            initialData.values[0]
          ).reduce((acc, key) => {
            if (initialData.values[0][key] !== "") {
              acc[key] = initialData.values[0][key];
            }
            return acc;
          }, {});

          setDynamicValues(filteredInitialValues);

          // Set formik initialValues using the inputs object
          const updatedValues = { ...initialData.values[0], ...inputs };
          formik.setValues(updatedValues);
        } else {
          formik.setValues(initialData.values[0]);
        }
      }
    };

    getInitials();
  }, [selectedPreName, type]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the formik values using setFieldValue
    formik.setFieldValue(name, value);
    // No need to update dynamicValues here
  };

  return (
    <>
      {type === "create-noc" && (
        <>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 0, pb: 0 }}>
            Address of Property Being Improved (Mandatory)
          </Typography>
          <TextField
            disabled={editFromCreate}
            error={
              !!(formik.touched.streetNumber && formik.errors.streetNumber)
            }
            fullWidth
            helperText={
              formik.touched.streetNumber && formik.errors.streetNumber
            }
            label="Street Number*"
            name="streetNumber"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            value={formik.values.streetNumber || ""}
          />
          {selectedPreName === "Citrus" ? (
            <Grid container gap={1}>
              <Grid sm={12} lg={3.5} item sx={{ ml: "auto" }}>
                <TextField
                  disabled={editFromCreate}
                  fullWidth
                  label="UNIT"
                  name="unitNumber"
                  onBlur={formik.handleBlur}
                  onChange={handleInputChange}
                  value={formik.values.unitNumber || ""}
                />
              </Grid>
              <Grid sm={12} lg={3.5} item>
                <TextField
                  disabled={editFromCreate}
                  fullWidth
                  label="APT"
                  name="apt"
                  onBlur={formik.handleBlur}
                  onChange={handleInputChange}
                  value={formik.values.apt || ""}
                />
              </Grid>
              <Grid sm={12} lg={3.5} item sx={{ mr: "auto" }}>
                <TextField
                  disabled={editFromCreate}
                  fullWidth
                  label="LOT"
                  name="lot"
                  onBlur={formik.handleBlur}
                  onChange={handleInputChange}
                  value={formik.values.lot || ""}
                />
              </Grid>
            </Grid>
          ) : (
            <TextField
              disabled={editFromCreate}
              fullWidth
              label="Unit Number"
              name="unitNumber"
              onBlur={formik.handleBlur}
              onChange={handleInputChange}
              value={formik.values.unitNumber || ""}
            />
          )}

          <TextField
            disabled={editFromCreate}
            error={!!(formik.touched.streetName && formik.errors.streetName)}
            fullWidth
            helperText={formik.touched.streetName && formik.errors.streetName}
            label="Street Name*"
            name="streetName"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            value={formik.values.streetName || ""}
          />
          <TextField
            disabled={editFromCreate}
            error={formik.touched.zip && formik.errors.zip}
            fullWidth
            helperText={formik.touched.zip && formik.errors.zip}
            label="ZIP Code"
            name="zip"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            type="zip"
            value={formik.values.zip || ""}
          />
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 0, pb: 0 }}>
            Optional Fields
          </Typography>
        </>
      )}

      {type === "moreResults" && (
        <>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 0, pb: 0 }}>
            Address of Property Being Improved (Mandatory)
          </Typography>
          <TextField
            disabled={editFromCreate}
            error={
              !!(formik.touched.streetNumber && formik.errors.streetNumber)
            }
            fullWidth
            helperText={
              formik.touched.streetNumber && formik.errors.streetNumber
            }
            label="Street Number*"
            name="streetNumber"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            value={formik.values.streetNumber || ""}
          />
          <TextField
            disabled={editFromCreate}
            fullWidth
            label="Unit Number"
            name="unitNumber"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            value={formik.values.unitNumber || ""}
          />
          <TextField
            disabled={editFromCreate}
            error={!!(formik.touched.streetName && formik.errors.streetName)}
            fullWidth
            helperText={formik.touched.streetName && formik.errors.streetName}
            label="Street Name*"
            name="streetName"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            value={formik.values.streetName || ""}
          />
          <TextField
            disabled={editFromCreate}
            error={formik.touched.zip && formik.errors.zip}
            fullWidth
            helperText={formik.touched.zip && formik.errors.zip}
            label="ZIP Code"
            name="zip"
            onBlur={formik.handleBlur}
            onChange={handleInputChange}
            type="zip"
            value={formik.values.zip || ""}
          />
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 0, pb: 0 }}>
            Optional Fields
          </Typography>
        </>
      )}

      {initials.map((initial) => {
        if (initial.county === selectedPreName) {
          return initial.fields.map((field, i) => (
            <TextField
              key={i}
              fullWidth
              label={field.label}
              name={field.name}
              onBlur={formik.handleBlur}
              onChange={handleInputChange}
              value={formik.values[field.value] || ""}
            />
          ));
        }
        return null;
      })}

      {formik.errors.submit && (
        <Typography color="error" sx={{ mt: 3 }} variant="body2">
          {formik.errors.submit}
        </Typography>
      )}
    </>
  );
};

export default TextFields;
