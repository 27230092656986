import {
  Box,
  Grid,
  Container,
  Card,
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InQueueDrawer from "../noc/InQueueDrawer";
import { useNavigate, useLocation } from "react-router-dom";
import SelectCountyHeader from "../noc/SelectCountyHeader";
import SelectCountyForm from "../noc/SelectCountyForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

import axios from "axios";
import DefaultModal from "../modals/DefaultModal";

const CreateNoc = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [cardRemain, setCardRemain] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const cardPerPage = 8;
  const [countiesData, setCountiesData] = useState([]);
  const [filteredCountiesData, setFilteredCountiesData] = useState([
    countiesData,
  ]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [targetTime, setTargetTime] = useState(60);
  const [intervalId, setIntervalId] = useState(null);

  const [dataLoading, setDataLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [createNocLoading, setCreateNocLoading] = useState(false);
  const [selectedPreForm, setSelectedPreForm] = useState("");
  const currentUser = useSelector((state) => state.auth.user);
  const noc_data = useSelector((state) => state.nocData.nocData);
  const [isClosed, setIsClosed] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewImg, setPreviewImg] = useState([]);
  const [county, setCounty] = useState("");
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [fullRetrieve, setFullRetrieve] = useState(false);
  const [pdf, setPdf] = useState("");
  const [text, setText] = useState(
    "All other fields will be filled automatically. Some fields are not visible in the preview."
  );
  const [moreResultsText, setMoreResultsText] = useState("");
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [addressRow, setAddressRow] = useState([]);
  const [cardFlip, setCardFlip] = useState(false);
  const [isRetrievingAddress, setIsRetrievingAddress] = useState(false);
  const [retrieveLoading, setRetrieveLoading] = useState(false);
  const [openDefaultModal, setOpenDefaultModal] = useState(false);
  const [inQueueMessage, setInQueueMessage] = useState(
    // "Calculating queue position..."
    ""
  );
  const [favoritesOpen, setFavoritesOpen] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({
    street: "",
    number: "",
    unit: "",
    suffix: "",
    zip: "",
    fullAddress: "",
    retrieved: "",
    fullRetrieve: "",
  });
  const theme = useTheme();

  const [defaultValues, setDefaultValues] = useState();
  const [editFromCreate, setEditFromCreate] = useState(false);
  const [formCreated, setFormCreated] = useState();
  const [isFormCreated, setIsFormCreated] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [inQueueDrawer, setInQueueDrawer] = useState(false);
  const [newRequest, setNewRequest] = useState(false);
  const [newRequestID, setNewRequestID] = useState("");
  const [selectedPreName, setSelectedPreName] = useState("");

  const progressDisabled = false;
  const progressValue = 0;
  const [intervalStarts, setIntervalStarts] = useState(false);
  const [cancelDefaultLoading, setCancelDefaultLoading] = useState(false);

  const [licenseType, setLicenseType] = useState("");
  const [copySelected, setCopySelected] = useState({});
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseText, setLicenseText] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0);
  const [disableSelect, setDisableSelect] = useState(false);

  const updateNotification = async (task_id) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/update-notification`,
      { task_id: task_id },
      { withCredentials: true }
    );
  };

  const startInterval = () => {
    let initialInterval = 1000; // Initial interval: 1000 milliseconds (1 second)

    const newIntervalId = setInterval(() => {
      setElapsedTime((prevTime) => {
        const newElapsedTime = prevTime + 1;

        // Check if elapsed time is a multiple of 10 (60, 70, 80, 90, etc.)
        if (newElapsedTime % 10 === 0) {
          initialInterval -= 50; // Increase the interval by 100 milliseconds
        }

        return newElapsedTime;
      });
    }, initialInterval);

    setIntervalId(newIntervalId);
    setIntervalStarts(true);
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.user &&
      currentUser.user.selectedCountiesNOC &&
      !currentUser.user.selectedCountiesNOC.some(
        (county) => selectedPreForm === county.form
      )
    ) {
      setOpenDrawer(false);
    }
  }, [selectedPreForm, currentUser?.user?.selectedCountiesNOC]);

  useEffect(() => {
    if (newRequest === true) {
      const targetTask = currentUser?.nocUserTasks.find(
        (task) => task.task_id === newRequestID
      );
      if (targetTask) {
        if (targetTask.status === "completed") {
          clearInterval(intervalId);
          setElapsedTime(0);
          if (targetTask.success === "more") {
            if (openDrawer && !inQueueDrawer) {
              setDefaultAddress({
                ...defaultAddress,
                retrieved: !targetTask.original_values.allRetrieve
                  ? String(targetTask.listPerPage)
                  : targetTask.return_values.length,
                fullRetrieve: !targetTask.original_values.allRetrieve
                  ? String(targetTask.numberOfLists)
                  : targetTask.return_values.length,
              });

              if (targetTask.listPages === 1) {
                setFullRetrieve(true);
                setIsRetrievingAddress(true);
              } else {
                setIsRetrievingAddress(false);
              }
              setAddressRow(targetTask.return_values);
              setCardFlip(true);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setDisableSelect(false);
              setRetrieveLoading(false);
              setText(targetTask.message);
              if (fullRetrieve) {
                setMoreResultsText("Addresses are retrieved successfully!");
              }
            }
            setRetrieveLoading(false);
            // setIsRetrievingAddress(false);
            setNewRequest(false);
            setNewRequestID("");
            setFullRetrieve(false);
          } else if (targetTask.success === true) {
            if (openDrawer && !inQueueDrawer) {
              setText(targetTask.message);
              setPreview(true);
              setIsReadyToDownload(true);
              setPreviewImg(targetTask.return_values.jpg);
              setPdf(targetTask.return_values.pdf);
              updateNotification(targetTask.task_id);
              setPreviewLoading(false);
              setCreateNocLoading(false);
              setDisableSelect(true);
              setEditFromCreate(true);
              setFormCreated(targetTask.return_values?.createdForm);
              setIsFormCreated(true);
            }
            setNewRequest(false);
            setNewRequestID("");
          } else if (targetTask.success === false) {
            if (openDrawer && !inQueueDrawer) {
              setText(targetTask.message);
              updateNotification(targetTask.task_id);
              setCreateNocLoading(false);
              setRetrieveLoading(false);
              setDisableSelect(false);
              setPreviewLoading(false);
            }
            setNewRequest(false);
            setNewRequestID("");
          }
        }
      }
    }
  }, [currentUser, newRequest]);

  useEffect(() => {
    if (copySelected?.licenseStatus === "not_found") {
      setLicenseText("License Number not Found");
      setInvalid(true);
      setRetrieveLoading(true);
    } else if (copySelected?.licenseStatus === "unverified") {
      setLicenseText("License Number not Found");
      setInvalid(true);
      setRetrieveLoading(true);
    } else if (copySelected?.process_status === "queued") {
      setLicenseText("Waiting for Validation");
      setInvalid(true);
      setRetrieveLoading(true);
    } else if (
      copySelected?.valid === false &&
      copySelected?.expiry !== "" &&
      copySelected?.licenseStatus !== "not_found"
    ) {
      setLicenseText(copySelected?.licenseStatus);
      setInvalid(true);
      setRetrieveLoading(true);
    } else if (
      copySelected?.valid === true &&
      copySelected?.confirmed === false
    ) {
      setLicenseText(copySelected?.licenseStatus);
      setInvalid(true);
      setRetrieveLoading(true);
    } else {
      setLicenseText("Verified License Number");
      setInvalid(false);
      setRetrieveLoading(false);
    }
  }, [copySelected, licenseText]);

  useEffect(() => {
    if (
      !currentUser ||
      !currentUser.user ||
      !currentUser.nocUserForms ||
      !noc_data
    ) {
      // Data is not available yet, do nothing
      return;
    }
    setCopySelected(currentUser?.user.contractors[selectedLicenseIndex]);
    if (currentUser.user.expired === true) {
      navigate("/subscription");
    } else if (currentUser.user.selectedCountiesNOC.length === 0) {
      navigate("/selectcounties");
    } else {
      setLicenseType(
        currentUser?.user.contractors[selectedLicenseIndex].licenseType
      );
      setLicenseNumber(
        currentUser?.user.contractors[selectedLicenseIndex].licenseNumber
      );
      if (
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus ===
        "not_found"
      ) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }

      const favoritesExist =
        JSON.parse(localStorage.getItem("favorites")) || [];

      if (
        favoritesExist.length !== 0 &&
        currentUser.user.selectedCountiesNOC.length === 0
      ) {
        // Clear local storage
        localStorage.removeItem("favorites");
      }

      const updatedFavorites = favoritesExist.filter((favorite) =>
        currentUser?.user?.selectedCountiesNOC.some(
          (selectedCounty) => selectedCounty.name === favorite.name
        )
      );
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));

      try {
        if (favoritesOpen) {
          // Load favorites from local storage
          const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
          setCountiesData(favorites);
          setFilteredCountiesData(favorites);
        } else {
          setCountiesData(currentUser.user.selectedCountiesNOC);
          setFilteredCountiesData(currentUser.user.selectedCountiesNOC);
        }

        setDataLoading(false);
      } catch (e) {
        setDataLoading(false);

        // navigate("/login");
      }
    }
  }, [currentUser, favoritesOpen, noc_data]);

  useEffect(() => {
    if (!isMd) {
      setFormDrawerOpen(false);
    }
  }, [isMd, text]);

  useEffect(() => {
    if (!openDrawer && intervalId) {
      clearInterval(intervalId);
      setElapsedTime(0);
    }
  }, [openDrawer, intervalId]);

  const handleSearch = (event) => {
    setCurrentPage(1);
    let value = event.target.value.toLowerCase();
    let result = [];
    result = countiesData.filter((data) => {
      return data.name.toLowerCase().search(value) !== -1;
    });
    // setTasks(result)
    setFilteredCountiesData(result);
  };

  const getCurrentTasks = (tasks) => {
    const indexOfLastCard = currentPage * cardPerPage;
    const indexOfFirstCard = indexOfLastCard - cardPerPage;
    return tasks.slice(indexOfFirstCard, indexOfLastCard);
  };
  const handleDisplayNOC = () => {
    let nocLeft = "";

    if (
      currentUser?.user.role === "admin" ||
      currentUser?.user.role === "tester" ||
      currentUser?.user?.admin === true
    ) {
      nocLeft = 1000 - currentUser?.user.analytics.nocCreatedInPeriod;
    } else {
      nocLeft = currentUser?.user.analytics.availableNocInPeriod;
    }

    if (nocLeft === 0 || nocLeft < 0) {
      return (
        <Typography
          ml="auto"
          my={10}
          mr="auto"
          textAlign="center"
          variant="h5"
          color="error"
        >
          You have reached the limit of available NOC. <br />
          Please update your package or come back later.
        </Typography>
      );
    } else {
      return (
        <SelectCountyForm
          selectedPreName={selectedPreName}
          setSelectedPreName={setSelectedPreName}
          setOpenDefaultModal={setOpenDefaultModal}
          setDisableSelect={setDisableSelect}
          disableSelect={disableSelect}
          setSelectedLicenseIndex={setSelectedLicenseIndex}
          invalid={invalid}
          setLicenseNumber={setLicenseNumber}
          licenseNumber={licenseNumber}
          licenseText={licenseText}
          copySelected={copySelected}
          setCopySelected={setCopySelected}
          licenseType={licenseType}
          setLicenseType={setLicenseType}
          noc_data={noc_data}
          setFilteredCountiesData={setFilteredCountiesData}
          setCountiesData={setCountiesData}
          favoritesOpen={favoritesOpen}
          currentUser={currentUser}
          isSmallScreen={isSmallScreen}
          intervalStarts={intervalStarts}
          elapsedTime={elapsedTime}
          targetTime={targetTime}
          progressDisabled={progressDisabled}
          progressValue={progressValue}
          setNewRequestID={setNewRequestID}
          setNewRequest={setNewRequest}
          moreResultsText={moreResultsText}
          setFullRetrieve={setFullRetrieve}
          selectedPreForm={selectedPreForm}
          setSelectedPreForm={setSelectedPreForm}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          formDrawerOpen={formDrawerOpen}
          setFormDrawerOpen={setFormDrawerOpen}
          editFromCreate={editFromCreate}
          setRetrieveLoading={setRetrieveLoading}
          isRetrievingAddress={isRetrievingAddress}
          setIsRetrievingAddress={setIsRetrievingAddress}
          retrieveLoading={retrieveLoading}
          defaultAddress={defaultAddress}
          setText={setText}
          text={text}
          pdf={pdf}
          isReadyToDownload={isReadyToDownload}
          setIsReadyToDownload={setIsReadyToDownload}
          setCounty={setCounty}
          filteredCountiesData={filteredCountiesData}
          getCurrentTasks={getCurrentTasks}
          createNocLoading={createNocLoading}
          previewLoading={previewLoading}
          previewImg={previewImg}
          setPreviewImg={setPreviewImg}
          preview={preview}
          setPreview={setPreview}
          setIsPreview={setIsPreview}
          type="create-noc"
          isClosed={isClosed}
          setIsClosed={setIsClosed}
          formik={formik}
          selectedName={selectedName}
          selectCards={selectCards}
          cardRemain={cardRemain}
          loading={dataLoading}
          totalCounty={countiesData.length}
          cardPerPage={cardPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          enlargeType="createNOC"
          enlargeHeader="Create PDF"
          enlargeNote="To create your PDF, please enter the values below."
          addressRow={addressRow}
          cardFlip={cardFlip}
          setAddressRow={setAddressRow}
          setCardFlip={setCardFlip}
          defaultValues={defaultValues}
          setCreateNocLoading={setCreateNocLoading}
          setPreviewLoading={setPreviewLoading}
          setDefaultAddress={setDefaultAddress}
          setEditFromCreate={setEditFromCreate}
          isFormCreated={isFormCreated}
        />
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      streetNumber: "",
      unitNumber: "",
      apt: "",
      lot: "",
      streetName: "",
      zip: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      streetNumber: Yup.number()
        .typeError("Must be a number")
        .required("Street Number is required"),
      unitNumber: Yup.string(),
      apt: Yup.string(),
      lot: Yup.string(),
      streetName: Yup.string()
        .min(4, "Please enter at least 4 characters.")
        .required("Street Name is required"),
      zip: Yup.string().matches(/^\d{5}([-]?\d{4})?$/, "Invalid ZIP Code"),
    }),
    onSubmit: async (values, helpers) => {
      let cName = "";
      let cAddress = "";

      if (
        currentUser.user.contractors[selectedLicenseIndex].type === "custom"
      ) {
        cName = currentUser.user.contractors[selectedLicenseIndex].name;
        cAddress = currentUser.user.contractors[selectedLicenseIndex].address;
      } else {
        cName = currentUser.user.contractors[selectedLicenseIndex].proName;
        cAddress =
          currentUser.user.contractors[selectedLicenseIndex].proAddress;
      }
      values["_id"] = String(currentUser.user._id);
      values["allRetrieve"] = false;
      values["county"] = county;
      values["customerId"] = currentUser.user.customerId;
      values["email"] = currentUser?.subuser
        ? currentUser?.subuser?.email
        : currentUser.user.email;
      values["contractorName"] = cName;
      values["contractorAddress"] = cAddress;
      values["contractorPhone"] =
        currentUser.user.contractors[selectedLicenseIndex].phone;
      values["contractorFax"] =
        currentUser.user.contractors[selectedLicenseIndex].fax;
      values["contractorEmail"] =
        currentUser.user.contractors[selectedLicenseIndex].email;
      values["licenseNumber"] =
        currentUser.user.contractors[selectedLicenseIndex].licenseNumber;
      values["licenseType"] =
        currentUser.user.contractors[selectedLicenseIndex].licenseType;
      values["licenseStatus"] =
        currentUser.user.contractors[selectedLicenseIndex].licenseStatus;
      values["img"] = selectedPreForm;
      setDefaultValues(values);

      if (isPreview) {
        if (preview && !isMd) {
          setPreview(false);
        } else {
          setText("Please wait while we create the preview.");
          setPreviewLoading(true);
          setDisableSelect(true);
          setCreateNocLoading(true);
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/createPreview`,
              {
                values,
              },
              { withCredentials: true }
            );

            setPreviewImg(resp.data.jpg);
            setText("Preview has been created successfully");
            setIsPreview(false);
            setPreview(true);
            if (isMd) {
              setFormDrawerOpen(true);
            }
            setPreviewLoading(false);
            setCreateNocLoading(false);
            setDisableSelect(false);
          } catch (err) {
            setText(err.message);
            setIsPreview(false);
            setPreviewLoading(false);
            setCreateNocLoading(false);
            setDisableSelect(false);
          }
        }
      } else if (editFromCreate) {
        setText("Please wait. Editing in progress...");
        setPreviewLoading(true);
        setDisableSelect(true);
        // formCreated;
        values["pdfUrl"] = formCreated.pdfUrl;
        values["pdfImage"] = formCreated.pdfImage;
        values["pdfImage2"] = formCreated.pdfImage2;
        values["county"] = formCreated.county;
        values["customerId"] = formCreated.inputs.customerId;
        values["id"] = formCreated.id;
        values["contractorName"] = formCreated.inputs.contractorName;
        values["contractorAddress"] = formCreated.inputs.contractorAddress;
        values["contractorPhone"] = formCreated.inputs.contractorPhone;
        values["contractorFax"] = formCreated.inputs.contractorFax;
        values["contractorEmail"] = formCreated.inputs.contractorEmail;
        values["legalDescription"] = formCreated.inputs.legalDescription;
        values["primaryAddress"] = formCreated.inputs.primaryAddress;
        values["owner"] = formCreated.inputs.owner;
        values["ownerAddress"] = formCreated.inputs.ownerAddress;
        if (formCreated.inputs.county !== "Duval") {
          values["taxFolio"] = formCreated.inputs.taxFolio;
        }

        // const resp = await axios.post("https://sr360noc.com/nocApi/editPDF", {
        //   values,
        // });
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/editPDF`,
          {
            values,
          },
          { withCredentials: true }
        );

        setFormCreated(resp.data.data);
        setPreview(true);
        setPreviewImg(resp.data.jpg);
        setPdf(resp.data.pdf);

        setPreviewLoading(false);
        setText("Form edited successfully");
      } else {
        setTargetTime(60);
        startInterval();
        try {
          setRetrieveLoading(true);
          setDisableSelect(true);
          setCreateNocLoading(true);
          setPreviewLoading(true);

          let countyLeft =
            currentUser &&
            currentUser.user.role === "user" &&
            currentUser?.user?.admin !== true
              ? currentUser?.user.analytics?.availableNocInPeriod
              : 1000 - currentUser?.user.analytics.nocCreatedInPeriod;

          if (countyLeft === 0 || countyLeft < 0) {
            setText("You have reached your create NOC limit");
            setRetrieveLoading(true);
          }

          setText("Calculating queue position...");

          // setCreateNocLoading(true);

          // const resp = await axios.post("https://sr360noc.com/nocApi/createNoc", {
          //   values,
          // });
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/createNoc`,
            {
              values,
            },
            { withCredentials: true }
          );

          if (resp.data.queuePosition <= 0) {
            setNewRequestID(resp.data.task_id);
            setNewRequest(true);
            setText(
              "The data is being downloaded and a PDF file is being created. Please wait, this may take some time."
            );
          } else if (resp.data.queuePosition > 0 && openDrawer) {
            setInQueueMessage("");
            setInQueueDrawer(true);
          }
        } catch (err) {
          console.log(err);
          setText(err.message);
          setRetrieveLoading(false);
          setCreateNocLoading(false);
          setPreviewLoading(false);
        }
      }
    },
  });

  const selectCards = (countyName, form) => {
    if (selectedName.includes(countyName)) {
      let copyArray = [...selectedName];
      let index = copyArray.indexOf(countyName);
      copyArray.splice(index, 1);

      let copyForm = [...selectedForm];
      let indexForm = copyForm.indexOf(form);
      copyForm.splice(indexForm, 1);

      setCardRemain(cardRemain + 1);
      setSelectedName(copyArray);
      setSelectedForm(copyForm);
    } else {
      if (cardRemain !== 0) {
        setSelectedName([...selectedName, countyName]);
        setSelectedForm([...selectedForm, form]);
        setCardRemain(cardRemain - 1);
      }
    }
  };

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl" py={3}>
              <Card
                py={3}
                px={isSmallScreen ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container>
                  <SelectCountyHeader
                    currentUser={currentUser}
                    type="create-noc"
                    setCurrentPage={setCurrentPage}
                    favoritesOpen={favoritesOpen}
                    setFavoritesOpen={setFavoritesOpen}
                    isSmallScreen={isSmallScreen}
                    remainingLebal="Remaining NOCs"
                    handleSearch={handleSearch}
                    countyLeft={
                      currentUser &&
                      currentUser.user.role === "user" &&
                      currentUser?.user?.admin !== true
                        ? currentUser?.user.analytics?.availableNocInPeriod
                        : 1000 - currentUser?.user.analytics.nocCreatedInPeriod
                    }
                    countyOutOf={
                      currentUser &&
                      currentUser?.user.role === "user" &&
                      currentUser?.user?.admin !== true
                        ? currentUser?.user.analytics.availableNocInPeriod +
                          currentUser?.user.analytics.nocCreatedInPeriod
                        : 1000
                    }
                    headerText="Create NOC PDF Form"
                    subtitleText={
                      currentUser?.user.selectedCountiesNOC.length === 0
                        ? "Please select Counties before start creating NOC"
                        : "To create a PDF file, please select one form."
                    }
                  />
                </Grid>
                <Grid container>{handleDisplayNOC()}</Grid>
                <Grid container justifyContent="center">
                  {pathname === "/user/welcome" && (
                    <>
                      <Button
                        // onClick={() => router.push("/user/dashboard")}
                        disabled={cardRemain !== 0}
                        size="large"
                        sx={{
                          mt: 2,
                          backgroundColor: "primary",
                          width: "30rem",
                        }}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Grid>
              </Card>
            </Container>
          </Box>

          {/* NOC STARTED DRAWER */}
          <InQueueDrawer
            inQueueDrawer={inQueueDrawer}
            setInQueueDrawer={setInQueueDrawer}
            inQueueMessage={inQueueMessage}
            isMd={isMd}
          />

          <DefaultModal
            openDefaultModal={openDefaultModal}
            setOpenDefaultModal={setOpenDefaultModal}
            setOpenDrawer={setOpenDrawer}
            cancelDefaultLoading={cancelDefaultLoading}
            setCancelDefaultLoading={setCancelDefaultLoading}
            selectedPreName={selectedPreName}
          />
        </>
      )}
    </>
  );
};

export default CreateNoc;
