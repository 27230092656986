// localStorageReducer.js
const initialState = {
  email: "",
  password: "",
  remember: false,
};

const localStorageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCAL_STORAGE_ITEM":
      if (action.payload.key === "ujsffq") {
        return {
          ...state,
          email: action.payload.value.email,
          password: action.payload.value.password,
        };
      }
      return state;
    case "REMOVE_LOCAL_STORAGE_ITEM":
      if (action.payload === "ujsffq") {
        return {
          ...state,
          email: "",
          password: "",
          remember: false,
        };
      }
      return state;

    default:
      return state;
  }
};

export default localStorageReducer;
