import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import axios from "axios";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const exitHandler = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/nocApi/auth/logout`, {
        withCredentials: true,
      });
      window.location.reload();
    } catch {
      window.location.reload();
    }

    // router.push("/auth/login");
  };
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      sx={{ width: "auto" }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" align="center" sx={{ marginTop: "1rem" }}>
          {props.user?.user && props.user?.subuser
            ? props.user?.subuser.role
            : props.user?.user.role === "admin"
            ? "Super User"
            : props.user?.user.role === "tester"
            ? "Tester"
            : "Administrator"}
        </Typography>
        <div
          style={{
            borderBottom: "3px solid #d3d3d3",
            marginTop: "3px",
          }}
        ></div>
        <Typography variant="overline" align="center">
          {props.user?.user &&
            props.user?.user.personal[0].firstName +
              " " +
              props.user?.user.personal[0].lastName}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {props.user?.user && props.user?.subuser
            ? props.user?.subuser.email
            : props.user?.user.email}
        </Typography>
        <Divider />

        {props.user?.subuser && (
          <>
            <div
              style={{
                borderBottom: "3px solid #d3d3d3",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            ></div>
            <Typography
              variant="overline"
              align="center"
              sx={{ marginTop: "1rem" }}
            >
              Your Administrator
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {props.user?.subuser && props.user?.subuser?.admin}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {props.user?.subuser && props.user?.user?.email}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={exitHandler}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};
