import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Grid,
  styled,
  Switch,
  Container,
  Card,
  Button,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  Tabs,
  CardHeader,
  CardContent,
  CardActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ChangePlanHeader = ({ isMd, setOpenDrawer, title, p1, p2 }) => {
  return (
    <Grid container sx={{ display: "flex", overflow: "auto", width: "100%" }}>
      <Grid item md={12} sx={isMd ? { order: 1, width: "100%" } : {}}>
        <Stack sx={{ justifyContent: "center", flex: "2" }}>
          <Stack
            sx={{
              width: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <Typography variant="h4" textAlign="center" sx={{ mb: 2, mt: 2 }}>
              {title}
            </Typography>

            <IconButton onClick={() => setOpenDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{ color: "#990000", fontWeight: "bold" }}
            >
              {p1}
            </Typography>
            <Typography
              sx={{ color: "#990000", fontWeight: "bold" }}
              textAlign="center"
              variant="body1"
            >
              {p2}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ChangePlanHeader;
