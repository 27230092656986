import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MessageNotifications from "../../admin/MessageNotifications";
import MessageGrid from "../../admin/MessageGrid";

const Messages = () => {
  const theme = useTheme();
  const [reasonsData, setReasonsData] = useState([]);
  const [messagesDataRows, setMessagesDataRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedName, setSelectedName] = useState();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const getMessages = async () => {
      try {
        const reasonsResp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/message-reasons-amounts`,
          { withCredentials: true }
        );

        setReasonsData(reasonsResp.data.data);
        // setData(resp.data.data.reasons);
      } catch (e) {
        console.log(e);
      }
    };
    getMessages();
  }, []);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          backgroundColor: "#EEF2F6",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} md={4} lg={4}>
              <MessageNotifications
                isSm={isSm}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                setMessagesDataRows={setMessagesDataRows}
                reasonsData={reasonsData}
                setReasonsData={setReasonsData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Grid>
            <Grid xs={12} md={8} lg={8}>
              <MessageGrid
                isSm={isSm}
                setMessagesDataRows={setMessagesDataRows}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                title="Income Messages"
                messagesDataRows={messagesDataRows}
                setReasonsData={setReasonsData}
              />
            </Grid>
            {/* <Grid xs={12} ml="auto" mr="auto" md={8} lg={8}>
              <ReadMessage title="Message" />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Messages;
