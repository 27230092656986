import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  TextField,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

const EditCard = ({
  add,
  setCurrentIndex,
  setOpenDrawer,
  setPaymentChange,
  errorText,
  setErrorText,
  CardElement,
  createCard,
  cards,
  setCards,
  currentIndex,
  setOpen,
}) => {
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const CARD_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",

        fontWeight: "500",
        fontSize: "16px",
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      cardName: "",
      expiry: "",
      cvc: "",
    },
    validationSchema: yup.object({
      cardName: yup.string().required("Cardholder name is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        if (!terms) {
          setLoading(false);
          return setErrorText("You must accept terms and conditions");
        }

        const card = await createCard(values["cardName"]);

        if (card.error) {
          setLoading(false);
          return setErrorText(card.error.message);
        }

        const send_obj = {
          new_card: card,
          new_cardName: values["cardName"],
          old_card: cards[currentIndex],
          add,
        };

        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/change-card`,
          {
            send_obj,
          },
          { withCredentials: true }
        );

        if (resp.data.success) {
          setErrorText(resp.data.message);

          setCards(
            resp.data.cards.map((card) => {
              const lastTwo = String(card.exp_year).slice(-2);
              const first = card.exp_month;

              return {
                id: card.id,
                cardNum: "**** **** **** " + card.last4,
                cardName: card.name ? card.name : card.card_holder,
                expires:
                  (first < 10 ? "0" + String(first) : String(first)) +
                  "/" +
                  lastTwo,
                default: card.default,
              };
            })
          );
          setErrorText(resp.data.message);
          setCurrentIndex(0);
          setPaymentChange(true);
          setOpenDrawer(false);
          setLoading(false);
        } else {
          return setErrorText(resp.data.message);
        }
      } catch (err) {
        console.log(err);
        setErrorText(err.response.data.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoading(false);
      }
    },
  });
  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ px: "2rem", py: "2rem" }}>
          <Stack direction="row" width="100%">
            <Typography pl={2} mb={5} mt={3} variant="h5">
              {add ? "Add Credit Card" : "Update Credit Card Details"}
            </Typography>
            <IconButton
              onClick={() => setOpenDrawer(false)}
              sx={{ mb: 5, ml: "auto" }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Grid item xs={12}>
            <TextField
              error={!!(formik.touched.cardName && formik.errors.cardName)}
              fullWidth
              helperText={formik.touched.cardName && formik.errors.cardName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.cardName}
              name="cardName"
              label="Cardholder Name*"
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className="card-container"
              style={{
                border: "2px solid #f4f4f4",
                cursor: "text",
                padding: "1rem",
                borderRadius: "10px",
              }}
            >
              <CardElement options={CARD_OPTIONS} />
            </div>
          </Grid>
          <Grid item xs={12} mt={-2}>
            <FormControlLabel
              sx={{ mt: "5px", mr: "auto" }}
              required
              control={
                <Checkbox
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                />
              }
              label={
                <Typography
                  sx={{ textAlign: "center", lineHeight: "3px" }}
                  color="text.secondary"
                  variant="body2"
                >
                  I agree the{" "}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setOpen(true);
                    }}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    Terms & Conditions
                  </Link>
                </Typography>
              }
              size="sm"
            />
          </Grid>
          <Grid item xs={12} mt={-2}>
            <Typography
              variant="body1"
              color="#FF6666"
              fontWeight="bold"
              textAlign="center"
              sx={{ mt: "1rem", minHeight: "1.5rem" }}
            >
              {errorText}
            </Typography>
          </Grid>
        </Grid>
        <Stack mx={4}>
          <LoadingButton
            loading={loading}
            size="large"
            sx={{ mt: 0, mb: 2 }}
            type="submit"
            variant="contained"
          >
            {add ? "Add" : "Update"}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};

export default EditCard;
