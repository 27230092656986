// src/store/middleware/jwtCookieMiddleware.js
import {
  setUserAuthenticated,
  setUserUnauthenticated,
} from "../actions/authActions";
import Cookies from "js-cookie";

const jwtCookieMiddleware = (store) => (next) => (action) => {
  if (action.type === "SET_TOKEN_COOKIE") {
    // Save the JWT token to a cookie
    document.cookie = `NocCreator=${action.payload}; path=/`;
  } else if (action.type === "REMOVE_TOKEN_COOKIE") {
    // Remove the JWT token cookie
    Cookies.remove("NocCreator", {
      domain: process.env.REACT_APP_DOMAIN, // Replace with your production domain
      path: "/", // The path where the cookie is set
      secure: true, // Cookie should be secure if used in HTTPS
      sameSite: "Strict", // SameSite policy
    });
  } else if (action.type === "CHECK_TOKEN_COOKIE") {
    // Check for a JWT token cookie on app load
    let nocCookie = Cookies.get("NocCreator");

    if (nocCookie) {
      try {
        store.dispatch(setUserAuthenticated()); // Assuming you have a 'SET_USER' action to set the user data in the store.
      } catch (error) {
        console.error("Error decoding JWT token:", error);
        store.dispatch({ type: "REMOVE_TOKEN_COOKIE" }); // Remove the invalid token cookie
      }
    } else {
      store.dispatch(setUserUnauthenticated());
    }
  }

  return next(action);
};

export default jwtCookieMiddleware;
