import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { TasksGrid } from "../../admin/management/TasksGrid";
import UpdateTasks from "../../admin/UpdateTasks";
import UpdateUserTask from "../../admin/UpdateUserTask";

const Tasks = () => {
  const theme = useTheme();
  const [tasksData, setTasksData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [sortModel, setSortModel] = useState(undefined);
  const [filterModel, setFilterModel] = useState(undefined);
  const [counties, setCounties] = useState([]);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [bulkDisabled, setBulkDisabled] = useState(true);
  const [singleLoading, setSingleLoading] = useState(false);
  const [disabledFields, setDisabledFields] = useState(true);

  const [waitingConfirmation, setWaitingConfirmation] = useState(false);

  const [singleRegenerationData, setSingleRegenerationData] = useState({
    taskId: "",
    contractor: "",
    date: "",
    email: "",
    scrapingFault: "",
    county: "",
    status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasksPromise = axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_tasks`,
          {
            page: page,
            rowsPerPage: rowsPerPage,
          },
          {
            withCredentials: true,
          }
        );

        const analyticsPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/analytics_admin`,
          {
            withCredentials: true,
          }
        );

        const [tasksResponse, analyticsResponse] = await Promise.all([
          tasksPromise,
          analyticsPromise,
        ]);

        setCounties(analyticsResponse.data.data.nocOverview.counties);

        if (tasksResponse.data.success) {
          setTasksData(tasksResponse.data.data);
          setTotalRowsCount(tasksResponse.data.rows_count);
        }

        setDataLoading(false);
      } catch (e) {
        console.log(e);
        setDataLoading(false);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 3,
            backgroundColor: "#EEF2F6",
          }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              <Grid xs={12} md={12} lg={12}>
                <TasksGrid
                  setDisabledFields={setDisabledFields}
                  setSingleRegenerationData={setSingleRegenerationData}
                  title="All NOC Creations"
                  tasksDataRows={tasksData}
                  setTasksData={setTasksData}
                  isSm={isSm}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  setDataLoading={setDataLoading}
                  dataLoading={dataLoading}
                  totalRowsCount={totalRowsCount}
                  setTotalRowsCount={setTotalRowsCount}
                  rowsPerPage={rowsPerPage}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <UpdateTasks
                  waitingConfirmation={waitingConfirmation}
                  setWaitingConfirmation={setWaitingConfirmation}
                  singleLoading={singleLoading}
                  counties={counties}
                  bulkDisabled={bulkDisabled}
                  setBulkDisabled={setBulkDisabled}
                  bulkLoading={bulkLoading}
                  setBulkLoading={setBulkLoading}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <UpdateUserTask
                  singleRegenerationData={singleRegenerationData}
                  setSingleRegenerationData={setSingleRegenerationData}
                  singleLoading={singleLoading}
                  setSingleLoading={setSingleLoading}
                  disabledFields={disabledFields}
                  setDisabledFields={setDisabledFields}
                />
              </Grid>
              {/* <Grid xs={12} md={6}>
                <ClearQueue />
              </Grid> */}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Tasks;
