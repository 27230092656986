import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TermsAndConditionsModal from "../modals/TermsAndConditionsModal";
import InvoiceGrid from "../InvoiceGrid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Grid,
  Container,
  Card,
  Button,
  Typography,
  useMediaQuery,
  CardHeader,
  CardContent,
  Drawer,
  useTheme,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { PencilIcon } from "@heroicons/react/24/outline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditCard from "../EditCard";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";

const Payment = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const issm = useMediaQuery(theme.breakpoints.up("md"));
  const [cards, setCards] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [paymentChange, setPaymentChange] = useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [defLoading, setDefLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [add, setAdd] = useState(false);
  const [fetching, setFetching] = useState(false);

  let count = 0;

  const stripe = useStripe();
  const elements = useElements();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const createCard = async (name) => {
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: {
          name: name,
          email: currentUser?.subuser
            ? currentUser?.subuser?.email
            : currentUser?.user?.email,
        },
      });
      return paymentMethod;
    } catch (err) {
      setErrorText(err.message);
    }
  };

  const handleRemovePayment = async () => {
    setRemoveLoading(true);
    setDisableAll(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/remove-payment-card`,
        { card: cards[currentIndex] },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setCards(
          resp.data.cards.map((card) => {
            const lastTwo = String(card.exp_year).slice(-2);
            const first = card.exp_month;

            return {
              id: card.id,
              cardNum: "**** **** **** " + card.last4,
              cardName: card.name ? card.name : card.card_holder,
              expires:
                (first < 10 ? "0" + String(first) : String(first)) +
                "/" +
                lastTwo,
              default: card.default,
            };
          })
        );
        setErrorText(resp.data.message);
      }

      setCurrentIndex(0);
      setRemoveLoading(false);
      setDisableAll(false);
    } catch (e) {
      setRemoveLoading(false);
      setDisableAll(false);
      console.log(e);
    }
  };

  const handleSetDefault = async () => {
    setDefLoading(true);
    setDisableAll(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/set-default-card`,
        { card: cards[currentIndex] },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setCards(
          resp.data.cards.map((card) => {
            const lastTwo = String(card.exp_year).slice(-2);
            const first = card.exp_month;

            return {
              id: card.id,
              cardNum: "**** **** **** " + card.last4,
              cardName: card.name ? card.name : card.card_holder,
              expires:
                (first < 10 ? "0" + String(first) : String(first)) +
                "/" +
                lastTwo,
              default: card.default,
            };
          })
        );
        setErrorText(resp.data.message);
      }
      setCurrentIndex(0);
      setDefLoading(false);
      setDisableAll(false);
    } catch (e) {
      setDefLoading(false);
      setDisableAll(false);
      console.log(e);
    }
  };

  const handleFetch = async () => {
    setFetching(true);
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/get-payment-details`,

        { withCredentials: true }
      );
      if (resp.data.success) {
        if (!currentUser?.subuser || currentUser.subuser !== "Basic") {
          setCards(
            resp.data.data.cards.map((card) => {
              const lastTwo = String(card.exp_year).slice(-2);
              const first = card.exp_month;

              return {
                id: card.id,
                cardNum: "**** **** **** " + card.last4,
                cardName: card.name ? card.name : card.card_holder,
                expires:
                  (first < 10 ? "0" + String(first) : String(first)) +
                  "/" +
                  lastTwo,
                default: card.default,
              };
            })
          );
        }

        setInvoiceList(resp.data.data.invoices);
        setFetching(false);
      }
      setFetching(false);
    } catch (e) {
      console.log(e);
      setFetching(false);
    }
  };

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser.user.expired === true) {
      navigate("/subscription");
      return;
    }

    if (currentUser?.subuser && currentUser?.subuser.role === "Basic User") {
      navigate("/dashboard");
    }
  }, [currentUser]);

  useEffect(() => {
    const get_card = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/get-payment-details`,

          { withCredentials: true }
        );
        console.log(currentUser);
        if (resp.data.success) {
          if (!currentUser?.subuser || currentUser.subuser !== "Basic") {
            setCards(
              resp.data.data.cards.map((card) => {
                const lastTwo = String(card.exp_year).slice(-2);
                const first = card.exp_month;

                return {
                  id: card.id,
                  cardNum: "**** **** **** " + card.last4,
                  cardName: card.name ? card.name : card.card_holder,
                  expires:
                    (first < 10 ? "0" + String(first) : String(first)) +
                    "/" +
                    lastTwo,
                  default: card.default,
                };
              })
            );
          }

          setInvoiceList(resp.data.data.invoices);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };
    if (count < 1) {
      get_card();
      count += 1;
    }
  }, []);

  useEffect(() => {}, [cards]);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              backgroundColor: "#EEF2F6",
              justifyContent: "center",
            }}
          >
            <Container>
              <Grid container justifyContent="center">
                <>
                  <Grid item xs={12} md={12} lg={12} mb={3} mt={3}>
                    <Card sx={{ position: "relative" }}>
                      <CardHeader title={"Manage Card"} />
                      <Tooltip title="Refresh">
                        <IconButton
                          sx={{ position: "absolute", right: 14, top: 22 }}
                          className={fetching ? "rotate" : ""}
                          color="primary"
                          onClick={handleFetch}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>

                      <CardContent>
                        <Grid container>
                          <Grid item md={6} xs={12}>
                            {cards?.map((card, index) => (
                              <Box
                                key={index}
                                className="parent"
                                sx={{
                                  position: "relative",
                                  display:
                                    index === currentIndex ? "block" : "none",
                                  position: "relative",
                                }}
                              >
                                <img
                                  src="/img/website_img/bankcardEmpty.jpg"
                                  style={{
                                    width: "100%",
                                    borderRadius: "20px",
                                  }}
                                />
                                {card?.default && (
                                  <Typography
                                    className="child"
                                    sx={{
                                      position: "absolute",
                                      color: "#fff",
                                      position: "absolute",
                                      transform: "translate(-2%,-40%)",
                                      top: "8%",
                                      right: "4%",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    <CheckCircleOutlineIcon
                                      color="#fff"
                                      width={20}
                                      style={{
                                        verticalAlign: "middle",
                                        marginBottom: "2px",
                                      }}
                                    />{" "}
                                    Default Payment Card
                                  </Typography>
                                )}

                                <Typography
                                  className="child"
                                  sx={{
                                    position: "absolute",
                                    color: "#fff",
                                    position: "absolute",
                                    transform: "translate(-2%,-40%)",
                                    top: "50%",
                                    left: "5%",
                                    fontSize: "2rem",
                                  }}
                                >
                                  {card.cardNum}
                                </Typography>
                                <Typography
                                  className="child"
                                  sx={{
                                    position: "absolute",
                                    color: "#fff",
                                    transform: "translate(-2%,-40%)",
                                    top: "85%",
                                    left: "2.5%",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {card.cardName}
                                </Typography>
                                <Typography
                                  sx={{
                                    position: "absolute",
                                    color: "#fff",
                                    transform: "translate(-2%,-40%)",
                                    top: "85%",
                                    left: "37%",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {card.expires}
                                </Typography>
                              </Box>
                            ))}
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            sx={{ order: issm ? 1 : 2 }}
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignitems="center"
                            >
                              <Grid item sx={{ width: "20rem" }}>
                                <Button
                                  disabled={disableAll}
                                  fullWidth
                                  variant="contained"
                                  color="success"
                                  startIcon={
                                    <AddIcon color="#fff" width={20} />
                                  }
                                  onClick={() => {
                                    setOpenDrawer(true);
                                    setAdd(true);
                                  }}
                                >
                                  Add Credit Card
                                </Button>
                                <Button
                                  sx={{ mt: 1 }}
                                  disabled={
                                    disableAll ||
                                    (currentUser?.subuser &&
                                      cards[currentIndex]?.default === true)
                                  }
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  startIcon={
                                    <PencilIcon color="#fff" width={20} />
                                  }
                                  onClick={() => {
                                    setOpenDrawer(true);
                                    setAdd(false);
                                  }}
                                >
                                  Edit Credit Card
                                </Button>
                                {!currentUser?.subuser && (
                                  <LoadingButton
                                    loading={defLoading}
                                    onClick={handleSetDefault}
                                    disabled={
                                      cards[currentIndex]?.default || disableAll
                                    }
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    variant="contained"
                                    color="warning"
                                    startIcon={
                                      <CheckCircleOutlineIcon
                                        color="#fff"
                                        width={20}
                                      />
                                    }
                                  >
                                    {cards[currentIndex]?.default
                                      ? "Default Payment Card"
                                      : "Set as Default Payment Card"}
                                  </LoadingButton>
                                )}

                                <LoadingButton
                                  loading={removeLoading}
                                  onClick={handleRemovePayment}
                                  disabled={
                                    cards[currentIndex]?.default || disableAll
                                  }
                                  sx={{ mt: 1 }}
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                  startIcon={
                                    <DeleteIcon color="#fff" width={20} />
                                  }
                                >
                                  Remove Credit Card
                                </LoadingButton>
                                <Typography
                                  variant="body1"
                                  color="#FF6666"
                                  fontWeight="bold"
                                  textAlign="center"
                                  sx={{ mt: "1rem" }}
                                >
                                  {errorText}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            sx={{
                              order: issm ? 2 : 1,
                              mt: !issm ? 1 : 0,
                              mb: !issm ? 1 : 0,
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              {cards?.map((_, index) => (
                                <span
                                  key={index}
                                  onClick={() => handleDotClick(index)}
                                  style={{
                                    cursor: "pointer",
                                    margin: "0 5px",
                                    width: "10px",
                                    height: "10px",
                                    backgroundColor:
                                      index === currentIndex ? "#333" : "#ccc",
                                    display: "inline-block",
                                    borderRadius: "50%",
                                  }}
                                />
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Drawer
                    PaperProps={
                      issm && {
                        sx: {
                          width: "50vw",
                        },
                      }
                    }
                    anchor="right"
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                  >
                    <EditCard
                      add={add}
                      setCurrentIndex={setCurrentIndex}
                      cards={cards}
                      setCards={setCards}
                      currentIndex={currentIndex}
                      setOpen={setOpen}
                      createCard={createCard}
                      CardElement={CardElement}
                      errorText={errorText}
                      setErrorText={setErrorText}
                      currentUser={currentUser}
                      setOpenDrawer={setOpenDrawer}
                      setPaymentChange={setPaymentChange}
                    />
                  </Drawer>
                </>

                {currentUser && (
                  <Grid item xs={12} md={12} lg={12}>
                    <InvoiceGrid
                      payments={true}
                      invoiceRow={invoiceList}
                      title="Invoices"
                    />
                  </Grid>
                )}
              </Grid>
            </Container>
          </Box>
        </>
      )}

      <TermsAndConditionsModal open={open} onClose={handleCloseModal} />
    </>
  );
};

export default Payment;
