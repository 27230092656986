import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useSignIn, useClerk } from "@clerk/clerk-react";
import { loginUser, clearError } from "../../store/actions/authActions";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const LoginComponent = () => {
  const recaptchaRef = useRef(null);
  const { signIn } = useSignIn();
  const { signOut } = useClerk();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [count, setCount] = useState(0);
  const [recaptchaKey, setRecaptchaKey] = useState(Date.now());

  const handleRecaptchaVerify = async (response) => {
    setRecaptchaValue(response);

    setRecaptchaVerified(true);
  };

  const resetRecaptcha = () => {
    setRecaptchaKey(Date.now());
    setRecaptchaValue("");
    setRecaptchaVerified(false);
    setLoginDisabled(true);
  };

  const signInButtonStyle = isSmallScreen
    ? {
        width: "100%",
        backgroundColor: "#F8FAFC",
        mb: 1,
        p: "6px",
      }
    : {
        width: "145px",
        backgroundColor: "#F8FAFC",
        py: "5px",
      };

  useEffect(() => {
    const getItemFromLocalStorage = (key) => {
      const item = localStorage.getItem(key);
      return JSON.parse(item); // If the item is an object, parse it back to an object
    };

    const userData = getItemFromLocalStorage("ujsffq");

    if (userData) {
      setDefEmail(userData.email);
      setDefPwd("000000");
      setRemember(true);
    }
  }, []);

  useEffect(() => {
    if (count > 0) {
      if (recaptchaVerified === false) {
        setLoginDisabled(true);
      } else {
        setLoginDisabled(false);
      }
    }
  }, [recaptchaVerified, count]);

  useEffect(() => {
    if (
      error !== "" &&
      error !== "We are updating our application. Please come back later"
    ) {
      const timer = setTimeout(() => {
        dispatch(clearError()); // Dispatch the clearError action
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: defEmail || "",
      password: defPwd || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      if (count > 0) {
        if (recaptchaVerified === false) {
          return;
        }
        // const is_verified = await handleVerifyRecaptcha();

        // if (is_verified.data.success === false) {
        //   return;
        // }
      }
      try {
        const { email, password } = values;
        const result = await dispatch(
          loginUser(email, password, remember, recaptchaValue, count, defEmail)
        );
        if (!result) {
          resetRecaptcha();
          setCount(count + 1);
          setRemember(false);
          if (typeof localStorage !== "undefined") {
            // Remove the item with the name "ujsffq"
            localStorage.removeItem("ujsffq");
          }
          formik.setFieldValue("email", "");
          formik.setFieldValue("password", "");
          formik.setTouched({});
          console.log("No result");
        }
        if (result && result.message === "normal") {
          let existingData = localStorage.getItem("ujsffq");

          // Parse the existing data as JSON or create an empty object if it doesn't exist
          let existingObject = existingData ? JSON.parse(existingData) : {};

          // Update the existing object with the new email value
          existingObject.email = email;

          // Set the updated object in localStorage
          localStorage.setItem("ujsffq", JSON.stringify(existingObject));

          // if (result.data.first) {
          //   navigate("/selectcounties");
          // } else {
          navigate("/dashboard");
        } else if (result && result.message === "checkout") {
          window.location.href = result.data;
        } else if (result && result.message === "faunset") {
          const token = result.data;
          navigate(`/2fa-secure?token=${token}`);
          return;
        } else if (result && result.message === "emailFa") {
          const token = result.data;
          navigate(`/2fa-secure/email?token=${token}&remember=${remember}`);
          return;
        } else if (result && result.message === "totp") {
          const token = result.data;
          navigate(`/2fa-secure/totp?token=${token}&remember=${remember}`);
          return;
        } else if (result && result.message === "smsFa") {
          const token = result.data;
          navigate(`/2fa-secure/sms?token=${token}&remember=${remember}`);
          return;
        }
      } catch (err) {
        console.log("Catch error");
        setCount(count + 1);
        resetRecaptcha();
        console.log(err);
      }
    },
  });

  const signInWith = async (strategy) => {
    try {
      await signIn.authenticateWithRedirect({
        strategy,
        redirectUrl: "/login",
        redirectUrlComplete: "/sso_redirect_1saf32mf23mnk23f23",
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box
        sx={
          isSmallScreen
            ? {
                justifyContent: "center",
                overflow: "auto",
                width: "100%",
                height: "100vh",
              }
            : {
                backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                backgroundRepeat: "repeat",
                width: "100%",
                flex: "1 1 auto",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                height: "100vh",
              }
        }
      >
        <Box
          sx={
            isSmallScreen
              ? {
                  display: "flex",
                  alignItems: "center",
                  flex: "1 1 auto",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  py: "10px",
                  width: "100%",
                }
              : {
                  backgroundColor: "#fff",
                  maxWidth: 700,
                  borderRadius: "25px",
                  px: 3,
                  py: "10px",
                  width: "100%",
                  pt: "2rem",
                }
          }
        >
          <div
            style={
              isSmallScreen
                ? { width: "100%", padding: "1rem" }
                : { padding: "1rem" }
            }
          >
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  style={{
                    width: "20rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "-9px",
                  }}
                  alt="nocCreatorlogo"
                  src="/img/logos/nocLogo.png"
                />
              </Box>

              <Typography
                sx={{ textAlign: "center" }}
                color="#004976"
                variant="h6"
              >
                Hi, Welcome Back
              </Typography>
              <Typography
                sx={{ textAlign: "center", lineHeight: "3px", pb: "1rem" }}
                color="text.secondary"
                variant="body2"
              >
                Enter your credentials to continue
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  pt: "20px",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
                color="#101820"
                variant="h6"
              >
                Sign in with
              </Typography>

              <Stack
                justifyContent="center"
                direction={isSmallScreen ? "column" : "row"}
                spacing={isSmallScreen ? 0 : 5}
                sx={{ mx: "auto" }}
              >
                {/* <Grid item xs={12} sm={4}> */}
                <Button
                  fullWidth={isSmallScreen}
                  // disabled
                  size="large"
                  sx={signInButtonStyle}
                  variant="outlined"
                  onClick={() => signInWith("oauth_google")}
                >
                  <img
                    alt="google-sso"
                    src="/img/logos/google_logo.png"
                    style={{ width: "25px", marginRight: "8px" }}
                  />
                  Google
                </Button>
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={4}> */}
                <Button
                  // disabled
                  size="large"
                  sx={signInButtonStyle}
                  variant="outlined"
                  onClick={() => signInWith("oauth_facebook")}
                >
                  <img
                    alt="facebook-sso"
                    src="/img/logos/facebook_logo.png"
                    style={{ width: "25px", marginRight: "8px" }}
                  />
                  Facebook
                </Button>
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={4}> */}
                <Button
                  // disabled
                  size="large"
                  sx={signInButtonStyle}
                  variant="outlined"
                  onClick={() => signInWith("oauth_apple")}
                >
                  <img
                    alt="apple-sso"
                    src="/img/logos/apple_logo.png"
                    style={{ width: "35px" }}
                  />
                  Apple
                </Button>
                {/* </Grid> */}
              </Stack>

              <Box
                sx={{
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  pt: "5px",
                }}
              >
                <div
                  style={{
                    border: "1px solid #E7E5E5",
                    width: "100%",
                    marginRight: "2rem",
                  }}
                />
                <Button
                  disabled
                  size="large"
                  sx={{
                    width: "145px",
                    backgroundColor: "#F8FAFC",
                    py: "2px",
                    borderRadius: "8px",
                    mb: "10px",
                    mt: "8px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    signOut();
                  }}
                >
                  OR
                </Button>
                <div
                  style={{
                    border: "1px solid #E7E5E5",
                    width: "100%",
                    marginLeft: "2rem",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
                color="#101820"
                variant="h6"
              >
                Sign in with Email Address
              </Typography>
            </Stack>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e); // Trigger Formik's handleChange as well
                  }}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Box>
                    <FormControlLabel
                      sx={{ mt: "5px", mr: "auto" }}
                      control={
                        <Checkbox
                          checked={remember}
                          onChange={(e) => setRemember(e.target.checked)} // Update remember in Redux state
                        />
                      }
                      label={
                        <Typography
                          sx={{ textAlign: "center", lineHeight: "3px" }}
                          color="text.secondary"
                          variant="body2"
                        >
                          Remember Me
                        </Typography>
                      }
                      size="sm"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link
                      // component={NextLink}
                      sx={{
                        fontWeight: "bold",
                        ml: "auto",
                        mt: 1,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        navigate("/resetpassword");
                      }}
                      underline="hover"
                      variant="subtitle2"
                    >
                      Forgot password?
                    </Link>
                  </Box>
                </Grid>
              </Grid>

              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{ minHeight: "1.5rem" }}
                textAlign="center"
                my={1}
                color="error"
                fontWeight="bold"
              >
                {error}
              </Typography>
              {count > 0 && (
                <ReCAPTCHA
                  key={recaptchaKey}
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={handleRecaptchaVerify}
                />
              )}

              <LoadingButton
                disabled={loginDisabled}
                loading={loading}
                fullWidth
                size="large"
                sx={count > 0 ? { mt: 3 } : { mt: 0 }}
                type="submit"
                variant="contained"
              >
                Sign In
              </LoadingButton>
            </form>
            <div
              style={{
                border: "1px solid #E7E5E5",
                width: "100%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            />
            <Box
              sx={{
                flex: "1 1 auto",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Button
                onClick={(e) => {
                  navigate("/register");
                }}
                color="warning"
                fullWidth
                size="large"
                sx={{
                  backgroundColor: "#F2CD00",

                  ":hover": { backgroundColor: "#CDAF0B" },
                }}
                variant="contained"
              >
                Register NOW
              </Button>

              <Link
                // component={NextLink}
                sx={{
                  fontWeight: "bold",
                  // ml: "auto",
                  mt: 2,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  navigate("/");
                }}
                underline="hover"
                variant="subtitle2"
              >
                Back to Home
              </Link>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default LoginComponent;
