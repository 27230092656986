import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchUserData } from "../../store/actions/authActions";
import {
  Box,
  Grid,
  Container,
  Card,
  Typography,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

const Defaults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultCounty = location.state?.option;
  const currentUser = useSelector((state) => state.auth.user);
  const [defaultValues, setDefaultValues] = useState({
    general: "",
    interest: "",
  });
  const [selected, setSelected] = useState(defaultCounty || "");
  const [selectList, setSelectList] = useState();
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const [loadingPage, setLoadingPage] = useState(true);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  let nocCount = 0;

  const handleSubmit = async () => {
    setLoading(true);
    if (selected === "") {
      setLoading(false);
      return setErrorText("Please select a County");
    }

    try {
      const changed = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/editdefaults`,
        {
          selected: selected,
          general: defaultValues.general,
          interest: defaultValues.interest,
          email: currentUser?.user.email,
        },
        {
          withCredentials: true,
        }
      );

      if (changed.data.success) {
        dispatch(fetchUserData());

        const selectedList = [];
        currentUser.user.selectedCountiesNOC.forEach((item) => {
          selectedList.push(item.name);
        });

        setSelectList(selectedList);
        setErrorText("Default has been added/changed successfully");
      }
      setLoading(false);
    } catch (err) {
      window.location.reload();
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateNOC = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-selected-counties`,
          { form: defaultCounty },
          { withCredentials: true }
        );
      } catch (e) {
        console.log(e);
      }
    };

    if (nocCount < 1 && defaultCounty) {
      nocCount += 1;
      updateNOC();
    }
  }, []);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  useEffect(() => {
    if (!currentUser || !currentUser.user || !currentUser.nocUserForms) {
      return;
    }

    if (currentUser.user.expired === true) {
      navigate("/subscription");
      return;
    } else if (currentUser.user.selectedCountiesNOC.length === 0) {
      navigate("/selectcounties");
      return;
    }

    const selectedList = [];
    currentUser.user.selectedCountiesNOC.forEach((item) => {
      selectedList.push(item.name);
    });

    setSelectList(selectedList);

    if (selected !== "") {
      currentUser.user.selectedCountiesNOC.forEach((item, index) => {
        if (selected === item.name) {
          setDefaultValues({
            general: item.general,
            interest: item.interest,
          });
          setLoading(false);
        }
      });
    }
    setLoadingPage(false);
  }, [selected, currentUser]);

  return (
    <>
      {loadingPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="lg" py={3}>
              <Card
                py={3}
                px={3}
                component={Grid}
                sx={
                  isMd
                    ? { width: "90vw", ml: "auto", mr: "auto" }
                    : { width: "50vw", ml: "auto", mr: "auto" }
                }
                container
              >
                <Grid container justifyContent="center">
                  <Grid item sm={12}>
                    <Typography
                      sx={{ textAlign: "center", mb: 5 }}
                      variant="h5"
                    >
                      Add / Edit Defaults
                    </Typography>
                  </Grid>
                  <Grid item sm={12} width="100%">
                    <Stack spacing={3} mt={4}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel>Select County</InputLabel>
                        <Select
                          disabled={loading}
                          value={selected}
                          label="Select"
                          onChange={(e) => setSelected(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {selectList?.map(function (item, i) {
                            return (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <TextField
                        disabled={loading}
                        fullWidth
                        label="General Description of Improvements"
                        name="general"
                        onChange={(e) => {
                          setDefaultValues({
                            ...defaultValues,
                            general: e.target.value,
                          });
                        }}
                        value={defaultValues["general"]}
                      />
                      <TextField
                        disabled={loading}
                        fullWidth
                        label="Owner's Interest in Site of the Improvement"
                        name="interest"
                        onChange={(e) => {
                          setDefaultValues({
                            ...defaultValues,
                            interest: e.target.value,
                          });
                        }}
                        value={defaultValues["interest"]}
                      />
                      <Typography
                        variant="body1"
                        color="#F2CD00"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ minHeight: "1.5rem" }}
                      >
                        {errorText}
                      </Typography>
                      <LoadingButton
                        loading={loading}
                        fullWidth
                        size="large"
                        sx={{
                          mt: 2,
                          backgroundColor: "primary",
                        }}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        Submit Changes
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default Defaults;
