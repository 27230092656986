import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import jwtCookieMiddleware from "./middleware/jwtCookieMiddleware";
import authReducer from "./reducers/authReducer";
import localStorageReducer from "./reducers/localStorageReducer";
import drawerReducer from "./reducers/stateReducers";
import nocReducer from "./reducers/nocReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    localStorageData: localStorageReducer,
    nocData: nocReducer,
    states: drawerReducer,
  },
  middleware: [thunk, jwtCookieMiddleware],
});

export default store;
