import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Box,
  Grid,
  styled,
  Switch,
  Container,
  Card,
  Button,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  Tabs,
  CardHeader,
  CardContent,
  CardActions,
  useMediaQuery,
  useTheme,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

const SuccessCreateExpiredSubscription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const queryParams = new URLSearchParams(location.search);
  const tokenQuery = queryParams.get("token");
  const sessionIdQuery = queryParams.get("session_id");
  const [forwardUrl, setForwardUrl] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  let runs = 0;

  useEffect(() => {
    const validate = async () => {
      runs += 1;
      const isChangePlanCookie = Cookies.get("expiredPlan");
      const isCookie = Cookies.get("NocCreator");
      const tokenValues = {
        tokenQuery,
        sessionIdQuery,
      };
      if (isChangePlanCookie) {
        if (isCookie) {
          if (!tokenQuery) {
            navigate("/dashboard");
          } else {
            try {
              const resp = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/validate-expired-plan-token`,
                {
                  tokenValues,
                },
                { withCredentials: true }
              );

              if (resp.data.success === true) {
                setForwardUrl("/dashboard");
                setPageLoading(false);
              } else {
                navigate("/dashboard");
              }
            } catch (e) {
              console.log(e);
              navigate("/dashboard");
            }
          }
        } else {
          if (!tokenQuery) {
            navigate("/login");
          } else {
            try {
              const resp = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/validate-expired-plan-token`,
                {
                  tokenValues,
                },
                { withCredentials: true }
              );

              if (resp.data.success === true) {
                setForwardUrl("/login");
                setPageLoading(false);
              } else {
                navigate("/login");
              }
            } catch (e) {
              console.log(e);
              navigate("/login");
            }
          }
        }
      } else {
        if (isCookie) {
          navigate("/dashboard");
        } else {
          navigate("/login");
        }
      }
    };
    if (runs < 1) {
      validate();
    }
  }, []);

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "20rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/logos/nocLogo.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color="primary"
                variant="h4"
                align="center"
              >
                Subscription Successful
              </Typography>
              <Typography
                sx={{ mb: 2, mt: 5 }}
                color="primary"
                variant="h5"
                align="center"
              >
                Important Information
              </Typography>
              <Paper
                elevation={3}
                style={{ padding: "16px", maxWidth: "400px", margin: "auto" }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary="New Plan"
                      secondary="Your account has been upgraded/downgraded to the new selected plan. This plan is now active and ready for use."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Payment Confirmation"
                      secondary="The payment for the new plan has been successfully processed."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Invoice"
                      secondary="An invoice for the recent transaction has been sent to your registered email address. You can review and download the invoice for your records."
                    />
                  </ListItem>
                </List>
              </Paper>
              <Grid item sm={12} sx={{ mt: 4, mx: 1 }}>
                <Button
                  fullWidth
                  onClick={() => navigate(forwardUrl)}
                  size="large"
                  sx={{ mt: 0, p: 2 }}
                  variant="contained"
                >
                  F I N I S H
                </Button>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SuccessCreateExpiredSubscription;
