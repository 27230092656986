import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ApexChart from "react-apexcharts";

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.warning.main],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      // title: {
      //   text: "Month",
      // },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.warning,
        },
      },
    },
  };
};

const UserOverview = (props) => {
  const { chartSeries, sx, setUserYear, handleUserChartValues, userYear } =
    props;
  const chartOptions = useChartOptions();
  const years = ["2023", "2024", "2025", "2026", "2027"];

  const handleChange = (event) => {
    setUserYear(event.target.value);
    handleUserChartValues();
  };
  return (
    <Card sx={sx} style={{ position: "relative" }}>
      <CardHeader title="Total Users" />
      <CardContent>
        <Box sx={{ position: "absolute", top: 30, right: 30, width: "8rem" }}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Select year</InputLabel>
            <Select
              value={userYear}
              label="user_select"
              onChange={handleChange}
            >
              {years.map(function (item, i) {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <ApexChart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type="line"
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default UserOverview;
