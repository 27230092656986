import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSignIn, useClerk } from "@clerk/clerk-react";
import { loginUser, clearError } from "../../store/actions/authActions";
import { setLocalStorageItem } from "../../store/actions/localStorageActions";

import Cookies from "js-cookie";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

const EmailFaAuthPass = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.auth.error);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const rememberFromQuery = queryParams.get("remember");
  const [step, setStep] = useState(1); // To track the current step
  const [emailLoading, setEmailLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [emailSentText, setEmailSentText] = useState("");
  let count = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if a cookie named 'yourCookieName' has the value 'NocCreator'
      if (Cookies.get("NocCreator")) {
        navigate("/dashboard");
      } else {
        // Cookie does not have the desired value
        // Add an alternative code block here if needed
      }
    }, 5000); // Check every 3 seconds
    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email-pass-verified`,
          {
            token: tokenFromQuery,
            remember_token: rememberFromQuery,
          },
          { withCredentials: true }
        );

        if (tokenResponse.data.success === false) {
          navigate("/login");
        } else {
          if (rememberFromQuery === "true") {
            dispatch(
              setLocalStorageItem("ujsffq", {
                email: tokenResponse.data.data.email,
              })
            );
          }
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/clear-email-verify`,
            {
              token: tokenFromQuery,
            }
          );

          dispatch({ type: "CHECK_TOKEN_COOKIE" });
        }
      } catch (error) {
        console.error("Error", error);
        navigate("/login");
      }
    };

    if (count < 1) {
      process_data();
      count += 1;
    }
  }, []);

  const handleResendCode = async () => {
    navigate("/dashboard");
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "20rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/logos/nocLogo.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color="primary"
                variant="h4"
                align="center"
              >
                Two Factor Authentication was Successful
              </Typography>
              <Typography color="primary" variant="h5" align="center">
                Redirecting to Dashboard
              </Typography>

              <Grid container justifyContent="center" spacing={2}>
                {images.map((image, index) => (
                  <Grid item sm={12} lg={4} key={index}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "transparent",
                        flexDirection: "column",
                        cursor: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box sx={{ pb: 3, pt: 1 }}>
                        <img
                          alt={image.alt}
                          src={image.src}
                          width={
                            selectedImage === index
                              ? image.width * 1.1
                              : image.width
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}

                <>
                  <Grid container justifyContent="center" mb={2} mt={5}>
                    <Grid item sm={12} sx={{ width: "100%" }}>
                      {/* <LoadingButton
                        onClick={callAuth}
                        disabled={blocked}
                        loading={loading}
                        fullWidth
                        size="large"
                        sx={{ mt: 0, p: 2 }}
                        type="submit"
                        variant="contained"
                      >
                        SEND VERIFICATION EMAIL
                      </LoadingButton> */}
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                        sx={{ mb: 5 }}
                      >
                        In 5 seconds, you will be redirected to the relevant
                        page. If you do not wish to wait, please click the
                        button below.
                      </Typography>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2, minHeight: "1.5rem" }}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="error"
                        fontWeight="bold"
                      >
                        {blockedText}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onClick={handleResendCode}
                      >
                        <Typography
                          component={Link}
                          variant="body1"
                          sx={
                            resendDisabled
                              ? {
                                  textAlign: "center",
                                  cursor: "auto",
                                  textDecoration: "none",
                                }
                              : { textAlign: "center", cursor: "pointer" }
                          }
                          color={resendDisabled ? "#d4d4d4" : "primary"}
                          fontWeight="bold"
                        >
                          Go to Dashboard
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

const images = [
  {
    alt: "authenticator",
    src: "/img/logos/2falogo.png",
    // width: 167,
    width: 250,
  },
];

export default EmailFaAuthPass;
