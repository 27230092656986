import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import TextFields from "./TextFields";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { PencilIcon } from "@heroicons/react/24/outline";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import ReactCardFlip from "react-card-flip";
import MoreResultsList from "../noc/MoreResultsList";
import { useNavigate } from "react-router-dom";

const EnlargeCountyForm = ({
  disableSelect,
  setSelectedLicenseIndex,
  invalid,
  licenseNumber,
  setLicenseNumber,
  licenseText,
  copySelected,
  setCopySelected,
  currentUser,
  intervalStarts,
  elapsedTime,
  targetTime,
  progressDisabled,
  progressValue,
  editFromCreate,
  retrieveLoading,
  defaultAddress,
  selectCards,
  enlargeType,
  selectedPreName,
  selectedPreForm,
  enlargeHeader,
  enlargeNote,
  enlargeFormInput,
  setEnlargeFormInput,
  setOpenDrawer,
  formik,
  type,
  preview,
  setIsPreview,
  previewImg,
  previewLoading,
  setCounty,
  createNocLoading,
  pdfImg,
  pdfImg2,
  inputs,
  editLoading,
  errorText,
  disableEdit,
  pdf,
  isReadyToDownload,
  text,
  setSelectedRow,
  addressRow,
  cardFlip,
  setCardFlip,
  setAddressRow,
  isRetrievingAddress,
  setIsRetrievingAddress,
  setRetrieveLoading,
  defaultValues,
  setCreateNocLoading,
  setPreviewLoading,
  setDefaultAddress,
  formDrawerOpen,
  setFormDrawerOpen,
  setPreview,
  isFormCreated,
  setNocStartedDrawer,
  setNewRequestID,
  setNewRequest,
  setInQueueMessage,
  setInQueueDrawer,
  setFullRetrieve,
  moreResultsText,
  licenseType,
  setLicenseType,
}) => {
  const [dynamicValues, setDynamicValues] = useState({});
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    if (selectedPreName === "Citrus") {
      const { street, number, unit, apt, lot, zip } = rowData;

      setDynamicValues({
        streetName: street || "",
        streetNumber: number || "",
        unitNumber: unit || "",
        apt: apt || "",
        lot: lot || "",
        zip: zip || "",
      });
    } else {
      const { street, number, unit, zip } = rowData;

      // Get the current formik values

      // Update the dynamicValues state with the clicked row's data for streetName, streetNumber, unitNumber, and zip
      setDynamicValues({
        streetName: street || "",
        streetNumber: number || "",
        unitNumber: unit || "",
        zip: zip || "",
      });
    }

    // Set the other formik values back to the formik state
    // formik.setValues({ ...formik.values, ...otherValues });
  };

  const handleIMG = () => {
    if (type === "edit") {
      if (pdfImg2 !== "") {
        if (preview) {
          return (
            <>
              <img
                alt="noc-preview"
                src={previewImg[0]}
                style={{ width: "100%" }}
              />
              <img
                alt="noc-preview"
                src={previewImg[1]}
                style={{ width: "100%" }}
              />
            </>
          );
        } else {
          return (
            <>
              <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
              <img alt="noc-pdf" src={pdfImg2} style={{ width: "100%" }} />;
            </>
          );
        }
      } else {
        if (preview) {
          return (
            <>
              <img
                alt="noc-preview"
                src={previewImg[0]}
                style={{ width: "100%" }}
              />
            </>
          );
        } else {
          return (
            <>
              <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
            </>
          );
        }
      }
    } else {
      let regex = /(\d+)(?=\.[^.]+$)/;

      // Check if the filename contains a number
      if (regex.test(pdfImg)) {
        // If it does, keep the original file path
        let originalFilePath = pdfImg;

        // And create a new file path with the number replaced with the specific number
        let newFilePath = pdfImg.replace(regex, 2);
        if (preview) {
          return (
            <>
              <img
                alt="preview-noc"
                src={previewImg[0]}
                style={{ width: "100%" }}
              />
              <img
                alt="preview-noc"
                src={previewImg[1]}
                style={{ width: "100%" }}
              />
            </>
          );
        } else {
          return (
            <>
              <img
                alt="noc-pdf"
                src={originalFilePath}
                style={{ width: "100%" }}
              />
              <img alt="noc-pdf" src={newFilePath} style={{ width: "100%" }} />;
            </>
          );
        }
      } else {
        if (preview) {
          return (
            <>
              <img
                alt="preview-noc"
                src={previewImg[0]}
                style={{ width: "100%" }}
              />
            </>
          );
        } else {
          return (
            <>
              <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
            </>
          );
        }
      }
    }
  };

  const handleIconButton = async () => {
    setOpenDrawer(false);
    if (type === "create-noc") {
      setCreateNocLoading(false);
      setPreviewLoading(false);
    }
    // setNewRequest(false);
    // setNewRequestID("");
    if (type === "welcome") {
      setEnlargeFormInput({
        general: "Alterations and repairs",
        interest: "Fee simple",
        form: "",
        name: "",
        added_name: "",
        added_email: "",
        locked: false,
      });
    }
  };

  const handleSelectedForm = () => {
    setOpenDrawer(false);
    selectCards(selectedPreName, selectedPreForm);

    setEnlargeFormInput({
      general: "Alterations and repairs",
      interest: "Fee simple",
      form: "",
      name: "",
      added_name: `${currentUser?.user.personal[0].firstName[0].toUpperCase()}.${
        currentUser?.user.personal[0].lastName
      }`,
      added_email: currentUser?.subuser
        ? currentUser?.subuser?.email
        : currentUser?.user.email,
      locked: false,
    });
  };

  const handlePreview = () => {
    if (preview && isMd) {
      setPreview(false);
    }
    setCardFlip(false);
    setCounty(selectedPreName);
    setIsPreview(true);
  };

  const handleReturnForm = () => {
    return (
      <>
        {enlargeType === "firstSelect" ? (
          <>
            <Stack
              spacing={3}
              mt={4}
              py={2}
              px={1}
              sx={{ maxHeight: "55vh", overflowY: "auto" }}
            >
              <TextField
                fullWidth
                label="General description of improvements"
                name="general"
                onChange={(e) =>
                  setEnlargeFormInput({
                    ...enlargeFormInput,
                    general: e.target.value,
                  })
                }
                value={enlargeFormInput["general"]}
              />
              <TextField
                fullWidth
                label="Owner's interest in site of the improvement"
                name="interest"
                onChange={(e) =>
                  setEnlargeFormInput({
                    ...enlargeFormInput,
                    interest: e.target.value,
                  })
                }
                value={enlargeFormInput["interest"]}
              />
            </Stack>
            <Box gap={2} sx={{ display: "flex", flexDirection: "row" }}>
              <Button
                onClick={handleSelectedForm}
                fullWidth
                size="large"
                sx={{
                  mt: 2,
                  mb: 2,
                  backgroundColor: "primary",
                }}
                variant="contained"
              >
                SELECT
              </Button>
            </Box>
            {isMd && (
              <Box
                gap={isMd ? 0 : 2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <LoadingButton
                  loading={previewLoading}
                  onClick={() => {
                    setFormDrawerOpen(true);
                  }}
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#F2CD00",
                    ":hover": { backgroundColor: "#CDAF0B" },
                    mb: 1,
                  }}
                >
                  SHOW FORM
                </LoadingButton>
              </Box>
            )}
          </>
        ) : (
          <>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
              <Stack
                spacing={3}
                mt={4}
                px={1}
                py={2}
                sx={{ maxHeight: "45vh", overflowY: "auto", mb: 2 }}
              >
                <TextFields
                  editFromCreate={editFromCreate}
                  dynamicValues={dynamicValues}
                  setDynamicValues={setDynamicValues}
                  formik={formik}
                  selectedPreName={selectedPreName}
                  type={type}
                  inputs={inputs}
                />
              </Stack>
              <Box
                gap={isMd ? 0 : 2}
                sx={
                  isMd
                    ? {
                        display: "flex",
                        flexDirection: "column",
                      }
                    : { display: "flex", flexDirection: "row" }
                }
              >
                {handleCheckButton()}
              </Box>
              {isMd && (
                <Box
                  gap={isMd ? 0 : 2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <LoadingButton
                    loading={previewLoading}
                    onClick={() => {
                      setFormDrawerOpen(true);
                    }}
                    variant="contained"
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: "#F2CD00",
                      ":hover": { backgroundColor: "#CDAF0B" },
                      mb: 1,
                    }}
                  >
                    SHOW FORM
                  </LoadingButton>
                </Box>
              )}

              <Typography
                variant="body1"
                sx={{
                  color: "#BCA631",
                  fontWeight: "bold",
                  textAlign: "center",
                  pb: 2,
                }}
              >
                {type === "edit" ? errorText : text}
              </Typography>
            </form>
          </>
        )}
      </>
    );
  };

  const handleCheckButton = () => {
    if (enlargeType === "edit") {
      return (
        <>
          <LoadingButton
            startIcon={<PencilIcon color="#fff" width={isMd ? 15 : 20} />}
            loading={editLoading}
            color="warning"
            type="submit"
            fullWidth
            size={isMd ? "small" : "large"}
            sx={
              isMd
                ? {
                    mt: 2,
                    mb: 1,
                  }
                : { mt: 2, mb: 2 }
            }
            variant="contained"
          >
            EDIT
          </LoadingButton>
          <LoadingButton
            loading={editLoading}
            // disabled={disableEdit}
            startIcon={
              <DocumentArrowDownIcon color="#fff" width={isMd ? 15 : 20} />
            }
            href={pdf}
            target="_blank"
            fullWidth
            size={isMd ? "small" : "large"}
            sx={
              isMd
                ? {
                    mb: 1,
                    background: "primary",
                  }
                : { mt: 2, mb: 2, background: "primary" }
            }
            variant="contained"
          >
            Download
          </LoadingButton>
        </>
      );
    } else {
      // AFTER CREATED
      if (editFromCreate) {
        return (
          <>
            <LoadingButton
              startIcon={<PencilIcon color="#fff" width={isMd ? 15 : 20} />}
              type="submit"
              loading={previewLoading}
              // onClick={handlePreview}
              fullWidth
              size={isMd ? "small" : "large"}
              sx={
                isMd
                  ? {
                      mt: 2,
                      mb: 1,
                      color: "#fff",
                      backgroundColor: "#F2CD00",
                      ":hover": { backgroundColor: "#CDAF0B" },
                    }
                  : {
                      mt: 2,
                      mb: 2,
                      color: "#fff",
                      backgroundColor: "#F2CD00",
                      ":hover": { backgroundColor: "#CDAF0B" },
                    }
              }
              variant="contained"
            >
              EDIT
            </LoadingButton>
            {isReadyToDownload ? (
              <>
                <Button
                  startIcon={
                    <DocumentArrowDownIcon
                      color="#fff"
                      width={isMd ? 15 : 20}
                    />
                  }
                  href={pdf}
                  target="_blank"
                  loading={createNocLoading}
                  fullWidth
                  size={isMd ? "small" : "large"}
                  sx={
                    isMd
                      ? {
                          mb: 1,
                          backgroundColor: "primary",
                        }
                      : { mt: 2, mb: 2, backgroundColor: "primary" }
                  }
                  variant="contained"
                >
                  DOWNLOAD
                </Button>
              </>
            ) : (
              <>
                <LoadingButton
                  onClick={(e) => {
                    setCounty(selectedPreName);
                    setCardFlip(false);
                    setAddressRow([]);
                    setIsPreview(false);
                  }}
                  loading={createNocLoading}
                  disabled={retrieveLoading}
                  type="submit"
                  fullWidth
                  size="large"
                  sx={{
                    mt: 2,
                    mb: 2,
                    backgroundColor: "primary",
                  }}
                  variant="contained"
                >
                  CREATE
                </LoadingButton>
              </>
            )}
          </>
        );
      } else {
        return (
          <>
            <LoadingButton
              disabled={isReadyToDownload || retrieveLoading}
              type="submit"
              loading={previewLoading}
              onClick={handlePreview}
              fullWidth
              size={isMd ? "small" : "large"}
              sx={
                isMd
                  ? {
                      mt: 2,
                      color: "#fff",
                      backgroundColor: "#F2CD00",
                      ":hover": { backgroundColor: "#CDAF0B" },
                    }
                  : {
                      mt: 2,
                      mb: 2,
                      color: "#fff",
                      backgroundColor: "#F2CD00",
                      ":hover": { backgroundColor: "#CDAF0B" },
                    }
              }
              variant="contained"
            >
              {!isMd ? (preview ? "RESET" : "PREVIEW") : "PREVIEW"}
            </LoadingButton>
            {isReadyToDownload ? (
              <>
                <Button
                  href={pdf}
                  target="_blank"
                  loading={createNocLoading}
                  fullWidth
                  size={isMd ? "small" : "large"}
                  sx={
                    isMd
                      ? {
                          mb: 2,
                          backgroundColor: "primary",
                        }
                      : { mt: 2, mb: 2, backgroundColor: "primary" }
                  }
                  variant="contained"
                >
                  Download
                </Button>
              </>
            ) : (
              <>
                <LoadingButton
                  onClick={(e) => {
                    // setNocStartedDrawer(true);
                    setCounty(selectedPreName);
                    setCardFlip(false);
                    setAddressRow([]);
                    setIsPreview(false);
                  }}
                  loading={createNocLoading}
                  disabled={retrieveLoading}
                  type="submit"
                  fullWidth
                  size={isMd ? "small" : "large"}
                  sx={
                    isMd
                      ? {
                          mt: 1,
                          mb: 1,
                          backgroundColor: "primary",
                        }
                      : { mt: 2, mb: 2, backgroundColor: "primary" }
                  }
                  variant="contained"
                >
                  CREATE
                </LoadingButton>
              </>
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <Grid container sx={{ display: "flex", overflow: "auto" }}>
        {!isMd && (
          <Grid
            item
            md={6}
            sx={
              isMd
                ? { maxHeight: "100vh", overflow: "auto", order: 2 }
                : { maxHeight: "100vh", overflow: "auto" }
            }
          >
            {type === "create-noc" ? (
              <Box
                sx={{
                  display: "column",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={
                    type !== "create-noc"
                      ? { display: "none", mt: "1rem", ml: "1rem" }
                      : {
                          display: "block",
                          mt: "1rem",
                          ml: "1rem",
                          position: "absolute",
                          left: 0,
                          top: 5,
                          zIndex: 2,
                        }
                  }
                  disabled={addressRow.length === 0}
                  onClick={() => setCardFlip(!cardFlip)}
                >
                  {cardFlip === true ? (
                    <CloseIcon />
                  ) : (
                    <FormatListBulletedIcon />
                  )}
                </IconButton>

                <ReactCardFlip
                  isFlipped={cardFlip}
                  flipDirection="horizontal"
                  flipSpeedBackToFront={1}
                  flipSpeedFrontToBack={1}
                >
                  <Box>{handleIMG()}</Box>

                  <Box sx={{ height: "100vh" }}>
                    <MoreResultsList
                      moreResultsText={moreResultsText}
                      setFullRetrieve={setFullRetrieve}
                      setIsPreview={setIsPreview}
                      setNewRequestID={setNewRequestID}
                      setNewRequest={setNewRequest}
                      setInQueueMessage={setInQueueMessage}
                      setInQueueDrawer={setInQueueDrawer}
                      setRetrieveLoading={setRetrieveLoading}
                      isRetrievingAddress={isRetrievingAddress}
                      setIsRetrievingAddress={setIsRetrievingAddress}
                      retrieveLoading={retrieveLoading}
                      defaultAddress={defaultAddress}
                      handleRowClick={handleRowClick}
                      setSelectedRow={setSelectedRow}
                      addressRow={addressRow}
                      formik={formik}
                      defaultValues={defaultValues}
                      setCreateNocLoading={setCreateNocLoading}
                      setPreviewLoading={setPreviewLoading}
                      setAddressRow={setAddressRow}
                      setDefaultAddress={setDefaultAddress}
                    />
                  </Box>
                </ReactCardFlip>
              </Box>
            ) : (
              <Box>{handleIMG()}</Box>
            )}
          </Grid>
        )}

        <Grid
          item
          md={6}
          px={isMd ? 1 : 4}
          sx={isMd ? { order: 1, width: "100%" } : {}}
        >
          <Stack pt={2}>
            <Box sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center", flex: "2" }}>
                <Typography variant="h4" textAlign="center">
                  {selectedPreName} County
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {enlargeHeader}
                </Typography>
                <Typography mt={2} variant="body2" textAlign="center">
                  {enlargeNote}
                </Typography>
                {copySelected?.valid === true &&
                copySelected?.confirmed === false ? (
                  <Typography sx={{ color: "#FF0000" }} align="center">
                    {currentUser?.subuser
                      ? "This license number is still needs to be confirmed. Please reach out to your administrator for further assistance."
                      : " Please confirm your license number through your profile settings to be able to create an NOC for this license type."}
                  </Typography>
                ) : copySelected?.process_status === "queued" ? (
                  <Typography sx={{ color: "#FF0000" }} align="center">
                    Validation in progress. Please wait.
                  </Typography>
                ) : (
                  invalid && (
                    <Typography sx={{ color: "#FF0000" }} align="center">
                      {currentUser?.subuser
                        ? "We're sorry, but this license number is not valid. Please reach out to your administrator for further assistance."
                        : "Your license number is invalid. Please update it through your profile settings to be able to create an NOC for this license type."}
                    </Typography>
                  )
                )}
                {invalid && !currentUser?.subuser && (
                  <Button
                    sx={{ mt: 2 }}
                    variant="outlined"
                    onClick={() => {
                      navigate("/profilesettings", {
                        state: { option: copySelected?.licenseType },
                      });
                    }}
                  >
                    {copySelected?.valid === true &&
                    copySelected?.confirmed === false
                      ? "Confirm Your Contractor Details"
                      : copySelected?.process_status === "queued"
                      ? "Details"
                      : "Change License Number"}
                  </Button>
                )}

                {type === "create-noc" && (
                  <Grid
                    container
                    sx={{
                      mt: 2,
                      marginBottom: "-2rem",
                      backgroundColor: "#f6f6f6",
                      p: 2,
                      borderRadius: "1rem",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Grid item lg={6} sm={12} order={isMd ? 2 : 1}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel id="licenseType-label">
                          License Type
                        </InputLabel>
                        <Select
                          disabled={disableSelect}
                          labelId="license_types"
                          name="licenseType"
                          label="License Type"
                          value={licenseType}
                          onChange={(e) => {
                            const index =
                              currentUser?.user?.contractors.findIndex(
                                (contractor) =>
                                  contractor.licenseType === e.target.value
                              );

                            setSelectedLicenseIndex(index);
                            // Use Formik's setFieldValue to update the licenseType
                            setLicenseType(e.target.value);

                            // Now you can update other form fields based on the selected licenseType
                            const selectedContractor =
                              currentUser?.user?.contractors.find(
                                (contractor) =>
                                  contractor.licenseType === e.target.value
                              );

                            setLicenseNumber(selectedContractor.licenseNumber);
                            setCopySelected(selectedContractor);
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {currentUser?.user?.contractors?.map(function (
                            contractor,
                            i
                          ) {
                            const getStyles = () => {
                              let dotColor = "";

                              if (contractor.process_status === "queued") {
                                dotColor = "#004976";
                              } else if (
                                contractor.valid === true &&
                                contractor.confirmed === false
                              ) {
                                dotColor = "#F2CD00";
                              } else if (
                                contractor.valid === true &&
                                contractor.confirmed === true
                              ) {
                                dotColor = "#006400";
                              } else if (contractor.valid === false) {
                                dotColor = "#a21717";
                              }

                              return {
                                "&::after": {
                                  content: '""',
                                  display: "inline-block",
                                  width: "10px", // Adjust the size of the dot
                                  height: "10px", // Adjust the size of the dot
                                  marginLeft: "5px", // Adjust the spacing between text and dot
                                  borderRadius: "50%",
                                  backgroundColor: dotColor,
                                },
                              };
                            };

                            return (
                              <MenuItem
                                key={i}
                                value={contractor.licenseType}
                                sx={getStyles()}
                              >
                                <Typography variant="body2">
                                  {contractor.licenseType}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center", // Vertically center the content
                          justifyContent: "center",
                          height: "100%", // Ensure the Box takes up the full height
                        }}
                      >
                        <Box
                          sx={
                            copySelected?.valid === false &&
                            copySelected?.process_status === "completed"
                              ? { borderBottom: "5px solid #990000" }
                              : copySelected?.process_status === "queued"
                              ? { borderBottom: "5px solid #004976" }
                              : { borderBottom: "5px solid #006400" }
                          }
                        >
                          <Typography
                            variant="h4"
                            sx={
                              copySelected?.valid === false &&
                              copySelected?.process_status === "completed"
                                ? { marginBottom: "-7px", color: "#990000" }
                                : copySelected?.process_status === "queued"
                                ? { marginBottom: "-7px", color: "#004976" }
                                : { marginBottom: "-7px", color: "#006400" }
                            }
                          >
                            {licenseNumber}
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          sx={
                            copySelected?.valid === false &&
                            copySelected?.process_status === "completed"
                              ? { color: "#990000" }
                              : copySelected?.process_status === "queued"
                              ? { color: "#004976" }
                              : { color: "#006400" }
                          }
                        >
                          {licenseText}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={
                            copySelected?.valid === false &&
                            copySelected?.process_status === "completed"
                              ? { color: "#990000" }
                              : copySelected?.process_status === "queued"
                              ? { color: "#004976" }
                              : { color: "#006400" }
                          }
                        >
                          {copySelected?.expiry}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Stack>
              <Stack sx={{ width: "auto" }}>
                <IconButton onClick={handleIconButton}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          {handleReturnForm()}
          {type === "create-noc" && (
            <Stack sx={{ justifyContent: "center" }}>
              <LinearProgress
                disabled={progressDisabled}
                variant="determinate"
                value={
                  intervalStarts
                    ? (elapsedTime / targetTime) * 100
                    : progressValue
                } // Set the progress value here
                sx={{ mt: 1, mb: 1 }}
              />
            </Stack>
          )}
        </Grid>
      </Grid>

      <Drawer
        PaperProps={{}}
        anchor="right"
        open={formDrawerOpen}
        onClose={() => setFormDrawerOpen(false)}
      >
        <Stack sx={{ width: "auto", ml: "auto" }}>
          <IconButton onClick={() => setFormDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item md={6} sx={{ maxHeight: "100%", order: 2 }}>
          {type === "create-noc" ? (
            <Box
              sx={{
                display: "column",
                position: "relative",
              }}
            >
              <IconButton
                sx={
                  type !== "create-noc"
                    ? { display: "none", mt: "1rem", ml: "1rem" }
                    : {
                        display: "block",
                        mt: "1rem",
                        ml: "1rem",
                        position: "absolute",
                        left: 0,
                        top: 5,
                        zIndex: 2,
                      }
                }
                disabled={addressRow.length === 0}
                onClick={() => setCardFlip(!cardFlip)}
              >
                {cardFlip === true ? <CloseIcon /> : <FormatListBulletedIcon />}
              </IconButton>

              <ReactCardFlip
                isFlipped={cardFlip}
                flipDirection="horizontal"
                flipSpeedBackToFront={1}
                flipSpeedFrontToBack={1}
              >
                <Box>{handleIMG()}</Box>
                <Box sx={{ height: "100vh" }}>
                  <MoreResultsList
                    moreResultsText={moreResultsText}
                    setFullRetrieve={setFullRetrieve}
                    setNewRequestID={setNewRequestID}
                    setRetrieveLoading={setRetrieveLoading}
                    isRetrievingAddress={isRetrievingAddress}
                    setIsRetrievingAddress={setIsRetrievingAddress}
                    retrieveLoading={retrieveLoading}
                    defaultAddress={defaultAddress}
                    handleRowClick={handleRowClick}
                    setSelectedRow={setSelectedRow}
                    addressRow={addressRow}
                    formik={formik}
                    defaultValues={defaultValues}
                    setCreateNocLoading={setCreateNocLoading}
                    setPreviewLoading={setPreviewLoading}
                    setAddressRow={setAddressRow}
                    setDefaultAddress={setDefaultAddress}
                  />
                </Box>
              </ReactCardFlip>
            </Box>
          ) : (
            <Box>{handleIMG()}</Box>
          )}
          {preview && !isFormCreated && (
            <LoadingButton
              type="submit"
              loading={previewLoading}
              onClick={handlePreview}
              fullWidth
              size="mall"
              sx={{
                position: "fixed",
                bottom: 0,
                borderRadius: 0,
                mt: 2,
                color: "#fff",
                backgroundColor: "#F2CD00",
                ":hover": { backgroundColor: "#CDAF0B" },
              }}
              variant="contained"
            >
              RESET
            </LoadingButton>
          )}
        </Grid>
      </Drawer>
    </>
  );
};

export default EnlargeCountyForm;
