import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const InvoiceGrid = ({ title, invoiceRow }) => {
  const invoiceColumns = [
    { field: "id", headerName: "Invoice Number", width: 160 },
    {
      field: "created",
      headerName: "Date",
      width: 150,
      editable: false,
    },
    {
      field: "billing_reason",
      headerName: "Billing Reason",
      width: 160,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      editable: false,
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 170,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 110,
      editable: false,
    },
    {
      field: "interval",
      headerName: "Interval",
      width: 100,
      editable: false,
    },
    {
      field: "hosted_invoice_url",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="View Invoice" arrow>
              <IconButton
                href={params.row.hosted_invoice_url}
                target="_blank"
                variant="contained"
                color="warning"
              >
                <OpenInNewIcon width={25} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "invoice_pdf",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Download Invoice" arrow>
              <IconButton
                href={params.row.invoice_pdf}
                target="_blank"
                variant="contained"
                color="primary"
              >
                <CloudDownloadIcon width={25} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
          rows={invoiceRow}
          columns={invoiceColumns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
        />
      </CardContent>
    </Card>
  );
};

export default InvoiceGrid;
