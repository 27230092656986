import InputField from "../../inputComponents/InputField";
import SelectField from "../../inputComponents/SelectField";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Checkbox,
  Link,
  useMediaQuery,
  useTheme,
  Paper,
  Box,
  Stack,
} from "@mui/material";
import TermsAndConditionsModal from "../../modals/TermsAndConditionsModal";
import { useState } from "react";

const Additional = ({
  // newsLetter,
  // setNewsLetter,
  terms,
  setTerms,
  invite,
  setInvite,
  additionalData,
  setInvitationRows,
  invitationRows,
  contractorList,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleInvitationNameChange = (index, value) => {
    setInvitationRows((prevState) => {
      const updatedRows = [...prevState];
      updatedRows[index][`name_${index + 1}`] = value;
      return updatedRows;
    });
  };

  const handleInvitationEmailChange = (index, value) => {
    setInvitationRows((prevState) => {
      const updatedRows = [...prevState];
      updatedRows[index][`email_${index + 1}`] = value;
      return updatedRows;
    });
  };

  return (
    <>
      <Typography
        align="center"
        sx={
          isSm
            ? { color: "#F2CD00", fontWeight: "bold", mt: 3 }
            : { color: "#F2CD00", fontWeight: "bold", mt: 6 }
        }
        variant="subtitle1"
      >
        Share your thoughts in our quick survey and enjoy free access to our
        website for a limited time! {<br />} Your feedback matters! Participate
        in the survey to help us improve our services.
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          mt: "1rem",
          minHeight: "55vh",
          overflowY: "auto",
          paddingRight: "2px",
        }}
      >
        <Grid item xs={12} md={6}>
          <InputField name="jobTitle" label="Job Title/Position" />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField
            name="referralSource"
            label="Referral Source"
            menu={additionalData?.referrals}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField
            name="companyName"
            contractorList={contractorList}
            label="Company Name(s)"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            name="companySize"
            label="Company Size"
            menu={additionalData?.company_size}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            type="number"
            name="yearsInBusiness"
            label="Years in Business"
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <InputField name="preferences" label="Interests or Preferences" />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <InputField name="sector" label="Industry or Sector" />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <InputField name="purpose" label="Purpose of Registration" />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={false}
            name="revenue"
            label="Revenue Range"
            menu={additionalData?.revenue_range}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={false}
            name="avgNoc"
            label="Average Monthly NOC Recordings"
            menu={additionalData?.average_noc}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={true}
            numSelection={5}
            name="topProject"
            label="Top Company Project Types (Select up to five)"
            menu={additionalData?.top_projects}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={true}
            numSelection={3}
            name="formsYouLike"
            label="Forms You'd Like Us to Assist in Creating (Choose Your Top 3)"
            menu={additionalData?.forms_you_like}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={false}
            name="primaryGoals"
            label={`Primary Goals for Using NOC Creator (Select Your Top Priority)`}
            menu={additionalData?.primary_goals}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            sx={{ fontWeight: "bold", textAlign: "center" }}
            color="text.secondary"
            variant="body2"
          >
            Would you like to invite some other contractors to use NOC Creator?
          </Typography>
          <Stack direction="row">
            <FormControlLabel
              sx={{ mt: "5px", ml: "auto" }}
              control={
                <Checkbox
                  checked={invite}
                  onChange={(e) => setInvite(e.target.checked)}
                />
              }
              label="Yes"
              labelPlacement="start"
              size="sm"
            />
            <FormControlLabel
              sx={{ mt: "5px", mr: "auto" }}
              control={
                <Checkbox
                  checked={!invite}
                  onChange={(e) => setInvite(!e.target.checked)}
                />
              }
              label="No"
              labelPlacement="start"
              size="sm"
            />
          </Stack>
        </Grid>

        <Box sx={invite ? { display: "block" } : { display: "none" }}>
          <Typography variant="h5" align="center" sx={{ mt: 2 }}>
            Invitations
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" align="center">
                Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" align="center">
                Email
              </Typography>
            </Grid>
          </Grid>
          <Paper
            elevation={5}
            style={
              isSm
                ? {
                    padding: "20px",
                    backgroundColor: "#e0e0e0", // Darker background color
                    marginLeft: "16px",
                  }
                : {
                    padding: "20px",
                    backgroundColor: "#e0e0e0", // Darker background color
                    marginLeft: "17px",
                  }
            }
          >
            <Grid container spacing={2}>
              {/* Map over invitation rows */}
              {invitationRows.map((row, index) => (
                <Grid container spacing={2} item xs={12} key={index}>
                  <Grid item xs={6}>
                    <TextField
                      label=""
                      disabled={row[`disabled_${index + 1}`]}
                      fullWidth
                      variant="standard"
                      name={`name_${index + 1}`}
                      value={row[`name_${index + 1}`]}
                      onChange={(e) =>
                        handleInvitationNameChange(index, e.target.value)
                      }
                      error={row[`name_${index + 1}_error`]}
                      sx={{
                        "& input": {
                          borderBottom: row[`name_${index + 1}_error`]
                            ? "2px solid #f00"
                            : "1px solid #ccc",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="email"
                      disabled={row[`disabled_${index + 1}`]}
                      label=""
                      fullWidth
                      variant="standard"
                      name={`email_${index + 1}`}
                      value={row[`email_${index + 1}`]}
                      onChange={(e) =>
                        handleInvitationEmailChange(index, e.target.value)
                      }
                      error={row[`email_${index + 1}_error`]}
                      sx={{
                        "& input": {
                          borderBottom: row[`email_${index + 1}_error`]
                            ? "2px solid #f00"
                            : "1px solid #ccc",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            paddingRight: "1rem",
            paddingLeft: "1rem",
            mt: "1rem",
          }}
        >
          <Grid item xs={12} mt={-2} md={6}>
            <FormControlLabel
              sx={{ mt: "5px", mr: "auto" }}
              required
              control={
                <Checkbox
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                />
              }
              label={
                <Typography
                  sx={{ textAlign: "center", lineHeight: "3px" }}
                  color="text.secondary"
                  variant="body2"
                >
                  I agree the{" "}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setOpen(true);
                    }}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    Terms & Conditions
                  </Link>
                </Typography>
              }
              size="sm"
            />
          </Grid>
        </Grid>
        <TermsAndConditionsModal open={open} onClose={handleCloseModal} />
      </Grid>
    </>
  );
};

export default Additional;
