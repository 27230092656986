import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Dialog,
  Grid,
  Button,
} from "@mui/material";

const AvailableCountiesModal = ({ open, onClose, counties }) => {
  const [selectedCounty, setSelectedCounty] = useState("");

  const handleClose = () => {
    setSelectedCounty("");
    onClose();
  };

  const handleCountyClick = (county) => {
    setSelectedCounty(county);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          display: "flex",
          p: 2,
          backgroundColor: "#f0f0f0",
          overflow: "hidden",
        },
      }}
      aria-labelledby="available-counties-modal-title"
      aria-describedby="available-counties-modal-description"
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            id="available-counties-modal-title"
            gutterBottom
          >
            Available Counties
          </Typography>
          <List sx={{ height: "44rem", overflowY: "auto", pr: "10px" }}>
            {counties.map((county, index) => (
              <ListItem
                sx={
                  selectedCounty === county
                    ? {
                        pl: 0,
                        cursor: "pointer",
                        backgroundColor: "#f2cd00",
                        pl: "10px",
                      }
                    : { pl: 0, cursor: "pointer" }
                }
                key={index}
                onClick={() => handleCountyClick(county)}
              >
                <ListItemText primary={county} />
              </ListItem>
            ))}
          </List>
          <Button
            sx={{ width: "96%" }}
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          {selectedCounty && (
            <Box sx={{ mt: 2, overflowY: "auto", height: "50rem" }}>
              <Typography variant="h6" gutterBottom>
                {selectedCounty}
              </Typography>
              {selectedCounty === "Brevard" ? (
                <>
                  <img
                    src={`/img/NOC_jpg/Brevard1.jpg`}
                    alt={"Brevard1"}
                    style={{
                      width: "100%",
                      // maxWidth: 400,
                      cursor: "pointer",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                  <img
                    src={`/img/NOC_jpg/Brevard2.jpg`}
                    alt={"Brevard2"}
                    style={{
                      width: "100%",
                      // maxWidth: 400,
                      cursor: "pointer",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                </>
              ) : (
                <img
                  src={`/img/NOC_jpg/${selectedCounty}.jpg`}
                  alt={selectedCounty}
                  style={{
                    width: "100%",
                    // maxWidth: 400,
                    cursor: "pointer",
                    transition: "transform 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                />
              )}
            </Box>
          )}
          {!selectedCounty && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Click on a county to view its image
            </Typography>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AvailableCountiesModal;
