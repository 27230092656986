import { broadcastUpdate, updateUser } from "../store/actions/authActions";
import { getNotification } from "../store/actions/authActions";
import { getLiveNocData } from "../store/actions/dataActions";
import Cookies from "js-cookie";
let socket = null;
let reconnectTimeout = null;
const userSockets = new Map();

let currentReconnectAttempt = 0;

export const initializeWebSocket = (token, dispatch) => {
  console.log(currentReconnectAttempt);
  if (!socket) {
    socket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/nocApi_ws/createnocws?token=${token}`
    );

    socket.onopen = (event) => {
      currentReconnectAttempt = 0;
      console.log("Connection open");
    };

    socket.onmessage = (event) => {
      let receivedData;
      try {
        receivedData = JSON.parse(event.data);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }

      if (receivedData?.event === "user_update") {
        const updatedUser = receivedData.data;
        dispatch(updateUser(updatedUser));
      }

      if (receivedData?.event === "broadcast_subusers") {
        const updatedUser = receivedData.data;
        dispatch(broadcastUpdate(updatedUser));
      }

      if (receivedData?.event === "notification_update") {
        const updatedNotifications = receivedData.data;
        dispatch(getNotification([updatedNotifications]));
      }

      if (receivedData?.event === "broadcast") {
        const updatedBroadCast = receivedData.data;
        dispatch(getLiveNocData([updatedBroadCast]));
      }

      if (receivedData?.event === "firstInit") {
        const firstInit = receivedData.data;
        dispatch(getLiveNocData([firstInit]));
      }
    };

    socket.onclose = (event) => {
      console.log("ON CLOSE");
      console.log(currentReconnectAttempt);
      socket = null;

      if (event.code === 1000) {
        // Connection closed successfully (OK)
        userSockets.delete(token);
        Cookies.remove("NocCreator", {
          domain: process.env.REACT_APP_DOMAIN, // Replace with your production domain
          path: "/", // The path where the cookie is set
          secure: true, // Cookie should be secure if used in HTTPS
          sameSite: "Strict", // SameSite policy
        });
        window.location.reload();
      } else {
        // Handle unexpected closures and attempt reconnection
        console.error("WebSocket closed unexpectedly:", event);
        if (currentReconnectAttempt >= 3) {
          Cookies.remove("NocCreator", {
            domain: process.env.REACT_APP_DOMAIN, // Replace with your production domain
            path: "/", // The path where the cookie is set
            secure: true, // Cookie should be secure if used in HTTPS
            sameSite: "Strict", // SameSite policy
          });
          window.location.reload();
        }
        // Retry reconnection if attempts are less than 20
        if (!reconnectTimeout) {
          reconnectTimeout = setTimeout(() => {
            currentReconnectAttempt++;
            initializeWebSocket(token, dispatch);
            reconnectTimeout = null; // Clear the reconnect timeout
          }, 5000);
        }
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }
};

export const getWebSocket = () => {
  return socket;
};

export const closeWebSocket = () => {
  if (socket) {
    socket.close();
  }
};
