import {
  Box,
  Grid,
  styled,
  Switch,
  Container,
  Card,
  Button,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  Tabs,
  CardHeader,
  CardContent,
  CardActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

const ChangePlan = ({
  checked,
  setChecked,
  selectedName,
  setSelectedName,
  currentPlan,
  tiers,
  setPlan,
  terms,
  setTerms,
  setOpen,
  defPlan,
  type,
}) => {
  const [checkedStatus, setcheckedStatus] = useState(
    defPlan.interval === "month" ? false : true
  );
  const useSliderStyle = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down("sm"));
    const isMd = useMediaQuery(theme.breakpoints.down("md"));
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));

    if (isLg) {
      return {
        ml: "auto",
        mr: "auto",
        maxWidth: "70vw",
        minHeight: "60.55vh",
        display: "flex",
        alignItems: "center",
      };
    } else if (isSm) {
      return {
        ml: "auto",
        mr: "auto",
        maxWidth: "85vw",
        minHeight: "52vh",
        display: "flex",
        alignItems: "center",
      };
    } else if (isMd) {
      return {
        ml: "auto",
        mr: "auto",
        maxWidth: "89vw",
        minHeight: "52vh",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  const handleSelect = (tier) => {
    setSelectedName(tier.title);
    setPlan(tier);
  };

  useEffect(() => {}, []);

  const handleDisable = (tier) => {
    const checkedSatus = defPlan.interval === "month" ? false : true;

    if (tier.title === defPlan.title && checkedSatus === checked) {
      return true;
    }
  };

  const handleCardStyle = (tier) => {
    const checkedSatus = defPlan.interval === "month" ? false : true;

    if (type === "change") {
      if (tier.title === defPlan.title && checkedSatus === checked) {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#90b7f9",
          color: "#fff",
        };
      }
    }

    if (checked) {
      if (currentPlan === tier.price_id_year) {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#76A0B9",
          color: "#fff",
        };
      }
      if (selectedName === tier.title) {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#F2CD00",
          color: "#004976",
        };
      } else {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#EEF2F6",
          color: "#004976",
        };
      }
    } else {
      if (currentPlan === tier.price_id_month) {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#76A0B9",
          color: "#fff",
        };
      }
      if (selectedName === tier.title) {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#F2CD00",
          color: "#004976",
        };
      } else {
        return {
          mr: "1rem",
          minWidth: "15rem",
          backgroundColor: "#EEF2F6",
          color: "#004976",
        };
      }
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const sliderStyle = useSliderStyle();

  return (
    <>
      <Stack
        sx={{ pb: 2, mt: 2 }}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body1"
          sx={!checked ? { fontWeight: "bold" } : { fontWeight: "normal" }}
        >
          Monthly
        </Typography>
        <AntSwitch
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
        <Typography
          variant="body1"
          sx={
            checked
              ? { fontWeight: "bold", color: "#1890ff" }
              : { fontWeight: "normal" }
          }
        >
          Annual
        </Typography>
      </Stack>
      <Box
        component={Tabs}
        value={selectedName}
        sx={sliderStyle}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {tiers
          ?.filter((tier) => tier.title !== "Free")
          .sort((a, b) => a.id - b.id)
          .map((tier, i) => (
            // Enterprise card is full width at sm breakpoint
            <Stack key={i} value={tier?.title} sx={{ ml: "auto", mr: "auto" }}>
              <Card sx={handleCardStyle(tier)}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: "inherit",
                    pt: 2,
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h5" color="primary">
                      {"$" + (!checked ? tier.monthlyPrice : tier.yearlyPrice)}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {/* {tier.title === "Free" && (!checked ? "/month" : "/year")} */}
                      {!checked ? "/month" : "/year"}
                    </Typography>
                  </Box>

                  {tier.description.map((line) => (
                    <Typography
                      sx={
                        tier.title === "Free"
                          ? { textAlign: "center" }
                          : { textAlign: "center" }
                      }
                      variant="subtitle2"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    disabled={type === "change" ? handleDisable(tier) : null}
                    onClick={() => handleSelect(tier)}
                    sx={{ width: "100%" }}
                    variant={
                      selectedName === tier.title ? "contained" : "outlined"
                    }
                  >
                    {selectedName === tier.title ? "SELECTED" : tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Stack>
          ))}
      </Box>
      <Grid item sm={12}>
        <Stack>
          <FormControlLabel
            sx={{ mt: "5px", mr: "auto" }}
            required
            control={
              <Checkbox
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
              />
            }
            label={
              <Typography
                sx={{ textAlign: "center", lineHeight: "3px" }}
                color="text.secondary"
                variant="body2"
              >
                I agree the{" "}
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpen(true);
                  }}
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Terms & Conditions
                </Link>
              </Typography>
            }
            size="sm"
          />
        </Stack>
      </Grid>
    </>
  );
};

export default ChangePlan;
