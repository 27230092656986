import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SelectCountyHeader = ({
  type,
  remainingLebal,
  countyLeft,
  countyOutOf,
  headerText,
  subtitleText,
  handleSearch,
  isSmallScreen,
  favoritesOpen,
  setFavoritesOpen,
  setCurrentPage,
  currentUser,
}) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const handleToggleFavorites = () => {
    setCurrentPage(1);
    setFavoritesOpen(!favoritesOpen);
    const inputElement = document.getElementById("searchInput");
    if (inputElement) {
      inputElement.value = ""; // Clear the search input value
    }
  };

  const getLabelText = () => {
    if (type === "create-noc") {
      if (
        currentUser?.user.role === "admin" ||
        currentUser?.user.role === "tester" ||
        currentUser?.user?.admin === true
      ) {
        return "Unlimited";
      } else if (currentUser?.user.role === "user") {
        if (currentUser?.user.trialEnded === false) {
          return `${countyLeft} / ${countyOutOf}`;
        } else if (currentUser?.tier.numNOC === "unlimited") {
          return "Unlimited";
        } else {
          return `${countyLeft} / ${countyOutOf}`;
        }
      }
    } else {
      return `${countyLeft} / ${countyOutOf}`;
    }
  };

  return (
    <>
      <Grid xs={12} item md={3} px={3} order={isMatch ? 3 : 1}>
        <Typography
          sx={isMatch ? { mt: 3 } : {}}
          textAlign="center"
          variant="body2"
        >
          {remainingLebal}
        </Typography>
        <Typography textAlign="center" variant="body2">
          {getLabelText()}
        </Typography>
      </Grid>
      <Grid xs={12} item md={6} px={3} order={isMatch ? 1 : 2}>
        <Typography mb={2} textAlign="center" variant="h5">
          {headerText}
        </Typography>
        <Typography
          sx={isMatch ? { mb: 3 } : {}}
          textAlign="center"
          variant="subtitle2"
        >
          {subtitleText}
        </Typography>
      </Grid>
      <Grid xs={12} item md={3} px={3} order={isMatch ? 2 : 3}>
        <TextField
          id="searchInput"
          onChange={(e) => {
            handleSearch(e);
          }}
          fullWidth
          placeholder="SEARCH"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      {type === "create-noc" && (
        <Grid
          xs={12}
          md={6}
          item
          px={3}
          order={isMatch ? 2 : 3}
          sx={{ mt: 1, mx: "auto" }}
        >
          <Button
            color={favoritesOpen ? "primary" : "warning"}
            sx={{ fontWeight: "bold" }}
            onClick={handleToggleFavorites}
            variant="outlined"
            fullWidth
          >
            {favoritesOpen ? "SHOW ALL" : "F A V O R I T E S"}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default SelectCountyHeader;
