import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

const UpdateUserTask = ({
  singleRegenerationData,
  setSingleRegenerationData,
  singleLoading,
  setSingleLoading,
  disabledFields,
  setDisabledFields,
}) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (text !== "") {
      const intervalId = setInterval(() => {
        // Your code to run at the interval
        setText("");
      }, 3000);

      // Clear the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [text]); // The emp

  const submitSingleGeneration = async () => {
    setSingleLoading(true);
    setDisabledFields(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/single-generation`,
        { singleRegenerationData },
        { withCredentials: true }
      );

      if (resp.data.success === true) {
        const values = resp.data.data;
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/createNoc`,
          {
            values,
          },
          { withCredentials: true }
        );

        setText("NOC is added to the queue");
        setSingleRegenerationData({
          taskId: "",
          contractor: "",
          date: "",
          email: "",
          scrapingFault: "",
          county: "",
          status: "",
        });
      } else {
        setText(resp.data.message);
      }

      setSingleLoading(false);
      setDisabledFields(true);
    } catch (e) {
      console.log(e);
      setSingleLoading(false);
      setDisabledFields(false);
    }
  };

  return (
    <Card>
      <CardHeader title="Single NOC Regeneration Manager" sx={{}} />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={12} lg={6}>
            <TextField
              disabled
              fullWidth
              label="County"
              value={singleRegenerationData.county}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              disabled
              fullWidth
              label="Status"
              value={singleRegenerationData.status}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              disabled
              fullWidth
              label="Is Scraping Fault?"
              value={singleRegenerationData.scrapingFault}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              disabled
              fullWidth
              label="Email"
              value={singleRegenerationData.email}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              disabled
              fullWidth
              label="Date"
              value={singleRegenerationData.date}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <TextField
              disabled
              fullWidth
              label="Contractor"
              value={singleRegenerationData.contractor}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Typography
            variant="body1"
            color="#F2CD00"
            fontWeight="bold"
            textAlign="center"
            sx={{ mt: 1, mb: 1, minHeight: "1.5rem" }}
          >
            {text}
          </Typography>
          <LoadingButton
            onClick={submitSingleGeneration}
            disabled={disabledFields}
            loading={singleLoading}
            fullWidth
            size="large"
            sx={{ mt: 0 }}
            variant="contained"
          >
            Start Single Generation
          </LoadingButton>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UpdateUserTask;
