import React from "react";
import {
  Button,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const FormNavigation = (props) => {
  const handleLoading = () => {
    if (props.isLastStep) {
      return (
        <LoadingButton
          sx={{ width: "100%" }}
          fullWidth={isSmallScreen}
          type="submit"
          color="primary"
          variant="contained"
          loading={props.isLoad}
          size="Large"
        >
          {handleLastStep()}
        </LoadingButton>
      );
    } else {
      return (
        <LoadingButton
          loading={props.nextLoad}
          type="submit"
          color="primary"
          sx={{ width: "100%" }}
          fullWidth={isSmallScreen}
          variant="contained"
          size="Large"
        >
          {handleLastStep()}
        </LoadingButton>
      );
    }
  };

  const handleLastStep = () => {
    if (props.isLastStep) {
      if (props.isExpired) {
        return "PAY";
      } else {
        // return "SIGN UP AND PAY NOTHING TODAY";
        return "PROCEED";
      }
    } else {
      return "Next";
    }
  };
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Typography
        variant="body1"
        fontWeight="bold"
        textAlign="center"
        color="#F04438"
        mb={2}
        sx={{ minHeight: "1.5rem" }}
      >
        {props.errorText}
      </Typography>

      <Grid
        gap={isSmallScreen ? 0 : 1}
        alignItems="center"
        container
        sx={{
          mb: isSmallScreen ? theme.spacing(2) : 0,
          justifyContent: isSmallScreen ? "center" : "space-between",
        }}
      >
        <Grid item xs={12} md={5} order={isSmallScreen ? 3 : 1}>
          {props.hasPrevious ? (
            <Button
              sx={
                isSmallScreen
                  ? { mt: "0.5rem", width: "100%" }
                  : { width: "100%" }
              }
              variant="contained"
              type="button"
              onClick={props.onBackClick}
              fullWidth={isSmallScreen}
              size="Large"
            >
              Back
            </Button>
          ) : (
            <Button
              sx={
                isSmallScreen
                  ? { mt: "0.5rem", width: "100%" }
                  : { width: "100%" }
              }
              variant="contained"
              disabled
              type="button"
              fullWidth={isSmallScreen}
              size="Large"
            >
              Back
            </Button>
          )}
        </Grid>

        <Grid order={isSmallScreen ? 1 : 3} item xs={12} md={5}>
          {props.cardRemain !== 0 &&
          props.stepNumber === 2 &&
          props.isSSOLogin === true ? (
            <Button
              sx={{ width: "100%" }}
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={isSmallScreen}
              size="large"
            >
              {handleLastStep()}
            </Button>
          ) : (
            handleLoading()
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormNavigation;
