import {
  CardHeader,
  CardContent,
  Card,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import MailIcon from "@mui/icons-material/Mail";
import CreateIcon from "@mui/icons-material/Create";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { LoadingButton } from "@mui/lab";

const ReadMessage = ({
  title,
  message,
  read,
  setRead,
  setOpenDrawerMessage,
  textField,
  setTextField,
  errorText,
  setErrorText,
  loading,
  setLoading,
  customerEmail,
  messageId,
  setReasonsData,
  selectedName,
  setMessagesDataRows,
  isSm,
}) => {
  const onSubmit = async () => {
    if (textField === "") {
      setErrorText("Message is required!");
    } else if (textField.length < 60) {
      setErrorText("Message length must be 60 character or more!");
    }

    try {
      setLoading(true);
      const items = { email: customerEmail, text: textField, id: messageId };

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/send-customer-message`,
        { items },
        { withCredentials: true }
      );

      const reasonsResp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/message-reasons-amounts`,
        { withCredentials: true }
      );

      setReasonsData(reasonsResp.data.data);
      if (selectedName) {
        const item = selectedName;
        const messagesResp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/get-posted-messages`,
          { item },
          { withCredentials: true }
        );
        setMessagesDataRows(messagesResp.data.data);
      }

      setErrorText(resp.data.message);

      setLoading(false);
      setTextField("");
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <>
            <Tooltip title={read ? "Write Message" : "Read Messages"}>
              <IconButton
                onClick={() => {
                  setRead(!read);
                }}
              >
                {read ? <CreateIcon /> : <MailIcon />}
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={() => {
                setOpenDrawerMessage(false);
                setTextField("");
                setRead(true);
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </>
        }
      />
      {read ? (
        <>
          <CardContent
            sx={
              isSm
                ? { height: "40rem", overflowY: "auto" }
                : { height: "20rem", overflowY: "auto" }
            }
          >
            <TextField
              value={message}
              disabled
              multiline={true}
              minRows={isSm ? 10 : 10}
              fullWidth
            />
          </CardContent>
        </>
      ) : (
        <>
          <CardContent
            sx={
              isSm
                ? { height: "40rem", overflowY: "auto" }
                : { height: "40rem", overflowY: "auto" }
            }
          >
            <TextField
              value={textField}
              onChange={(e) => {
                setTextField(e.target.value);
              }}
              sx={isSm ? { width: "100%", mt: 0 } : { width: "100%", mt: 4 }}
              label="Message"
              multiline
              rows={isSm ? 15 : 10}
            />
            <Typography
              variant="body1"
              color="#F2CD00"
              fontWeight="bold"
              textAlign="center"
              sx={{ mt: 2, minHeight: "1.5rem" }}
            >
              {errorText}
            </Typography>
            <LoadingButton
              loading={loading}
              onClick={onSubmit}
              fullWidth
              size="large"
              sx={{
                mt: 2,
                backgroundColor: "primary",
              }}
              variant="contained"
            >
              Send Message
            </LoadingButton>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default ReadMessage;
