import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSignIn, useClerk } from "@clerk/clerk-react";
import { loginUser, clearError } from "../../store/actions/authActions";
import SelectField from "../inputComponents/SelectField";

import Cookies from "js-cookie";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const DynamicAuth = () => {
  const { signIn } = useSignIn();
  const { signOut } = useClerk();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const error = useSelector((state) => state.auth.error);
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [processLoad, setProcessLoad] = useState(false);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [iconsBlocked, setIconsBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const sessionIdQuery = queryParams.get("session_id");
  const [step, setStep] = useState(1); // To track the current step
  const [emailLoading, setEmailLoading] = useState(false);
  const [mobileInputDisabled, setMobileInputDisabled] = useState(false);
  const [mobileText, setMobileText] = useState("");
  const [memorable, setMemorable] = useState("");
  const [memorableText, setMemorableText] = useState("");
  const [memorableSelections, setMemorableSelection] = useState([]);
  const [fromMemorableBlocked, setFromMemorableBlocked] = useState(true);
  const initialValues = {
    mobile: "",
    code: "",
  };

  useEffect(() => {
    if (blocked) {
      const timer = setInterval(() => {
        navigate("/login");
        clearInterval(timer);
      }, 6000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [blocked, step]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if a cookie named 'yourCookieName' has the value 'NocCreator'
      if (Cookies.get("NocCreator")) {
        navigate("/selectcounties");
      } else {
        // Cookie does not have the desired value
        // Add an alternative code block here if needed
      }
    }, 3000); // Check every 3 seconds

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const areAllFieldsFilled = inputValues.every((val) => val !== "");

    const callAuth = async () => {
      setProcessLoad(true);
      const code = inputValues.join("");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/auth-user`,
          {
            code: code,
            token: tokenFromQuery,
            memorable: memorable,
            memorableText: memorableText,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          dispatch({ type: "CHECK_TOKEN_COOKIE" });
        }
      } catch (e) {
        setProcessLoad(false);
        if (e.response.data.message === "blocked") {
          setBlocked(true);
          if (e.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(e.response.data.data);
          }

          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
          );
        } else if (e.response.data.message === "countDown") {
          setBlockedText(
            `You have only ${e.response.data.data} more attempts left. After that, you will be temporarily blocked from authentication for an hour.`
          );
          setTryLeft(e.response.data.data);
        }

        setInputValues(["", "", "", "", "", ""]);
        console.log(e);
      }
    };

    if (areAllFieldsFilled) {
      callAuth();
    }
  }, [inputValues]);

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const savePaidUser = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/save-checkout-user`,
          {
            token: sessionIdQuery,
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/get-user-detail`,
          {
            token: tokenFromQuery,
          }
        );

        if (tokenResponse.data.success) {
          const maskedEmail = maskEmail(tokenResponse.data.data);
          setMemorableSelection(tokenResponse.data.memorableList);
          setUserEmail(maskedEmail);
          // setUserEmail(tokenResponse.data.data);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/generate_qr`,
          {
            token: tokenFromQuery,
          },
          {
            responseType: "blob",
          }
        );
        if (response.status === 200) {
          const qrCodeUrl = URL.createObjectURL(new Blob([response.data]));
          setQRCodeSrc(qrCodeUrl);
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error.response.data.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error.response.data.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    if (sessionIdQuery) {
      savePaidUser();
    }
    process_data();
  }, []);

  const handleEmailFa = async () => {
    try {
      setEmailLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email_auth`,
        {
          token: tokenFromQuery,
          memorable: memorable,
          memorableText: memorableText,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        dispatch({ type: "CHECK_TOKEN_COOKIE" });
      }

      if (response.data.success === false) {
        setEmailLoading(false);
      }
    } catch (e) {
      console.log(e);
      setEmailLoading(false);
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleImageClick = (index) => {
    if (selectedImage === index) {
      setSelectedImage(null);
    } else {
      setSelectedImage(index);
    }
  };

  const validationSchemas = [
    // Validation schema for Step 1
    Yup.object({
      mobile: Yup.string()
        .min(10, "Number is too short")
        .max(10, "Number is too long")
        .required("Mobile number is required"),
    }),
    // Validation schema for Step 2 (code)
    Yup.object({
      code: Yup.string().required("Verification code is required"),
    }),
  ];

  useEffect(() => {
    const getItemFromLocalStorage = (key) => {
      const item = localStorage.getItem(key);
      return JSON.parse(item); // If the item is an object, parse it back to an object
    };

    const userData = getItemFromLocalStorage("ujsffq");

    if (userData) {
      setDefEmail(userData.email);
      setDefPwd(userData.password);
      setRemember(true);
    }
  }, []);

  useEffect(() => {
    if (
      error !== "" &&
      error !== "We are updating our application. Please come back later"
    ) {
      const timer = setTimeout(() => {
        dispatch(clearError()); // Dispatch the clearError action
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  useEffect(() => {
    if (memorableText === "" || memorable === "" || memorableText.length < 4) {
      setFromMemorableBlocked(true);
      setIconsBlocked(true);
      setSelectedImage(null);
    } else {
      setFromMemorableBlocked(false);
      setIconsBlocked(false);
    }
  }, [memorableText, memorable, fromMemorableBlocked]);

  // Generate a random six-character number

  const mobileFormik = useFormik({
    initialValues,
    validationSchema: validationSchemas[step - 1], // Use the schema for the current step
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      const send_sms = async () => {
        try {
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/sms/sendVerify`,
            {
              mobile: values["mobile"],
              token: tokenFromQuery,
            }
          );
          return resp;
        } catch (e) {
          console.log(e);
        }
      };

      if (step === 1) {
        const send_response = await send_sms();

        if (send_response.data.success === true) {
          setStep(2);
          setMobileInputDisabled(true);
          setMobileText(send_response.data.message);
          setIconsBlocked(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(true);
        const verify_sms = async () => {
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/getVerify`,
              {
                code: values["code"],
                token: tokenFromQuery,
                memorable: memorable,
                memorableText: memorableText,
              },
              { withCredentials: true }
            );
            return resp;
          } catch (e) {
            values["code"] = "";
            setLoading(false);
            setMobileText("Invalid Verification code");
            console.log(e);
          }
        };

        const smsResp = await verify_sms();

        if (smsResp.data.success) {
          dispatch({ type: "CHECK_TOKEN_COOKIE" });
        } else {
          values["code"] = "";
          setLoading(false);
          setMobileText("Invalid Verification code");
        }
      }
    },
  });

  const maskEmail = (email) => {
    const atPosition = email.indexOf("@");
    if (atPosition !== -1) {
      const charsToMask = Math.max(
        1,
        Math.floor(email.slice(0, atPosition).length / 2)
      );
      const maskedEmail =
        email.slice(0, charsToMask) +
        "*".repeat(atPosition - charsToMask) +
        email.slice(atPosition);
      return maskedEmail;
    } else {
      return email;
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100&",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "20rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/logos/nocLogo.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color="primary"
                variant="h4"
                align="center"
              >
                Enabling 2FA for Secure Access to Our Platform
              </Typography>
              <Typography color="primary" variant="body1" align="center">
                At NOC Creator, your security is paramount. To safeguard your
                account and data, we require Two-Factor Authentication (2FA).
                This extra layer of protection helps prevent unauthorized access
                and keeps your information secure. Setting up 2FA is quick,
                easy, and essential for safe use of our platform.
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel>Select a reminder question</InputLabel>
                    <Select
                      label="Select"
                      value={memorable}
                      onChange={(e) => setMemorable(e.target.value)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "1.5px",
                            maxHeight: 150, // Set the desired max height for the dropdown menu
                            width: "auto",
                            maxWidth: "100%",
                          },
                        },
                      }}
                      sx={{
                        maxWidth: "100%", // Set the desired max-width for the input element
                        "& .MuiTypography-body2": {
                          whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                        },
                      }}
                    >
                      {memorableSelections?.map(function (item, i) {
                        return (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Enter your answer here"
                    type="text"
                    onChange={(e) => {
                      setMemorableText(e.target.value);
                    }}
                    value={memorableText}
                  />
                </Grid>
              </Grid>

              <Typography
                sx={{ mb: 2, mt: 5 }}
                color="primary"
                variant="h5"
                align="center"
              >
                Please Select One
              </Typography>

              <Grid container spacing={2} sx={{ mt: 4 }}>
                {images.map((image, index) => (
                  <Grid item sm={12} lg={4} key={index}>
                    <Box
                      sx={
                        blocked || iconsBlocked || fromMemorableBlocked
                          ? {
                              borderRadius: "10px",
                              backgroundColor: "transparent",
                              flexDirection: "column",
                              cursor: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }
                          : {
                              borderRadius: "10px",
                              backgroundColor:
                                selectedImage === index
                                  ? "#f3f3f3"
                                  : "transparent",
                              flexDirection: "column",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              transform:
                                hovered === index ? "scale(1.1)" : "scale(1)",
                              transition: "transform 0.3s ease",
                            }
                      }
                      onClick={() =>
                        !iconsBlocked || !fromMemorableBlocked
                          ? handleImageClick(index)
                          : null
                      }
                      onMouseEnter={() =>
                        !iconsBlocked || !fromMemorableBlocked
                          ? setHovered(index)
                          : null
                      }
                      // onMouseLeave={handleImageLeave}
                    >
                      <Box sx={{ pb: 3, pt: 1 }}>
                        <img
                          alt={image.alt}
                          src={image.src}
                          width={
                            selectedImage === index
                              ? image.width * 1.1
                              : image.width
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
                {selectedImage === 0 && (
                  <>
                    <Grid sx={{ mt: 6 }} item sm={12}>
                      <Box
                        sx={{
                          p: 4,
                          borderRadius: "10px",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        <Typography variant="h5" align="center">
                          {userEmail}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item sm={12}>
                      <LoadingButton
                        // SUBMIT //
                        onClick={handleEmailFa}
                        loading={emailLoading}
                        fullWidth
                        size="large"
                        sx={{ mt: 0, p: 2 }}
                        variant="contained"
                      >
                        P R O C E E D
                      </LoadingButton>
                    </Grid>
                    <Grid item sm={12}>
                      <Typography
                        variant="body1"
                        sx={{ minHeight: "1.5rem" }}
                        textAlign="center"
                        my={1}
                        color="error"
                        fontWeight="bold"
                      >
                        {error}
                      </Typography>
                    </Grid>
                  </>
                )}
                {selectedImage === 1 && (
                  <>
                    <form
                      style={{ width: "100%" }}
                      noValidate
                      autoComplete="off"
                      onSubmit={mobileFormik.handleSubmit}
                    >
                      <Grid container spacing={2}>
                        <Grid sx={{ mt: 6, mb: 6 }} item sm={12} lg={6}>
                          <TextField
                            error={
                              !!(
                                mobileFormik.touched.mobile &&
                                mobileFormik.errors.mobile
                              )
                            }
                            helperText={
                              mobileFormik.touched.mobile &&
                              mobileFormik.errors.mobile
                            }
                            disabled={mobileInputDisabled}
                            name="mobile"
                            type="number"
                            label="Mobile Number"
                            variant="filled"
                            fullWidth
                            onBlur={mobileFormik.handleBlur}
                            value={mobileFormik.values.mobile}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +1
                                </InputAdornment>
                              ),
                              inputProps: { pattern: "[0-9]*" }, // Allow only numeric input
                              // Add any other input props or attributes as needed
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              mobileFormik.handleChange(e); // Trigger Formik's handleChange as well
                            }}
                          />
                        </Grid>
                        <Grid sx={{ mt: 6 }} item sm={12} lg={6}>
                          {step === 2 && (
                            <TextField
                              error={
                                !!(
                                  mobileFormik.touched.code &&
                                  mobileFormik.errors.code
                                )
                              }
                              fullWidth
                              helperText={
                                mobileFormik.touched.code &&
                                mobileFormik.errors.code
                              }
                              name="code"
                              value={mobileFormik.values.code}
                              label="Verification Code"
                              variant="filled"
                              onChange={(e) => {
                                mobileFormik.handleChange(e); // Trigger Formik's handleChange as well
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography
                          variant="body1"
                          sx={{ minHeight: "1.5rem" }}
                          textAlign="center"
                          my={1}
                          color="#004976"
                          fontWeight="bold"
                        >
                          {mobileText}
                        </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          size="large"
                          sx={{ mt: 0, p: 2 }}
                          type="submit"
                          variant="contained"
                        >
                          {step === 1
                            ? "Send Verification Code"
                            : "S U B M I T"}
                        </LoadingButton>
                      </Grid>
                      <Grid item sm={12}>
                        <Typography
                          variant="body1"
                          sx={{ minHeight: "1.5rem" }}
                          textAlign="center"
                          my={1}
                          color="error"
                          fontWeight="bold"
                        >
                          {error}
                        </Typography>
                      </Grid>
                    </form>
                  </>
                )}
                {selectedImage === 2 && (
                  <>
                    <Grid
                      sx={{ mt: 3, overflowY: "auto", maxHeight: "15rem" }}
                      item
                      sm={12}
                      md={6}
                    >
                      <Typography variant="h5">Set up Authenticator</Typography>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Link
                              // component={NextLink}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                // ml: "auto",
                                mt: 2,
                                cursor: "pointer",
                              }}
                              target="_blank"
                              underline="hover"
                              variant="subtitle2"
                              rel="noopener noreferrer"
                              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            >
                              1. Download Google Authenticator
                            </Link>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            2. On your mobile device, go to your{" "}
                            <Link
                              // component={NextLink}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                // ml: "auto",
                                mt: 2,
                                cursor: "pointer",
                              }}
                              target="_blank"
                              underline="hover"
                              variant="subtitle2"
                              rel="noopener noreferrer"
                              href="https://myaccount.google.com/?pli=1&nlr=1"
                            >
                              Google Account
                            </Link>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            3. At the top, tap the Security tab
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            4. Under "You can add more sign-in options," tap
                            Authenticator
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            5. Tap Set up authenticator
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            6. Follow the on-screen steps
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Box
                        display="flex"
                        justifyContent="center" // Center horizontally
                        alignItems="center" // Center vertically
                        height="100%" // Ensure the box takes the full height
                      >
                        {qrCodeSrc ? (
                          <img width={200} src={qrCodeSrc} alt="QR Code" />
                        ) : (
                          <p>Loading QR Code...</p>
                        )}
                      </Box>
                    </Grid>
                    <Grid container>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          width: "50%",
                        }}
                      >
                        <Typography color="error" fontWeight="bold">
                          {blockedText}
                        </Typography>
                      </Box>

                      <Box
                        sx={
                          processLoad
                            ? { display: "flex", ml: "auto", mr: "auto" }
                            : { display: "flex", ml: "auto" }
                        }
                      >
                        {processLoad ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                height: "100&",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <CircularProgress color="warning" size="5rem" />
                            </Box>
                          </>
                        ) : (
                          inputValues.map((value, index) => (
                            <Grid item key={index} sx={{ mx: "6px" }}>
                              <TextField
                                disabled={blocked}
                                id={`input-${index}`}
                                value={value}
                                variant="outlined"
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                onPaste={index === 0 ? handlePaste : undefined}
                                inputProps={{
                                  maxLength: 1,
                                  style: {
                                    fontSize: "28px",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  width: "48px",
                                  textAlign: "center",
                                  border: "3px solid #000",
                                  borderRadius: "10px",
                                }}
                              />
                            </Grid>
                          ))
                        )}
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

const images = [
  {
    alt: "email",
    src: "/img/logos/emailImage.png",
    // width: 120,
    width: 100,
  },
  {
    alt: "mobile",
    src: "/img/logos/phone.png",
    // width: 180,
    width: 160,
  },
  {
    alt: "authenticator",
    src: "/img/logos/authenticator.png",
    // width: 167,
    width: 147,
  },
];

export default DynamicAuth;
