import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Typography,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { LicensesGrid } from "../../admin/LicensesGrid";
import { LoadingButton } from "@mui/lab";
import {
  license_types,
  counties,
  license_statuses,
  duplicated_selection,
  edit_pivot_license_types,
} from "../../../data/dbpr";

const Licenses = () => {
  const theme = useTheme();
  const [tasksData, setTasksData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [sortModel, setSortModel] = useState(undefined);
  const [filterModel, setFilterModel] = useState(undefined);
  const [disabledFields, setDisabledFields] = useState(true);
  const [selectedLicenses, setSelectedLicenses] = useState(license_types);
  const [selectedCounties, setSelectedCounties] = useState(counties);
  const [selectedStatuses, setSelectedStatuses] = useState(license_statuses);
  const [selectedDuplicated, setSelectedDuplicated] = useState([]);

  const [totalLicenses, setTotalLicenses] = useState(0);
  const [totalDuplicatedAddress, setTotalDuplicatedAddress] = useState(0);
  const [totalSingleAddress, setTotalSingleAddress] = useState(0);

  const [first, setFirst] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [headers, setHeaders] = useState([]);
  const [pivot, setPivot] = useState([]);
  const [sortedPivot, setSortedPivot] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const [hideColumns, setHideColumns] = useState([]);
  const [tableSize, setTableSize] = useState("Extra Small");
  const [pivotHeight, setPivotHeight] = useState("20rem");

  const [operator, setOperator] = useState("");
  const [operand, setOperand] = useState("");
  const [highlightColor, setHighlightColor] = useState("");
  const [highlightHex, setHighlightHex] = useState("");
  const [resetDisabled, setResetDisabled] = useState(true);
  const [pivotLoading, setPivotLoading] = useState(false);
  const [pivotDisabled, setPivotDisabled] = useState(false);
  const [downloadDisabled, setDownloadDisabled] = useState(false);

  const [sortDirection, setSortDirection] = useState("none");

  const table1Ref = useRef(null);
  const table2Ref = useRef(null);

  let count = 0;
  const maxLength = 100;

  const handleScroll = () => {
    if (table1Ref.current && table2Ref.current) {
      table1Ref.current.scrollTop = table2Ref.current.scrollTop;
    }
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    try {
      const currentDate = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const licensesResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_licenses`,
        {
          page: page,
          rowsPerPage: rowsPerPage,
          sortModel: sortModel,
          selectedLicenses: selectedLicenses,
          counties: selectedCounties,
          statuses: selectedStatuses,
          duplicated: selectedDuplicated,
          exportPivot: false,
          exportCsv: true,
        },
        {
          withCredentials: true,
          responseType: "text",
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([licensesResponse.data], { type: "text/csv" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `contractors_${currentDate}.csv`; // Specify the filename here
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
      setDownloadLoading(false);
    }
    setDownloadLoading(false);
  };

  const handleReset = () => {
    setHideColumns([]);
    setTableSize("Extra Small");
    setOperator("");
    setOperand("");
    setHighlightColor("");
    setResetDisabled(true);
  };

  const handlePivotSort = () => {
    if (sortDirection === "none") {
      setSortDirection("asc");
      setSortedPivot(pivot.slice().sort((a, b) => a.Total - b.Total));
    } else if (sortDirection === "asc") {
      setSortDirection("desc");
      setSortedPivot(pivot.slice().sort((a, b) => b.Total - a.Total));
    } else if (sortDirection === "desc") {
      setSortDirection("none");
      setSortedPivot(pivot);
    }
  };

  const handleExportPivot = async () => {
    setPivotLoading(true);
    try {
      const currentDate = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const licensesResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_licenses`,
        {
          page: page,
          rowsPerPage: rowsPerPage,
          sortModel: sortModel,
          selectedLicenses: selectedLicenses,
          counties: selectedCounties,
          statuses: selectedStatuses,
          duplicated: selectedDuplicated,
          hideColumns: hideColumns,
          exportPivot: true,
          export_csv: false,
          sortDirection: sortDirection,
        },
        {
          withCredentials: true,
          responseType: "text",
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([licensesResponse.data], { type: "text/csv" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `contractors_pivot_${currentDate}.csv`; // Specify the filename here
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      setPivotLoading(false);
    } catch (e) {
      console.log(e);
      setPivotLoading(false);
    }
    setPivotLoading(false);
  };

  const handleHighlight = (rowValue) => {
    if (!operator || !operand || !highlightColor) {
      return { backgroundColor: "" };
    }
    const tolerance = 0.0001;

    if (operator === "Equal To =") {
      if (Math.abs(Number(rowValue) - operand) < tolerance) {
        return { backgroundColor: highlightHex };
      } else {
        return { backgroundColor: "" };
      }
    } else if (operator === "Less Than <") {
      if (Number(rowValue) < operand) {
        return { backgroundColor: highlightHex };
      } else {
        return { backgroundColor: "" };
      }
    } else if (operator === "Less Than or Equal To <=") {
      if (Number(rowValue) <= operand) {
        return { backgroundColor: highlightHex };
      } else {
        return { backgroundColor: "" };
      }
    } else if (operator === "Greater Than >") {
      if (Number(rowValue) > operand) {
        return { backgroundColor: highlightHex };
      } else {
        return { backgroundColor: "" };
      }
    } else if (operator === "Greater Than or Equal To >=") {
      if (Number(rowValue) >= operand) {
        return { backgroundColor: highlightHex };
      } else {
        return { backgroundColor: "" };
      }
    }
  };

  const renderLicenseSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.includes("All") || selected.length === 26) {
      return "All";
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const renderCountySelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.includes("All") || selected.length === 69) {
      return "All";
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const renderStatusSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.includes("All") || selected.length === 32) {
      return "All";
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const renderDuplicatedSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const renderPivotColumnSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.includes("All") || selected.length === 27) {
      return "All";
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const handleSelect = async () => {
    setFirst(true);
    setBtnLoading(true);
    setDownloadDisabled(true);
    setPivotDisabled(true);
    try {
      const licensesResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_licenses`,
        {
          page: 0,
          rowsPerPage: rowsPerPage,
          sortModel: sortModel,
          selectedLicenses: selectedLicenses,
          counties: selectedCounties,
          statuses: selectedStatuses,
          duplicated: selectedDuplicated,
          exportPivot: false,
          export_csv: false,
        },
        {
          withCredentials: true,
        }
      );
      if (licensesResponse.data.success) {
        setTasksData(licensesResponse.data.data.contractors);
        setTotalRowsCount(licensesResponse.data.data.totals.total_licenses);
        setTotalLicenses(licensesResponse.data.data.totals.total_licenses);
        setTotalDuplicatedAddress(
          licensesResponse.data.data.totals.licenses_with_duplicated_addresses
        );
        setTotalSingleAddress(
          licensesResponse.data.data.totals.total_license_single_address
        );
        const headersResp = Object.keys(licensesResponse.data.data.pivot[0]);
        setPivot(licensesResponse.data.data.pivot);
        setSortedPivot(licensesResponse.data.data.pivot);
        setSortDirection("none");
        setHeaders(headersResp);
        setPage(0);
      }
      setDataLoading(false);
      setBtnLoading(false);
      setDownloadDisabled(false);
      setPivotDisabled(false);
    } catch (e) {
      console.log(e);
      setDataLoading(false);
      setBtnLoading(false);
      setDownloadDisabled(false);
      setPivotDisabled(false);
    }
  };

  const handleLicenseSelectChange = (event) => {
    const selectedValues = event.target.value;
    setFirst(false);

    // Check if "All" is included in the selected values
    if (selectedValues.includes("All")) {
      // If "All" is selected, set the selected values to only "All"
      setSelectedLicenses(license_types);
    } else if (selectedValues.includes("None")) {
      // If "All" is selected, set the selected values to only "All"
      setSelectedLicenses([]);
    } else {
      // If individual options are selected, update state with selected values
      setSelectedLicenses(selectedValues);
    }
  };

  const handlePivotColumnSelectChange = (event) => {
    const selectedValues = event.target.value;
    setResetDisabled(false);

    // Check if "All" is included in the selected values
    if (selectedValues.includes("All")) {
      // If "All" is selected, set the selected values to only "All"
      setHideColumns(edit_pivot_license_types);
    } else if (selectedValues.includes("None")) {
      // If "All" is selected, set the selected values to only "All"
      setHideColumns([]);
    } else {
      // If individual options are selected, update state with selected values
      setHideColumns(selectedValues);
    }
  };

  const handleCountySelectChange = (event) => {
    const selectedValues = event.target.value;
    setFirst(false);

    // Check if "All" is included in the selected values
    if (selectedValues.includes("All")) {
      // If "All" is selected, set the selected values to only "All"
      setSelectedCounties(counties);
    } else if (selectedValues.includes("None")) {
      // If "All" is selected, set the selected values to only "All"
      setSelectedCounties([]);
    } else {
      // If individual options are selected, update state with selected values
      setSelectedCounties(selectedValues);
    }
  };

  const handleStatusSelectChange = (event) => {
    const selectedValues = event.target.value;
    setFirst(false);

    // Check if "All" is included in the selected values
    if (selectedValues.includes("All")) {
      // If "All" is selected, set the selected values to only "All"
      setSelectedStatuses(license_statuses);
    } else if (selectedValues.includes("None")) {
      // If "All" is selected, set the selected values to only "All"
      setSelectedStatuses([]);
    } else {
      // If individual options are selected, update state with selected values
      setSelectedStatuses(selectedValues);
    }
  };

  useEffect(() => {
    if (tableSize === "Extra Small") {
      setPivotHeight("20rem");
    } else if (tableSize === "Small") {
      setPivotHeight("25rem");
    } else if (tableSize === "Medium") {
      setPivotHeight("30rem");
    } else if (tableSize === "Large") {
      setPivotHeight("40rem");
    } else if (tableSize === "Extra Large") {
      setPivotHeight("50rem");
    }
  }, [tableSize]);

  useEffect(() => {
    if (highlightColor) {
      if (highlightColor === "Yellow") {
        setHighlightHex("#FFFFCC");
      } else if (highlightColor === "Green") {
        setHighlightHex("#66FF66");
      } else if (highlightColor === "Red") {
        setHighlightHex("#FFCCCC");
      } else if (highlightColor === "Purple") {
        setHighlightHex("#E6E6FA");
      } else if (highlightColor === "Grey") {
        setHighlightHex("#D3D3D3");
      }
    }
  }, [highlightColor]);

  useEffect(() => {
    // Check if "All" is included in the selected values
    if (selectedLicenses.includes("All")) {
      // Update state to include all options except "All"
      setSelectedLicenses(
        license_types.filter((option) => option !== "All" && option !== "None")
      );
    }

    if (selectedCounties.includes("All")) {
      // Update state to include all options except "All"
      setSelectedCounties(
        counties.filter((option) => option !== "All" && option !== "None")
      );
    }

    if (selectedStatuses.length > 0) {
      if (selectedStatuses.includes("All")) {
        // Update state to include all options except "All"
        setSelectedStatuses(
          license_statuses.filter(
            (option) => option !== "All" && option !== "None"
          )
        );
      }
    }

    if (hideColumns.length > 0) {
      if (hideColumns.includes("All")) {
        // Update state to include all options except "All"
        setHideColumns(
          edit_pivot_license_types.filter(
            (option) => option !== "All" && option !== "None"
          )
        );
      }
    }
  }, [selectedLicenses, selectedCounties, selectedStatuses, hideColumns]);

  useEffect(() => {}, [page, rowsPerPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const licensesResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_licenses`,
          {
            page: page,
            rowsPerPage: rowsPerPage,
            sortModel: sortModel,
            selectedLicenses: selectedLicenses,
            counties: selectedCounties,
            statuses: selectedStatuses,
            duplicated: selectedDuplicated,
            exportPivot: false,
            export_csv: false,
          },
          {
            withCredentials: true,
          }
        );
        if (licensesResponse.data.success) {
          setTasksData(licensesResponse.data.data.contractors);
          setTotalRowsCount(licensesResponse.data.data.totals.total_licenses);
          setTotalLicenses(licensesResponse.data.data.totals.total_licenses);
          setTotalDuplicatedAddress(
            licensesResponse.data.data.totals.licenses_with_duplicated_addresses
          );
          setTotalSingleAddress(
            licensesResponse.data.data.totals.total_license_single_address
          );
          const headersResp = Object.keys(licensesResponse.data.data.pivot[0]);
          setPivot(licensesResponse.data.data.pivot);
          setSortedPivot(licensesResponse.data.data.pivot);
          setHeaders(headersResp);
        }

        setDataLoading(false);
      } catch (e) {
        console.log(e);
        setDataLoading(false);
      }
    };

    if (count < 1) {
      count += 1;
      fetchData();
    }
  }, []);

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 3,
            backgroundColor: "#EEF2F6",
          }}
        >
          <Container maxWidth="xxl">
            <Grid container spacing={5}>
              <Grid xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader
                    titleTypographyProps={{
                      variant: "h4",
                      textAlign: "center",
                    }}
                    title="Filters"
                  />
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                      sx={{ alignItems: "top", mb: "2rem" }}
                    >
                      <Grid sm={4} xs={12}>
                        <Typography align="center" variant="h6">
                          Total licenses
                        </Typography>
                        <Typography align="center" variant="body1">
                          {totalLicenses}
                        </Typography>
                      </Grid>
                      <Grid sm={4} xs={12}>
                        <Typography align="center" variant="h6">
                          Licenses with duplicated addresses
                        </Typography>
                        <Typography align="center" variant="body1">
                          {totalDuplicatedAddress}
                        </Typography>
                      </Grid>
                      <Grid sm={4} xs={12}>
                        <Typography align="center" variant="h6">
                          Total licenses single address
                        </Typography>
                        <Typography align="center" variant="body1">
                          {totalSingleAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid spacing={2} sx={{ alignItems: "center" }} container>
                      <Grid sm={6} xs={12}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="licenseType-label">
                            License Types
                          </InputLabel>
                          <Select
                            labelId="license_types"
                            label="License Type"
                            multiple
                            value={selectedLicenses}
                            onChange={handleLicenseSelectChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  marginTop: "1.5px",
                                  maxHeight: 280, // Set the desired max height for the dropdown menu
                                  width: "auto",
                                  maxWidth: "100%",
                                },
                              },
                            }}
                            renderValue={renderLicenseSelectedValues}
                            sx={{
                              maxWidth: "100%", // Set the desired max-width for the input element
                              "& .MuiTypography-body2": {
                                whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                              },
                            }}
                          >
                            {license_types.map(function (contractor, i) {
                              return (
                                <MenuItem key={i} value={contractor}>
                                  <Typography variant="body2">
                                    {contractor}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid sm={6} xs={12}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="licenseType-label">
                            Main Address Counties
                          </InputLabel>
                          <Select
                            labelId="Counties"
                            label="Counties"
                            multiple
                            value={selectedCounties}
                            onChange={handleCountySelectChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  marginTop: "1.5px",
                                  maxHeight: 280, // Set the desired max height for the dropdown menu
                                  width: "auto",
                                  maxWidth: "100%",
                                },
                              },
                            }}
                            renderValue={renderCountySelectedValues}
                            sx={{
                              maxWidth: "100%", // Set the desired max-width for the input element
                              "& .MuiTypography-body2": {
                                whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                              },
                            }}
                          >
                            {counties.map(function (contractor, i) {
                              return (
                                <MenuItem key={i} value={contractor}>
                                  <Typography variant="body2">
                                    {contractor}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid sm={6} xs={12}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="licenseType-label">
                            License Statuses
                          </InputLabel>
                          <Select
                            labelId="status"
                            label="status"
                            multiple
                            value={selectedStatuses}
                            onChange={handleStatusSelectChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  marginTop: "1.5px",
                                  maxHeight: 280, // Set the desired max height for the dropdown menu
                                  width: "auto",
                                  maxWidth: "100%",
                                },
                              },
                            }}
                            renderValue={renderStatusSelectedValues}
                            sx={{
                              maxWidth: "100%", // Set the desired max-width for the input element
                              "& .MuiTypography-body2": {
                                whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                              },
                            }}
                          >
                            {license_statuses.map(function (contractor, i) {
                              return (
                                <MenuItem key={i} value={contractor}>
                                  <Typography variant="body2">
                                    {contractor}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid sm={6} xs={12}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="licenseType-label">
                            Exclude Duplicated
                          </InputLabel>
                          <Select
                            labelId="duplicated"
                            label="duplicated"
                            multiple
                            value={selectedDuplicated}
                            onChange={(e) => {
                              setSelectedDuplicated(e.target.value);
                              setFirst(false);
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  marginTop: "1.5px",
                                  maxHeight: 280, // Set the desired max height for the dropdown menu
                                  width: "auto",
                                  maxWidth: "100%",
                                },
                              },
                            }}
                            renderValue={renderDuplicatedSelectedValues}
                            sx={{
                              maxWidth: "100%", // Set the desired max-width for the input element
                              "& .MuiTypography-body2": {
                                whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                              },
                            }}
                          >
                            {duplicated_selection.map(function (contractor, i) {
                              return (
                                <MenuItem key={i} value={contractor}>
                                  <Typography variant="body2">
                                    {contractor}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid sm={12} xs={12}>
                        <LoadingButton
                          loading={btnLoading}
                          disabled={
                            first ||
                            selectedLicenses.length === 0 ||
                            selectedCounties.length === 0 ||
                            selectedStatuses.length === 0 ||
                            pivotLoading ||
                            downloadLoading ||
                            pivotDisabled ||
                            downloadDisabled
                          }
                          onClick={handleSelect}
                          fullWidth
                          variant="contained"
                        >
                          Apply Filter
                        </LoadingButton>
                      </Grid>
                      <Grid sm={12} xs={12}>
                        <LoadingButton
                          disabled={
                            downloadDisabled ||
                            pivotLoading ||
                            pivotDisabled ||
                            !first
                          }
                          color="warning"
                          onClick={handleDownload}
                          loading={downloadLoading}
                          fullWidth
                          variant="contained"
                        >
                          Export CSV
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Card sx={{ position: "relative" }}>
                  <CardHeader
                    titleTypographyProps={{
                      variant: "h4",
                      textAlign: "center",
                    }}
                    title="Pivot Table"
                  />
                  <Tooltip
                    sx={{ position: "absolute", top: 28, right: 15 }}
                    title="Export Pivot Table"
                  >
                    <IconButton
                      disabled={
                        downloadLoading ||
                        pivotLoading ||
                        pivotDisabled ||
                        downloadDisabled ||
                        !first
                      }
                      className={pivotLoading ? "rotate" : ""}
                      color="primary"
                      onClick={handleExportPivot}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>

                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Accordion
                        expanded={isExpanded}
                        elevation={0}
                        sx={{ width: "100%" }}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            borderBottom: "none",
                            cursor: "default",
                            "&.MuiAccordionSummary-root:hover": {
                              cursor: "default",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            {isExpanded ? (
                              <Button
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "178.17px",
                                }}
                                variant="contained"
                                color="error"
                                startIcon={<RemoveIcon />}
                                onClick={() => {
                                  setIsExpanded(false);
                                }}
                              >
                                Hide
                              </Button>
                            ) : (
                              <Button
                                disabled={
                                  pivotLoading ||
                                  downloadLoading ||
                                  pivotDisabled ||
                                  downloadDisabled ||
                                  !first
                                }
                                sx={{
                                  cursor: "pointer",
                                  minWidth: "178.17px",
                                }}
                                variant="contained"
                                color="primary"
                                startIcon={<SettingsIcon />}
                                onClick={() => {
                                  setIsExpanded(true);
                                }}
                              >
                                Edit Pivot Table
                              </Button>
                            )}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid xs={12}>
                              <Typography variant="h5" align="center" mb={2}>
                                Edit Pivot Table
                              </Typography>
                            </Grid>

                            <Grid xs={12} md={6}>
                              <FormControl fullWidth variant="filled">
                                <InputLabel>Hide Columns</InputLabel>
                                <Select
                                  multiple
                                  value={hideColumns}
                                  onChange={handlePivotColumnSelectChange}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 280, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  renderValue={renderPivotColumnSelectedValues}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {edit_pivot_license_types.map(function (
                                    contractor,
                                    i
                                  ) {
                                    return (
                                      <MenuItem key={i} value={contractor}>
                                        <Typography variant="body2">
                                          {contractor}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FormControl fullWidth variant="filled">
                                <InputLabel>Change Table Size</InputLabel>
                                <Select
                                  value={tableSize}
                                  onChange={(e) => {
                                    setTableSize(e.target.value);
                                    setResetDisabled(false);
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 280, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {[
                                    "Extra Small",
                                    "Small",
                                    "Medium",
                                    "Large",
                                    "Extra Large",
                                  ].map(function (contractor, i) {
                                    return (
                                      <MenuItem key={i} value={contractor}>
                                        <Typography variant="body2">
                                          {contractor}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid xs={12} sx={{ mt: 2 }}>
                              <Typography variant="h6" align="center" mb={2}>
                                Highlight Values
                              </Typography>
                            </Grid>
                            <Grid xs={12} md={4}>
                              <FormControl fullWidth variant="filled">
                                <InputLabel>Operator</InputLabel>
                                <Select
                                  value={operator}
                                  onChange={(e) => {
                                    setOperator(e.target.value);
                                    setResetDisabled(false);
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 280, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {[
                                    "Equal To =",
                                    "Less Than <",
                                    "Less Than or Equal To <=",
                                    "Greater Than >",
                                    "Greater Than or Equal To >=",
                                  ].map(function (contractor, i) {
                                    return (
                                      <MenuItem key={i} value={contractor}>
                                        <Typography variant="body2">
                                          {contractor}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid xs={12} md={4}>
                              <TextField
                                fullWidth
                                type="number"
                                label="Enter a Value"
                                value={operand}
                                onChange={(e) => {
                                  setOperand(e.target.value);
                                  setResetDisabled(false);
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={4}>
                              <FormControl fullWidth variant="filled">
                                <InputLabel>Highlight Color</InputLabel>
                                <Select
                                  value={highlightColor}
                                  onChange={(e) => {
                                    setHighlightColor(e.target.value);
                                    setResetDisabled(false);
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 280, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {[
                                    "Yellow",
                                    "Green",
                                    "Red",
                                    "Purple",
                                    "Grey",
                                  ].map(function (contractor, i) {
                                    return (
                                      <MenuItem key={i} value={contractor}>
                                        <Typography variant="body2">
                                          {contractor}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid xs={12} align="center">
                              <Button
                                disabled={
                                  resetDisabled ||
                                  (hideColumns.length === 0 &&
                                    tableSize === "Extra Small" &&
                                    !operator &&
                                    !operand &&
                                    !highlightColor)
                                }
                                onClick={handleReset}
                                variant="contained"
                              >
                                Reset Settings
                              </Button>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Grid container>
                      <Grid xs={!hideColumns.includes("Total") ? 3 : 2}>
                        <Box
                          ref={table1Ref}
                          onScroll={handleScroll}
                          id="table1"
                          sx={{ overflow: "hidden", maxHeight: pivotHeight }}
                        >
                          <Table>
                            <TableHead
                              sx={{
                                position: "sticky",
                                top: 0,
                                height: "90px",
                              }}
                            >
                              <TableRow>
                                <TableCell>Main Address County</TableCell>
                                {!hideColumns.includes("Total") && (
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Total{" "}
                                      <IconButton onClick={handlePivotSort}>
                                        {sortDirection === "asc" ? (
                                          <ArrowDownwardIcon />
                                        ) : sortDirection === "desc" ? (
                                          <ArrowUpwardIcon />
                                        ) : (
                                          <ArrowRightIcon />
                                        )}
                                      </IconButton>
                                    </Box>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sortedPivot?.map((row, index) => (
                                <TableRow key={index}>
                                  {headers.slice(0, 2).map(
                                    (header, columnIndex) =>
                                      // Check if the current header should be hidden
                                      !hideColumns.includes(header) && (
                                        <TableCell
                                          key={header}
                                          sx={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {row[header]}
                                        </TableCell>
                                      )
                                  )}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Grid>
                      <Grid xs={!hideColumns.includes("Total") ? 9 : 10}>
                        <Box
                          id="table2"
                          ref={table2Ref}
                          onScroll={handleScroll}
                          sx={{ overflow: "auto", maxHeight: pivotHeight }}
                        >
                          <Table>
                            <TableHead
                              sx={{
                                position: "sticky",
                                top: 0,
                                height: "90px",
                              }}
                            >
                              <TableRow>
                                {headers
                                  ?.filter(
                                    (_, index) => index !== 0 && index !== 1
                                  )
                                  .map(
                                    (header) =>
                                      // Check if the current header should be hidden
                                      !hideColumns.includes(header) && (
                                        <TableCell key={header}>
                                          {header}
                                        </TableCell>
                                      )
                                  )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sortedPivot?.map((row, index) => (
                                <TableRow key={index}>
                                  {headers
                                    ?.filter(
                                      (_, columnIndex) =>
                                        columnIndex !== 0 &&
                                        columnIndex !== 1 &&
                                        // Check if the current column should be hidden
                                        !hideColumns.includes(
                                          headers[columnIndex]
                                        )
                                    )
                                    .map((header) => (
                                      <TableCell
                                        style={handleHighlight(row[header])}
                                        key={header}
                                      >
                                        {row[header]}
                                      </TableCell>
                                    ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <LicensesGrid
                  selectedDuplicated={selectedDuplicated}
                  selectedCounties={selectedCounties}
                  selectedStatuses={selectedStatuses}
                  selectedLicenses={selectedLicenses}
                  setTotalLicenses={setTotalLicenses}
                  setTotalDuplicatedAddress={setTotalDuplicatedAddress}
                  setTotalSingleAddress={setTotalSingleAddress}
                  setDisabledFields={setDisabledFields}
                  title="DBPR Contractors"
                  tasksDataRows={tasksData}
                  setTasksData={setTasksData}
                  isSm={isSm}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  setDataLoading={setDataLoading}
                  dataLoading={dataLoading}
                  totalRowsCount={totalRowsCount}
                  setTotalRowsCount={setTotalRowsCount}
                  rowsPerPage={rowsPerPage}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                  setPivot={setPivot}
                  setHeaders={setHeaders}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Licenses;
