import {
  Box,
  Card,
  CircularProgress,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import NocUsage from "../dashboard/NocUsage";
import TotalNoc from "../dashboard/TotalNoc";
import CreatedForms from "../dashboard/CreatedForms";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubusersGrid from "../datagridActions/SubusersGrid";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import validator from "validator";
import { fetchUserData } from "../../store/actions/authActions";
YupPassword(Yup);

const Subusers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageLoading, setPageLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCancelExpanded, setIsCancelExpanded] = useState(false);
  const [subuserError, setSubuserError] = useState("");
  const [subuserLoading, setSubuserLoading] = useState(false);
  const [addedText, setAddedText] = useState("");
  const [fetching, setFetching] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [removeSubuserLoading, setRemoveSubuserLoading] = useState(false);

  let fetchCount = 0;

  const addSubuserFormik = useFormik({
    enableReinitialize: false,
    initialValues: {
      first: "",
      last: "",
      email: "",
      role: "",
    },
    validationSchema: Yup.object({
      first: Yup.string().min(3).required("First name is required"),
      last: Yup.string().min(3).required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
      role: Yup.string().required("Role is required"),
    }),
    onSubmit: async (values, helpers) => {
      setSubuserLoading(true);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/add-subuser`,
          {
            values,
          },
          { withCredentials: true }
        );
        setIsExpanded(false);
        setAddedText(resp.data.message);
        helpers.resetForm();
      } catch (e) {
        console.log(e);
        setSubuserError(e.response.data.message);

        if (e.response.data?.type === "email") {
          helpers.setFieldValue("email", "");
        }
      }

      setSubuserLoading(false);
    },
  });
  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser?.subuser || currentUser?.user?.expired) {
      navigate("/dashboard");
    }
    setPageLoading(false);
  }, [currentUser]);

  useEffect(() => {
    if (subuserError !== "") {
      const subuserTimer = setInterval(() => {
        setSubuserError("");
      }, 5000);
      return () => clearInterval(subuserTimer);
    }

    if (addedText !== "") {
      const addedTextTimer = setInterval(() => {
        setAddedText("");
      }, 5000);
      return () => clearInterval(addedTextTimer);
    }
  }, [subuserError, addedText]);

  const handleFetch = async () => {
    setFetching(true);
    await dispatch(fetchUserData());
    setFetching(false);
  };

  useEffect(() => {
    const fetchUserOnce = async () => {
      dispatch(fetchUserData());
    };

    if (fetchCount < 1) {
      fetchCount += 1;
      fetchUserOnce();
    }
  }, []);

  const handleChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    selectedUser?.nocUsage.map((item) => {
      if (item.year === year) {
        chartList[item.month] = item.noc;
      }
      return null;
    });
    return chartList;
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl" py={3}>
              <Card
                py={3}
                px={isSmallScreen ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container>
                  <Grid item xs={12} sx={{ mb: 3, position: "relative" }}>
                    <Typography variant="h5" align="center">
                      Users
                    </Typography>
                    <IconButton
                      sx={{ position: "absolute", right: 0, top: -5 }}
                      className={fetching ? "rotate" : ""}
                      color="primary"
                      onClick={handleFetch}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12}>
                    <SubusersGrid
                      setRemoveSubuserLoading={setRemoveSubuserLoading}
                      removeSubuserLoading={removeSubuserLoading}
                      currentUser={currentUser}
                      subusersRow={currentUser?.subusers_row || []}
                      formik={addSubuserFormik}
                      setIsCancelExpanded={setIsCancelExpanded}
                      isCancelExpanded={isCancelExpanded}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      subuserError={subuserError}
                      subuserLoading={subuserLoading}
                      addedText={addedText}
                      setSelectedUser={setSelectedUser}
                      selectedUser={selectedUser}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} lg={8}>
                  {selectedUser && (
                    <NocUsage
                      fromSub={true}
                      year={year}
                      setYear={setYear}
                      handleChartValues={handleChartValues}
                      chartSeries={[
                        {
                          name: "Created NOC's",
                          data: handleChartValues(),
                        },
                      ]}
                      sx={{ height: "100%" }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  align="center"
                  sx={{ ml: "auto", mr: "auto" }}
                  lg={3}
                >
                  {selectedUser && (
                    <TotalNoc
                      fromSub={true}
                      difference={16}
                      positive={false}
                      value={selectedUser?.nocCreated}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  align="center"
                  // sx={{ ml: "auto", mr: "auto", mt: 3 }}
                  lg={12}
                >
                  {selectedUser && (
                    <CreatedForms
                      fromSub={true}
                      createdFormRow={selectedUser?.nocUserForms}
                      payments={false}
                      title="NOC PDF Forms created"
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default Subusers;
