import React, { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import axios from "axios";
import {
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";

const InputField = ({
  userEmail,
  name,
  label,
  type = "text",
  customLicenseType = "",
  page,
  setButtons,
  buttons,
  disableInputs,
  setDisableInputs,
  contractorList,
  setContractorList,
  validLicense,
  licenseText,
  setLicenseText,
  changeContractor,
  isEmailError,
  isPhoneError,
  validationComplete,
  ...props
}) => {
  const { values, setFieldValue, isValid, errors, setTouched, touched } =
    useFormikContext();
  const [field, meta, helpers] = useField(name); // Destructure Formik helpers
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (name === "companyName") {
      const uniqueCompanyNames = [
        ...new Set(
          contractorList.map((contractor) => contractor.contractorName)
        ),
      ];
      const concatenatedCompanyNames = uniqueCompanyNames.join(", ");
      setFieldValue("companyName", concatenatedCompanyNames);
    }
  }, [name, contractorList, validLicense]);

  const handlePhoneInputChange = (event) => {
    const { value } = event.target;
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    helpers.setValue(formattedPhoneNumber);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLicenseNumberInputChange = (event) => {
    // Replace letters with capital letters
    const value = event.target.value.toUpperCase();

    // Update Formik value
    helpers.setValue(value);
  };

  const handleNumberInputChange = (event) => {
    // Prevent negative numbers
    if (event.target.value < 0) {
      event.target.value = "";
    }
    // If the field is "licenseNumber," handle it differently
    if (name === "licenseNumber") {
      handleLicenseNumberInputChange(event);
    } else {
      // Update Formik value
      helpers.setValue(event.target.value);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        label={label}
        type={showPassword ? "text" : type}
        onKeyUp={
          name === "phone" || name === "contractorPhone"
            ? handlePhoneInputChange
            : null
        }
        {...field}
        {...props}
        disabled={name === "companyName" || disableInputs} // Disable the field if its name is "companyName"
        error={meta.touched && Boolean(meta.error)}
        onChange={handleNumberInputChange}
        helperText={
          contractorList &&
          contractorList[page]?.validLicense === false &&
          !isValid
            ? `This license already exists in our system - ${contractorList[page]?.licenseNumber}`
            : name === "email" && isEmailError === true && !isValid
            ? "Email address already exists"
            : name === "phone" && isPhoneError === true && !isValid
            ? "Phone number already exists"
            : meta.touched && meta.error
        }
        InputProps={
          name === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                      onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
      {name === "licenseNumber" &&
        validationComplete &&
        values["licenseType"] !== "" &&
        values["licenseNumber"] !== "" && (
          <InputLabel
            align="start"
            sx={{
              fontSize: "12px",
              m: "3px 14px 0",
              color:
                contractorList[page].validLicense === true
                  ? "#008000"
                  : "#FF0000",
              whiteSpace: "pre-line", // Set white-space property
            }}
          >
            {contractorList[page].validLicense === false && !isValid
              ? "This license already exists in our system"
              : "License will be verified against official databases after registration is complete"}
          </InputLabel>
        )}
    </>
  );
};

export default InputField;
