import React, { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import axios from "axios";
import {
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  TextField,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ChangePlanFormNavigation from "./ChangePlanFormNavigation";

const ChangePlanMultistepForm = ({
  type,
  page,
  setPage,
  tiers,
  setSelectedName,
  isLoad,
  children,
  initialValues,
  onSubmit,
  isExpired,
  cardRemain,
  isSSOLogin,
  setErrorText,
  errorText,
  terms,
  setPlan,
  plan,
  setUserEmail,
  contractorList,
  setContractorList,
  setDuplicatedLicenses,
  selectedName,
  oldPlan,
  checked,
  oldChecked,
  defPlan,
  validLicense,
  lastDisabled,
  setLastDisabled,
  currentUser,
  setIsload,
}) => {
  const theme = useTheme();
  const [stepLabel, setStepLabel] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [nextLoad, setNextLoad] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const steps = React.Children.toArray(children);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [errorMsg, setErrorMsg] = useState("");
  const [snapshot, setSnapshot] = useState(initialValues);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const handleVerifyLicense = async (values, actions) => {
    setIsload(true);
    contractorList[page]["licenseType"] = values["licenseType"];
    contractorList[page]["licenseNumber"] = values["licenseNumber"];
    contractorList[page]["contractorName"] = values["contractorName"];
    contractorList[page]["contractorAddress"] = values["contractorAddress"];
    contractorList[page]["contractorPhone"] = values["contractorPhone"];
    contractorList[page]["contractorFax"] = values["contractorFax"];
    contractorList[page]["contractorEmail"] = values["contractorEmail"];
    contractorList[page]["validLicense"] = contractorList[page]["validLicense"];
    const email = currentUser?.user.email;

    const hasInvalid = contractorList.some(
      (contractor) => !contractor.validLicense
    );

    if (hasInvalid) {
      setErrorText(
        "Please modify the license number(s) as some contractor(s) have invalid ones."
      );
      return false;
    }
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/checklicensenumber`,
        {
          contractorList,
          email,
        }
      );

      if (resp.data.success === true) {
        setContractorList(resp.data.data.copy_contractors);
      }

      if (resp.data.data.exist_indexes.length > 0) {
        actions.setFieldError("licenseNumber", "");
        setPage(resp.data.data.exist_indexes[0]);
        const updatedContractor =
          contractorList[resp.data.data.exist_indexes[0]];

        if (updatedContractor) {
          actions.setValues((prevValues) => ({
            ...prevValues,
            licenseType: updatedContractor.licenseType,
            licenseNumber: updatedContractor.licenseNumber,
            contractorName: updatedContractor.contractorName,
            contractorAddress: updatedContractor.contractorAddress,
            contractorPhone: updatedContractor.contractorPhone,
            contractorFax: updatedContractor.contractorFax,
            contractorEmail: updatedContractor.contractorEmail,
          }));
        }

        actions.setFieldError(
          "licenseNumber",
          "License number already exists."
        ); // Set error text
        actions.setFieldTouched("licenseNumber", true); // Mark the field as touched
        actions.setFieldValue("licenseNumber", ""); // Clear the value (optional, depends on your use case)
        setIsload(false);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      setIsload(false);
      console.log(e);
    }
    setIsload(false);
  };

  const nextStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previousStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (stepNumber === 1) {
      setNextLoad(true);
      const is_verified = await handleVerifyLicense(values, actions);

      if (is_verified === false) {
        // setErrorText("License Number(s) already exist in our records.");
        setNextLoad(false);
        return;
      }

      const {
        licenseType,
        licenseNumber,
        contractorName,
        contractorAddress,
        contractorPhone,
        contractorFax,
        contractorEmail,
      } = values;

      if (
        contractorList[contractorList.length - 1]["licenseNumber"] === "" &&
        contractorList[contractorList.length - 1]["contractorName"] === "" &&
        contractorList[contractorList.length - 1]["contractorAddress"] === "" &&
        contractorList[contractorList.length - 1]["contractorPhone"] === ""
      ) {
        setContractorList((prevContractorList) => {
          const lastContractorIndex = prevContractorList.length - 1;
          const updatedContractorList = [...prevContractorList];

          if (lastContractorIndex >= 0) {
            updatedContractorList[lastContractorIndex] = {
              licenseType,
              licenseNumber,
              contractorName,
              contractorAddress,
              contractorPhone,
              contractorFax,
              contractorEmail,
              validLicense: true,
            };
          }

          return updatedContractorList;
        });
      }

      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      nextStep(values);
    }
  };

  const updateStepLabel = useCallback(
    (stepIndex) => {
      if (stepIndex >= 0 && stepIndex < steps.length) {
        setStepLabel(steps[stepIndex].props.stepName);
      }
    },
    [steps]
  );

  useEffect(() => {
    updateStepLabel(stepNumber);
  }, [stepNumber]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => (
          <>
            <form onSubmit={formik.handleSubmit}>
              {isSmallScreen ? (
                <Stepper activeStep={stepNumber}>
                  {steps.map((currentStep, id) => {
                    return (
                      <Step key={id}>
                        <StepLabel></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              ) : (
                <Box
                  sx={{
                    my: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stepper activeStep={stepNumber} sx={{ width: "50%" }}>
                    {steps.map((currentStep, id) => {
                      const label = currentStep.props.stepName;
                      return (
                        <Step key={id}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              )}
              {isSmallScreen && (
                <Typography
                  color="primary"
                  fontWeight="bold"
                  align="center"
                  variant="body1"
                  mt={1}
                  mb={0}
                  pb={0}
                >
                  {stepLabel}
                </Typography>
              )}

              {step}
              <ChangePlanFormNavigation
                type={type}
                plan={plan}
                validLicense={validLicense}
                lastDisabled={lastDisabled}
                setLastDisabled={setLastDisabled}
                contractorList={contractorList}
                defPlan={defPlan}
                terms={terms}
                oldChecked={oldChecked}
                checked={checked}
                selectedName={selectedName}
                oldPlan={oldPlan}
                isLoad={isLoad}
                nextLoad={nextLoad}
                emailError={formik.errors}
                emailValue={formik.values.email}
                setErrorText={setErrorText}
                errorText={errorText}
                isSSOLogin={isSSOLogin}
                stepNumber={stepNumber}
                isExpired={isExpired}
                cardRemain={cardRemain}
                isLastStep={isLastStep}
                hasPrevious={stepNumber > 0}
                onBackClick={() => {
                  previousStep(formik.values);
                  updateStepLabel(stepNumber - 1); // Update the stepLabel when going back
                }}
              />
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ChangePlanMultistepForm;
export const FormStep = ({ stepName = "", children }) => children;
