import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import AvailableCountiesModal from "../modals/AvailableCountiesModal";

export default function LandingLayout() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [countyModalOpen, setCountyModalOpen] = useState(false);
  const [availableCounties, setAvailableCounties] = useState([]);
  const playerRef = useRef(null);
  const navigate = useNavigate();

  const handleEnded = () => {
    // When the video ends, seek to the beginning and start playing
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  useEffect(() => {
    const getCounties = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/available-counties`,
          { withCredentials: true }
        );

        setAvailableCounties(resp.data.data.counties);
      } catch (e) {
        console.log(e);
      }
    };

    getCounties();
  }, []);

  return (
    <>
      <Grid
        sx={
          isMatch
            ? {
                alignItems: "top",
                backgroundColor: "#EEF2F6",
                color: "#004976",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                overflowY: "auto",
                overflowx: "hidden",
                height: "100vh",
                pb: "1rem",
              }
            : {
                alignItems: "top",
                background: "#ededeb",
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                overflowY: "auto",
                overflowx: "hidden",
                height: "100vh",
                pb: "1rem",
              }
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: isMatch ? 2 : 10, // Adjust the padding here
            pt: 4,
            zIndex: "2",
            align: "center",
          }}
        >
          <Box>
            <Typography
              align="center"
              sx={
                isMatch
                  ? { mt: 0, color: "#004976", fontWeight: "bold" }
                  : { mt: 0, color: "#004976", fontWeight: "bold" }
              }
              variant={isMatch ? "h4" : "h2"}
            >
              Streamline Your NOC Form Creation Process!
            </Typography>
            <Typography
              align="center"
              sx={
                isMatch
                  ? {
                      mt: 2,
                      color: "#004976",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }
                  : {
                      mt: 2,
                      color: "#004976",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }
              }
              variant="subtitle1"
            >
              Effortlessly Generate Custom NOC Forms for{" "}
              <Typography
                onClick={() => {
                  setCountyModalOpen(true);
                }}
                variant="subtitle1"
                color="error"
                sx={
                  isMatch
                    ? {
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                    : {
                        fontWeight: "bold",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "20px",
                      }
                }
              >
                67 Florida Counties
              </Typography>{" "}
              <br /> Say goodbye to the complexities of NOC creation.
            </Typography>
          </Box>

          <Typography
            align="center"
            sx={{ color: "#004976", mt: "2rem" }}
            variant="body1"
          >
            With just a few clicks, you can craft tailored NOC forms that
            perfectly fit the requirements of 10 Florida counties. And the best
            part? It's incredibly easy to get started! Stay Ahead of the Curve –
            All 67 Counties on the Horizon! But that's not all – our expansion
            plans include allowing you to not only create an NOC form but also
            legally notarize it, record it with the appropriate clerk of courts
            and even submit it for you to the city where your permit is active!
            This means you'll have a one-stop solution for all your NOC and
            building permit form needs. Some other construction forms are coming
            up soon!
          </Typography>
          <Typography
            align="center"
            sx={{ color: "#004976", mb: "1rem", mt: "1rem" }}
            variant="body1"
          >
            Our NOC Creator is the Ultimate Time-Saver! Tired of sifting through
            legal descriptions? Or trying to find the right form for the county
            you need? Or constantly thinking if you got the right information in
            the right place? Let us handle it! Our NOC Creator automates the
            entire process for you. With our intelligent algorithms, we just
            need an address or even a partial address and we can populate all
            the necessary fields in your NOC, allowing you to breeze through the
            form-filling process.
          </Typography>
          <Typography
            align="center"
            sx={
              isMatch
                ? {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "0rem",
                    fontSize: "20px",
                  }
                : {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "0.5rem",
                    fontSize: "25px",
                  }
            }
            variant="subtitle1"
          >
            Click here to watch a short video on how the process works.
            Professional Results in less than ONE MINUTE.
          </Typography>
          {/* <div>
            <YouTube videoId={videoId} src={youtubeUrl} opts={opts} />
          </div> */}
          <div>
            <ReactPlayer
              ref={playerRef}
              url="https://vimeo.com/859719400?share=copy"
              width="100%" // Set the width of the player
              height="360px" // Set the height of the player
              controls // Display video controls (play, pause, etc.)
              onEnded={handleEnded}
              // onPlay={handlePlay}
            />
          </div>
          <Typography
            align="center"
            sx={{ color: "#004976", mt: "1rem" }}
            variant="body1"
          >
            Imagine having a fully filled-out, professionally designed NOC PDF
            form at your fingertips, ready for download, print or email to your
            customers in less than one minute. Our user-friendly interface and
            intuitive steps make this a reality.
          </Typography>
          <Typography
            align="center"
            sx={{ color: "#004976", mt: "1rem" }}
            variant="body1"
          >
            Unlock Efficiency – Embrace Simplicity. Experience the convenience
            and efficiency of our NOC Creator today. Best of all, for a limited
            time, you can access all our platform's features absolutely FREE!
            Yes, you read that right – no charges, no commitments.
          </Typography>
          <Typography
            align="center"
            sx={
              isMatch
                ? {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "1rem",
                    fontSize: "20px",
                  }
                : {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "1rem",
                    fontSize: "25px",
                  }
            }
            variant="subtitle1"
          >
            Act now and witness firsthand the revolution in NOC form creation.
            <br />
            <Typography
              onClick={() => {
                navigate("/register");
              }}
              variant="subtitle1"
              color="error"
              sx={{
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "20px",
              }}
            >
              Join Us Today!
            </Typography>{" "}
          </Typography>
        </Box>
      </Grid>
      <AvailableCountiesModal
        open={countyModalOpen}
        onClose={() => setCountyModalOpen(false)}
        counties={availableCounties}
      />
    </>
  );
}
