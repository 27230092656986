import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";

export const LicensesGrid = ({
  selectedLicenses,
  setTotalLicenses,
  setTotalDuplicatedAddress,
  setTotalSingleAddress,
  title,
  isSm,
  page,
  setPage,
  tasksDataRows,
  setRowsPerPage,
  rowsPerPage,
  setTasksData,
  totalRowsCount,
  setTotalRowsCount,
  sortModel,
  setSortModel,
  filterModel,
  setFilterModel,
  selectedCounties,
  selectedStatuses,
  selectedDuplicated,
  setPivot,
  setHeaders,
}) => {
  const [errorText, setErrorText] = useState("");
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (errorText !== "") {
        setErrorText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [errorText]);

  const handleSortModelChange = (model, details) => {
    setSortModel(model);
    fetchData(page, rowsPerPage, model, filterModel);
  };

  const handlePaginationModelChange = (model, details) => {
    const newPage = model.page;
    const newRowsPerPage = model.pageSize;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetchData(newPage, newRowsPerPage, sortModel, filterModel);
  };

  const fetchData = async (newPage, newRowsPerPage, sortModel, filterModel) => {
    setGridLoading(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_licenses`,
        {
          page: newPage,
          rowsPerPage: rowsPerPage,
          sortModel: sortModel,
          selectedLicenses: selectedLicenses,
          counties: selectedCounties,
          statuses: selectedStatuses,
          duplicated: selectedDuplicated,
          exportPivot: false,
          export_csv: false,
        },
        {
          withCredentials: true,
        }
      );

      setTasksData(resp.data.data.contractors);
      setTotalRowsCount(resp.data.data.totals.total_licenses);
      setTotalLicenses(resp.data.data.totals.total_licenses);
      setTotalDuplicatedAddress(
        resp.data.data.totals.licenses_with_duplicated_addresses
      );
      setTotalSingleAddress(resp.data.data.totals.total_license_single_address);
      const headersResp = Object.keys(resp.data.data.pivot[0]);
      setPivot(resp.data.data.pivot);
      setHeaders(headersResp);
      setGridLoading(false);
    } catch (e) {
      console.log(e);
      setGridLoading(false);
    }
  };

  const licenseDataColumns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: isSm ? 180 : 180,
    // },
    {
      field: "primary_name",
      headerName: "Primary Name",
      width: isSm ? 170 : 250,
    },
    {
      field: "dba_name",
      headerName: "DBA Name",
      width: isSm ? 180 : 350,
    },
    {
      field: "main_address",
      headerName: "Main Address",
      width: isSm ? 160 : 350,
    },
    {
      field: "main_address_county",
      headerName: "Main Address County",
      width: isSm ? 180 : 180,
    },
    {
      field: "license_mailing_address",
      headerName: "License Mailing Address",
      width: isSm ? 170 : 350,
    },
    {
      field: "license_mailing_county",
      headerName: "License Mailing County",
      width: isSm ? 130 : 180,
    },
    {
      field: "license_location_address",
      headerName: "License Location Address",
      width: isSm ? 130 : 350,
    },
    {
      field: "license_location_county",
      headerName: "License Location County",
      width: isSm ? 130 : 180,
    },
    {
      field: "license_type",
      headerName: "License Type",
      width: isSm ? 130 : 300,
    },
    {
      field: "rank",
      headerName: "Rank",
      width: isSm ? 130 : 180,
    },
    {
      field: "license_number",
      headerName: "License Number",
      width: isSm ? 130 : 180,
    },
    {
      field: "status",
      headerName: "Status",
      width: isSm ? 130 : 180,
    },
    {
      field: "licensure_date",
      headerName: "Licensure Date",
      width: isSm ? 130 : 180,
    },
    {
      field: "expires",
      headerName: "Expires",
      width: isSm ? 130 : 180,
    },
    {
      field: "special_qualifications",
      headerName: "Special Qualifications",
      width: isSm ? 130 : 350,
    },
    {
      field: "alternate_names",
      headerName: "Alternate Names",
      width: isSm ? 130 : 350,
    },
  ];

  return (
    <Card sx={{ minHeight: "35rem" }}>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          className="cell"
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            minHeight: "24rem",
          }}
          rows={tasksDataRows ? tasksDataRows : []}
          columns={licenseDataColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          // disableRowSelectionOnClick
          rowCount={totalRowsCount}
          pageSize={rowsPerPage}
          paginationMode="server"
          paginationModel={{
            page: page, // Set the current page value here
            pageSize: rowsPerPage, // Set the page size value here
          }}
          onPaginationModelChange={(model, details) => {
            handlePaginationModelChange(model, details);
          }}
          // onFilterModelChange={(model, details) => {
          //   handleFilterModelChange(model, details);
          // }}
          onSortModelChange={(model, details) => {
            handleSortModelChange(model, details);
          }}
          // onRowClick={(params, event, details) => {
          //   handleSaveRow(params, event, details);
          // }}
          loading={gridLoading}
        />

        <Typography
          variant="body1"
          color="#F2CD00"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: "1rem", minHeight: "1.5rem" }}
        >
          {errorText}
        </Typography>
      </CardContent>
    </Card>
  );
};
