import axios from "axios";
import {
  setLocalStorageItem,
  removeLocalStorageItem,
} from "./localStorageActions";

export const loginRequest = () => {
  return { type: "LOGIN_REQUEST" };
};

export const clearError = () => {
  return { type: "CLEAR_ERROR" };
};

export const loginSuccess = () => {
  return { type: "LOGIN_SUCCESS" };
};

export const loginFailure = (error) => {
  return { type: "LOGIN_FAILURE", payload: error };
};

export const updateUser = (userData) => {
  return {
    type: "UPDATE_USER",
    payload: userData,
  };
};

export const broadcastUpdate = (sharedUserData) => {
  return {
    type: "BROADCAST_UPDATE",
    payload: sharedUserData,
  };
};

export const getNotification = (notification) => {
  return {
    type: "GET_NOTIFICATION",
    payload: notification,
  };
};

export const setUserAuthenticated = () => {
  return { type: "SET_USER_AUTHENTICATED" };
};

export const setUserUnauthenticated = () => {
  return { type: "SET_USER_UNAUTHENTICATED" };
};

export const fetchUserData = () => async (dispatch, getState) => {
  const { auth } = getState(); // Get the authentication state from Redux
  if (auth.isAuthenticated) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/getuser`,
        { withCredentials: true }
      );

      // Dispatch the action to update user data
      dispatch(updateUser(res.data.data)); // Assuming updateUser is already defined

      return res.data;
    } catch (error) {
      // Handle the error
    }
  }
};

export const loginUser =
  (defEmail, pwd, remember, recaptchaValue, count, oldEmail) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest());

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/beforelogin`,
        {
          email: defEmail,
          oldEmail: oldEmail,
          password: pwd,
          remember: remember,
          value: recaptchaValue,
          count: count,
        },
        { withCredentials: true }
      );

      // const res = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}/nocApi/auth/login`,
      //   { email: defEmail, password: pwd },
      //   { withCredentials: true }
      // );

      if (!res.data || res.data.success !== true) {
        return null;
      }

      if (res.data.success === true) {
        if (!remember && defEmail !== "" && localStorage.getItem("ujsffq")) {
          dispatch(removeLocalStorageItem("ujsffq"));
        }

        dispatch(loginSuccess());

        return res.data;
      } else {
        dispatch(loginFailure(res.data.message));
        return null;
      }
    } catch (err) {
      console.log(err);
      if (err.code === "ERR_NETWORK") {
        dispatch(
          loginFailure(
            "We are updating our application. Please come back later"
          )
        );
        return null;
      }
      dispatch(loginFailure("Invalid Credentials"));
      return null;
    }
  };

export const logout = () => {
  return { type: "LOGOUT" };
};

export const setDefEmail = (defEmail) => ({
  type: "SET_DEF_EMAIL",
  payload: defEmail,
});

export const setRemember = (remember) => ({
  type: "SET_REMEMBER",
  payload: remember,
});

export const setErrorText = (errorText) => ({
  type: "SET_ERROR_TEXT",
  payload: errorText,
});
