import {
  Grid,
  Stack,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import TextFields from "./TextFields";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { PencilIcon } from "@heroicons/react/24/outline";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

const EnlargeEditForm = ({
  fromSub,
  setCopyInputs,
  editFromCreate,
  selectedPreName,
  enlargeHeader,
  enlargeNote,
  setOpenDrawer,
  formik,
  type,
  preview,
  previewImg,
  previewLoading,
  pdfImg,
  pdfImg2,
  inputs,
  editLoading,
  errorText,
  disableEdit,
  pdf,
  setSelectedRow,
  setFormDrawerOpen,
  formDrawerOpen,

  licenseType,
  licenseNumber,
  licenseStatus,
}) => {
  const [dynamicValues, setDynamicValues] = useState({});
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const handleIMG = () => {
    if (pdfImg2 !== "") {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={previewImg[1]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={pdfImg2} style={{ width: "100%" }} />;
          </>
        );
      }
    } else {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
          </>
        );
      }
    }
  };

  const handleIconButton = () => {
    setSelectedRow(null);
    if (setCopyInputs) {
      setCopyInputs(null);
    }
    setOpenDrawer(false);
  };

  const handleReturnForm = () => {
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Stack
            spacing={3}
            mt={4}
            px={1}
            py={2}
            sx={{ maxHeight: "45vh", overflowY: "auto" }}
          >
            <TextFields
              editFromCreate={editFromCreate}
              dynamicValues={dynamicValues}
              setDynamicValues={setDynamicValues}
              formik={formik}
              selectedPreName={selectedPreName}
              type={type}
              inputs={inputs}
            />
          </Stack>
          <Box
            gap={isMd ? 0 : 2}
            sx={
              isMd
                ? { display: "flex", flexDirection: "column" }
                : { display: "flex", flexDirection: "row" }
            }
          >
            {handleCheckButton()}
          </Box>
          {isMd && (
            <Box
              gap={isMd ? 0 : 2}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingButton
                loading={previewLoading}
                onClick={() => {
                  setFormDrawerOpen(true);
                }}
                variant="contained"
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "#F2CD00",
                  ":hover": { backgroundColor: "#CDAF0B" },
                  mb: 1,
                }}
              >
                SHOW FORM
              </LoadingButton>
            </Box>
          )}

          <Typography
            variant="body1"
            sx={{
              color: "#BCA631",
              fontWeight: "bold",
              textAlign: "center",
              pb: 2,
            }}
          >
            {errorText}
          </Typography>
        </form>
      </>
    );
  };

  const handleCheckButton = () => {
    return (
      <>
        <LoadingButton
          startIcon={<PencilIcon color="#fff" width={isMd ? 15 : 20} />}
          loading={editLoading}
          color="warning"
          type="submit"
          fullWidth
          size={isMd ? "small" : "large"}
          sx={
            isMd
              ? {
                  mt: 2,
                  mb: 1,
                }
              : { mt: 2, mb: 2 }
          }
          variant="contained"
        >
          EDIT
        </LoadingButton>
        <LoadingButton
          loading={editLoading}
          disabled={!disableEdit}
          startIcon={
            <DocumentArrowDownIcon color="#fff" width={isMd ? 15 : 20} />
          }
          href={pdf}
          target="_blank"
          fullWidth
          size={isMd ? "small" : "large"}
          sx={
            isMd
              ? {
                  mb: 1,
                  background: "primary",
                }
              : { mt: 2, mb: 2, background: "primary" }
          }
          variant="contained"
        >
          Download
        </LoadingButton>
      </>
    );
  };

  return (
    <>
      <Grid container sx={{ display: "flex", overflow: "auto" }}>
        {!isMd && (
          <Grid
            item
            md={6}
            sx={
              isMd
                ? { maxHeight: "100vh", overflow: "auto", order: 2 }
                : { maxHeight: "100vh", overflow: "auto" }
            }
          >
            <Box>{handleIMG()}</Box>
          </Grid>
        )}

        <Grid
          item
          md={6}
          px={isMd ? 1 : 4}
          sx={isMd ? { order: 1, width: "100%" } : {}}
        >
          <Stack pt={2}>
            <Box sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center", flex: "2" }}>
                <Typography variant="h4" textAlign="center">
                  {selectedPreName} County
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {!fromSub ? enlargeHeader : "View Form"}
                </Typography>
                <Typography mt={2} variant="body2" textAlign="center">
                  {enlargeNote}
                </Typography>
                <Grid
                  container
                  sx={{
                    mt: 2,
                    ml: "0.7rem",
                    marginBottom: "-2rem",
                    backgroundColor: "#f6f6f6",
                    p: 2,
                    borderRadius: "1rem",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Grid
                    sx={{ ml: "auto", mr: "auto" }}
                    item
                    lg={6}
                    sm={12}
                    order={isMd ? 2 : 1}
                  >
                    <Box
                      sx={
                        isMd
                          ? {
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                          : {
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }
                      }
                    >
                      <Typography variant="h6">{licenseType}</Typography>
                    </Box>
                  </Grid>
                  <Grid sx={{ ml: "auto", mr: "auto" }} item lg={6} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Vertically center the content
                        justifyContent: "center",
                        height: "100%", // Ensure the Box takes up the full height
                      }}
                    >
                      <Box sx={{ borderBottom: "5px solid #006400" }}>
                        <Typography
                          variant="h4"
                          sx={{ marginBottom: "-7px", color: "#006400" }}
                        >
                          {licenseNumber}
                        </Typography>
                      </Box>

                      <Typography variant="body2" sx={{ color: "#006400" }}>
                        {licenseStatus === ""
                          ? "Pre-Verified License Number"
                          : "Verified License Number"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
              <Stack sx={{ width: "auto" }}>
                <IconButton onClick={handleIconButton}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
          {!fromSub ? (
            handleReturnForm()
          ) : (
            <>
              <Box sx={{ position: "relative" }}>
                <Box
                  component="a"
                  href="https://www.sr360solutions.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "flex",
                    mt: "10vh",
                    mx: "auto",
                    width: "500px",
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <img alt="SR360solutions" src="/img/logos/Ball-icon.png" />
                </Box>
                <Box>
                  <Typography
                    sx={{ position: "absolute", top: 30, left: 280 }}
                    align="center"
                    variant="h3"
                  >
                    Powered by
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <Drawer
        PaperProps={{}}
        anchor="right"
        open={formDrawerOpen}
        onClose={() => setFormDrawerOpen(false)}
      >
        <Stack sx={{ width: "auto", ml: "auto" }}>
          <IconButton onClick={() => setFormDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item md={6} sx={{ maxHeight: "100%", order: 2 }}>
          <Box>{handleIMG()}</Box>
        </Grid>
      </Drawer>
    </>
  );
};

export default EnlargeEditForm;
