import { Card, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const TotalCounties = (props) => {
  const { value = 0 } = props;

  return (
    <Card sx={{ minHeight: "10rem", position: "relative" }}>
      <Tooltip
        title="This indicates the count of counties accessible to all users within your organization."
        placement="top"
      >
        <HelpOutlineIcon
          color="primary"
          sx={{ position: "absolute", right: 10, top: 10 }}
        />
      </Tooltip>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Available Counties
            </Typography>
            <Typography variant="h5">{value}</Typography>
          </Stack>
          <img
            alt="counties"
            src="/img/dashboard/countiesIcon.png"
            style={{ width: "5rem", margin: 0 }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TotalCounties;
