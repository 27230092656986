import * as yup from "yup";
import YupPassword from "yup-password";
import validator from "validator";
import ChangePlanMultistepForm, { FormStep } from "../ChangePlanMultistepForm";

import {
  Box,
  Grid,
  Container,
  Card,
  Button,
  Typography,
  Stack,
  CircularProgress,
  Drawer,
  useMediaQuery,
  useTheme,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import ChoosePlan from "../auth/registerSteps/ChoosePlan";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import TermsAndConditionsModal from "../modals/TermsAndConditionsModal";
import ChangePlan from "../ChangePlan";
import SubscribeExpiredPlan from "../SubscribeExpiredPlan";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import { fetchUserData } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import ChangePlanHeader from "../ChangePlanHeader";
import ChangePlanContractor from "../auth/registerSteps/ChangePlanContractor";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [oldPlan, setOldPlan] = useState("");
  const [currentPlan, setCurrentPlan] = useState();
  const currentUser = useSelector((state) => state.auth.user);
  const [selectedName, setSelectedName] = useState(null);
  const [checked, setChecked] = useState(true);
  const [plan, setPlan] = useState();
  const [tiers, setTiers] = useState([]);
  const [terms, setTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newSubscriptionDrawer, setNewSubscriptionDrawer] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defPlan, setDefPlan] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoad, setIsload] = useState(false);
  const [buttons, setButtons] = useState(["New Contractor"]);
  const [disableInputs, setDisableInputs] = useState(true);
  const [duplicatedLicenses, setDuplicatedLicenses] = useState([]);
  const [disabledTabs, setDisabledTabs] = useState([]);
  const [validLicense, setValidLicense] = useState(false);
  const [licenseText, setLicenseText] = useState("");
  const [additionalData, setAdditionalData] = useState();
  const [customLicenseType, setCustomLicenseType] = useState("");
  const [oldChecked, setOldChecked] = useState(false);
  const [oldPlanType, setOldPlanType] = useState("");
  const [lastDisabled, setLastDisabled] = useState(true);
  const [contractorList, setContractorList] = useState([]);

  let count = 0;

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleReactivatePlan = async () => {
    setIsLoading(true);

    try {
      const changePlan = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/resume-canceled-subscription`,
        { withCredentials: true }
      );

      if (changePlan.data.success) {
        setDefPlan((prevDefPlan) => ({
          ...prevDefPlan,
          expired: false,
          canceled: false,
          package_type: "Paid Package",
        }));
        setErrorText(changePlan.data.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setErrorText(changePlan.data.message);
      }
      await dispatch(fetchUserData());
    } catch (err) {
      console.log(err);
      setErrorText(err.message);
      setIsLoading(false);
    }
  };

  const handleCreateExpiredPlan = async () => {
    const priceId = checked ? plan.price_id_year : plan.price_id_month;
    if (!terms) {
      setErrorText("You must accept the terms and conditions.");
    } else {
      setIsLoading(true);

      try {
        const changePlan = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/expired-create-plan`,
          {
            priceId,
          },
          { withCredentials: true }
        );

        if (changePlan.data.success) {
          window.location.href = changePlan.data.data;
        } else {
          setIsLoading(false);
          setErrorText(changePlan.data.message);
        }
      } catch (err) {
        console.log(err);
        setErrorText(err.message);
        setIsLoading(false);
      }
    }
  };

  const fetchOldPlanData = (values, setTouched, setErrors) => {
    const updatedContractorList = [];
    const updatedButtons = [];

    currentUser?.user.contractors.forEach((contractor) => {
      const {
        licenseType,
        licenseNumber,
        name,
        proName,
        proAddress,
        address,
        phone,
        fax,
        email,
        licenseStatus,
        confirmed,
        type,
      } = contractor;

      const validLicense = confirmed && confirmed === true;

      if (validLicense) {
        if (type === "custom") {
          updatedContractorList.push({
            licenseType,
            licenseNumber,
            contractorName: name,
            contractorAddress: address,
            contractorPhone: phone,
            contractorFax: fax,
            contractorEmail: email,
            validLicense: true,
          });
        } else {
          updatedContractorList.push({
            licenseType,
            licenseNumber,
            contractorName: proName,
            contractorAddress: proAddress,
            contractorPhone: phone,
            contractorFax: fax,
            contractorEmail: email,
            validLicense: true,
          });
        }

        updatedButtons.push(licenseType);
      }
    });

    if (updatedContractorList.length === 0) {
      updatedContractorList.push({
        licenseType: "",
        licenseNumber: "",
        contractorName: "",
        contractorAddress: "",
        contractorPhone: "",
        contractorFax: "",
        contractorEmail: "",
        validLicense: true,
      });
      updatedButtons.push("New Contractor");
    }

    if (values !== undefined && updatedContractorList.length > 0) {
      values.contractorName = updatedContractorList[0]?.contractorName;
      values.contractorAddress = updatedContractorList[0]?.contractorAddress;
      values.licenseType = updatedContractorList[0]?.licenseType;
      values.licenseNumber = updatedContractorList[0]?.licenseNumber;
      values.contractorPhone = updatedContractorList[0]?.contractorPhone;
      values.contractorFax = updatedContractorList[0]?.contractorFax;
      values.contractorEmail = updatedContractorList[0]?.contractorEmail;

      setContractorList(updatedContractorList);
      setPage(0);
      setButtons(updatedButtons);
      if (typeof setTouched === "function") {
        setErrors({});
      }
    } else {
      setContractorList(updatedContractorList);
      setPage(0);
      setButtons(updatedButtons);
    }
  };

  useEffect(() => {
    const getTiers = async () => {
      if (!currentUser || !currentUser.user || !currentUser.nocUserForms) {
        return;
      }

      if (
        currentUser?.subuser &&
        currentUser?.subuser.role === "Basic User" &&
        !currentUser?.user?.expired
      ) {
        navigate("/dashboard");
      }

      fetchOldPlanData(undefined, undefined, undefined);

      if (contractorList.length > 1) {
        setOldPlanType("multi");
      } else {
        setOldPlanType("single");
      }

      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/get-current-tier`,
          { withCredentials: true }
        );

        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/additional`
        );

        if (res.data.success) {
          setAdditionalData(res.data.data);
        }
        if (resp.data.success) {
          const index = resp.data.data.findIndex(
            (item) => item.title === resp.data.myPlan["title"]
          );
          setTiers(resp.data.data);

          setPlan(resp.data.data[index]);
          setDisableInputs(false);
          setSelectedName(resp.data.myPlan["title"]);
          setOldPlan(resp.data.myPlan["title"]);
          setCurrentPlan(resp.data.data[index]["title"]);
          setChecked(resp.data.myPlan.interval === "month" ? false : true);
          setOldChecked(resp.data.myPlan.interval === "month" ? false : true);
          setDefPlan(resp.data.myPlan);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.log(e.message);
        setLoading(false);
      }
    };

    if (count < 1) {
      getTiers();
      count += 1;
    }
  }, [currentUser]);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  const startDateObj = new Date(defPlan?.current_period_start * 1000);
  const renewDateObj = new Date(defPlan?.current_period_end * 1000);

  const validationSchemaStep2 = yup.object({
    licenseType: yup.string().required("License type is required"),
    licenseNumber: yup
      .string()
      .min(7, "License number must be at least 7 characters long.")
      .max(13, "License number must not exceed 13 characters.")
      .required("License number is required")
      .test(
        "customValidation",
        "LIcense number prefix doesn't correspond with the license type selected",
        function (value) {
          // Find the tuple where the first value matches customLicenseType
          const matchingTuple = additionalData?.combined_license_types.find(
            (tuple) => tuple[0] === customLicenseType
          );

          // If a matching tuple is found, apply specific validation logic
          if (matchingTuple) {
            const [, secondValue] = matchingTuple;

            // Check if the value starts with the second value from the tuple and the rest are numbers
            const regex = new RegExp(`^${secondValue}\\d+$`);
            return regex.test(value);
          }

          // No matching tuple found, invalid license number
          return false;
        }
      ),
    contractorName: yup
      .string()
      .min(4, "Contractor name is too short")
      .required("Contractor name is required"),
    contractorAddress: yup
      .string()
      .min(5, "Address is too short")
      .required("Contractor Address is required"),
    contractorPhone: yup
      .string()
      .required("Phone number is required")
      .test("PhoneTest", "Phone number is invalid", function (value) {
        const isValidPhoneNumber = validator.isMobilePhone(value);
        if (isValidPhoneNumber) {
          return true;
        } else {
          return false;
        }
      }),
    contractorFax: yup
      .string()
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        "Fax Number is Invalid"
      ),
    contractorEmail: yup.string().email("Must be a valid email"),
  });

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl" py={3}>
              <Card py={3} px={3} component={Grid} container>
                <Grid container justifyContent="center">
                  <Grid item sm={12}>
                    {currentUser?.subuser &&
                    currentUser?.subuser?.role === "Basic User" &&
                    currentUser?.user?.expired ? (
                      <></>
                    ) : (
                      <Typography
                        sx={{ textAlign: "left", mb: 2 }}
                        variant="h5"
                      >
                        Current Plan
                      </Typography>
                    )}
                  </Grid>

                  <Grid item sm={12}>
                    <Stack>
                      <Paper
                        elevation={10} // Increase elevation for a stronger shadow
                        sx={
                          !defPlan?.expired
                            ? {
                                padding: "16px",
                                width: "30vw",
                                margin: "auto",
                                backgroundColor: "#f0f0f0", // Subtle background color
                                borderRadius: "8px", // Rounded corners
                              }
                            : {
                                padding: "16px",
                                width: "30vw",
                                margin: "auto",
                                backgroundColor: "#FFD9DC", // Subtle background color
                                border: "4px solid #FF0000",
                                borderRadius: "8px", // Rounded corners
                              }
                        } // Subtle 3D effect
                      >
                        <Typography
                          align="center"
                          variant="h4"
                          gutterBottom
                          style={
                            defPlan?.expired || defPlan?.canceled
                              ? { color: "#FF0000" }
                              : { color: "#4CAF50" }
                          }
                        >
                          {defPlan?.package_type}
                        </Typography>
                        {defPlan?.expired && (
                          <>
                            <Typography
                              align="center"
                              variant="body1"
                              gutterBottom
                              style={{ color: "#FF0000" }}
                            >
                              {currentUser?.subuser
                                ? "Your subscription is expired. Please contact your Administrator for further assistance."
                                : "Your payment has failed. Please create a new subscription to continue generating NOC."}
                            </Typography>
                          </>
                        )}
                        {defPlan?.canceled && (
                          <>
                            <Typography
                              align="center"
                              variant="body1"
                              gutterBottom
                              style={{ color: "#FF0000" }}
                            >
                              {`Your subscription is scheduled to be canceled on ${renewDateObj.toLocaleDateString(
                                "en-US"
                              )}. To continue using NOC Creator, please consider reactivating your subscription.`}
                            </Typography>
                          </>
                        )}
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <MonetizationOnIcon
                                style={{ color: "#2196F3" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Plan Name"
                              secondary={defPlan?.title}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MonetizationOnIcon
                                style={{ color: "#2196F3" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Cost"
                              secondary={
                                currentUser?.subuser &&
                                currentUser?.subuser?.role === "Basic User" &&
                                currentUser?.user?.expired
                                  ? "-------"
                                  : `$${defPlan?.price}`
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <ScheduleIcon style={{ color: "#FFC107" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary="Interval"
                              secondary={defPlan?.interval}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <LocationOnIcon style={{ color: "#E91E63" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary="Counties and NOC"
                              secondary={`${defPlan?.counties} counties, ${defPlan?.noc} NOC`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <EventIcon style={{ color: "#9C27B0" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary="Subscription Start Date"
                              secondary={
                                currentUser?.subuser &&
                                currentUser?.subuser?.role === "Basic User" &&
                                currentUser?.user?.expired
                                  ? "-------"
                                  : startDateObj.toLocaleDateString("en-US")
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <EventIcon style={{ color: "#9C27B0" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary="Subscription Renew Date"
                              secondary={
                                currentUser?.subuser &&
                                currentUser?.subuser?.role === "Basic User" &&
                                currentUser?.user?.expired
                                  ? "-------"
                                  : defPlan?.canceled
                                  ? "-"
                                  : renewDateObj.toLocaleDateString("en-US")
                              }
                            />
                          </ListItem>
                        </List>
                      </Paper>
                      <Typography
                        variant="body1"
                        color="#F2CD00"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ height: "1rem", mt: 3 }}
                      >
                        {errorText}
                      </Typography>
                      {currentUser.subuser ? (
                        <></>
                      ) : defPlan?.canceled ? (
                        <>
                          <LoadingButton
                            loading={isLoading}
                            size="large"
                            onClick={handleReactivatePlan}
                            sx={{
                              mt: 2,
                              width: "30rem",
                              ml: "auto",
                              mr: "auto",
                            }}
                            variant="contained"
                          >
                            Re-Activate Plan
                          </LoadingButton>
                        </>
                      ) : (
                        <>
                          <Button
                            size="large"
                            onClick={() => {
                              setOpenDrawer(true);
                            }}
                            sx={{
                              mt: 2,
                              width: "30rem",
                              ml: "auto",
                              mr: "auto",
                            }}
                            variant="outlined"
                          >
                            {defPlan?.expired
                              ? "Create New Subscription"
                              : "Change Plan"}
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Box>
        </>
      )}

      <TermsAndConditionsModal open={open} onClose={handleCloseModal} />
      <Drawer
        PaperProps={{
          sx: {
            width: isMd ? "100vw" : "70vw",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box sx={{ display: "flex", flexDirection: "column", px: 5 }}>
          <ChangePlanHeader
            isMd={isMd}
            title={defPlan?.expired ? "Create Subscription" : "Change Plan"}
            p1={
              defPlan?.expired
                ? "By creating a new subscription, you are authorizing immediate payment processing."
                : "Payment will be taken immediately upon implementation of the changes."
            }
            p2={
              defPlan?.expired
                ? "Any forms previously created will be retained. However, defaults and selected counties will be reset, requiring you to make the necessary selections again."
                : "Forms that have already been created will be retained. Defaults and selected counties will be lost and you will need to select them again."
            }
            setOpenDrawer={setOpenDrawer}
          />
          <ChangePlanMultistepForm
            setIsload={setIsload}
            currentUser={currentUser}
            type={defPlan?.expired ? "subscribe" : "change"}
            isLoad={isLoad}
            errorText={errorText}
            setErrorText={setErrorText}
            plan={plan}
            lastDisabled={lastDisabled}
            setLastDisabled={setLastDisabled}
            validLicense={validLicense}
            setContractorList={setContractorList}
            contractorList={contractorList}
            defPlan={defPlan}
            terms={terms}
            selectedName={selectedName}
            oldChecked={oldChecked}
            checked={checked}
            oldPlan={oldPlan}
            setPage={setPage}
            page={page}
            setDuplicatedLicenses={setDuplicatedLicenses}
            initialValues={{
              licenseType: contractorList[page]?.licenseType || "",
              licenseNumber: contractorList[page]?.licenseNumber || "",
              contractorName: contractorList[page]?.contractorName || "",
              contractorAddress: contractorList[page]?.contractorAddress || "",
              contractorPhone: contractorList[page]?.contractorPhone || "",
              contractorFax: contractorList[page]?.contractorFax || "",
              contractorEmail: contractorList[page]?.contractorEmail || "",
            }}
            onSubmit={async (values) => {
              setIsload(true);
              setIsLoading(true);
              setErrorText("Please Wait...");

              if (plan.type === "single" && contractorList.length > 1) {
                setErrorText(
                  "Limitation: Only a single contractor is permitted for single license subscription"
                );
                setIsload(false);
                setIsLoading(false);
                return;
              }

              const priceId = checked
                ? plan.price_id_year
                : plan.price_id_month;

              values["contractors"] = contractorList;
              values["priceId"] = priceId;

              if (defPlan?.expired) {
                try {
                  const changePlan = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/expired-create-plan`,
                    {
                      values,
                    },
                    { withCredentials: true }
                  );

                  if (changePlan.data.success) {
                    window.location.href = changePlan.data.data;
                  } else {
                    setIsLoading(false);
                    setErrorText(changePlan.data.message);
                  }
                } catch (err) {
                  console.log(err);
                  setErrorText(err.message);
                  setIsLoading(false);
                }
              } else {
                try {
                  const changePlan = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/change-plan`,
                    {
                      values,
                    },
                    { withCredentials: true }
                  );

                  if (changePlan.data.success) {
                    window.location.href = changePlan.data.data;
                  } else {
                    setIsLoading(false);
                    setErrorText(changePlan.data.message);
                  }
                } catch (err) {
                  console.log(err);
                  setErrorText(err.message);
                  setIsLoading(false);
                }
              }
            }}
          >
            <FormStep stepName="Choose a Plan" onSubmit={(e) => console.log(e)}>
              <ChangePlan
                type={defPlan?.expired ? "subscribe" : "change"}
                isMd={isMd}
                setOpenDrawer={setOpenDrawer}
                checked={checked}
                setChecked={setChecked}
                selectedName={selectedName}
                currentPlan={currentPlan}
                tiers={tiers}
                currentUser={currentUser}
                plan={plan}
                setSelectedName={setSelectedName}
                setPlan={setPlan}
                defPlan={defPlan}
                terms={terms}
                setTerms={setTerms}
                setOpen={setOpen}
                errorText={errorText}
                isLoading={isLoading}
              />
            </FormStep>
            <FormStep
              stepName="Contractor Details"
              onSubmit={(e) => console.log(e)}
              validationSchema={validationSchemaStep2}
            >
              <ChangePlanContractor
                type={defPlan?.expired ? "subscribe" : "change"}
                userEmail={currentUser?.user.email}
                fetchOldPlanData={fetchOldPlanData}
                plan={plan}
                oldPlanType={oldPlanType}
                setLicenseText={setLicenseText}
                licenseText={licenseText}
                contractorList={contractorList}
                setContractorList={setContractorList}
                validLicense={validLicense}
                setValidLicense={setValidLicense}
                disabledTabs={disabledTabs}
                setDisabledTabs={setDisabledTabs}
                duplicatedLicenses={duplicatedLicenses}
                setDuplicatedLicenses={setDuplicatedLicenses}
                disableInputs={disableInputs}
                setDisableInputs={setDisableInputs}
                page={page}
                setPage={setPage}
                buttons={buttons}
                setButtons={setButtons}
                customLicenseType={customLicenseType}
                setCustomLicenseType={setCustomLicenseType}
                additionalData={additionalData}
              />
            </FormStep>
          </ChangePlanMultistepForm>
        </Box>
      </Drawer>

      {/* NEW SUBSCRIPTION DRAWER */}
      <Drawer
        PaperProps={{
          sx: {
            width: isMd ? "100vw" : "50vw",
          },
        }}
        anchor="right"
        open={newSubscriptionDrawer}
        onClose={() => setNewSubscriptionDrawer(false)}
      >
        <SubscribeExpiredPlan
          isMd={isMd}
          setOpenDrawer={setNewSubscriptionDrawer}
          checked={checked}
          setChecked={setChecked}
          selectedName={selectedName}
          currentPlan={currentPlan}
          tiers={tiers}
          currentUser={currentUser}
          plan={plan}
          setSelectedName={setSelectedName}
          setPlan={setPlan}
          defPlan={defPlan}
          terms={terms}
          setTerms={setTerms}
          setOpen={setOpen}
          errorText={errorText}
          handleCreateExpiredPlan={handleCreateExpiredPlan}
          isLoading={isLoading}
        />
      </Drawer>
    </>
  );
};

export default Subscription;
