const initialState = {
  nocData: null,
};

const nocReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOC_DATA":
      return { ...state, nocData: action.payload }; // Update state with new data
    default:
      return state;
  }
};

export default nocReducer;
