import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Box,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Chart = styled(ApexChart)``;

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: "#76A0B9",
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "25rem",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

const NocUsage = (props) => {
  const { chartSeries, sx, setYear, handleChartValues, year } = props;
  const chartOptions = useChartOptions();
  const years = ["2023", "2024", "2025", "2026", "2027"];

  const handleChange = (event) => {
    setYear(event.target.value);
    handleChartValues();
  };

  return (
    <Card sx={sx} style={{ position: "relative" }}>
      {!props?.fromSub && (
        <Tooltip
          title="This chart presents an overview of your personal NOC usage history, showcasing the number of forms you've created over time."
          placement="top"
        >
          <HelpOutlineIcon
            color="primary"
            sx={{ position: "absolute", right: 10, top: 10 }}
          />
        </Tooltip>
      )}

      <CardHeader title="NOC Usage" />
      <CardContent>
        <Box sx={{ position: "absolute", top: 30, right: 30, width: "8rem" }}>
          <FormControl fullWidth variant="filled">
            <InputLabel>Select year</InputLabel>
            <Select value={year} label="select" onChange={handleChange}>
              {years.map(function (item, i) {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type="bar"
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default NocUsage;
