import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import usePlacesAutocomplete from "use-places-autocomplete";
import validator from "validator";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Grid,
  Container,
  Card,
  Button,
  Typography,
  Stack,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Select,
  InputLabel,
  TextField,
  useMediaQuery,
  useTheme,
  MenuItem,
  CircularProgress,
  LinearProgress,
  IconButton,
  Drawer,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { fetchUserData } from "../../store/actions/authActions";
import ChangeFA from "../ChangeFA";
import ChangePlanHeader from "../ChangePlanHeader";
import AddNewContractor from "../modals/AddNewContractor";
YupPassword(Yup);

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const optionValue = location.state?.option;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [selected, setSelected] = useState("");
  const [cancelSelected, setCancelSelected] = useState("");
  const currentUser = useSelector((state) => state.auth.user);
  const [removeInput, setRemoveInput] = useState("");
  const [leaveInput, setLeaveInput] = useState("");
  const [radioValue, setRadioValue] = useState("subscribed");
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [errorTextPassword, setErrorTextPassword] = useState("");
  const [errorTextContractor, setErrorTextContractor] = useState("");
  const [errorTextNewsletter, setErrorTextNewsletter] = useState("");
  const [errorTextCancel, setErrorTextCancel] = useState("");
  const [errorTextRemove, setErrorTextRemove] = useState("");
  const [licenseValue, setLicenseValue] = useState();
  const [copySelected, setCopySelected] = useState();
  const [licenseText, setLicenseText] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);

  const [enabled, setEnabled] = useState(true);
  const [newsletterDisabled, setNewsletterDisabled] = useState(true);
  const [radioDisabled, setRadioDisabled] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [contractorLoading, setContractorLoading] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [leavingReasons, setLeavingReasons] = useState({});
  // const [newLicenseNumber, setNewLicenseNumber] = useState("");
  const [licenseHidden, setLicenseHidden] = useState(true);
  const [isInvalidLicense, setIsInvalidLicense] = useState(true);
  const [additionalData, setAdditionalData] = useState();
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0);
  const [changeLoading, setChangeLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [mainText, setMainText] = useState("");
  const [heading, setheading] = useState("");
  const [selectedButton, setSelectedButton] = useState(0);
  const [noInputs, setNoInputs] = useState(false);
  const [openContractor, setOpenContractor] = useState(false);

  const [imageList, setImageList] = useState([]);
  const [currentFA, setCurrentFA] = useState("");
  const [currentImg, setCurrentImg] = useState("");
  const [imgWidth, setImgWidth] = useState("");
  const [qrCodeSrc, setQRCodeSrc] = useState(null);

  const [formattedNewPhone, setFormattedNewPhone] = useState("");
  const [disableInputs, setDisableInputs] = useState(true);
  const [newSubmit, setNewSubmit] = useState(false);
  const [newContractorError, setNewContractorError] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [addIconDisabled, setAddIconDisabled] = useState(true);
  const [changeTotp, setChangeTotp] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [contractors, setContractors] = useState({});
  const [notifyValue, setNotifyValue] = useState(0);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [notifyText, setNotifyText] = useState("");

  const [formattedPhone, setFormattedPhone] = useState(
    currentUser?.user.contractors[0]?.phone
  );

  const currentUserRef = useRef(currentUser); // Use a ref to hold the currentUser

  useEffect(() => {
    // Update the ref whenever currentUser changes
    currentUserRef.current = currentUser;
  }, [currentUser]);

  let count = 0;
  let dataCount = 0;
  let additionalCount = 0;
  let first_render_count = 0;

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setNotifyValue(newValue);
  };

  const handleNotify = async () => {
    setNotifyLoading(true);
    const notifyMethod = notifyValue === 0 ? "email" : "sms";
    const values = {
      notifyMethod,
      licenseContractor: contractors[selectedLicenseIndex],
    };
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/save-license-notification`,
        { values },
        { withCredentials: true }
      );

      setContractors((prevContractors) => {
        const updatedContractors = { ...prevContractors };
        const selectedContractor = updatedContractors[selectedLicenseIndex];

        // Check if the selected contractor exists and has process_status as "queued"
        if (
          selectedContractor &&
          selectedContractor.process_status === "queued"
        ) {
          // Add the notify key with the value "mobile"
          updatedContractors[selectedLicenseIndex] = {
            ...selectedContractor,
            notify: true,
            notifyMethod: notifyMethod,
          };
        }

        return updatedContractors;
      });
      setErrorTextContractor(
        `Notification has been successfully activated. You will receive notification via ${notifyMethod} once your license number is validated.`
      );
      setNotifyLoading(false);
      setIsExpanded(false);
      setNotifyValue(0);
    } catch (e) {
      setNotifyLoading(false);
    }
  };

  const startInterval = (selectedLicenseIndex) => {
    // Clear previous interval first
    clearInterval(contractors[selectedLicenseIndex]?.intervalId);

    const newIntervalId = setInterval(() => {
      setContractors((prevContractors) => {
        const selectedContractor = prevContractors[selectedLicenseIndex];
        const remainingTime = Math.max(
          0,
          selectedContractor.targetTime - selectedContractor.elapsedTime
        );

        return {
          ...prevContractors,
          [selectedLicenseIndex]: {
            ...selectedContractor,
            elapsedTime: selectedContractor.elapsedTime + 1,
          },
        };
      });
    }, 1000);

    // Use the callback version of setContractors to ensure the latest state
    setContractors((prevContractors) => ({
      ...prevContractors,
      [selectedLicenseIndex]: {
        ...prevContractors[selectedLicenseIndex],
        intervalId: newIntervalId,
      },
    }));
  };

  const handleChangeLicense = async (type) => {
    try {
      let updatedLicense = contractorFormik.values.newLicense;
      let oldLicense = contractorFormik.values.licenseNumber;
      let customValues = {};
      setChangeLoading(true);
      if (type && type === "update") {
        customValues = {
          licenseValue,
          newLicenseNumber: oldLicense,
          isUpdate: true,
        };
      } else {
        customValues = {
          licenseValue,
          newLicenseNumber: updatedLicense,
        };
      }

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-license-number`,
        { values: customValues },
        { withCredentials: true }
      );
      await dispatch(fetchUserData());
      // Calculate the estimated time in seconds
      const estimatedTimeInSeconds = resp?.data?.estimate || 0;

      // contractors[selectedLicenseIndex]?.process_status
      // Start the interval if the API call is successful
      if (resp.data.message !== "License number is already registered.") {
        setIsDuplicate(false);
        contractorFormik.setFieldError("newLicense", resp.data.message);
        if (type !== "update") {
          contractorFormik.setFieldValue(
            "licenseNumber",
            contractorFormik.values.newLicense
          );
        }

        setLicenseHidden(true);
        contractorFormik.setFieldValue("newLicense", "");
        setChangeLoading(false);

        setContractors((prevContractors) => ({
          ...prevContractors,
          [selectedLicenseIndex]: {
            ...prevContractors[selectedLicenseIndex],
            targetTime: estimatedTimeInSeconds,
            process_status: "queued",
            finish: resp?.data?.finish,
            date: resp?.data?.date,
            elapsedTime: 0,
            notify: false,
            notifyMethod: undefined,
            name: currentUser?.user?.contractors[selectedLicenseIndex]
              .licenseType,
          },
        }));

        startInterval(selectedLicenseIndex);
      } else {
        setIsDuplicate(true);
        // contractorFormik.setFieldError("newLicense", resp.data.message);
        setChangeLoading(false);
        contractorFormik.setFieldValue("newLicense", "");
      }

      // Dispatch the action after the API call
    } catch (e) {
      console.log(e);
      setChangeLoading(false);
    }
  };

  useEffect(() => {
    // Set the licenseType value in contractorFormik on the first render
    if (optionValue) {
      setEnabled(true);
      // Clear the error for the 'address' field

      const index = currentUser?.user?.contractors.findIndex(
        (contractor) => contractor.licenseType === optionValue
      );

      setLicenseValue(optionValue);
      setSelectedLicenseIndex(index);

      // Use Formik's setFieldValue to update the licenseType
      contractorFormik.setFieldValue("licenseType", optionValue);
      contractorFormik.setFieldValue("newLicense", "");

      // Now you can update other form fields based on the selected licenseType
      const selectedContractor = currentUser?.user?.contractors.find(
        (contractor) => contractor.licenseType === optionValue
      );

      setCopySelected(selectedContractor);

      if (
        selectedContractor &&
        selectedContractor.confirmed === true &&
        selectedContractor.type === "dbpr"
      ) {
        setSelectedButton(1);
        contractorFormik.setFieldValue("name", selectedContractor?.proName);
        contractorFormik.setFieldValue(
          "address",
          selectedContractor?.proAddress
        );
      } else if (
        selectedContractor &&
        selectedContractor.confirmed === true &&
        selectedContractor.type === "custom"
      ) {
        setSelectedButton(0);
        contractorFormik.setFieldValue("name", selectedContractor.name);
        contractorFormik.setFieldValue("address", selectedContractor.address);
      } else if (selectedContractor && selectedContractor.confirmed === false) {
        // Use Formik's setFieldValue to update other form fields
        if (selectedButton === 0) {
          contractorFormik.setFieldValue("name", selectedContractor.name);
          contractorFormik.setFieldValue("address", selectedContractor.address);
        } else if (selectedButton === 1) {
          contractorFormik.setFieldValue("name", selectedContractor?.proName);
          contractorFormik.setFieldValue(
            "address",
            selectedContractor?.proAddress
          );
        }
      }

      contractorFormik.setFieldValue("phone", selectedContractor?.phone);
      contractorFormik.setFieldValue("fax", selectedContractor?.fax);
      contractorFormik.setFieldValue("email", selectedContractor?.email);
      contractorFormik.setFieldValue(
        "licenseNumber",
        selectedContractor?.licenseNumber
      );
    }
  }, []);

  const resetInterval = () => {
    const intervalId = contractors[selectedLicenseIndex]?.intervalId;
    if (intervalId) {
      clearInterval(intervalId);
    }

    setContractors((prevContractors) => ({
      ...prevContractors,
      [selectedLicenseIndex]: {
        ...prevContractors[selectedLicenseIndex],
        intervalId: null,
        elapsedTime: 0,
        targetTime: 0,
        process_status: "completed",
      },
    }));
  };

  useEffect(() => {
    if (
      contractors[selectedLicenseIndex]?.notify &&
      contractors[selectedLicenseIndex]?.process_status === "queued"
    ) {
      setErrorTextContractor(
        `Notification has been successfully activated. You will receive notification via ${contractors[selectedLicenseIndex]?.notifyMethod} once your license number is validated.`
      );
    } else {
      setErrorTextContractor("");
    }
  }, [selectedLicenseIndex, contractors]);

  useEffect(() => {
    if (currentUser) {
      if (
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus ===
        "error"
      ) {
        resetInterval();
        setMainText(
          "The DBPR website is currently undergoing maintenance and is temporarily unavailable. Please try again later."
        );
        setheading("DBPR Maintenance");
        setNoInputs(true);
        setLicenseHidden(false);
      } else if (
        currentUser?.user.contractors[selectedLicenseIndex].process_status ===
        "queued"
      ) {
        setMainText(
          "Please be patient as we confirm the validity of your license number. You can leave this page and come back later"
        );
        setheading("Verification in progress...");
        setNoInputs(true);
        setLicenseHidden(true);
      } else if (
        currentUser?.user.contractors[selectedLicenseIndex].valid === true &&
        currentUser?.user.contractors[selectedLicenseIndex].confirmed === true
      ) {
        resetInterval();
        setMainText("Your contractor details are up to date");
        setheading("Verified License");
        setNoInputs(false);
        setLicenseHidden(true);
      } else if (
        currentUser?.user.contractors[selectedLicenseIndex].valid === true &&
        currentUser?.user.contractors[selectedLicenseIndex].confirmed === false
      ) {
        resetInterval();
        setMainText(
          "Please review your contractor information to ensure its accuracy. You can use your custom details or the information retrieved from the Florida DBPR database. Once confirmed, you won't be able to change it."
        );
        setheading("Confirm Your Contractor Details");
        setNoInputs(false);
        setLicenseHidden(true);
      } else if (
        currentUser?.user.contractors[selectedLicenseIndex].valid === false &&
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus ===
          "not_found" &&
        currentUser?.user.contractors[selectedLicenseIndex].expiry === ""
      ) {
        resetInterval();
        setMainText("Please update your license information");
        setheading("Invalid License Number");
        setNoInputs(true);
        setLicenseHidden(false);
      } else if (
        (currentUser?.user.contractors[selectedLicenseIndex].valid === false &&
          currentUser?.user.contractors[selectedLicenseIndex].expiry !== "" &&
          currentUser?.user.contractors[selectedLicenseIndex].licenseStatus !==
            "not_found") ||
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus !== ""
      ) {
        resetInterval();
        setMainText("Please update your license information");
        setheading("Expired License Number");
        setNoInputs(true);

        if (
          currentUser?.user.contractors[selectedLicenseIndex].confirmed === true
        ) {
          resetInterval();
          setLicenseHidden(true);
        } else {
          resetInterval();
          setLicenseHidden(false);
        }
      }

      // {currentUser?.user.analytics.contractorChange > 0
      //   ? "Changes can be made only once. If you run out and need assistance, please contact customer service"
      //   : "There are no more changes left for you to make. Please contact customer service for further assistance."}
    }
  }, [currentUser, mainText, selectedLicenseIndex]);

  const handleCustomDetails = () => {
    setSelectedButton(0);

    // Check if contractorFormik and copySelected are not undefined
    if (contractorFormik && copySelected) {
      // Update the form values using setValues
      contractorFormik.setValues({
        ...contractorFormik.values,
        name: copySelected.name,
        address: copySelected.address,
      });
    }
  };

  const handleDbprDetails = () => {
    setSelectedButton(1);
    // Check if contractorFormik and copySelected are not undefined
    if (contractorFormik && copySelected) {
      // Update the form values using setValues
      contractorFormik.setValues({
        ...contractorFormik.values,
        name: copySelected.proName,
        address: copySelected.proAddress,
      });
    }
  };

  useEffect(() => {
    if (copySelected?.licenseStatus === "not_found") {
      setLicenseText("License Number not Found");
    } else if (copySelected?.process_status === "queued") {
      setLicenseText("Waiting for Validation");
    } else {
      setLicenseText(copySelected?.licenseStatus);
    }
  }, [copySelected, additionalData]);

  const handleNewsletterSubmit = async () => {
    setNewsletterLoading(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/updatenewsletter`,
        { radioValue },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setErrorTextNewsletter(resp.data.message);

        try {
          dispatch(fetchUserData());

          setRadioDisabled(false);
        } catch (e) {
          console.log(e);
        }

        setNewsletterDisabled(true);
        setNewsletterLoading(false);
      }
    } catch (err) {
      console.log(err);

      setErrorTextNewsletter("Something went wrong");
      setNewsletterLoading(false);
    }
  };

  const handleLeave = async () => {
    if (leaveInput !== "Yes, I want to cancel my subscription") {
      setErrorTextCancel("Input does not match!");
      setLeaveInput("");
      setCancelSelected("");
    } else if (cancelSelected === "") {
      setErrorTextCancel("Please select a reason!");
    } else {
      setCancelLoading(true);
      const payload = { leaveInput, cancelSelected };
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/cancel-subscription`,
          {
            payload,
          },
          { withCredentials: true }
        );
        if (resp.data.success) {
          await dispatch(fetchUserData());
          setErrorTextCancel(resp.data.message);
          setCancelLoading(false);
          navigate("/subscription");
        }
      } catch (e) {
        console.log(e);
        setCancelLoading(false);
        setLeaveInput("");
        setCancelSelected("");
      }
    }
  };

  const handleRemove = async () => {
    if (removeInput !== "Yes, I want to leave permanently") {
      setErrorTextRemove("Input does not match!");
    } else if (selected === "") {
      setErrorTextRemove("Please select a reason!");
    } else {
      setRemoveLoading(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/removeprofile`,
          {
            removeInput,
            selected,
          },
          { withCredentials: true }
        );

        localStorage?.removeItem("ujsffq");
        window.location.reload();
      } catch {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (errorTextPassword !== "") {
      const passwordTimer = setTimeout(() => setErrorTextPassword(""), 3000);
      return () => clearTimeout(passwordTimer);
    }

    if (newContractorError !== "") {
      const newContractorTimer = setTimeout(
        () => setNewContractorError(""),
        5000
      );
      return () => clearTimeout(newContractorTimer);
    }

    if (notifyText !== "") {
      const notifyTextTimer = setTimeout(() => setNotifyText(""), 5000);
      return () => clearTimeout(notifyTextTimer);
    }

    // if (
    //   errorTextContractor !== "" &&
    //   (errorTextContractor ===
    //     `Notification has been successfully activated. You will receive notification via sms once your license number is validated.` ||
    //     errorTextContractor !==
    //       `Notification has been successfully activated. You will receive notification via email once your license number is validated.`)
    // ) {
    //   const contractorTimer = setTimeout(
    //     () => setErrorTextContractor(""),
    //     5000
    //   );
    //   return () => clearTimeout(contractorTimer);
    // }

    if (errorTextNewsletter !== "") {
      const contractorTimer = setTimeout(
        () => setErrorTextNewsletter(""),
        3000
      );
      return () => clearTimeout(contractorTimer);
    }

    if (errorTextCancel !== "") {
      const cancelTimer = setTimeout(() => setErrorTextCancel(""), 3000);
      return () => clearTimeout(cancelTimer);
    }

    if (errorTextRemove !== "") {
      const removeTimer = setTimeout(() => setErrorTextRemove(""), 3000);
      return () => clearTimeout(removeTimer);
    }

    if (radioValue === currentSubscription) {
      setNewsletterDisabled(true);
    } else {
      setNewsletterDisabled(false);
    }
  }, [
    errorTextPassword,
    errorTextContractor,
    errorTextCancel,
    errorTextRemove,
    errorTextNewsletter,
    radioValue,
    currentSubscription,
    enabled,
  ]);

  useEffect(() => {
    if (count < 1) {
      dispatch(fetchUserData());
      count += 1;
    }
  }, []);

  useEffect(() => {
    const getLeavingReasons = async () => {
      const dataResp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/leaving-reasons`
      );

      setLeavingReasons(dataResp.data.data.reasons);
    };

    if (dataCount < 1) {
      getLeavingReasons();
      dataCount += 1;
    }
  }, []);

  useEffect(() => {
    const getAdditional = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/additional`
      );

      setAdditionalData(res.data.data);
    };

    if (additionalCount < 1) {
      getAdditional();
      additionalCount += 1;
    }
  }, []);

  useEffect(() => {
    setLicenseValue(
      currentUser?.user?.contractors[selectedLicenseIndex]?.licenseType
    );
    setCopySelected(currentUser?.user?.contractors[selectedLicenseIndex]);

    // Check if contractorFormik is initialized before accessing it
    if (contractorFormik && !contractorFormik.isValid) {
      if (
        currentUser.user.contractors[selectedLicenseIndex]?.licenseStatus ===
          "not_found" ||
        currentUser.user.contractors[selectedLicenseIndex]?.licenseStatus !== ""
      ) {
        contractorFormik.setErrors({});
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const getQr = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/generate_to_change_qr`,
          {
            responseType: "blob",
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          const qrCodeUrl = URL.createObjectURL(new Blob([response.data]));
          setQRCodeSrc(qrCodeUrl);
        }
      } catch (e) {
        console.log(e);
      }
    };

    setImageList([
      {
        alt: "email",
        src: "/img/logos/emailImage.png",
        // width: 120,
        width: 80,
      },
      {
        alt: "mobile",
        src: "/img/logos/phone.png",
        // width: 180,
        width: 140,
      },

      {
        alt: "authenticator",
        src: "/img/logos/authenticator.png",
        // width: 167,
        width: 127,
      },
    ]);

    if (currentUser?.subuser) {
      if (currentUser?.subuser.auth_type === "totp") {
        setCurrentFA("totp");
        setCurrentImg("/img/logos/authenticator.png");
        setImgWidth(127);
      } else if (currentUser?.subuser.auth_type === "emailFa") {
        setCurrentFA("emailFa");
        setCurrentImg("/img/logos/emailImage.png");
        setImgWidth(80);
      } else if (currentUser?.subuser.auth_type === "smsFa") {
        setCurrentFA("smsFa");
        setImgWidth(140);
        setCurrentImg("/img/logos/phone.png");
      }
    } else {
      if (currentUser?.user.auth_type === "totp") {
        setCurrentFA("totp");
        setCurrentImg("/img/logos/authenticator.png");
        setImgWidth(127);
      } else if (currentUser?.user.auth_type === "emailFa") {
        setCurrentFA("emailFa");
        setCurrentImg("/img/logos/emailImage.png");
        setImgWidth(80);
      } else if (currentUser?.user.auth_type === "smsFa") {
        setCurrentFA("smsFa");
        setImgWidth(140);
        setCurrentImg("/img/logos/phone.png");
      }
    }

    try {
      if (!currentUser) {
        return;
      }

      if (
        currentUser.user.contractors.length >=
        currentUser.user.analytics.contractors_limit
      ) {
        setAddIconDisabled(true);
      } else {
        setAddIconDisabled(false);
      }

      setCopySelected(currentUser.user.contractors[selectedLicenseIndex]);

      if (currentUser?.subuser) {
        if (currentUser?.subuser.newsletter) {
          setRadioValue("subscribed");
          setCurrentSubscription("subscribed");

          setRadioDisabled(false);
        } else {
          setRadioValue("unsubscribed");
          setCurrentSubscription("unsubscribed");

          setRadioDisabled(false);
        }
      } else {
        if (currentUser.user.newsletter) {
          setRadioValue("subscribed");
          setCurrentSubscription("subscribed");

          setRadioDisabled(false);
        } else {
          setRadioValue("unsubscribed");
          setCurrentSubscription("unsubscribed");

          setRadioDisabled(false);
        }
      }
    } catch (err) {
      console.log(err);
    }

    getQr();
  }, [currentUser]);

  useEffect(() => {
    const startIntervalsForQueued = async () => {
      await dispatch(fetchUserData());
      const currentDate = Math.floor(Date.now() / 1000);

      currentUser?.user?.contractors.map((item, index) => {
        setContractors((prevContractors) => ({
          ...prevContractors,
          [index]: {
            intervalId: null,
            elapsedTime: 0,
            targetTime: 0,
            name: item.licenseType,
          },
        }));
      });

      // Access the latest currentUser from the ref
      const current = currentUserRef?.current;

      Object.keys(current?.user?.contractors).forEach((key) => {
        const contractor = current.user.contractors[key];
        const index = parseInt(key, 10); // Convert the key to an integer

        if (contractor.process_status === "queued") {
          const elapsedTime = currentDate - contractor.date;

          setContractors((prevContractors) => {
            const updatedContractors = { ...prevContractors }; // Assuming contractors is an object
            updatedContractors[index] = {
              ...updatedContractors[index],
              targetTime: contractor.estimate,
              process_status: "queued",
              finish: contractor.finish,
              date: contractor.date,
              notify: currentUser?.user?.contractors[index]?.notify,
              notifyMethod: currentUser?.user?.contractors[index]?.notifyMethod,
              elapsedTime: elapsedTime,
            };
            return updatedContractors;
          });

          // Start the interval for the selected contractor
          startInterval(index);
        }
      });

      setDataLoading(false);
    };

    if (first_render_count < 1 && currentUser) {
      startIntervalsForQueued();
      first_render_count += 1;
    }

    return () => {
      resetInterval();
    };
  }, []); // Removed selectedLicenseIndex from the dependency array

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      newPassword: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      newPasswordConfirmation: Yup.string()

        .required("Password confirmation is required")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.newPassword === value;
        }),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/password/changepwd`,
            {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              newPasswordConfirmation: values.newPasswordConfirmation,
            },
            { withCredentials: true }
          );
          if (res.data.success) {
            setErrorTextPassword(res.data.message);
            setLoading(false);
            values.oldPassword = "";
            values.newPassword = "";
            values.newPasswordConfirmation = "";
          }
        } catch (err) {
          console.log(err);
          setErrorTextPassword(err.response.data.message);
          values.oldPassword = "";
          values.newPassword = "";
          values.newPasswordConfirmation = "";
          setLoading(false);
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleLicenseNumberInputChange = (event) => {
    // Replace letters with capital letters
    const value = event?.target.value.toUpperCase();

    // Update Formik value using setFieldValue
    contractorFormik.setFieldValue("newLicense", value);
  };

  // CONTRACTOR FORMIK ///
  const handlePhoneChange = (event) => {
    const { value } = event.target;
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    setFormattedPhone(formattedPhoneNumber); // Update the formatted phone in state
    contractorFormik.setFieldValue("phone", formattedPhoneNumber); // Update formik field value
  };

  // CONTRACTOR FORMIK //
  const contractorFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      licenseType:
        currentUser?.user.contractors[selectedLicenseIndex]?.licenseType,
      newLicense: "",
      name:
        selectedButton === 1
          ? currentUser?.user.contractors[selectedLicenseIndex]?.proName
          : currentUser?.user.contractors[selectedLicenseIndex]?.name,
      address:
        selectedButton === 1
          ? currentUser?.user.contractors[selectedLicenseIndex]?.proAddress
          : currentUser?.user.contractors[selectedLicenseIndex]?.address,
      phone: formattedPhone,
      fax: currentUser?.user.contractors[selectedLicenseIndex]?.fax,
      email: currentUser?.user.contractors[selectedLicenseIndex]?.email,
      licenseNumber:
        currentUser?.user.contractors[selectedLicenseIndex]?.licenseNumber,
    },
    validationSchema: Yup.object({
      newLicense: !licenseHidden
        ? Yup.string()
            .min(7, "License number must be at least 7 characters long.")
            .max(13, "License number must not exceed 13 characters.")
            .required("License number is required")
            .test(
              "customValidation",
              "LIcense number prefix doesn't correspond with the license type selected",
              function (value) {
                // Find the tuple where the first value matches customLicenseType
                const matchingTuple =
                  additionalData?.combined_license_types.find(
                    (tuple) => tuple[0] === licenseValue
                  );

                // If a matching tuple is found, apply specific validation logic
                if (matchingTuple) {
                  const [, secondValue] = matchingTuple;

                  // Check if the value starts with the second value from the tuple and the rest are numbers
                  const regex = new RegExp(`^${secondValue}\\d+$`);
                  return regex.test(value);
                }

                // No matching tuple found, invalid license number
                return false;
              }
            )
        : Yup.string(),
      name:
        currentUser?.user.contractors[selectedLicenseIndex].valid === false &&
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus ===
          "not_found" &&
        currentUser?.user.contractors[selectedLicenseIndex].expiry === ""
          ? Yup.string()
          : Yup.string().min(3).required("Name is required"),
      address:
        currentUser?.user.contractors[selectedLicenseIndex].valid === false &&
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus ===
          "not_found" &&
        currentUser?.user.contractors[selectedLicenseIndex].expiry === ""
          ? Yup.string()
          : Yup.string()
              .min(5, "Address is too short")
              .required("Address is required"),
      phone:
        currentUser?.user.contractors[selectedLicenseIndex].valid === false &&
        currentUser?.user.contractors[selectedLicenseIndex].licenseStatus ===
          "not_found" &&
        currentUser?.user.contractors[selectedLicenseIndex].expiry === ""
          ? Yup.string()
          : Yup.string()
              .required("Phone number is required")
              .test("PhoneTest", "Phone number is invalid", function (value) {
                const isValidPhoneNumber = validator.isMobilePhone(value);

                if (isValidPhoneNumber) {
                  return true;
                } else {
                  return false;
                }
              }),
      fax: Yup.string().matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        "Fax Number is Invalid"
      ),
      email: Yup.string().email("Must be a valid email"),
    }),
    onSubmit: async (values, helpers) => {
      setChangeLoading(true);
      values["selectedButtonDetail"] = selectedButton;
      try {
        // ADD API CALL TO AUTHENTICATE ///
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/finalize-contractor`,
          {
            values,
          },
          { withCredentials: true }
        );

        if (resp.data.success) {
          dispatch(fetchUserData());
          setEnabled(true);
          setMainText("Your contractor details are up to date");
          setheading("Verified License");
          setChangeLoading(false);
        } else {
          setErrorTextContractor(resp.data.message);
          setChangeLoading(false);
          setEnabled(true);
        }
        ////////////////////////////////////
      } catch (err) {
        console.log(err);
        helpers.setStatus({ success: false });
        if (err.response.data?.error === "phone") {
          helpers.setFieldValue("phone", "");
        }
        helpers.setSubmitting(false);
        setChangeLoading(false);
        setErrorTextContractor(err.response.data?.message);
        setEnabled(false);
      }
    },
  });

  // ADD NEW CONTRACTOR FORMIK //
  const addContractorFormik = useFormik({
    enableReinitialize: false,
    initialValues: {
      licenseType: "",
      name: "",
      address: "",
      phone: formattedNewPhone,
      fax: "",
      email: "",
      licenseNumber: "",
    },
    validationSchema: Yup.object({
      licenseType: Yup.string().required("License type is required"),
      licenseNumber: Yup.string()
        .min(7, "License number must be at least 7 characters long.")
        .max(13, "License number must not exceed 13 characters.")
        .required("License number is required")
        .test(
          "customValidation",
          "LIcense number prefix doesn't correspond with the license type selected",
          function (value) {
            // Find the tuple where the first value matches customLicenseType
            const matchingTuple = additionalData?.combined_license_types.find(
              (tuple) => tuple[0] === addContractorFormik.values?.licenseType
            );

            // If a matching tuple is found, apply specific validation logic
            if (matchingTuple) {
              const [, secondValue] = matchingTuple;

              // Check if the value starts with the second value from the tuple and the rest are numbers
              const regex = new RegExp(`^${secondValue}\\d+$`);
              return regex.test(value);
            }

            // No matching tuple found, invalid license number
            return false;
          }
        ),
      name: Yup.string().min(3).required("Name is required"),
      address: Yup.string()
        .min(5, "Address is too short")
        .required("Address is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);

          if (isValidPhoneNumber) {
            return true;
          } else {
            return false;
          }
        }),
      fax: Yup.string().matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        "Fax Number is Invalid"
      ),
      email: Yup.string().email("Must be a valid email"),
    }),
    onSubmit: async (values, helpers) => {
      setNewSubmit(true);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/add-new-contractor`,
          {
            values,
          },
          { withCredentials: true }
        );

        if (resp.data.success) {
          await dispatch(fetchUserData());
          // Calculate the estimated time in seconds
          const estimatedTimeInSeconds = resp?.data?.estimate || 0;

          // Create a new key based on the length of the contractors object
          const newContractorKey = Object.keys(contractors).length;

          // Create a new contractor object
          const newContractor = {
            targetTime: estimatedTimeInSeconds,
            process_status: "queued",
            finish: resp?.data?.finish,
            date: resp?.data?.date,
            elapsedTime: 0,
            notify: false,
            notifyMethod: undefined,
            name: values.licenseType,
          };

          // Update the contractors object with the new contractor
          setContractors((prevContractors) => ({
            ...prevContractors,
            [newContractorKey]: newContractor,
          }));

          // Start interval for the new contractor outside of setContractors
          startInterval(newContractorKey);

          setIsAdded(true);

          helpers.resetForm();
          setOpenContractor(false);
        } else {
          setNewContractorError(resp.data.message);
        }
      } catch (e) {
        setNewContractorError(e.response.data.message);
        console.log(e);
      }

      setNewSubmit(false);
    },
  });
  // useEffect to set the formatted phone number when the component mounts
  useEffect(() => {
    if (currentUser?.user.contractors[0]?.phone) {
      const digitsOnly = currentUser.user.contractors[0]?.phone.replace(
        /\D/g,
        ""
      ); // Remove non-numeric characters
      const formattedPhoneNumber = digitsOnly.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "$1-$2-$3"
      ); // Add dashes after 3rd and 6th digits
      setFormattedPhone(formattedPhoneNumber); // Set the formatted phone in state
    }
  }, [currentUser]);

  useEffect(() => {
    if (addContractorFormik) {
      if (addContractorFormik.values?.licenseType === "") {
        setDisableInputs(true);
      } else {
        setDisableInputs(false);
      }
    }
  }, [addContractorFormik]);

  useEffect(() => {
    if (isAdded) {
      if (
        currentUser?.user.contractors.length >=
        currentUser?.user.analytics.contractors_limit
      ) {
        setAddIconDisabled(true);
      }

      const lastIndex = currentUser?.user?.contractors.length - 1;
      const newLicenseTypeValue =
        currentUser?.user?.contractors[lastIndex]?.licenseType;

      setLicenseValue(newLicenseTypeValue);
      setSelectedLicenseIndex(lastIndex);

      // Use Formik's setFieldValue to update the licenseType
      contractorFormik.setFieldValue("licenseType", newLicenseTypeValue);
      contractorFormik.setFieldValue("newLicense", "");

      // Now you can update other form fields based on the selected licenseType
      const selectedContractor = currentUser?.user?.contractors[lastIndex];

      setCopySelected(selectedContractor);

      if (
        selectedContractor &&
        selectedContractor.confirmed === true &&
        selectedContractor.type === "dbpr"
      ) {
        setSelectedButton(1);
        contractorFormik.setFieldValue("name", selectedContractor?.proName);
        contractorFormik.setFieldValue(
          "address",
          selectedContractor?.proAddress
        );
      } else if (
        selectedContractor &&
        selectedContractor.confirmed === true &&
        selectedContractor.type === "custom"
      ) {
        setSelectedButton(0);
        contractorFormik.setFieldValue("name", selectedContractor.name);
        contractorFormik.setFieldValue("address", selectedContractor.address);
      } else if (selectedContractor && selectedContractor.confirmed === false) {
        // Use Formik's setFieldValue to update other form fields
        if (selectedButton === 0) {
          contractorFormik.setFieldValue("name", selectedContractor.name);
          contractorFormik.setFieldValue("address", selectedContractor.address);
        } else if (selectedButton === 1) {
          contractorFormik.setFieldValue("name", selectedContractor?.proName);
          contractorFormik.setFieldValue(
            "address",
            selectedContractor?.proAddress
          );
        }
      }

      contractorFormik.setFieldValue("phone", selectedContractor?.phone);
      contractorFormik.setFieldValue("fax", selectedContractor.fax);
      contractorFormik.setFieldValue("email", selectedContractor.email);
      contractorFormik.setFieldValue(
        "licenseNumber",
        selectedContractor.licenseNumber
      );
      setIsAdded(false);
    }
  }, [isAdded]);

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center", // Add this line to center vertically
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="lg" py={3}>
              {!currentUser?.subuser && (
                <>
                  <Card
                    pb={3}
                    pt={1}
                    px={3}
                    mb={4}
                    component={Grid}
                    sx={
                      isMd
                        ? { width: "90vw", ml: "auto", mr: "auto" }
                        : { width: "50vw", ml: "auto", mr: "auto" }
                    }
                  >
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Typography variant="body2" align="left">
                            {currentUser?.tier.type === "multi"
                              ? "Multi License"
                              : "Single License"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography
                            sx={{
                              ml: "auto",
                            }}
                            variant="body2"
                            align="right"
                          >
                            {currentUser?.tier.type === "multi"
                              ? `${currentUser?.user.contractors.length}/${currentUser?.user.analytics.contractors_limit}`
                              : "1/1"}
                          </Typography>
                          {currentUser?.tier.type === "multi" ? (
                            <IconButton
                              disabled={
                                addIconDisabled || currentUser?.user?.expired
                              }
                              color="primary"
                              aria-label="add"
                              onClick={() => {
                                setOpenContractor(true);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              disabled
                              color="primary"
                              aria-label="add"
                              sx={{
                                visibility: "hidden",
                                pointerEvents: "none",
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        borderBottom: "2px solid #d3d3d3",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                      }}
                    />
                    <form noValidate onSubmit={contractorFormik.handleSubmit}>
                      <Grid container justifyContent="center">
                        <Grid item sm={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} md={6}>
                              <Typography
                                sx={{ textAlign: "left", mb: 1 }}
                                variant="h6"
                              >
                                Manage Contractor Details
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl
                                fullWidth
                                variant="filled"
                                error={
                                  !!(
                                    contractorFormik.touched?.licenseType &&
                                    contractorFormik.errors?.licenseType
                                  )
                                }
                              >
                                <InputLabel id="licenseType-label">
                                  License Type
                                </InputLabel>
                                <Select
                                  labelId="license_types"
                                  name="licenseType"
                                  label="License Type"
                                  value={
                                    contractorFormik.values?.licenseType || ""
                                  }
                                  onChange={(e) => {
                                    console.log(contractors);

                                    if (isExpanded) {
                                      setIsExpanded(false);
                                    }

                                    if (notifyValue !== 0) {
                                      setNotifyValue(0);
                                    }

                                    setEnabled(true);

                                    // Clear the error for the 'address' field

                                    const index =
                                      currentUser?.user?.contractors.findIndex(
                                        (contractor) =>
                                          contractor.licenseType ===
                                          e.target.value
                                      );

                                    setLicenseValue(e.target.value);
                                    setSelectedLicenseIndex(index);

                                    // Use Formik's setFieldValue to update the licenseType
                                    contractorFormik.setFieldValue(
                                      "licenseType",
                                      e.target.value
                                    );
                                    contractorFormik.setFieldValue(
                                      "newLicense",
                                      ""
                                    );

                                    // Now you can update other form fields based on the selected licenseType
                                    const selectedContractor =
                                      currentUser?.user?.contractors.find(
                                        (contractor) =>
                                          contractor.licenseType ===
                                          e.target.value
                                      );

                                    setCopySelected(selectedContractor);

                                    // if (!contractors[index]) {
                                    //   setContractors((prevContractors) => ({
                                    //     ...prevContractors,
                                    //     [index]: {
                                    //       intervalId: null,
                                    //       elapsedTime: 0,
                                    //       targetTime: 0,
                                    //     },
                                    //   }));
                                    // }

                                    if (
                                      selectedContractor &&
                                      selectedContractor.confirmed === true &&
                                      selectedContractor.type === "dbpr"
                                    ) {
                                      setSelectedButton(1);
                                      contractorFormik.setFieldValue(
                                        "name",
                                        selectedContractor?.proName
                                      );
                                      contractorFormik.setFieldValue(
                                        "address",
                                        selectedContractor?.proAddress
                                      );
                                    } else if (
                                      selectedContractor &&
                                      selectedContractor.confirmed === true &&
                                      selectedContractor.type === "custom"
                                    ) {
                                      setSelectedButton(0);
                                      contractorFormik.setFieldValue(
                                        "name",
                                        selectedContractor.name
                                      );
                                      contractorFormik.setFieldValue(
                                        "address",
                                        selectedContractor.address
                                      );
                                    } else if (
                                      selectedContractor &&
                                      selectedContractor.confirmed === false
                                    ) {
                                      // Use Formik's setFieldValue to update other form fields
                                      if (selectedButton === 0) {
                                        contractorFormik.setFieldValue(
                                          "name",
                                          selectedContractor.name
                                        );
                                        contractorFormik.setFieldValue(
                                          "address",
                                          selectedContractor.address
                                        );
                                      } else if (selectedButton === 1) {
                                        contractorFormik.setFieldValue(
                                          "name",
                                          selectedContractor?.proName
                                        );
                                        contractorFormik.setFieldValue(
                                          "address",
                                          selectedContractor?.proAddress
                                        );
                                      }
                                    }

                                    contractorFormik.setFieldValue(
                                      "phone",
                                      selectedContractor?.phone
                                    );
                                    contractorFormik.setFieldValue(
                                      "fax",
                                      selectedContractor?.fax
                                    );
                                    contractorFormik.setFieldValue(
                                      "email",
                                      selectedContractor?.email
                                    );
                                    contractorFormik.setFieldValue(
                                      "licenseNumber",
                                      selectedContractor?.licenseNumber
                                    );

                                    // contractorFormik.setTouched({});
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 150, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {currentUser?.user?.contractors?.map(
                                    function (contractor, i) {
                                      const getStyles = () => {
                                        let dotColor = "";

                                        if (
                                          contractor.process_status === "queued"
                                        ) {
                                          dotColor = "#004976";
                                        } else if (
                                          contractor.valid === true &&
                                          contractor.confirmed === false
                                        ) {
                                          dotColor = "#F2CD00";
                                        } else if (
                                          contractor.valid === true &&
                                          contractor.confirmed === true
                                        ) {
                                          dotColor = "#006400";
                                        } else if (contractor.valid === false) {
                                          dotColor = "#a21717";
                                        }

                                        return {
                                          "&::after": {
                                            content: '""',
                                            display: "inline-block",
                                            width: "10px", // Adjust the size of the dot
                                            height: "10px", // Adjust the size of the dot
                                            marginLeft: "5px", // Adjust the spacing between text and dot
                                            borderRadius: "50%",
                                            backgroundColor: dotColor,
                                          },
                                        };
                                      };

                                      return (
                                        <MenuItem
                                          key={i}
                                          value={contractor.licenseType}
                                          sx={getStyles()}
                                        >
                                          <Typography variant="body2">
                                            {contractor.licenseType}
                                          </Typography>
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* <div
                        style={{
                          borderBottom: "2px solid #d3d3d3",
                          marginTop: "1rem",
                        }}
                      /> */}
                          <Typography
                            sx={{
                              textAlign: "center",
                              mb: 2,
                              mt: 2,
                              color: "#004976",
                            }}
                            variant="h4"
                          >
                            {heading}
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                              mb: 4,
                            }}
                            variant="body1"
                          >
                            {mainText}
                          </Typography>
                          {copySelected?.process_status === "queued" && (
                            <>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  mb: 3,
                                }}
                                variant="body1"
                              >
                                Estimated Confirmation Time:
                              </Typography>
                              <Typography color="success">
                                {contractors[selectedLicenseIndex]
                                  ?.process_status === "queued" &&
                                  (contractors[selectedLicenseIndex]
                                    ?.targetTime <= 60 ||
                                  contractors[selectedLicenseIndex]
                                    ?.targetTime -
                                    contractors[selectedLicenseIndex]
                                      ?.elapsedTime <=
                                    60
                                    ? "Less than a minute"
                                    : Math.floor(
                                        (contractors[selectedLicenseIndex]
                                          ?.targetTime -
                                          contractors[selectedLicenseIndex]
                                            ?.elapsedTime) /
                                          60
                                      ) + " Minutes")}
                              </Typography>
                            </>
                          )}
                          {contractors[selectedLicenseIndex]?.process_status ===
                            "queued" && (
                            <LinearProgress
                              variant="determinate"
                              value={
                                (contractors[selectedLicenseIndex]
                                  ?.elapsedTime /
                                  contractors[selectedLicenseIndex]
                                    ?.targetTime) *
                                100
                              }
                              sx={{ mt: 1, mb: 1 }}
                            />
                          )}
                        </Grid>

                        <Grid item sm={12} width="100%">
                          <Grid container alignItems="top">
                            <Grid item lg={6} sm={12} order={isMd ? 2 : 1}>
                              <Typography variant="h5" align="center" mt={1}>
                                {contractorFormik?.values?.licenseType}
                              </Typography>
                            </Grid>
                            <Grid item lg={6} sm={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center", // Vertically center the content
                                  justifyContent: "center",
                                  height: "100%", // Ensure the Box takes up the full height
                                }}
                              >
                                <Box
                                  sx={
                                    copySelected?.valid === false &&
                                    copySelected?.process_status === "completed"
                                      ? { borderBottom: "5px solid #990000" }
                                      : copySelected?.process_status ===
                                        "queued"
                                      ? { borderBottom: "5px solid #004976" }
                                      : { borderBottom: "5px solid #006400" }
                                  }
                                >
                                  <Typography
                                    variant="h4"
                                    sx={
                                      copySelected?.valid === false &&
                                      copySelected?.process_status ===
                                        "completed"
                                        ? {
                                            marginBottom: "-7px",
                                            color: "#990000",
                                          }
                                        : copySelected?.process_status ===
                                          "queued"
                                        ? {
                                            marginBottom: "-7px",
                                            color: "#004976",
                                          }
                                        : {
                                            marginBottom: "-7px",
                                            color: "#006400",
                                          }
                                    }
                                  >
                                    {contractorFormik.values.licenseNumber}
                                  </Typography>
                                </Box>

                                <Typography
                                  variant="body2"
                                  sx={
                                    copySelected?.valid === false &&
                                    copySelected?.process_status === "completed"
                                      ? { color: "#990000" }
                                      : copySelected?.process_status ===
                                        "queued"
                                      ? { color: "#004976" }
                                      : { color: "#006400" }
                                  }
                                >
                                  {licenseText}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={
                                    copySelected?.valid === false &&
                                    copySelected?.process_status === "completed"
                                      ? { color: "#990000" }
                                      : copySelected?.process_status ===
                                        "queued"
                                      ? { color: "#004976" }
                                      : { color: "#006400" }
                                  }
                                >
                                  {copySelected?.expiry}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          {currentUser?.user.contractors[selectedLicenseIndex]
                            ?.valid === true &&
                            currentUser?.user.contractors[selectedLicenseIndex]
                              ?.process_status === "completed" && (
                              <Grid container spacing={1} sx={{ my: 2 }}>
                                <Grid item sm={12} lg={6}>
                                  <Button
                                    onClick={handleCustomDetails}
                                    fullWidth
                                    disableRipple
                                    disabled={
                                      copySelected?.confirmed ||
                                      currentUser?.user?.expired
                                    }
                                    variant={
                                      selectedButton === 0
                                        ? "contained"
                                        : "outlined"
                                    }
                                  >
                                    Use Custom Details
                                  </Button>
                                </Grid>
                                <Grid item sm={12} lg={6}>
                                  <Button
                                    onClick={handleDbprDetails}
                                    fullWidth
                                    disableRipple
                                    disabled={
                                      copySelected?.confirmed ||
                                      currentUser?.user?.expired
                                    }
                                    variant={
                                      selectedButton === 1
                                        ? "contained"
                                        : "outlined"
                                    }
                                  >
                                    Use Florida DBPR Database details
                                  </Button>
                                </Grid>
                              </Grid>
                            )}

                          <Stack spacing={3} mt={4}>
                            {!licenseHidden && (
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched.newLicense &&
                                    contractorFormik.errors.newLicense
                                  )
                                }
                                helperText={
                                  isDuplicate && !contractorFormik.isValid
                                    ? "License number is already registered."
                                    : contractorFormik.touched.newLicense &&
                                      contractorFormik.errors.newLicense
                                    ? contractorFormik.errors.newLicense
                                    : ""
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={(event) => {
                                  handleLicenseNumberInputChange(event);
                                  setIsDuplicate(false);
                                }}
                                // (e) => {
                                // Replace letters with capital letters
                                // const value = e.target.value.toUpperCase();

                                // Update the state with the modified value
                                // setNewLicenseNumber(value);
                                // }

                                fullWidth
                                label="Change License Number"
                                name="newLicense"
                                // value={newLicenseNumber}
                                value={contractorFormik.values.newLicense || ""}
                              />
                            )}
                            {!noInputs && (
                              <>
                                <TextField
                                  error={
                                    !!(
                                      contractorFormik.touched.name &&
                                      contractorFormik.errors.name
                                    )
                                  }
                                  helperText={
                                    contractorFormik.touched.name &&
                                    contractorFormik.errors.name
                                  }
                                  onBlur={contractorFormik.handleBlur}
                                  onChange={contractorFormik.handleChange}
                                  fullWidth
                                  label="Name*"
                                  name="name"
                                  value={contractorFormik.values.name || ""}
                                  disabled={
                                    copySelected?.confirmed && !enabled
                                      ? false
                                      : selectedButton === 1
                                      ? true
                                      : heading ===
                                        "Confirm Your Contractor Details"
                                      ? false
                                      : enabled
                                  }
                                />
                                <TextField
                                  error={
                                    !!(
                                      contractorFormik.touched.address &&
                                      contractorFormik.errors.address
                                    )
                                  }
                                  helperText={
                                    contractorFormik.touched.address &&
                                    contractorFormik.errors.address
                                  }
                                  onBlur={contractorFormik.handleBlur}
                                  onChange={contractorFormik.handleChange}
                                  fullWidth
                                  label="Address*"
                                  name="address"
                                  value={contractorFormik.values.address || ""}
                                  disabled={
                                    copySelected?.confirmed && !enabled
                                      ? false
                                      : selectedButton === 1
                                      ? true
                                      : heading ===
                                        "Confirm Your Contractor Details"
                                      ? false
                                      : enabled
                                  }
                                />
                                <TextField
                                  error={Boolean(
                                    contractorFormik.touched?.phone &&
                                      contractorFormik.errors?.phone
                                  )}
                                  helperText={
                                    contractorFormik.touched?.phone &&
                                    contractorFormik.errors?.phone
                                  }
                                  onBlur={contractorFormik.handleBlur}
                                  onChange={handlePhoneChange}
                                  fullWidth
                                  label="Phone Number*"
                                  name="phone"
                                  value={contractorFormik?.values?.phone || ""}
                                  disabled={
                                    heading ===
                                    "Confirm Your Contractor Details"
                                      ? false
                                      : enabled
                                  }
                                />
                                <TextField
                                  error={
                                    !!(
                                      contractorFormik.touched.fax &&
                                      contractorFormik.errors.fax
                                    )
                                  }
                                  helperText={
                                    contractorFormik.touched.fax &&
                                    contractorFormik.errors.fax
                                  }
                                  onBlur={contractorFormik.handleBlur}
                                  onChange={contractorFormik.handleChange}
                                  fullWidth
                                  label="Fax Number"
                                  name="fax"
                                  value={contractorFormik.values.fax || ""}
                                  disabled={
                                    heading ===
                                    "Confirm Your Contractor Details"
                                      ? false
                                      : enabled
                                  }
                                />
                                <TextField
                                  error={
                                    !!(
                                      contractorFormik.touched.email &&
                                      contractorFormik.errors.email
                                    )
                                  }
                                  helperText={
                                    contractorFormik.touched.email &&
                                    contractorFormik.errors.email
                                  }
                                  onBlur={contractorFormik.handleBlur}
                                  onChange={contractorFormik.handleChange}
                                  fullWidth
                                  label="Email Address"
                                  name="email"
                                  value={contractorFormik.values.email || ""}
                                  disabled={
                                    heading ===
                                    "Confirm Your Contractor Details"
                                      ? false
                                      : enabled
                                  }
                                />
                              </>
                            )}

                            {contractorFormik.errors.submit && (
                              <Typography
                                color="error"
                                sx={{ mt: 3 }}
                                variant="body2"
                              >
                                {contractorFormik.errors.submit}
                              </Typography>
                            )}
                            <Typography
                              variant="body1"
                              color="error"
                              fontWeight="bold"
                              textAlign="center"
                            >
                              {errorTextContractor}
                            </Typography>

                            <Grid container>
                              <>
                                {/* ------NOTIFY--------- */}
                                {Math.floor(
                                  (contractors[selectedLicenseIndex]
                                    ?.targetTime -
                                    contractors[selectedLicenseIndex]
                                      ?.elapsedTime) /
                                    60
                                ) >= 2 &&
                                  !contractors[selectedLicenseIndex]
                                    ?.notify && (
                                    <Grid item xs={12}>
                                      <Accordion
                                        expanded={isExpanded}
                                        elevation={0}
                                        sx={{
                                          width: "100%",
                                          padding: 0,
                                        }}
                                      >
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            padding: 0,
                                            borderBottom: "none",
                                            cursor: "default",

                                            "&.MuiAccordionSummary-root:hover":
                                              {
                                                cursor: "default",
                                              },
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: "100%",
                                              padding: 0,
                                            }}
                                          >
                                            {isExpanded ? (
                                              <Button
                                                disabled={
                                                  currentUser?.subusers
                                                    .length >= 20
                                                }
                                                sx={{
                                                  cursor: "pointer",
                                                }}
                                                size="large"
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                startIcon={<RemoveIcon />}
                                                onClick={() => {
                                                  setIsExpanded(false);
                                                }}
                                              >
                                                Hide
                                              </Button>
                                            ) : (
                                              <Button
                                                sx={{
                                                  cursor: "pointer",
                                                  width: "100%",
                                                }}
                                                size="large"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  setIsExpanded(true);
                                                }}
                                              >
                                                Notify Me
                                              </Button>
                                            )}
                                          </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Typography
                                            align="center"
                                            variant="h6"
                                          >
                                            Please select how you would like to
                                            be notified once the license has
                                            been validated.
                                          </Typography>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                              <Tabs
                                                value={notifyValue}
                                                onChange={handleChange}
                                                variant="fullWidth"
                                                indicatorColor="primary"
                                                textColor="primary"
                                              >
                                                <Tab label="EMAIL" />
                                                <Tab label="SMS" />
                                              </Tabs>
                                            </Grid>
                                          </Grid>
                                          <Typography
                                            align="center"
                                            sx={{ mt: "1.5rem" }}
                                            variant="h6"
                                          >
                                            {notifyValue === 0
                                              ? currentUser?.user?.email
                                              : currentUser?.user?.personal[0]
                                                  ?.phone}
                                          </Typography>
                                          <Box>
                                            <Typography
                                              variant="body1"
                                              color="error"
                                              fontWeight="bold"
                                              textAlign="center"
                                              sx={{
                                                mt: 2,
                                                minHeight: "1.5rem",
                                              }}
                                            ></Typography>
                                            <LoadingButton
                                              onClick={handleNotify}
                                              loading={notifyLoading}
                                              variant="outlined"
                                              size="large"
                                              fullWidth
                                            >
                                              Notify Me
                                            </LoadingButton>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                  )}

                                {/* ---------- */}
                                <Grid item xs={12}>
                                  {currentUser?.user.contractors[
                                    selectedLicenseIndex
                                  ].valid === true &&
                                  currentUser?.user.contractors[
                                    selectedLicenseIndex
                                  ].confirmed ===
                                    true ? null : copySelected?.licenseStatus ===
                                      "not_found" ||
                                    copySelected?.confirmed === false ? (
                                    <>
                                      <LoadingButton
                                        loading={changeLoading}
                                        disabled={
                                          currentUser?.user?.expired ||
                                          heading ===
                                            "Confirm Your Contractor Details"
                                            ? false
                                            : !contractorFormik.isValid ||
                                              contractorFormik.values
                                                .newLicense === "" ||
                                              (contractorFormik.values
                                                .newLicense ===
                                                contractorFormik.values
                                                  .licenseNumber &&
                                                copySelected?.licenseStatus !==
                                                  "error")
                                        }
                                        type={
                                          heading ===
                                          "Confirm Your Contractor Details"
                                            ? "submit"
                                            : "button"
                                        }
                                        onClick={
                                          heading ===
                                          "Confirm Your Contractor Details"
                                            ? null
                                            : handleChangeLicense
                                        }
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        sx={{
                                          backgroundColor: "#004976",
                                          mt: 2,

                                          ":hover": {
                                            backgroundColor: "#4338CA",
                                          },
                                        }}
                                        variant="contained"
                                      >
                                        {heading ===
                                        "Confirm Your Contractor Details"
                                          ? "Confirm"
                                          : "Change License Number"}
                                      </LoadingButton>
                                    </>
                                  ) : (
                                    <>
                                      <LoadingButton
                                        loading={changeLoading}
                                        disabled={
                                          currentUser?.user?.expired ||
                                          currentUser
                                            ? heading ===
                                              "Verification in progress..."
                                              ? true
                                              : false
                                            : true
                                        }
                                        onClick={() => {
                                          if (
                                            heading === "Expired License Number"
                                          ) {
                                            handleChangeLicense("update");
                                          } else {
                                            setEnabled(!enabled);
                                            if (enabled === false) {
                                              // Your other logic
                                            }
                                          }
                                        }}
                                        color="warning"
                                        fullWidth
                                        size="large"
                                        sx={{
                                          backgroundColor: "#F2CD00",
                                          mt: 2,

                                          ":hover": {
                                            backgroundColor: "#CDAF0B",
                                          },
                                        }}
                                        variant="contained"
                                      >
                                        {heading === "Expired License Number"
                                          ? "Sync with Latest DBPR Data"
                                          : !enabled
                                          ? "Cancel"
                                          : "Enable Edit"}
                                      </LoadingButton>
                                    </>
                                  )}
                                </Grid>
                              </>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                    </form>
                  </Card>
                </>
              )}
              {/* CONTRACTOR DETAILS */}

              {/* Password CHANGE */}
              <Card
                py={3}
                px={3}
                mb={4}
                component={Grid}
                sx={
                  isMd
                    ? { width: "90vw", ml: "auto", mr: "auto" }
                    : { width: "50vw", ml: "auto", mr: "auto" }
                }
              >
                <Grid container justifyContent="center">
                  <Grid item sm={12}>
                    <Typography
                      sx={{ textAlign: "center", mb: 5 }}
                      variant="h5"
                    >
                      Change Password
                    </Typography>
                  </Grid>
                  <Grid item sm={12} width="100%">
                    <form noValidate onSubmit={passwordFormik.handleSubmit}>
                      <Stack spacing={3} mt={4}>
                        <TextField
                          error={
                            !!(
                              passwordFormik.touched.oldPassword &&
                              passwordFormik.errors.oldPassword
                            )
                          }
                          helperText={
                            passwordFormik.touched.oldPassword &&
                            passwordFormik.errors.oldPassword
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="Old Password*"
                          name="oldPassword"
                          type={showOldPassword ? "text" : "password"}
                          value={passwordFormik.values.oldPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowOldPassword(!showOldPassword);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                  onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          error={
                            !!(
                              passwordFormik.touched.newPassword &&
                              passwordFormik.errors.newPassword
                            )
                          }
                          helperText={
                            passwordFormik.touched.newPassword &&
                            passwordFormik.errors.newPassword
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="New Password*"
                          name="newPassword"
                          type={showNewPassword ? "text" : "password"}
                          value={passwordFormik.values.newPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowNewPassword(!showNewPassword);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                  onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          error={
                            !!(
                              passwordFormik.touched.newPasswordConfirmation &&
                              passwordFormik.errors.newPasswordConfirmation
                            )
                          }
                          helperText={
                            passwordFormik.touched.newPasswordConfirmation &&
                            passwordFormik.errors.newPasswordConfirmation
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="Confirm Password*"
                          name="newPasswordConfirmation"
                          type="password"
                          value={passwordFormik.values.newPasswordConfirmation}
                        />
                        {passwordFormik.errors.submit && (
                          <Typography
                            color="error"
                            sx={{ mt: 3 }}
                            variant="body2"
                          >
                            {passwordFormik.errors.submit}
                          </Typography>
                        )}
                        <Typography
                          variant="body1"
                          color="error"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          {errorTextPassword}
                        </Typography>

                        <LoadingButton
                          loading={loading}
                          type="submit"
                          fullWidth
                          size="large"
                          sx={{
                            mt: 2,
                            backgroundColor: "primary",
                          }}
                          variant="contained"
                        >
                          Submit Changes
                        </LoadingButton>
                      </Stack>
                    </form>
                  </Grid>
                </Grid>
              </Card>

              {/* CAHGNE 2FA */}
              <ChangeFA
                qrCodeSrc={qrCodeSrc}
                currentUser={currentUser}
                currentFA={currentFA}
                setCurrentFA={setCurrentFA}
                imageList={imageList}
                setImageList={setImageList}
                currentImg={currentImg}
                setCurrentImg={setCurrentImg}
                imgWidth={imgWidth}
                setImgWidth={setImgWidth}
              />

              {/* NEWSLETTER */}
              <Card
                py={3}
                px={3}
                component={Grid}
                sx={
                  isMd
                    ? { width: "90vw", ml: "auto", mr: "auto" }
                    : { width: "50vw", ml: "auto", mr: "auto" }
                }
              >
                <Box justifyContent="center" sx={{ width: "100%" }}>
                  <Grid item sm={12}>
                    <Typography
                      sx={{ textAlign: "center", mb: 2 }}
                      variant="h5"
                    >
                      Manage Newsletter
                    </Typography>
                  </Grid>
                  <Grid container justifyContent={"space-around"} mt={6}>
                    <FormControl>
                      <RadioGroup
                        component={Box}
                        sx={{ display: "flex", flexDirection: "row" }}
                        name="controlled-radio-buttons-group"
                        value={radioValue}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="subscribed"
                          control={<Radio disabled={radioDisabled} />}
                          label={
                            radioValue === "subscribed"
                              ? "Subscribed for Newsletter"
                              : "Subscribe for Newsletter"
                          }
                        />

                        <FormControlLabel
                          value="unsubscribed"
                          control={<Radio disabled={radioDisabled} />}
                          label={
                            radioValue === "unsubscribed"
                              ? "Unsubscribed from Newsletter"
                              : "Unsubscribe for Newsletter"
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid container>
                    <Typography
                      mt={2}
                      variant="body1"
                      color="#F2CD00"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ ml: "auto", mr: "auto" }}
                    >
                      {errorTextNewsletter}
                      {/* Newsletter subscription has been changed successfully */}
                    </Typography>
                    <LoadingButton
                      loading={newsletterLoading}
                      disabled={newsletterDisabled}
                      onClick={handleNewsletterSubmit}
                      fullWidth
                      size="large"
                      sx={{
                        mt: 2,
                        backgroundColor: "primary",
                      }}
                      variant="contained"
                    >
                      Submit Changes
                    </LoadingButton>
                  </Grid>
                </Box>
              </Card>
              {!currentUser?.subuser && (
                <>
                  {/* REMOVE SUBSCRIPTION */}
                  {currentUser &&
                    (currentUser.user.expired ||
                    currentUser.user.cancellation_time ? (
                      <></>
                    ) : (
                      <>
                        <Typography
                          variant="h4"
                          color="error"
                          textAlign="center"
                          mt={4}
                        >
                          DANGER ZONE
                        </Typography>
                        <div
                          style={{
                            border: "4px solid #F01329",
                            marginTop: "0.5rem",
                          }}
                        />
                        <Card
                          py={3}
                          px={3}
                          mt={5}
                          component={Grid}
                          sx={
                            isMd
                              ? { width: "90vw", ml: "auto", mr: "auto" }
                              : { width: "50vw", ml: "auto", mr: "auto" }
                          }
                        >
                          <Grid container>
                            <Grid item sm={12} mb={3}>
                              <Typography
                                sx={{ textAlign: "center", mb: 2 }}
                                variant="h5"
                              >
                                Cancel Subscription
                              </Typography>
                              <Typography
                                color="error"
                                sx={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  mb: 3,
                                }}
                                variant="body1"
                              >
                                We encourage you to try out our different
                                packages before making a decision.
                              </Typography>
                            </Grid>
                            <Grid container spacing={10}>
                              <Grid item sm={6}>
                                <Stack>
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      mb: 1,
                                    }}
                                    variant="body1"
                                  >
                                    Are you sure to cancel subscription?
                                  </Typography>
                                  <Typography
                                    sx={{ textAlign: "center", mb: 3 }}
                                    variant="body2"
                                  >
                                    Type in "Yes, I want to cancel my
                                    subscription" to cancel subscription!
                                  </Typography>
                                  <TextField
                                    onChange={(e) => {
                                      setLeaveInput(e.target.value);
                                    }}
                                    fullWidth
                                    label="Type"
                                    name="leaveInput"
                                    value={leaveInput}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item sm={6}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    mb: 1,
                                  }}
                                  variant="body1"
                                >
                                  Please select reason!
                                </Typography>
                                <FormControl fullWidth variant="filled">
                                  <InputLabel>Select</InputLabel>
                                  <Select
                                    label="Select"
                                    value={cancelSelected}
                                    onChange={(e) =>
                                      setCancelSelected(e.target.value)
                                    }
                                  >
                                    {leavingReasons?.cancel_subscription.map(
                                      function (item, i) {
                                        return (
                                          <MenuItem key={i} value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item sm={12} mt={5}>
                                <Stack>
                                  <Typography
                                    minHeight="1.5rem"
                                    variant="body1"
                                    color="#F2CD00"
                                    fontWeight="bold"
                                    textAlign="center"
                                  >
                                    {errorTextCancel}
                                  </Typography>
                                  <LoadingButton
                                    loading={cancelLoading}
                                    color="error"
                                    onClick={handleLeave}
                                    fullWidth
                                    size="large"
                                    sx={{
                                      mt: 3,
                                    }}
                                    variant="contained"
                                  >
                                    CANCEL SUBSCRIPTION
                                  </LoadingButton>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </>
                    ))}
                </>
              )}

              {/* REMOVE PROFILE
              <Card
                py={3}
                px={3}
                mt={5}
                component={Grid}
                sx={
                  isMd
                    ? { width: "90vw", ml: "auto", mr: "auto" }
                    : { width: "50vw", ml: "auto", mr: "auto" }
                }
              >
                <Grid container>
                  <Grid item sm={12} mb={3}>
                    <Typography
                      sx={{ textAlign: "center", mb: 2 }}
                      variant="h5"
                    >
                      Remove Profile
                    </Typography>
                    <Typography
                      color="error"
                      sx={{ textAlign: "center", fontWeight: "bold", mb: 3 }}
                      variant="body1"
                    >
                      We are sorry to see you go! Would you like us to assist
                      you in any way? We will be happy to assist you if you
                      contact our customer service department.
                    </Typography>
                  </Grid>
                  <Grid container spacing={10}>
                    <Grid item sm={6}>
                      <Stack>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            mb: 1,
                          }}
                          variant="body1"
                        >
                          Are you sure to leave?
                        </Typography>
                        <Typography
                          sx={{ textAlign: "center", mb: 3 }}
                          variant="body2"
                        >
                          Type in "Yes, I want to leave permanently" to remove
                          your profile!
                        </Typography>
                        <TextField
                          onChange={(e) => {
                            setRemoveInput(e.target.value);
                          }}
                          fullWidth
                          label="Type"
                          name="leaveInput"
                          value={removeInput}
                        />
                      </Stack>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography
                        sx={{ textAlign: "center", fontWeight: "bold", mb: 1 }}
                        variant="body1"
                      >
                        Please select reason!
                      </Typography>
                      <FormControl fullWidth variant="filled">
                        <InputLabel>Select</InputLabel>
                        <Select
                          label="Select"
                          value={selected}
                          onChange={(e) => setSelected(e.target.value)}
                        >
                          {leavingReasons?.remove_profile.map(function (
                            item,
                            i
                          ) {
                            return (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item sm={12} mt={5}>
                      <Stack>
                        <Typography
                          minHeight="1.5rem"
                          variant="body1"
                          color="#F2CD00"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          {errorTextRemove}
                        </Typography>
                        <LoadingButton
                          loading={removeLoading}
                          color="error"
                          onClick={handleRemove}
                          fullWidth
                          size="large"
                          sx={{
                            mt: 3,
                          }}
                          variant="contained"
                        >
                          REMOVE PROFILE
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Card> */}
            </Container>
          </Box>

          <Drawer
            PaperProps={{
              sx: {
                width: isMd ? "100vw" : "70vw",
              },
            }}
            anchor="right"
            open={openContractor}
            onClose={() => setOpenContractor(false)}
          >
            <Box sx={{ display: "flex", flexDirection: "column", px: 5 }}>
              <form noValidate onSubmit={addContractorFormik.handleSubmit}>
                <ChangePlanHeader
                  isMd={isMd}
                  title="Add Contractor"
                  p1="By adding a new contractor, immediate implementation of changes will take effect, ensuring seamless integration into your workflow."
                  p2="It's important to note that once a contractor is added, they become a permanent part of your records. Be mindful of this decision, as added contractors cannot be removed."
                  setOpenDrawer={setOpenContractor}
                />
                <AddNewContractor
                  isSm={isMd}
                  additionalData={additionalData}
                  formik={addContractorFormik}
                  currentUser={currentUser}
                  setFormattedNewPhone={setFormattedNewPhone}
                  disableInputs={disableInputs}
                  newSubmit={newSubmit}
                  newContractorError={newContractorError}
                />
              </form>
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};

export default ProfileSettings;
