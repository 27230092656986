// TAX PARCEL, FOLIO / LEGAL DESC / OWNERS
export const initials = [
  {
    county: "Alachua",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        suretyName: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lenderName: "",
        lenderAddress: "",
        lenderPhone: "",
        personName: "",
        personAddress: "",
        personPhone: "",
        personName2: "",
        personAddress2: "",
        personPhone2: "",
        expirationDate: "",
        // signatory: "",
        // day: "",
        // month: "",
        // year: "",
        // nameOfPerson: "",
        // typeOfAuthority: "",
        // nameOfParty: "",
        // commissionNum: "",
        // personalKnown: "",
        // identificationType: "",
      },
    ],
    fields: [
      {
        name: "suretyName",
        label: "5. Surety Name",
        value: "suretyName",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5. Surety Amount of bond $",
        value: "amountOfBond",
      },
      {
        name: "lenderName",
        label: "6. Lender Name",
        value: "lenderName",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "personName",
        label: "7. Designated Person Name",
        value: "personName",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personName2",
        label: "8. Designated Person Name",
        value: "personName2",
      },
      {
        name: "personAddress2",
        label: "8. Designated Person Address",
        value: "personAddress2",
      },
      {
        name: "personPhone2",
        label: "8. Designated Person Phone",
        value: "personPhone2",
      },

      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      // {
      //   name: "signatory",
      //   label: "9. Signatory's Title/Office",
      //   value: "signatory",
      // },
      // {
      //   name: "day",
      //   label: "Day",
      //   value: "day",
      // },
      // {
      //   name: "month",
      //   label: "Month",
      //   value: "month",
      // },
      // {
      //   name: "year",
      //   label: "Year",
      //   value: "year",
      // },

      // {
      //   name: "nameOfPerson",
      //   label: "Name of Person",
      //   value: "nameOfPerson",
      // },
      // {
      //   name: "typeOfAuthority",
      //   label: "Type of Authority",
      //   value: "typeOfAuthority",
      // },
      // {
      //   name: "nameOfParty",
      //   label: "Name of Party",
      //   value: "nameOfParty",
      // },
      // {
      //   name: "commissionNum",
      //   label: "Commission Number",
      //   value: "commissionNum",
      // },
      // {
      //   name: "personalKnown",
      //   label: "Personal Known",
      //   value: "personalKnown",
      // },
      // {
      //   name: "identificationType",
      //   label: "Produced Identification",
      //   value: "identificationType",
      // },
    ],
  },
  {
    county: "Baker",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimpleName: "",
        surety: "",
        amountOfBond: "",
        lender: "",
        personName: "",
        designated1: "",
        of: "",
        expirationDate: "",
        date: "",
        // countyOf: "",
        // day: "",
        // month: "",
        // year: "",
        // personName2: "",
        // typeOfAuthority: "",
        // nameOfParty: "",
        // personallyKnown: "",
        // identification: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimpleName",
        label: "3.(b) Name and address of fee simple title holder",
        value: "feeSimpleName",
      },
      {
        name: "surety",
        label: "5. Surety Name and address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "6. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "7. Lender",
        value: "lender",
      },
      {
        name: "personName",
        label: "8. Designated Person",
        value: "personName",
      },
      {
        name: "designated1",
        label: "9. Owner designated",
        value: "designated1",
      },
      {
        name: "of",
        label: "9. Owner designates... of",
        value: "of",
      },
      {
        name: "expirationDate",
        label: "10. Expiration date of Notice of Commencement",
        value: "expirationDate",
      },
      {
        name: "date",
        label: "Date",
        value: "date",
      },
      // {
      //   name: "countyOf",
      //   label: "County of",
      //   value: "countyOf",
      // },
      // {
      //   name: "day",
      //   label: "Day",
      //   value: "day",
      // },
      // {
      //   name: "month",
      //   label: "Month",
      //   value: "month",
      // },
      // {
      //   name: "year",
      //   label: "Year",
      //   value: "year",
      // },
      // {
      //   name: "personName2",
      //   label: "Person Name",
      //   value: "personName2",
      // },
      // {
      //   name: "typeOfAuthority",
      //   label: "Type of Authority",
      //   value: "typeOfAuthority",
      // },
      // {
      //   name: "nameOfParty",
      //   label: "Name of Party",
      //   value: "nameOfParty",
      // },
      // {
      //   name: "personallyKnown",
      //   label: "Personally Known",
      //   value: "personallyKnown",
      // },
      // {
      //   name: "identification",
      //   label: "Produced Identification",
      //   value: "identification",
      // },
    ],
  },
  {
    county: "Bay",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimpleName: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lenderName: "",
        lenderAddress: "",
        lenderPhone: "",
        personName: "",
        personAddress: "",
        personPhone: "",
        designated1: "",
        of: "",
        designatedPhone: "",
        expirationDate: "",
        // day: "",
        // month: "",
        // year: "",
        // nameOfPerson: "",
        // personallyKnown: "",
        // identification: "",
        // typeOfIdentification: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimpleName",
        label: "Fee Simple Titleholder Name",
        value: "feeSimpleName",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee simple Titleholder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Payment Bond Surety",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Payment Bond Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "Payment Bond Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lenderName",
        label: "Lender Name",
        value: "lenderName",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "personName",
        label: "Designated Person Name",
        value: "personName",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "designated1",
        label: "Owner designates",
        value: "designated1",
      },
      {
        name: "of",
        label: "Owner designates... of",
        value: "of",
      },
      {
        name: "designatedPhone",
        label: "Designated Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
      // {
      //   name: "day",
      //   label: "Day",
      //   value: "day",
      // },
      // {
      //   name: "month",
      //   label: "Month",
      //   value: "month",
      // },
      // {
      //   name: "year",
      //   label: "Year",
      //   value: "year",
      // },
      // {
      //   name: "nameOfPerson",
      //   label: "Name of Person",
      //   value: "nameOfPerson",
      // },
      // {
      //   name: "personallyKnown",
      //   label: "Personally Known",
      //   value: "personallyKnown",
      // },
      // {
      //   name: "identification",
      //   label: "Produced identification",
      //   value: "identification",
      // },
      // {
      //   name: "typeOfIdentification",
      //   label: "Type of identification Produced",
      //   value: "typeOfIdentification",
      // },
    ],
  },
  {
    county: "Bradford",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        returnTo: "",
        preparedBy: "",
        appraiserID: "",
        feeSimpleName: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        lenderName: "",
        lenderAddress: "",
        personName: "",
        personAddress: "",
        designatedName: "",
        designatedAddress: "",
        // identification: "",
        // day: "",
        // month: "",
        // year: "",
      },
    ],
    fields: [
      {
        name: "returnTo",
        label: "Return To",
        value: "returnTo",
      },
      {
        name: "preparedBy",
        label: "This Instrument Prepared By",
        value: "preparedBy",
      },
      {
        name: "appraiserID",
        label: "Property Appraiser's Parcel ID",
        value: "appraiserID",
      },
      {
        name: "feeSimpleName",
        label: "Fee Simple Titleholder Name",
        value: "feeSimpleName",
      },
      {
        name: "feeSimpleAddress",
        label: "Titleholder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Surety Mailing",
        value: "suretyAddress",
      },
      {
        name: "lenderName",
        label: "Lender Name",
        value: "lenderName",
      },
      {
        name: "lenderAddress",
        label: "Lender Mailing Address",
        value: "lenderAddress",
      },
      {
        name: "personName",
        label: "Designated Person Name",
        value: "personName",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designatedName",
        label: "Owner designates: Name",
        value: "designatedName",
      },
      {
        name: "designatedAddress",
        label: "Owner designates: Address",
        value: "designatedAddress",
      },
      // {
      //   name: "identification",
      //   label: "Identification",
      //   value: "identification",
      // },

      // {
      //   name: "day",
      //   label: "Day",
      //   value: "day",
      // },
      // {
      //   name: "month",
      //   label: "Month",
      //   value: "month",
      // },
      // {
      //   name: "year",
      //   label: "Year",
      //   value: "year",
      // },
    ],
  },
  {
    county: "Brevard",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        feeSimpleName: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "feeSimpleName",
        label: "3.(c) Name and address of fee simple titleholder",
        value: "feeSimpleName",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name and Address",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner designates: Phone Number",
        value: "designatedPhone",
      },

      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Broward",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimpleName: "",
        surety: "",
        lender: "",
        person: "",
        designated: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimpleName",
        label: "Name and Address of Fee Simple Titleholder",
        value: "feeSimpleName",
      },
      {
        name: "surety",
        label: "5. Surety's Name, Address, Phone and Bond Amount",
        value: "surety",
      },
      {
        name: "lender",
        label: "6. Lender's Name, Address and Phone",
        value: "lender",
      },
      {
        name: "person",
        label: "7. Designated person Name, Address and Phone",
        value: "person",
      },
      {
        name: "designated",
        label: "8. Owner designates: Name, Address and Phone",
        value: "designated",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Calhoun",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimpleName: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        suretyFax: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimpleName",
        label: "3.(c) Name and Address of Fee Simple Titleholder",
        value: "feeSimpleName",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "5.(c) Surety Fax Number",
        value: "suretyFax",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name and Address",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner designates: Phone Number",
        value: "designatedPhone",
      },

      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Charlotte",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimpleName: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        suretyCity: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        lenderCity: "",
        person: "",
        designated: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimpleName",
        label: "3.(d) Name and address of fee simple title Holder",
        value: "feeSimpleName",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(a) Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5.(b) Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyCity",
        label: "5.(b) Surety City/State/Zip Code",
        value: "suretyCity",
      },
      {
        name: "amountOfBond",
        label: "5.(c) Amount of Bond: $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(a) Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6.(b) Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderCity",
        label: "6.(b) Lender City/State/Zip Code",
        value: "lenderCity",
      },
      {
        name: "person",
        label: "7. Designated Person Name/Address/Phone Number",
        value: "person",
      },
      {
        name: "designated",
        label: "8. Owner designates: Name/Address/Phone Number",
        value: "designated",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Citrus",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        unitNumber: "",
        apt: "",
        lot: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond $",
        value: "amountOfBond",
      },

      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name and Address",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Clay",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",

        permitNumber: "",
        feeSimpleTitleholder: "",
        suretyName: "",
        suretyAddress: "",
        suretyPhone: "",
        suretyEmail: "",
        amountOfBond: "",
        lenderName: "",
        lenderAddress: "",
        lenderPhone: "",
        lenderEmail: "",
        personName: "",
        personAddress: "",
        personPhone: "",
        designatedName: "",
        designatedOf: "",
        date: "",
        // applicantDate: "",
        // notaryDate: "",
        // personallyKnown: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimpleTitleholder",
        label: "3. Name and Address of Fee Simple Titleholder",
        value: "feeSimpleTitleholder",
      },
      {
        name: "suretyName",
        label: "5. Surety's Name'",
        value: "suretyName",
      },
      {
        name: "suretyPhone",
        label: "5. Surety's Phone Number",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5. Surety's Address",
        value: "suretyAddress",
      },

      {
        name: "suretyEmail",
        label: "5. Surety's Email'",
        value: "suretyEmail",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lenderName",
        label: "6. Lender's Name",
        value: "lenderName",
      },
      {
        name: "lenderPhone",
        label: "6. Lender's Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6. Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderEmail",
        label: "6. Lender's Email",
        value: "lenderEmail",
      },
      {
        name: "personName",
        label: "7. Designated Person Name",
        value: "personName",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designatedName",
        label: "8. Name (person who receive copy of the Lienor's notice)",
        value: "designatedName",
      },
      {
        name: "designatedOf",
        label: "8. Of (person who receive copy of the Lienor's notice)",
        value: "designatedOf",
      },
      {
        name: "date",
        label: "9. Different recording date",
        value: "date",
      },
      // {
      //   name: "applicantDate",
      //   label: "Date (Applicant)",
      //   value: "applicantDate",
      // },
      // {
      //   name: "personallyKnown",
      //   label: "Personally Known",
      //   value: "personallyKnown",
      // },

      // {
      //   name: "notaryDate",
      //   label: "Date (Notary)",
      //   value: "notaryDate",
      // },
    ],
  },
  {
    county: "Collier",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedBy: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(d) Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(d) Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.(b) Surety Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone Number",
        value: "suretyPhone",
      },

      {
        name: "amountOfBond",
        label: "5.(d) Amount of Bond: $",
        value: "amountOfBond",
      },

      {
        name: "lender",
        label: "6.(a) Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6.(c) Lender's Address",
        value: "lenderAddress",
      },

      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(b) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(c) Phone Numbers of Designated Persons",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedBy",
        label: "8.(b) Owner designates: Phone Number",
        value: "designatedBy",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Columbia",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        feeSimple: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "feeSimple",
        label: "3.(b) Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.(a) Surety Address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond",
        value: "amountOfBond",
      },

      {
        name: "suretyPhone",
        label: "5.(c) Surety Telephone No.",
        value: "suretyPhone",
      },

      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone No.",
        value: "lenderPhone",
      },

      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(a) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Telephone No.",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner designates: Telephone No.",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "DeSoto",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        bondingName: "",
        bondingPhone: "",
        bondAmount: "",
        bondingAddress: "",
        lenderName: "",
        lenderPhone: "",
        lenderAddress: "",
        designatedName: "",
        designatedPhone: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "bondingName",
        label: "Bonding Company Name",
        value: "bondingName",
      },
      {
        name: "bondingPhone",
        label: "Bonding Company Phone",
        value: "bondingPhone",
      },
      {
        name: "bondAmount",
        label: "Bond Amount",
        value: "bondAmount",
      },
      {
        name: "bondingAddress",
        label: "Bonding Company Address",
        value: "bondingAddress",
      },
      {
        name: "lenderName",
        label: "Lending Company Name",
        value: "lenderName",
      },
      {
        name: "lenderPhone",
        label: "Lending Company Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lending Company Address",
        value: "lenderAddress",
      },
      {
        name: "designatedName",
        label: "Owner Designated Name",
        value: "designatedName",
      },
      {
        name: "designatedPhone",
        label: "Owner Designated Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedAddress",
        label: "Owner Designated Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Dixie",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond $",
        value: "amountOfBond",
      },

      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "7.(b) Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name and Address",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Month/Day",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Duval",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        taxFolio: "",
        feeSimpleTitleholder: "",
        feeSimpleName: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        suretyFax: "",
        amountOfBond: "",
        loanName: "",
        loanAddress: "",
        loanPhone: "",
        loanFax: "",
        personName: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        designatedName: "",
        designatedAddress: "",
        designatedPhone: "",
        designatedFax: "",
        date: "",
        // stateOF: "",
        // countyOf: "",
        // commissionExpires: "",
        // personallyKnown: "",
        // producedIdentification: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "taxFolio",
        label: "Tax Folio No.",
        value: "taxFolio",
      },
      {
        name: "feeSimpleTitleholder",
        label: "Fee Simple Titleholder (if other than owner)",
        value: "feeSimpleTitleholder",
      },
      {
        name: "feeSimpleName",
        label: "Fee Simple Name",
        value: "feeSimpleName",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee Simple Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety (if any)",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "Surety Phone No.",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "Surety Fax No.",
        value: "suretyFax",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "loanName",
        label: "Name (Person who making a loan)",
        value: "loanName",
      },
      {
        name: "loanAddress",
        label: "Address (Person who making a loan)",
        value: "loanAddress",
      },
      {
        name: "loanPhone",
        label: "Phone No. (Person who making a loan)",
        value: "loanPhone",
      },
      {
        name: "loanFax",
        label: "Fax No. (Person who making a loan)",
        value: "loanFax",
      },
      {
        name: "personName",
        label: "Designated Person Name",
        value: "personName",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone No.",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "Designated Person Fax No.",
        value: "personFax",
      },
      {
        name: "designatedName",
        label: "Name (person who receive copy of the Lienor's notice)",
        value: "designatedName",
      },
      {
        name: "designatedAddress",
        label: "Address (person who receive copy of the Lienor's notice)",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "Phone No. (person who receive copy of the Lienor's notice)",
        value: "designatedPhone",
      },
      {
        name: "designatedFax",
        label: "Fax No. (person who receive copy of the Lienor's notice)",
        value: "designatedFax",
      },
      {
        name: "date",
        label: "Different recording date",
        value: "date",
      },
      // {
      //   name: "stateOF",
      //   label: "State of",
      //   value: "stateOF",
      // },
      // {
      //   name: "countyOf",
      //   label: "County of",
      //   value: "countyOf",
      // },
      // {
      //   name: "commissionExpires",
      //   label: "My commission expires",
      //   value: "commissionExpires",
      // },
      // {
      //   name: "personallyKnown",
      //   label: "Personally Known",
      //   value: "personallyKnown",
      // },
      // {
      //   name: "producedIdentification",
      //   label: "Produced Identification",
      //   value: "producedIdentification",
      // },
    ],
  },

  {
    county: "Escambia",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        name: "",
        address: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        lenderName: "",
        lenderAddress: "",
        personName: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "name",
        label: "Prepared By: Name",
        value: "name",
      },
      {
        name: "address",
        label: "Prepared By: Address",
        value: "address",
      },
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5. Surety Name, Address and Phone",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lenderName",
        label: "6. Lender Name",
        value: "lenderName",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address and Phone",
        value: "lenderAddress",
      },
      {
        name: "personName",
        label: "7. Designated personName Name",
        value: "personName",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Address and Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner designates: Name",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner designates...Of",
        value: "designatedOf",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Flagler",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(b) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(c) Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner designates: Name",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner designates...Of",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Month/Day",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Franklin",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personAddress: "",
        designated: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit #",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Name & Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "Surety Name & Address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "Amount: $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },

      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "Owner designates",
        value: "designated",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Gadsden",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        lender: "",
        person: "",
        designated: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(d) Name and Address of Fee Simple Title Holder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5. Bond Amount: $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name and Address",
        value: "lender",
      },
      {
        name: "person",
        label: "7. Designated Person Name and Address",
        value: "person",
      },
      {
        name: "designated",
        label: "8. Owner designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner designates: Address",
        value: "designated",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Gilchrist",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        descOfProperty: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "descOfProperty",
        label: "1. Description of Property",
        value: "descOfProperty",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.(b) Surety Address",
        value: "suretyAddress",
      },

      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(d) Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.(a) Lender Address",
        value: "lender",
      },

      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(b) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(c) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner designates...Of",
        value: "designated",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Glades",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.(a) Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond",
        value: "amountOfBond",
      },

      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(a) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8.(a) Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },

      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Gulf",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "feeSimple",
        label: "Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "Payment Bond Surety",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "Surety Phone",
        value: "suretyPhone",
      },

      {
        name: "amountOfBond",
        label: "Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },

      {
        name: "lenderPhone",
        label: "Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },

      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Hamilton",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        city: "",
        lender: "",
        lenderContact: "",
        lenderAddress: "",
        lenderCity: "",
        lenderState: "",
        lenderZip: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        designated: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "city",
        label: "City of",
        value: "city",
      },
      {
        name: "lender",
        label: "Lender Name",
        value: "lender",
      },
      {
        name: "lenderContact",
        label: "Lender Contact",
        value: "lenderContact",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderCity",
        label: "Lender City",
        value: "lenderCity",
      },

      {
        name: "lenderState",
        label: "Lender State",
        value: "lenderState",
      },

      {
        name: "lenderZip",
        label: "Lender Zip Code",
        value: "lenderZip",
      },
      {
        name: "lenderPhone",
        label: "Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Hardee",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Name and Address of Fee Simple TitleHolder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond $",
        value: "amountOfBond",
      },

      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates: Name and Address",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },

      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Hendry",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        strap: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        person: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit #",
        value: "permitNumber",
      },
      {
        name: "strap",
        label: "Strap #",
        value: "strap",
      },
      {
        name: "feeSimple",
        label: "Fee Simple TitleHolder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee Simple TitleHolder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Name and Address of Lender",
        value: "lender",
      },

      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Hernando",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(b) Name and Address of Fee Simple TitleHolder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(b) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(c) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "7.(b) Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Highlands",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        name: "",
        address: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "name",
        label: "Prepared By: Name",
        value: "name",
      },
      {
        name: "address",
        label: "Prepared By: Address",
        value: "address",
      },
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Name and Address of Fee Simple TitleHolder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(b) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(c) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.(a) Lender's Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.(a) Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Hillsborough",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        amountOfBond: "",
        suretyAddress: "",
        suretyPhone: "",
        lender: "",
        contact: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "contact",
        label: "6. Lender Contact",
        value: "contact",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Holmes",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        suretyFax: "",
        suretyEmail: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        lenderFax: "",
        lenderEmail: "",
        person: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        personEmail: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        designatedFax: "",
        designatedEmail: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "5. Surety Fax",
        value: "suretyFax",
      },
      {
        name: "suretyEmail",
        label: "5. Surety Email",
        value: "suretyEmail",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderFax",
        label: "6. Lender Fax",
        value: "lenderFax",
      },
      {
        name: "lenderEmail",
        label: "6. Lender Email",
        value: "lenderEmail",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "7. Designated Person Fax",
        value: "personFax",
      },

      {
        name: "personEmail",
        label: "7. Designated Person Email",
        value: "personEmail",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedFax",
        label: "8. Owner Designates: Fax",
        value: "designatedFax",
      },

      {
        name: "designatedEmail",
        label: "8. Owner Designates: Email",
        value: "designatedEmail",
      },
    ],
  },
  {
    county: "Indian River",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit #",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "4.(d) Fee Simple Title Holder Name and Address",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "6.(a) Surety Name & Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "6.(b) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "6.(b) Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "7.(a) Lender Name & Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "7.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "8.(a) Designated Person Name & Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "8.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "8.(b) Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "9.(a) Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "9.(a) Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "9.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "10. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Jackson",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        preparedName: "",
        preparedAddress: "",
        returnName: "",
        returnAddress: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedPhone: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "returnName",
        label: "Return to: Name",
        value: "returnName",
      },
      {
        name: "returnAddress",
        label: "Return to: Address",
        value: "returnAddress",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Jefferson",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        preparedName: "",
        preparedAddress: "",
        feeSimple: "",
        feeSimpleAddress: "",
        lender: "",
        lenderAddress: "",
        person: "",
        personAddress: "",
        designated: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "feeSimple",
        label: "4. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "4. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Lafayette",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        propertyDesc: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "propertyDesc",
        label: "1. Description of Property",
        value: "propertyDesc",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.(b) Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(d) Amount of Bond: $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.(a) Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(b) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(c) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Lake",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        returnName: "",
        returnAddress: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "returnName",
        label: "Return to: Name",
        value: "returnName",
      },
      {
        name: "returnAddress",
        label: "Return to: Address",
        value: "returnAddress",
      },
      {
        name: "permitNumber",
        label: "Permit No",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Lee",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        suretyFax: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        lenderFax: "",
        person: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        designatedFax: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "5. Surety Fax",
        value: "suretyFax",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderFax",
        label: "6. Lender Fax",
        value: "lenderFax",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "7. Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedFax",
        label: "8. Owner Designates: Fax",
        value: "designatedFax",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Leon",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        propertyDesc: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        suretyFax: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        lenderFax: "",
        person: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        designatedFax: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No",
        value: "permitNumber",
      },
      {
        name: "propertyDesc",
        label: "1. Description of Property",
        value: "propertyDesc",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "5. Surety Fax",
        value: "suretyFax",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderFax",
        label: "6. Lender Fax",
        value: "lenderFax",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "7. Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedFax",
        label: "8. Owner Designates: Fax",
        value: "designatedFax",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Levy",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        propertyDesc: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "propertyDesc",
        label: "1. Description of Property",
        value: "propertyDesc",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.(b) Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(d) Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.(a) Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(b) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(c) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Liberty",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimpleTitleholder: "",
        feeSimpleName: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        suretyFax: "",
        amountOfBond: "",
        loanName: "",
        loanAddress: "",
        loanPhone: "",
        loanFax: "",
        personName: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        designatedName: "",
        designatedAddress: "",
        designatedPhone: "",
        designatedFax: "",
        date: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimpleTitleholder",
        label: "Fee Simple Titleholder",
        value: "feeSimpleTitleholder",
      },
      {
        name: "feeSimpleName",
        label: "Fee Simple Name",
        value: "feeSimpleName",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee Simple Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety (if any)",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond $",
        value: "amountOfBond",
      },

      {
        name: "suretyPhone",
        label: "Surety Phone No.",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "Surety Fax No.",
        value: "suretyFax",
      },
      {
        name: "loanName",
        label: "Name (Person who making a loan)",
        value: "loanName",
      },
      {
        name: "loanAddress",
        label: "Address (Person who making a loan)",
        value: "loanAddress",
      },
      {
        name: "loanPhone",
        label: "Phone No. (Person who making a loan)",
        value: "loanPhone",
      },
      {
        name: "loanFax",
        label: "Fax No. (Person who making a loan)",
        value: "loanFax",
      },
      {
        name: "personName",
        label: "Designated Person Name",
        value: "personName",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone No.",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "Designated Person Fax No.",
        value: "personFax",
      },
      {
        name: "designatedName",
        label: "Name (person who receive copy of the Lienor's notice)",
        value: "designatedName",
      },
      {
        name: "designatedAddress",
        label: "Address (person who receive copy of the Lienor's notice)",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "Phone No. (person who receive copy of the Lienor's notice)",
        value: "designatedPhone",
      },
      {
        name: "designatedFax",
        label: "Fax No. (person who receive copy of the Lienor's notice)",
        value: "designatedFax",
      },
      {
        name: "date",
        label: "Expiration Date",
        value: "date",
      },
    ],
  },
  {
    county: "Madison",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        lender: "",
        contact: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "lender",
        label: "Lender Name",
        value: "lender",
      },
      {
        name: "contact",
        label: "Contact",
        value: "contact",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Manatee",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        suretyFax: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Name and Address of Fee Simple TitleHolder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5.(b) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5.(c) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "5.(c) Surety Fax",
        value: "suretyFax",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates: Name and Address",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Marion",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple TitleHolder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple TitleHolder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(c) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name, Address and Phone",
        value: "lender",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },

      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Martin",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit #",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Fee Simple TitleHolder Name and Address",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "Bond Amount",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Lender's Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },

      {
        name: "designated",
        label: "Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Miami-Dade",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        lender: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5. Surety Name, Address and Phone",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender's Name and Address",
        value: "lender",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Monroe",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        preparedName: "",
        preparedAddress: "",
        preparedZip: "",
        feeSimple: "",
        feeSimpleAddress: "",
        amountOfBond: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit",
        value: "permitNumber",
      },
      {
        name: "preparedName",
        label: "Prepared by: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared by: Address",
        value: "preparedAddress",
      },
      {
        name: "preparedZip",
        label: "Prepared by: Zip",
        value: "preparedZip",
      },
      {
        name: "feeSimple",
        label: "3.(c) Fee Simple TitleHolder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.(c) Fee Simple TitleHolder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "amountOfBond",
        label: "4.(c) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(b) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5.(c) Surety Address",
        value: "suretyAddress",
      },
      {
        name: "lender",
        label: "6.(a) Lender's Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6.(c) Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.(a) Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Nassau",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        feeSimpleTitleholder: "",
        feeSimpleAddress: "",
        feeSimplePhone: "",
        feeSimpleFax: "",
        surety: "",
        suretyPhone: "",
        suretyFax: "",
        suretyAddress: "",
        amountOfBond: "",
        lenderName: "",
        lenderAddress: "",
        lenderPhone: "",
        lenderFax: "",
        month: "",
        day: "",
        year: "",
      },
    ],
    fields: [
      {
        name: "feeSimpleTitleholder",
        label: "Fee Simple Title holder",
        value: "feeSimpleTitleholder",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee simple Title holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "feeSimplePhone",
        label: "Fee simple Title holder Phone",
        value: "feeSimplePhone",
      },
      {
        name: "feeSimpleFax",
        label: "Fee simple Title holder Fax",
        value: "feeSimpleFax",
      },
      {
        name: "surety",
        label: "Surety",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "Surety Fax",
        value: "suretyFax",
      },
      {
        name: "suretyAddress",
        label: "Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lenderName",
        label: "Lender's Name",
        value: "lenderName",
      },
      {
        name: "lenderAddress",
        label: "Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderFax",
        label: "Lender's Fax",
        value: "lenderFax",
      },
      {
        name: "month",
        label: "Expiration Month",
        value: "month",
      },
      {
        name: "day",
        label: "Expiration Day",
        value: "day",
      },
      {
        name: "year",
        label: "Expiration Year",
        value: "year",
      },
    ],
  },
  {
    county: "Okaloosa",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        lenderName: "",
        lenderPhone: "",
        lenderAddress: "",
        lenderCity: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit #",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "Surety's Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "Surety's Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "Surety's Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond: $",
        value: "amountOfBond",
      },
      {
        name: "lenderName",
        label: "Lender's Name",
        value: "lenderName",
      },
      {
        name: "lenderPhone",
        label: "Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderCity",
        label: "Lender's City",
        value: "lenderCity",
      },
      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Okeechobee",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderFax: "",
        person: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Name of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "Address of Fee Simple Titleholder",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "Surety's Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond: $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Lender (Name and Address)",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderFax",
        label: "Lender's Fax",
        value: "lenderFax",
      },
      {
        name: "person",
        label: "Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "Designated Person Fax",
        value: "personFax",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "Owner Designates...of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Orange",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        preparedName: "",
        preparedAddress: "",
        preparedZip: "",
        returnName: "",
        returnAddress: "",
        returnZip: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedPhone: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "preparedZip",
        label: "Prepared By: Zip",
        value: "preparedZip",
      },
      {
        name: "returnName",
        label: "Return to: Name",
        value: "returnName",
      },
      {
        name: "returnAddress",
        label: "Return to: Address",
        value: "returnAddress",
      },
      {
        name: "returnZip",
        label: "Return to: Zip",
        value: "returnZip",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Osceola",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        preparedName: "",
        preparedAddress: "",
        preparedZip: "",
        returnName: "",
        returnAddress: "",
        returnZip: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedPhone: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "preparedZip",
        label: "Prepared By: Zip",
        value: "preparedZip",
      },
      {
        name: "returnName",
        label: "Return to: Name",
        value: "returnName",
      },
      {
        name: "returnAddress",
        label: "Return to: Address",
        value: "returnAddress",
      },
      {
        name: "returnZip",
        label: "Return to: Zip",
        value: "returnZip",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },

      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Palm Beach",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        preparedName: "",
        preparedAddress: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.c Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "amountOfBond",
        label: "5.a Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5.b Surety's Phone",
        value: "suretyPhone",
      },
      {
        name: "surety",
        label: "5.c Surety Name and Address",
        value: "surety",
      },
      {
        name: "lender",
        label: "6.a Lender's Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.a Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.b Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.a Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.b Phone Number of Designated Person",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.a Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.a Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.b Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Pasco",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Name of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Address of Fee Simple Titleholder",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5. Surety's Telephone No.",
        value: "suretyPhone",
      },

      {
        name: "lender",
        label: "6. Lender's Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6 Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6 Lender's Telephone No.",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Telephone Number of Designated Person",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Pinellas",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.b Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.a Surety: Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.b Surety: Telephone No.",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.c Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.a Lender: Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.b Lender: Telephone No.",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.a Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.b Designated Person Telephone No.",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8 Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8 Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.a Owner Designates: Telephone No.",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Polk",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.c Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.a Surety: Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.b Surety: Phone Number",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.c Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.a Lender: Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.b Lender's phone number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.a Designated Person: Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.b Designated Person: Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8 Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8 Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.a Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Putnam",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.c Name of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.c Address of Fee Simple Titleholder",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5.a Surety: Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.a Surety: Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5.b Surety: Phone Number",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.c Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.a Lender: Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.a Lender: Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.b Lender's phone number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.a Designated Person: Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.a Designated Person: Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.b Designated Person: Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.a Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.a Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.b Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Santa Rosa",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        preparedName: "",
        preparedAddress: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Name of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3 Address of Fee Simple Titleholder",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5. Surety: Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety: Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5. Surety: Phone Number",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender: Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender: Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender: Phone Number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7. Designated Person: Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person: Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person: Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Sarasota",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        amountOfBond: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "surety",
        label: "5. Surety: Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5. Surety: Phone Number",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "5. Surety: Address",
        value: "suretyAddress",
      },
      {
        name: "lender",
        label: "6. Lender: Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6. Lender: Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "6. Lender: Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7. Designated Person: Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7. Designated Person: Phone Number",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person: Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Seminole",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        preparedName: "",
        preparedAddress: "",
        preparedZip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "preparedZip",
        label: "Prepared By: Zip",
        value: "preparedZip",
      },
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },

      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone",
        value: "lenderPhone",
      },

      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "8. Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8. Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },

  {
    county: "St Johns",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        dateOfRecording: "",
        feeSimpleTitleholder: "",
        feeSimpleAddress: "",
        surety: "",
        amountOfBond: "",
        suretyAddress: "",
        suretyPhone: "",
        suretyFax: "",
        lenderName: "",
        lenderPhone: "",
        lenderAddress: "",
        lenderFax: "",
        personName: "",
        personPhone: "",
        personAddress: "",
        personFax: "",
        designatedName: "",
        designatedOf: "",
        designatedPhone: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "dateOfRecording",
        label: "Date of recording",
        value: "dateOfRecording",
      },
      {
        name: "feeSimpleTitleholder",
        label: "Fee simple title holder",
        value: "feeSimpleTitleholder",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee simple Title holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety's name",
        value: "surety",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "suretyAddress",
        label: "Surety's Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "Surety's Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "Surety's Fax",
        value: "suretyFax",
      },

      {
        name: "lenderName",
        label: "Lender's Name",
        value: "lenderName",
      },
      {
        name: "lenderPhone",
        label: "Lender's Phone",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lender's Address",
        value: "lenderAddress",
      },

      {
        name: "lenderFax",
        label: "Lender's Fax",
        value: "lenderFax",
      },
      {
        name: "personName",
        label: "Designated person name",
        value: "personName",
      },
      {
        name: "personPhone",
        label: "Designated person phone",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "Designated person address",
        value: "personAddress",
      },
      {
        name: "personFax",
        label: "Designated person fax",
        value: "personFax",
      },
      {
        name: "designatedName",
        label: "Name (person who receive copy of the Lienor's notice)",
        value: "designatedName",
      },
      {
        name: "designatedOf",
        label: "Of (person who receive copy of the Lienor's notice)",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "Phone (person who receive copy of the Lienor's notice)",
        value: "designatedPhone",
      },
    ],
  },
  {
    county: "St Lucie",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        amountOfBond: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Fee Simple Titleholder: Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee Simple Titleholder: Address",
        value: "feeSimpleAddress",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "surety",
        label: "Surety: Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "Surety: Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "Surety: Phone Number",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "Lender: Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "Lender: Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lender: Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "Designated Person: Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "Designated Person: Phone Number",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "Designated Person: Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Sumter",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        preparedName: "",
        preparedAddress: "",
        permitNumber: "",
        secRange: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        suretyFax: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        lenderFax: "",
        person: "",
        personAddress: "",
        personPhone: "",
        personFax: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        designatedFax: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "preparedName",
        label: "Prepared By: Name",
        value: "preparedName",
      },
      {
        name: "preparedAddress",
        label: "Prepared By: Address",
        value: "preparedAddress",
      },
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "secRange",
        label: "Sec Twp Rng",
        value: "secRange",
      },
      {
        name: "feeSimple",
        label: "3.c Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.c Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5.a Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.a Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5.b Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5.c Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "suretyFax",
        label: "5.d Surety Fax Number",
        value: "suretyFax",
      },
      {
        name: "lender",
        label: "6.a Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.a Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.b Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "lenderFax",
        label: "6.c Lender Fax Number",
        value: "lenderFax",
      },
      {
        name: "person",
        label: "7.a Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.a Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.b Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "personFax",
        label: "7.c Designated Person Fax Number",
        value: "personFax",
      },
      {
        name: "designated",
        label: "8.a Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8.a Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8.b Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "designatedFax",
        label: "8.c Owner Designates: Fax Number",
        value: "designatedFax",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Suwannee",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        feeSimplePhone: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit #",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.c Fee Simple Title Holder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.c Fee Simple Title Holder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "feeSimplePhone",
        label: "3.c Fee Simple Title Holder Phone",
        value: "feeSimplePhone",
      },
      {
        name: "surety",
        label: "5.a Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.a Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5.b Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6. Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender Phone Number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Taylor",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        lenderAddress: "",
        person: "",
        personPhone: "",
        personAddress: "",
        designated: "",
        designatedPhone: "",
        designatedAddress: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "Surety Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "Surety Phone #",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "Bond Amt",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Lender Name",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "Lender Phone #",
        value: "lenderPhone",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },

      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone #",
        value: "personPhone",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone #",
        value: "designatedPhone",
      },
      {
        name: "designatedAddress",
        label: "Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Union",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
        expirationYear: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.c Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3.c Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5.a Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5.a Surety Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5.b Surety Phone Number",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.c Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.a Lender's Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6.a Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6.b Lender's Phone Number",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.a Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7.a Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7.b Designated Person Phone Number",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.a Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.a Owner Designates...of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.b Owner Designates: Phone Number",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
      {
        name: "expirationYear",
        label: "9. Expiration Year",
        value: "expirationYear",
      },
    ],
  },
  {
    county: "Volusia",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        surety: "",
        suretyPhone: "",
        amountOfBond: "",
        lender: "",
        lenderPhone: "",
        person: "",
        personPhone: "",
        designated: "",
        designatedOf: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3.(c) Name and Address of Fee Simple Titleholder",
        value: "feeSimple",
      },
      {
        name: "surety",
        label: "5.(a) Surety Name and Address",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "5.(b) Surety Phone",
        value: "suretyPhone",
      },
      {
        name: "amountOfBond",
        label: "5.(c) Amount of Bond $",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "6.(a) Lender Name and Address",
        value: "lender",
      },
      {
        name: "lenderPhone",
        label: "6.(b) Lender Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7.(a) Designated Person Name and Address",
        value: "person",
      },
      {
        name: "personPhone",
        label: "7.(b) Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8.(a) Owner Designates",
        value: "designated",
      },
      {
        name: "designatedOf",
        label: "8.(a) Owner Designates...Of",
        value: "designatedOf",
      },
      {
        name: "designatedPhone",
        label: "8.(b) Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Wakulla",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyAddress: "",
        amountOfBond: "",
        suretyPhone: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit Number",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "3. Fee Simple Titleholder Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "3. Fee Simple Titleholder Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "5. Surety Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "5. Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "suretyPhone",
        label: "5. Surety Telephone No.",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6. Lender's Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender's Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender's Telephone No.",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7. Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person Telephone No.",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Telephone No.",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Walton",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        permitNumber: "",
        feeSimple: "",
        feeSimpleAddress: "",
        surety: "",
        suretyPhone: "",
        suretyAddress: "",
        amountOfBond: "",
        lender: "",
        lenderAddress: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "permitNumber",
        label: "Permit No.",
        value: "permitNumber",
      },
      {
        name: "feeSimple",
        label: "Fee Simple Titleholder: Name",
        value: "feeSimple",
      },
      {
        name: "feeSimpleAddress",
        label: "Fee Simple Titleholder: Address",
        value: "feeSimpleAddress",
      },
      {
        name: "surety",
        label: "Surety Bond Name",
        value: "surety",
      },
      {
        name: "suretyPhone",
        label: "Surety Bond Phone",
        value: "suretyPhone",
      },
      {
        name: "suretyAddress",
        label: "Surety Bond Address",
        value: "suretyAddress",
      },
      {
        name: "amountOfBond",
        label: "Amount of Bond",
        value: "amountOfBond",
      },
      {
        name: "lender",
        label: "Lender Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "Lender Address",
        value: "lenderAddress",
      },
      {
        name: "person",
        label: "Designated Person Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "Designated Person Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "Designated Person Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "Owner Designates: Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "Expiration Date",
        value: "expirationDate",
      },
    ],
  },
  {
    county: "Washington",
    values: [
      {
        streetNumber: "",
        unitNumber: "",
        streetName: "",
        zip: "",
        surety: "",
        suretyAddress: "",
        suretyPhone: "",
        lender: "",
        lenderAddress: "",
        lenderPhone: "",
        person: "",
        personAddress: "",
        personPhone: "",
        designated: "",
        designatedAddress: "",
        designatedPhone: "",
        expirationDate: "",
      },
    ],
    fields: [
      {
        name: "surety",
        label: "5. Surety: Name",
        value: "surety",
      },
      {
        name: "suretyAddress",
        label: "5. Surety: Full Address",
        value: "suretyAddress",
      },
      {
        name: "suretyPhone",
        label: "5. Surety: Phone",
        value: "suretyPhone",
      },
      {
        name: "lender",
        label: "6. Lender: Name",
        value: "lender",
      },
      {
        name: "lenderAddress",
        label: "6. Lender: Address",
        value: "lenderAddress",
      },
      {
        name: "lenderPhone",
        label: "6. Lender: Phone",
        value: "lenderPhone",
      },
      {
        name: "person",
        label: "7. Designated Person: Name",
        value: "person",
      },
      {
        name: "personAddress",
        label: "7. Designated Person: Full Address",
        value: "personAddress",
      },
      {
        name: "personPhone",
        label: "7. Designated Person: Phone",
        value: "personPhone",
      },
      {
        name: "designated",
        label: "8. Owner Designates: Name",
        value: "designated",
      },
      {
        name: "designatedAddress",
        label: "8. Owner Designates: Full Address",
        value: "designatedAddress",
      },
      {
        name: "designatedPhone",
        label: "8. Owner Designates: Phone",
        value: "designatedPhone",
      },
      {
        name: "expirationDate",
        label: "9. Expiration Date",
        value: "expirationDate",
      },
    ],
  },
];
